import { publicConfig } from '../../../../functions/src/shared/config';

export const paths = {
	DEFAULT: {
		ROOT: '',
		SIGN_IN: 'sign-in',
		SIGN_OUT: 'sign-out',
		VERIFY_PHONE: 'verify-phone',
		FORGOT_PASSWORD: 'forgot-password',
		AUTH_ACTIONS: 'auth-actions',
		RESET_PASSWORD: 'reset-password',
		INVITE: 'i/:inviteId',
		HANDOVER: {
			ROOT: publicConfig.creditBuilder.handoverPath.split('/')[0],
			INVITE: publicConfig.creditBuilder.handoverPath.split('/')[1],
			DATA_TRANSFER: 'data-transfer',
		},
		AVENUE_LIVING: 'avenueliving',
		CHECKOUT: 'checkout',
		PAYMENT_STATUS: 'payment-status',
		AGREEMENT: {
			ROOT: 'agreement',
			LINE_OF_CREDIT: 'line-of-credit',
			PAD: 'pad',
		},
	},

	REGISTRATION: {
		ROOT: 'registration',
		USER: {
			ROOT: 'user',
			SIGN_UP: 'sign-up',
			SIGN_UP_CREDIT_BUILDER: 'sign-up/credit-builder',
			SIGN_UP_REFERRAL_PARTNER: 'sign-up/r/:referralCode',
			VERIFY_PHONE: 'verify-phone',
			VERIFY_EMAIL: 'verify-email',
		},
		RESIDENCY: {
			ROOT: 'address',
			INPUT_ZIP_CODE: 'input-zip-code',
			SELECT_PROPERTY: 'select-property',
			SELECT_UNIT: 'select-unit',
			LANDLORD_CONNECTED: 'landlord-connected',
			MANUAL_ADDRESS: 'manual-onboarding',
			RECOMMENDED_UNITS: 'recommended-units',
		},
		COUNTRY_NOT_SUPPORTED: 'country-not-supported',
		ONBOARDING_SURVEY: 'onboarding-survey',
		LANDLORD_PAYMENT_METHOD: 'landlord-payment-method',
		BANK: {
			ROOT: 'bank',
			CONNECT_BANK: 'connect-bank',
			CONNECT_CARD: 'connect-card',
			AWAITING_TRANSACTIONS: 'verify-transactions',
			MANUAL_ENTER_BANK_DETAILS: 'enter-bank-details',
			MANUAL_START_VERIFICATION: 'start-verification',
			MANUAL_PENDING_VERIFICATION: 'pending-verification',
			CANNOT_VALIDATE_EARNINGS: 'cannot-validate-earnings',
		},
		CONFIGURE: {
			ROOT: 'configure',
			SELECT_RENT_AMOUNT: 'select-rent-amount',
			ROOMMATES_RENT_AMOUNT_CONFIRM: 'roommates-rent-amount-confirm',
			SELECT_SCHEDULE: 'select-schedule',
			CONFIRM_CUSTOM_RENT: 'confirm-custom-rent',
		},
		ONBOARDING_SUCCESS: {
			ROOT: 'onboarding-success',
			LANDLORD: 'landlord',
			RESCHEDULE: 'reschedule',
			SUPPORT: 'support',
		},

		CUSTOM_RENT_CREDIT_BUILDER: 'confirm-credit-builder',
		CUSTOM_RENT_WAITLIST: 'waitlist',

		CREDIT_BUILDER: {
			ROOT: 'credit-builder',
			SETUP: {
				ROOT: 'setup',
				BANK: {
					ROOT: 'bank',
					CONNECT_BANK: 'connect-bank',
					CONNECT_CARD: 'connect-card',
					AWAITING_TRANSACTIONS: 'verify-transactions',
					MANUAL_ENTER_BANK_DETAILS: 'enter-bank-details',
					MANUAL_START_VERIFICATION: 'start-verification',
					MANUAL_PENDING_VERIFICATION: 'pending-verification',
					CANNOT_VALIDATE_EARNINGS: 'cannot-validate-earnings',
					SELECT_PAYMENT_METHOD: 'select-payment-method',
					ADD_DEBIT_CARD: 'add-debit-card',
					ADD_CREDIT_CARD: 'add-credit-card',
				},
			},
			SUCCESS: 'success',
			HISTORY_REPORTING: 'history-reporting',
			SUBSCRIPTION_TYPE: 'subscription-type',
			PAYMENT_COMPLETED: 'payment-completed',
		},
		PAYMENT_PORTAL: 'payment-portal',
		DIRECT_DEBIT: {
			ROOT: 'direct-debit',
			SETUP: 'setup',
			CARD_DETAILS: 'card-details',
			SUCCESS: 'success',
		},
	},

	ACCOUNT: {
		ROOT: 'account',
		DASHBOARD: 'dashboard',
		SETTINGS: 'settings',
		NSF: 'nsf',
		DISABLE_CUSTOM_RENT: 'disable-custom-rent',
		CHANGE_RENT_AMOUNT: 'change-rent-amount',
		UPLOAD_STATEMENTS: 'upload',
		MICRO_DEPOSIT_VERIFICATION: 'micro-deposit-verification',
		BANK_VERIFICATION_PENDING: 'bank-verification-pending',
		PARTNER: 'partner',
		CUSTOM_RENT_CREDIT_REPORTING: 'custom-rent-credit-reporting',
		DID_YOU_MOVE_OUT: 'did-you-move-out',
		INSTA_MONEY: {
			ROOT: 'instamoney',
			REQUEST: 'request',
			NOT_ELIGIBLE: 'not-eligible',
			TIP: 'tip-us',
			NO_TIP_REASON: 'no-tip-reason',
			USE: 'how-will-you-use',
			CHOOSE_PAYBACK_DATE: 'choose-payback-dates',
			CONFIRM_PAYBACK_DATE: 'confirm-payback-dates',
		},
		HISTORY: {
			ROOT: 'history',
			CUSTOM_RENT: 'customrent',
			INSTA_MONEY: 'instamoney',
			CREDIT_BUILDER: 'credit-builder',
		},
		CONFIGURE: {
			ROOT: 'configure',
			SELECT_SCHEDULE: 'select-schedule',
			CONFIRM_CUSTOM_RENT: 'confirm-custom-rent',
			ENABLE_CUSTOM_RENT: 'enable-custom-rent',
			CUSTOM_RENT_LOC: 'custom-rent-reporting',
		},
		BANK: {
			ROOT: 'bank',
			CONNECT_BANK: 'connect-bank',
			CONNECT_CARD: 'connect-card',
			AWAITING_TRANSACTIONS: 'verify-transactions',
			AWAITING_RECONNECT_TRANSACTIONS: 'verify-reconnect-transactions',
			MANUAL_ENTER_BANK_DETAILS: 'enter-bank-details',
			MANUAL_START_VERIFICATION: 'start-verification',
			MANUAL_PENDING_VERIFICATION: 'pending-verification',
			CANNOT_VALIDATE_EARNINGS: 'cannot-validate-earnings',
			RECONNECT_BANK: 'reconnect-bank',
			SELECT_PAYMENT_METHOD: 'select-payment-method',
			ADD_CREDIT_CARD: 'add-credit-card',
		},
		RESIDENCY: {
			ROOT: 'residency',
			INPUT_ZIP_CODE: 'input-zip-code',
			SELECT_PROPERTY: 'select-property',
			SELECT_UNIT: 'select-unit',
			LANDLORD_CONNECTED: 'landlord-connected',
			MANUAL_ADDRESS: 'manual-onboarding',
			RECOMMENDED_UNITS: 'recommended-units',
		},
		CREDIT_BUILDER: {
			ROOT: 'credit-builder',
			SETUP: 'setup',
			SUCCESS: 'success',
			PAYMENT_COMPLETED: 'payment-completed',
			SUBSCRIPTION_TYPE: 'subscription-type',
			CONFIRM_RENT_PAYMENT: 'confirm-rent-payment',
			HISTORY_REPORTING: 'history-reporting',
			INDIRECT_LAST_MONTH_OF_LEASE: 'lease-update',
			PAST_RESIDENCY_DETAILS: 'past-residency-details',
			PAYMENT_SUMMARY: 'payment-summary',
		},
		DIRECT_DEBIT: {
			ROOT: 'direct-debit',
			SETUP: 'setup',
			CARD_DETAILS: 'card-details',
			SUCCESS: 'success',
			CHARGE: ':ptid/charge',
			CHARGE_SUCCESS: 'charge-success',
		},
		PAYMENT_PORTAL: 'payment-portal',
		UPLOAD_LEASE_AGREEMENTS: 'upload-lease-agreements',
	},
};

export const ADMIN_USERS_URL = '/admin/users';

const linksProxyHandler: (prefix: string) => ProxyHandler<typeof paths> = (prefix) => ({
	get(target, p) {
		// eslint-disable-next-line
		const value = Reflect.get(target, p);

		if (typeof value === 'object' && 'ROOT' in value) {
			// eslint-disable-next-line
			return new Proxy(value, linksProxyHandler(`${prefix}${value.ROOT}${value.ROOT ? '/' : ''}`));
		}

		if (p === 'ROOT') {
			if (prefix.length === 1) return prefix;
			return prefix.slice(0, prefix.length - 1);
		}

		return typeof value === 'string' && `${prefix}${value}`;
	},
});

export const links = new Proxy(paths, linksProxyHandler('/'));
