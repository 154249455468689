import React from 'react';
import styled, { keyframes } from 'styled-components';

import { colors } from '../../variables';

const load = keyframes`
	0% {
	transform: rotate(0deg);
	}
	
	100% {
	transform: rotate(360deg);
	}
`;

const Circle = styled.div`
	position: absolute;
	top: 20%;
	left: 20%;
	width: 60%;
	height: 60%;
	border-radius: 50%;
	background: linear-gradient(to right, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
	animation: ${load} 1.4s infinite linear;
	transform: translateZ(0);

	&::before {
		width: 50%;
		height: 50%;
		background: ${colors.white};
		border-radius: 100% 0 0 0;
		position: absolute;
		top: 0;
		left: 0;
		content: '';
	}

	&::after {
		background: ${colors.teal};
		width: 75%;
		height: 75%;
		border-radius: 50%;
		content: '';
		margin: auto;
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
	}
`;

const Wrapper = styled.div`
	position: relative;
	background-color: ${colors.teal};
	border-radius: 50%;
	height: 3.2rem;
	width: 3.2rem;
`;

export const Element = ({ className }: { className?: string }) => (
	<Wrapper className={className}>
		<Circle />
	</Wrapper>
);

export const Loader = styled(Element)``;

export const LoaderCentered = styled(Element)`
	margin: 0 auto;
`;
