// @ts-expect-error custom url
import langEnCaUrl from 'url:../../../../lang/en-CA.json.compiled';
// @ts-expect-error custom url
import langFrCaUrl from 'url:../../../../lang/fr-CA.json.compiled';

import { allUserLocales, UserLocale } from '../../../../../functions/src/shared/user';

export const LANGUAGE_KEY = 'lang';
export const localesProviderService = {
	localeUrls: {
		'en-CA': langEnCaUrl,
		'fr-CA': langFrCaUrl,
	} as Record<UserLocale, string>,
	sanitizeLanguage(language: string): UserLocale {
		const localeLower = language.toLowerCase();

		const matchingLocale = allUserLocales.find(
			(availableLocale) => availableLocale.toLocaleLowerCase() === localeLower,
		);
		if (matchingLocale) {
			return matchingLocale;
		}

		if (localeLower.startsWith('en')) {
			return UserLocale.EnglishCa;
		}
		if (localeLower.startsWith('fr')) {
			return UserLocale.FrenchCa;
		}

		return UserLocale.EnglishCa;
	},
	resolveLocale(param: {
		storageLocale: string | null | undefined;
		locale: UserLocale | undefined;
		userSettingsLocale: UserLocale | null | undefined;
		searchParamsLocale: string | null;
	}): UserLocale | undefined {
		if (param.searchParamsLocale) {
			return this.sanitizeLanguage(param.searchParamsLocale);
		}
		if (param.userSettingsLocale) {
			return this.sanitizeLanguage(param.userSettingsLocale);
		}
		if (param.storageLocale) {
			return this.sanitizeLanguage(param.storageLocale);
		}

		return this.sanitizeLanguage(navigator.language);
	},
};
