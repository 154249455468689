import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import image from 'url:../../static/shapes/support.png';

import { Box, Button, Footer, Spacing, Text } from '../../../base-ui/components';

import { links } from '../../Router/paths';

const StyledImage = styled.div`
	display: block;
	left: 0;
	position: relative;
	top: -4rem;
	width: calc(100% + 10rem);
	@media (max-width: 450px) {
		width: calc(100% + 4rem);
		top: -2rem;
	}
`;

export const PendingVerification = () => {
	return (
		<Box isColumn verticalCenter>
			<StyledImage>
				<img alt="" src={image} />
			</StyledImage>
			<Spacing $size="s" />
			<Text type="title">
				<FormattedMessage
					defaultMessage="Reviewing your transactions"
					id="pending-verification.reviewing-your-transactions"
				/>
			</Text>
			<Spacing $size="l" />

			<Text type="small">
				<FormattedMessage
					defaultMessage="Almost there! We will shortly verify your transactions and reach out if we have any questions."
					id="pending-verification.almost-there"
				/>
			</Text>
			<Footer>
				<Button linkTo={links.ACCOUNT.DASHBOARD}>
					<FormattedMessage defaultMessage="Continue" id="common.continue" />
				</Button>
			</Footer>
		</Box>
	);
};
