import React, { PropsWithChildren, ReactNode } from 'react';
import styled from 'styled-components';

import { Skeleton } from '@mui/material';

import { colors, fonts } from '../../variables';

const BlockCheckbox = styled.div<{ $selected: boolean }>`
	width: 2rem;
	height: 2rem;
	border-radius: 100%;
	border: 1px solid ${colors.green};

	position: absolute;
	top: 2.3rem;
	right: 2rem;
	cursor: pointer;
	&::after {
		display: block;
		content: '';
		position: absolute;
		width: 10px;
		height: 10px;
		top: 50%;
		left: 50%;
		transform: translate3d(-50%, -50%, 0);
		transition: background-color 150ms;
		background-color: ${({ $selected }) => ($selected ? colors.green : 'none')};
		border-radius: 100%;
	}
	&:hover::after {
		background-color: ${colors.green};
	}
	@media (max-width: 450px) {
		top: 1.6rem;
	}
`;

const Wrapper = styled.div<{ $selected: boolean }>`
	width: 100%;
	background: #ffffff;
	box-sizing: border-box;
	transition: box-shadow 150ms;
	box-shadow: 0px 0px 20px rgba(23, 23, 23, 0.05)
		${({ $selected }) => ($selected ? `, inset 0 0 0 1px ${colors.green}` : '')};
	border-radius: 10px;
	padding: 2rem;
	margin-bottom: 2rem;
	position: relative;
	@media (max-width: 450px) {
		padding: 1.5rem;
	}
`;

const BlockTitle = styled.p`
	font: ${fonts.font2};
	color: ${colors.green};
	@media (max-width: 450px) {
		font-size: 1.6rem;
		line-height: 2.4rem;
	}
`;

export const BlockText = styled.p`
	font: ${fonts.font3};
	color: ${colors.textBody};
	opacity: 0.8;
	margin-top: 0.5rem;
	@media (max-width: 450px) {
		font-size: 1.4rem;
		line-height: 2.2rem;
	}
`;

type BlockProps = PropsWithChildren<{
	selected: boolean;
	title?: ReactNode;
	line1?: ReactNode;
	line2?: ReactNode;
	line3?: ReactNode;
	onClick: () => void;
	testId?: string;
}>;

export const ScrollableBlockList = styled.div`
	max-height: 25rem;
	overflow: auto;
	margin: 0 -2rem;
	padding: 0 2rem;
	@media (max-width: 450px) {
		max-height: 25rem;
	}
`;

export const Block = ({ title, selected, onClick, testId, line1, line2, line3, children }: BlockProps) => {
	const hasSubText = line1 || line2 || line3;
	return (
		<Wrapper onClick={onClick} $selected={selected} data-testid={testId}>
			<BlockTitle>{title}</BlockTitle>
			{hasSubText && (
				<BlockText>
					{line1}
					<br />
					{line2}
					<br />
					{line3}
				</BlockText>
			)}
			<BlockCheckbox $selected={selected} />

			{children}
		</Wrapper>
	);
};

export const BlockSkeleton = () => {
	return (
		<Wrapper $selected={false}>
			<BlockTitle>
				<Skeleton variant="text" height={40} />
			</BlockTitle>
			<BlockText>
				<Skeleton variant="text" />
				<Skeleton variant="text" />
			</BlockText>
		</Wrapper>
	);
};
