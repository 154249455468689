export const unsafeGetFieldFromState = <T>(state: unknown, field: string) => {
	if (!state) {
		return undefined;
	}
	if (typeof state !== 'object') {
		return undefined;
	}
	if (!(field in state)) {
		return undefined;
	}

	return state[field] as T;
};
