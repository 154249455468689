import styled from 'styled-components';

import { Icon } from '../../../base-ui/components';

export const LogoutIcon = styled(Icon)`
	width: 2.8rem;
	height: 2.8rem;
	font-size: 2.8rem;
	margin-left: 0.5rem;
`;
