import React, { CSSProperties } from 'react';
import styled from 'styled-components';

import { colors } from '../../variables';
import * as icons from './Icons';

export type IconName = keyof typeof icons;

export type IconProps = {
	icon: IconName;
	color?: CSSProperties['color'];
	className?: string;
	onClick?: () => void;
	style?: CSSProperties;
};

const IconWrapper = styled.div`
	width: 4rem;
	height: 4rem;
	position: relative;

	svg {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate3d(-50%, -50%, 0);
	}
`;

export const Icon = ({ icon, color, className, onClick, style }: IconProps) => {
	const Component = icons[icon];

	return (
		<IconWrapper onClick={onClick} className={className} style={style}>
			<Component fill={color || colors.textBody} />
		</IconWrapper>
	);
};
