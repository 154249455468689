import React, { ComponentProps } from 'react';
import { first } from 'lodash';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import congrats from 'url:../static/shapes/congrats.png';
import learning from 'url:../static/shapes/learning.png';
import onboarding1 from 'url:../static/shapes/onboarding-1.png';
import payToLandlord from 'url:../static/shapes/pay-to-landlord.png';
import reschedule from 'url:../static/shapes/reschedule.png';
import support from 'url:../static/shapes/support.png';

import { CreditBuilderSubscriptionPeriodType } from '../../../../functions/src/credit-reporting/credit-reporting-subscription';
import { BusinessEventType } from '../../../../functions/src/shared/business-events';
import { publicConfig } from '../../../../functions/src/shared/config';
import { PropertyManagerCreditBuilder } from '../../../../functions/src/shared/landlords';
import { RentMonth } from '../../../../functions/src/shared/rent-month';
import { typedIncludes } from '../../../../functions/src/shared/utils';

import { FormattedCents } from '../../shared/components/formatted-cents';
import { formatRentMonthIntl } from '../../shared/components/formatted-rent-month';
import { RouterOutput, trpc } from '../../shared/trpc/client';

import { Button, Footer, Spacing, Text } from '../../base-ui/components';

import { CloseButton } from '../components/close-button';
import { Confirm, ConfirmList } from '../components/confirm';
import { ImgPreload } from '../components/img-preload';
import { createCheckoutLink } from '../components/stripe/stripe-service';
import { useUserData } from '../contexts/user-data-context';
import { links } from '../Router/paths';
import { rentHistoryStatusesAvailableForUpsell } from '../types/credit-builder-history-upsell.type';
import { useTrackOnboardingEvent } from '../utils/track-onboarding-event';

type Props = {
	variant: 'onboarding' | 'account';
};

export const CreditBuilderSuccess = ({ variant }: Props) => {
	const navigate = useNavigate();

	const { home } = useUserData();
	const trackOnboardingEvent = useTrackOnboardingEvent();

	const info = trpc.creditReporting.getUserInfo.useQuery();
	const { payment } = getPaymentFromGetInfo(variant, info.data);

	const landlord = home?.landlord;
	const utils = trpc.useUtils();
	const intl = useIntl();
	const historyUpsellPayment = first(info.data?.historyUpsellPayments);
	const isUpsell = payment === undefined && Boolean(historyUpsellPayment);
	const cbIsFree =
		home?.creditBuilderPricing?.monthlyPriceCents === 0 && home?.creditBuilderPricing?.yearlyPriceCents === 0;
	const isPaidByLandlordOrFree =
		home?.landlord?.creditBuilder === PropertyManagerCreditBuilder.PaidByLandlord || cbIsFree;

	const handleEnd = () => {
		trackOnboardingEvent.mutate(
			{ type: BusinessEventType.OnboardingCreditBuilderRentalEnd },
			{
				onSuccess() {
					navigate(links.ACCOUNT.DASHBOARD);
				},
			},
		);
	};

	const confirm = trpc.creditReporting.confirmRental.useMutation({
		async onSuccess({ onboardingEvents, home: newHome }) {
			utils.user.listOnboardingEvents.setData(undefined, onboardingEvents);
			utils.user.home.setData(undefined, newHome);
			navigate(createCreditBuilderCheckoutLink({ payment: payment ?? historyUpsellPayment ?? null, variant }));
			return;
		},
	});

	if (info.isPending) return null;

	const onConfirm = () => {
		confirm.mutate();
	};

	function getPaymentLabel() {
		if (home?.landlord?.creditBuilder === PropertyManagerCreditBuilder.PaidByResident || !landlord) {
			const rentReportingSubscriptionPeriod =
				payment?.metadata?.subscriptionPeriodType ?? home?.creditSubject?.rentReportingSubscriptionPeriod;

			const monthlyPriceCents =
				home?.creditBuilderPricing?.monthlyPriceCents ?? publicConfig.creditBuilder.monthlyPriceCents;
			const yearlyPriceCents =
				home?.creditBuilderPricing?.yearlyPriceCents ?? publicConfig.creditBuilder.yearlyPriceCents;

			const formattedAmount = (
				<FormattedCents
					value={
						rentReportingSubscriptionPeriod === CreditBuilderSubscriptionPeriodType.Yearly
							? yearlyPriceCents
							: monthlyPriceCents
					}
				/>
			);

			return (
				<Text>
					<FormattedMessage
						defaultMessage="You will be charged {amountText} {subscriptionPeriod, select, yearly {annually} other {every month}} for CreditBuilder subscription."
						id="credit-builder-success.charge-message"
						values={{ amountText: formattedAmount, subscriptionPeriod: rentReportingSubscriptionPeriod }}
					/>
				</Text>
			);
		}

		// TODO: 8168 copy when paid because you are an employee
		const feesCoveredBy = landlord?.propertyManagerName ? `${landlord.propertyManagerName}` : 'your landlord';
		return (
			<Text>
				<FormattedMessage
					defaultMessage="Your CreditBuilder fees are covered by {feesCoveredBy}."
					id="credit-builder-success.fees-covered-message"
					values={{ feesCoveredBy }}
				/>
			</Text>
		);
	}

	const oneTimeCharge =
		(payment && home?.creditSubject?.rentHistoryUpsell === 'enrolled') ||
		(historyUpsellPayment &&
			typedIncludes(rentHistoryStatusesAvailableForUpsell, home?.creditSubject?.rentHistoryUpsell));

	return (
		<>
			<CloseButton onClick={handleEnd} />

			<Text type="title">
				<FormattedMessage
					defaultMessage="Confirm your CreditBuilder terms"
					id="credit-builder-success.confirm-terms-title"
				/>
			</Text>
			<Spacing $size="l" />

			<Text>
				<FormattedMessage
					defaultMessage="Almost set! Please confirm the details below."
					id="credit-builder-success.confirm-details-message"
				/>
			</Text>
			<Spacing $size="l" />

			<Confirm showBankInformation={false} />
			<Spacing $size="l" />

			<ConfirmList
				items={
					[
						{
							label: (
								<Text>
									<FormattedMessage
										defaultMessage="Your rent reporting will start in {currentRentMonth} and will continue until your lease end."
										id="credit-builder-success.rent-reporting-start-message"
										values={{ currentRentMonth: formatRentMonthIntl(intl, RentMonth.current()) }}
									/>
								</Text>
							),
						},
						{
							label: (
								<Text>
									<FormattedMessage
										defaultMessage="Your balance and rent payments will be reported periodically to Equifax."
										id="credit-builder-success.balance-reporting-message"
									/>
								</Text>
							),
						},
						oneTimeCharge && {
							testId: 'onboarding-cb-confirm-terms-upsell-label',
							label: (
								<Text>
									<FormattedMessage
										defaultMessage="You will be a charged a one time fee of {formattedAmount} to report your past payments"
										id="credit-builder-success.past-payments-fee-message"
										values={{
											formattedAmount: (
												<FormattedCents
													value={
														home?.creditBuilderPricing?.historyReportingUpsellPriceCents ??
														publicConfig.creditBuilder.historyReportingUpsellPriceCents
													}
												/>
											),
										}}
									/>
								</Text>
							),
						},
						(!isUpsell || isPaidByLandlordOrFree) && {
							testId: 'onboarding-cb-confirm-terms-payment-label',
							label: getPaymentLabel(),
						},
					].filter(Boolean) as ComponentProps<typeof ConfirmList>['items']
				}
			/>
			<Text>
				<FormattedMessage
					defaultMessage="At anytime you can update your lease details to keep your residency information up to date."
					id="credit-builder-success.update-lease-details-message"
				/>
			</Text>
			<Spacing $size="xl" />

			<Footer stickyOnMobile>
				<Button onClick={onConfirm} testId="onboarding-cb-confirm-terms-confirm-button">
					<FormattedMessage defaultMessage="Confirm" id="common.confirm" />
				</Button>
			</Footer>

			<ImgPreload src={congrats} />
			<ImgPreload src={learning} />
			<ImgPreload src={support} />
			<ImgPreload src={reschedule} />
			<ImgPreload src={onboarding1} />
			<ImgPreload src={payToLandlord} />
		</>
	);
};

export const getPaymentFromGetInfo = (
	variant: 'onboarding' | 'account',
	data?: RouterOutput['creditReporting']['getUserInfo'],
) => {
	const payment = first(data?.payments);

	const checkoutLink = payment ? createCreditBuilderCheckoutLink({ payment, variant }) : undefined;

	const unpaid = data?.paidByResident && data?.unpaid;

	return { payment, checkoutLink, unpaid };
};

export function createCreditBuilderCheckoutLink(input: {
	payment: { id: string } | null;
	variant: 'onboarding' | 'account';
}) {
	const { payment, variant } = input;
	if (!payment?.id) {
		return links.ACCOUNT.CREDIT_BUILDER.PAYMENT_COMPLETED;
	}

	return createCheckoutLink({
		ptid: payment.id,
		successPath:
			variant === 'account'
				? links.ACCOUNT.CREDIT_BUILDER.PAYMENT_COMPLETED
				: links.REGISTRATION.CREDIT_BUILDER.PAYMENT_COMPLETED,
	});
}
