import React from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import instaMoneySentIllustration from 'url:../../static/shapes/instamoney-sent.svg';

import { IsoDate, isoDateToJsDate } from '../../../../../functions/src/shared/iso-date';

import { Anchor, Button, Center, Divider, Spacing, Text } from '../../../base-ui/components';

import { useInterface } from '../../contexts/hooks';

export const InstaMoneySentModalContent = ({ paybackDate }: { paybackDate: IsoDate }) => {
	const { closeModal } = useInterface();

	return (
		<Center data-testid="instamoney-sent-modal-content">
			<img src={instaMoneySentIllustration} alt="" />
			<Spacing $size="s" />

			<Text>
				<FormattedMessage
					defaultMessage="Your money is on its way!"
					id="insta-money-sent-modal-content.your-money-is-on-its-way"
				/>
			</Text>
			<Text type="small">
				<FormattedMessage
					defaultMessage="You should receive your money within 48 hours"
					id="insta-money-sent-modal-content.you-should-receive-your-money-within-48-hours"
				/>
			</Text>

			<Divider />

			<Text>
				<FormattedMessage
					defaultMessage="Your payback date is {paybackDate}"
					id="insta-money-sent-modal-content.your-payback-date"
					values={{ paybackDate: <FormattedDate value={isoDateToJsDate(paybackDate)} /> }}
				/>
			</Text>

			<Text type="small" center>
				<FormattedMessage
					defaultMessage="For all your questions please email our member team at {email}"
					id="insta-money-sent-modal-content.for-all-your-questions-please-email-our-member-team"
					values={{
						email: (
							<Anchor href="mailto:support@myzenbase.com" target="_blank">
								support@myzenbase.com
							</Anchor>
						),
					}}
				/>
			</Text>

			<Spacing $size="m" />

			<Button onClick={closeModal}>
				<FormattedMessage defaultMessage="Ok" id="insta-money-payment-scheduled-modal-content.ok" />
			</Button>
		</Center>
	);
};
