import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { Paper, ProgressBar, Spacing, Text } from '../../base-ui/components';

const SmallerText = styled(Text)`
	font-size: 1.6rem;
	@media (max-width: 450px) {
		font-size: 1.4rem;
	}
`;

const TinyText = styled(Text)`
	font-size: 1.4rem;
`;

const ProgressBarWrapper = styled.div`
	max-width: 34rem;
`;

export const StatusPanel = ({
	finished,
	type,
}: {
	finished: boolean;
	type: 'awaiting-transactions' | 'credit-builder-handover-data-transfer';
}) => {
	const [progress, setProgress] = useState(finished ? 100 : 30);

	const updateProgress = () => {
		setProgress((prevProgress) => (prevProgress < 90 ? prevProgress + 10 : prevProgress));
	};

	useEffect(() => {
		const interval = setInterval(updateProgress, 1500);
		if (finished) {
			clearInterval(interval);
			setProgress(100);
		}

		return () => clearInterval(interval);
	}, [finished]);

	return (
		<Paper>
			{type === 'awaiting-transactions' && (
				<>
					<SmallerText type="small">
						<FormattedMessage
							defaultMessage="This may take up to <strong>2 minutes.</strong>"
							id="status-panel.this-may-take-up-to"
						/>
					</SmallerText>
					<SmallerText type="small">
						<FormattedMessage
							defaultMessage="You don’t need to refresh your screen."
							id="status-panel.you-dont-need-to-refresh"
						/>
					</SmallerText>
					<Spacing $size="s" />
					<TinyText type="small" color="highlighted">
						<FormattedMessage
							defaultMessage="{progress}% complete"
							id="status-panel.progress-complete"
							values={{ progress }}
						/>
					</TinyText>
				</>
			)}
			{type === 'credit-builder-handover-data-transfer' && (
				<>
					<SmallerText type="small">
						<FormattedMessage
							defaultMessage="This should take <strong>a few seconds.</strong>"
							id="status-panel.few-seconds"
						/>
					</SmallerText>
					<SmallerText type="small">
						<FormattedMessage
							defaultMessage="Please do not refresh your screen."
							id="status-panel.please-no-refresh"
						/>
					</SmallerText>
					<Spacing $size="s" />
				</>
			)}
			<Spacing $size="s" />
			<ProgressBarWrapper>
				<ProgressBar type="determinate" value={progress} />
			</ProgressBarWrapper>
		</Paper>
	);
};
