import React from 'react';

export const CheckmarkCircle = () => {
	return (
		<svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<circle cx="12" cy="12" r="11.5" fill="#1ECEC9" stroke="white" />
			<path
				d="M16.6663 9L10.833 15L7.33301 11.4"
				stroke="white"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};
