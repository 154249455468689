import React from 'react';
import * as Sentry from '@sentry/react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import '../shared/sentry';

import { App } from './app';

Sentry.setTag('app', 'app');

const container = document.getElementById('app') as HTMLElement;
const root = createRoot(container);
root.render(
	<BrowserRouter>
		<App />
	</BrowserRouter>,
);
