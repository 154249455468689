import React from 'react';
import { FormattedDate, FormattedMessage, FormattedNumber } from 'react-intl';
import instaMoneyPaybackScheduledIllustration from 'url:../../static/shapes/instamoney-payback-scheduled.svg';

import { IsoDate, isoDateToJsDate } from '../../../../../functions/src/shared/iso-date';

import { Button, Center, Divider, Spacing, Text } from '../../../base-ui/components';

import { useInterface } from '../../contexts/hooks';

type Props = {
	effectiveDate: IsoDate;
	amount: number;
};

export const InstaMoneyPaymentScheduledModalContent = ({ effectiveDate, amount }: Props) => {
	const { closeModal } = useInterface();

	return (
		<Center data-testid="instamoney-payment-scheduled-modal-content">
			<img src={instaMoneyPaybackScheduledIllustration} alt="" />
			<Spacing $size="s" />

			<Text>
				<FormattedMessage
					defaultMessage="Your Payback is scheduled for"
					id="insta-money-payment-scheduled-modal-content.your-payback-is-scheduled-for"
				/>{' '}
				<FormattedDate value={isoDateToJsDate(effectiveDate)} year="numeric" month="long" day="2-digit" />
			</Text>

			<Divider />

			<Text type="small" center>
				<FormattedMessage
					defaultMessage="We will automatically withdraw {amount} on {date} from your bank account."
					id="insta-money-payment-scheduled-modal-content.we-will-automatically-withdraw"
					values={{
						amount: <FormattedNumber value={amount} style="currency" currency="CAD" />,
						date: (
							<FormattedDate
								value={isoDateToJsDate(effectiveDate)}
								year="numeric"
								month="long"
								day="2-digit"
							/>
						),
					}}
				/>
			</Text>

			<Spacing $size="m" />

			<Button onClick={closeModal}>
				<FormattedMessage defaultMessage="Ok" id="insta-money-payment-scheduled-modal-content.ok" />
			</Button>
		</Center>
	);
};
