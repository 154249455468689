import { RentMonth } from '../../../../functions/src/shared/rent-month';
import { CustomRentConfigForMonth } from '../../../../functions/src/shared/user-rent';

import { RouterOutput } from '../../shared/trpc/client';

export function getCustomRentStartRentMonth(
	config: CustomRentConfigForMonth,
	home: RouterOutput['user']['home'] | undefined | null,
) {
	const startingMonth = config.startingMonth ?? config.rentMonth.month;
	const startingYear = config.startingYear ?? config.rentMonth.year;

	const startingRentMonth = RentMonth.fromDbParams({ year: startingYear, month: startingMonth });
	const isStartingRentMonthInGracePeriod =
		RentMonth.current().equals(startingRentMonth) || RentMonth.current().next().equals(startingRentMonth);

	const customRentStartRentMonth = home?.rentThatCanBeDisabled
		? RentMonth.fromDbParams(home.rentThatCanBeDisabled)
		: isStartingRentMonthInGracePeriod && home?.isInGracePeriod
			? RentMonth.fromDbParams({ year: startingYear, month: startingMonth })
			: RentMonth.fromDbParams(config.rentMonth);
	return customRentStartRentMonth;
}
