import React from 'react';
import styled from 'styled-components';

import { Animation } from '../../../../shared/components/animation';

import animationData from './Loader.json';

const Wrapper = styled.div`
	position: fixed;
	background: rgba(0, 0, 0, 0.3);
	height: 100vh;
	width: 100vw;
	top: 0;
	left: 0;
	right: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	pointer-events: none;
	& > div {
		width: 120px !important;
		height: 120px !important;
	}
	z-index: 1;
`;

type Props = {
	isOpen?: boolean;
};

export const Loader = ({ isOpen }: Props) => {
	if (isOpen) {
		return (
			<Wrapper>
				<Animation animation={{ animationData, loop: true }} sx={{ width: 400, height: 400 }} />
			</Wrapper>
		);
	}

	return <></>;
};
