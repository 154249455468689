import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components';
import onboarding1 from 'url:../../static/shapes/verify-email.png';

import { LandlordConnectedLogo as BaseLandlordConnectedLogo } from '../../../shared/components/landlord-logo';
import { trpc } from '../../../shared/trpc/client';

import { Button, Spacing, Text } from '../../../base-ui/components';
import { LoaderCentered } from '../../../base-ui/components/Loader/Loader';
import { Center } from '../../../base-ui/utils';

import { AlertError } from '../../components/alert-error';
import { OnboardingCarousel } from '../../components/carousel/onboarding-carousel';
import { links } from '../../Router/paths';
import { setInvite } from '../../utils/invite-storage';

const ImagePanel = styled.div`
	margin-bottom: 3rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: 44.6rem;
	top: 8rem;
`;

const LandlordConnectedLogo = styled(BaseLandlordConnectedLogo)`
	margin: 2.5rem 0 5rem;
`;

export const Invite = () => {
	const { inviteId, secret } = useParams<{ inviteId: string; secret?: string }>();
	const { state } = useLocation();
	if (!inviteId) throw new Error('inviteId url param missing');

	useEffect(() => {
		setInvite(inviteId, secret);
	}, [inviteId]);

	const { data, isPending, isError } = trpc.public.getInviteDetails.useQuery(
		{ invite: inviteId, secret: secret ?? '' },
		{ refetchOnWindowFocus: false },
	);

	if (isPending) {
		return <LoaderCentered />;
	}

	if (isError) {
		return <AlertError />;
	}

	if (state === 'step-1') {
		return (
			<Center>
				<OnboardingCarousel />
				<Spacing $size="m" />
				<Button linkTo={links.REGISTRATION.ROOT}>
					<FormattedMessage defaultMessage="Continue" id="common.continue" />
				</Button>
			</Center>
		);
	}

	return (
		<Center>
			<ImagePanel>
				{'propertyManagerName' in data && data.propertyManagerName ? (
					<LandlordConnectedLogo propertyManagerName={data.propertyManagerName} showZenbase showMail />
				) : (
					<img src={onboarding1} />
				)}

				<Text type="title" center>
					<FormattedMessage defaultMessage="You've been invited to Zenbase" id="invite.invited-to-zenbase" />
				</Text>
				<Spacing $size="m" />
				<Text center>
					<FormattedMessage
						defaultMessage="Please continue to complete your sign up and split your rent for the rent day Bliss!"
						id="invite.complete-sign-up"
					/>
				</Text>
				<Spacing $size="m" />
			</ImagePanel>

			<Button linkTo={{}} linkState="step-1">
				<FormattedMessage defaultMessage="Continue" id="common.continue" />
			</Button>
		</Center>
	);
};
