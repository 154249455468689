import React, { useEffect, useMemo } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { z } from 'zod';

import { BusinessEventType } from '../../../../functions/src/shared/business-events';
import { userPaymentPortalNotSetNameValue } from '../../../../functions/src/shared/user';

import { trpc } from '../../shared/trpc/client';
import { triggerChatWidget } from '../../shared/zendesk';

import { Button, Footer, Spacing, Text } from '../../base-ui/components';
import { colors } from '../../base-ui/variables';

import { PaymentPortalSelect } from '../components/payment-portal-select';
import { useUserData } from '../contexts/hooks';
import { useTrackOnboardingEvent } from '../utils/track-onboarding-event';

type Props = {
	onSuccess: () => void;
	onSkip?: () => void;
};

export const PaymentPortalSelection = ({ onSuccess, onSkip }: Props) => {
	const {
		handlers: { silentRefetch },
	} = useUserData();

	const update = trpc.user.update.useMutation();
	const trackOnboardingEvent = useTrackOnboardingEvent();

	useEffect(() => {
		trackOnboardingEvent.mutate({ type: BusinessEventType.OnboardingPaymentPortalStart });
	}, []);

	const intl = useIntl();
	const schema = useMemo(
		() =>
			z.object({
				name: z.string({ required_error: 'Please select your payment portal' }),
			}),
		[intl],
	);

	type FormData = z.infer<typeof schema>;
	const form = useForm<FormData>({
		resolver: zodResolver(schema),
	});

	const submit = async ({ name }: FormData) => {
		await update.mutateAsync({ paymentPortal: { name } });
		if (name === userPaymentPortalNotSetNameValue) {
			await trackOnboardingEvent
				.mutateAsync({ type: BusinessEventType.OnboardingPaymentPortalEnd })
				.catch(console.error);
		}

		await silentRefetch();
		onSuccess();
	};

	const handleSkip = async () => {
		if (onSkip) {
			await trackOnboardingEvent
				.mutateAsync({ type: BusinessEventType.OnboardingPaymentPortalEnd })
				.catch(console.error);

			onSkip();
		} else {
			triggerChatWidget();
		}
	};

	return (
		<FormProvider {...form}>
			<Text type="title">
				<FormattedMessage defaultMessage="Select your payment portal" id="payment-portal-selection-title" />
			</Text>

			<Spacing $size="s" />
			<Text type="small">
				<FormattedMessage
					defaultMessage="Please connect to the payment portal of your property to securely verify your residency."
					id="payment-portal-selection-description-1"
				/>
			</Text>
			<Spacing $size="s" />
			<Text type="small">
				<FormattedMessage
					defaultMessage="If your payment portal is {notListedText}, select “I don’t have a payment portal” at the bottom."
					id="payment-portal-selection-description-2"
					values={{
						notListedText: (
							<span style={{ color: colors.greenHover }}>
								<FormattedMessage
									defaultMessage="not listed or you don’t have one"
									id="not-listed-text"
								/>
							</span>
						),
					}}
				/>
			</Text>
			<Spacing $size="xl" />

			<PaymentPortalSelect onChange={form.handleSubmit(submit)} />

			<Footer>
				<Button
					type="link"
					onClick={handleSkip}
					disabled={form.formState.isSubmitting}
					testId="onboarding-landlord-payment-portal-selection-no-portal-option"
				>
					<FormattedMessage defaultMessage="I don't have a payment portal" id="no-portal-option-button" />
				</Button>
			</Footer>
		</FormProvider>
	);
};
