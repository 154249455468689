import React from 'react';

export const History = () => (
	<svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M12 4.9963H4.99707" stroke="#0A6375" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
		<path
			d="M11.9999 4.99609C16.42 4.99609 20.0032 8.57931 20.0032 12.9994C20.0032 17.4195 16.42 21.0028 11.9999 21.0028C7.5798 21.0028 3.99658 17.4195 3.99658 12.9994"
			stroke="#0A6375"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M7.99832 1.99414L4.99707 4.99539"
			stroke="#0A6375"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M7.99832 7.99734L4.99707 4.99609"
			stroke="#0A6375"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M15.0015 13.7497H11.1919V9.83203"
			stroke="#0A6375"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);
