import React from 'react';
import { snakeCase } from 'lodash';
import { useController } from 'react-hook-form';
import activeBuilding from 'url:./logos/active-building.png';
import amcRentPay from 'url:./logos/amc-rent-pay.png';
import appfolio from 'url:./logos/appfolio.png';
import aptexx from 'url:./logos/aptexx.png';
import avalonAccess from 'url:./logos/avalon-access.png';
import campusVillage from 'url:./logos/campus-village.png';
import clickPay from 'url:./logos/click-pay.png';
import domuso from 'url:./logos/domuso.png';
import entrata from 'url:./logos/entrata.png';
import leaseRunner from 'url:./logos/lease-runner.png';
import macProperties from 'url:./logos/mac-properties.png';
import maderaResidential from 'url:./logos/madera-residential.png';
import myImtResidentPortal from 'url:./logos/my-imt-resident-portal.png';
import pathlight from 'url:./logos/pathlight.png';
import placePay from 'url:./logos/place-pay.png';
import rentCafe from 'url:./logos/rent-cafe.png';
import rentPayment from 'url:./logos/rent-payment.png';
import rentecDirect from 'url:./logos/rentec-direct.png';
import residentBillPay from 'url:./logos/resident-bill-pay.png';
import resihome from 'url:./logos/resihome.png';
import zego from 'url:./logos/zego.png';

import { ErrorMessage, Item, ItemLogo, Wrapper } from './payment-portal-select.styles';

export const paymentPortals = [
	{ name: 'Rent Cafe', logo: rentCafe },
	{ name: 'Clickpay', logo: clickPay },
	{ name: 'Aptexx', logo: aptexx },
	{ name: 'Activebuilding', logo: activeBuilding },
	{ name: 'AMC RentPay', logo: amcRentPay },
	{ name: 'Appfolio', logo: appfolio },
	{ name: 'Avalon Access', logo: avalonAccess },
	{ name: 'Campus Village', logo: campusVillage },
	{ name: 'Pathlight', logo: pathlight },
	{ name: 'Domuso', logo: domuso },
	{ name: 'Honeybadger pro', logo: resihome },
	{ name: 'My IMT resident portal', logo: myImtResidentPortal },
	{ name: 'Lease runner', logo: leaseRunner },
	{ name: 'Mac properties', logo: macProperties },
	{ name: 'Madera residential', logo: maderaResidential },
	{ name: 'Paylease', logo: zego },
	{ name: 'Place pay', logo: placePay },
	{ name: 'Rentec Direct', logo: rentecDirect },
	{ name: 'RentPayment', logo: rentPayment },
	{ name: 'Resident Bill Pay', logo: residentBillPay },
	{ name: 'Entrata', logo: entrata },
];

type Props = {
	onChange: () => void;
};

export const PaymentPortalSelect = ({ onChange: propsOnChange }: Props) => {
	const {
		field: { onChange },
		fieldState: { error },
	} = useController({ name: 'name' });

	return (
		<>
			<Wrapper>
				{paymentPortals.map(({ name, logo }) => {
					const onClick = () => {
						onChange(name);
						propsOnChange();
					};

					return (
						<Item
							key={name}
							onClick={onClick}
							title={name}
							data-testid={`onboarding-landlord-payment-portal-selection-option-${snakeCase(name)}`}
						>
							<ItemLogo $src={logo} />
						</Item>
					);
				})}
			</Wrapper>
			<ErrorMessage>{error?.message}</ErrorMessage>
		</>
	);
};
