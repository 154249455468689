import React from 'react';
import { FormattedNumber } from 'react-intl';

import { centsToAmount } from '../../../../functions/src/shared/monetary';

type Props = {
	value: number;
};

export const FormattedCents = ({ value }: Props) => {
	return <FormattedNumber value={centsToAmount(value)} style="currency" currency="CAD" />;
};
