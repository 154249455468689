import React, { Fragment } from 'react';
import styled from 'styled-components';

import { copyToClipboard } from '../../../shared/copy-to-clipboard';

export const MonerisTestData = () => {
	return (
		<TestCardsGrid>
			{[
				{ number: '4242424254545454', label: 'VISA' },
				{ number: '5454545442424242', label: 'MasterCard' },
				{ number: '373599005095005', label: 'Amex' },
				{ number: 'T0A0A0', label: 'Postal code' },
			].map(({ number, label }) => {
				const copy = () => copyToClipboard(number);
				return (
					<Fragment key={label}>
						<TestCardItem onClick={copy}>{label}</TestCardItem>
						<TestCardItem onClick={copy}>{number}</TestCardItem>
					</Fragment>
				);
			})}
		</TestCardsGrid>
	);
};

const TestCardsGrid = styled.div`
	display: grid;
	grid-template-columns: auto 1fr;
	gap: 2rem;
	z-index: 1;
	position: fixed;

	@media screen and (max-width: 997px) {
		& {
			bottom: 0;
		}
	}
	@media screen and (min-width: 998px) {
		& {
			right: 40px;
		}
	}
`;

const TestCardItem = styled.div`
	cursor: pointer;
`;
