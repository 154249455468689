import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import awaitingTransaction from 'url:../../static/shapes/awaitingTransaction.png';
import { z } from 'zod';

import { useLocalStorage } from '../../../shared/use-local-storage';

import { Box, Button, Divider, Footer, Icon, Loader, Spacing, Text } from '../../../base-ui/components';
import { colors } from '../../../base-ui/variables';

import { StatusPanel } from '../../components/status-panel';
import { links } from '../../Router/paths';

const Image = styled.img`
	width: 230px;
	position: absolute;
	right: 5rem;
	z-index: -1;
	top: 5rem;
	@media (max-width: 450px) {
		width: 165px;
		top: 6.5rem;
		right: 0;
	}
`;

export function HandoverDataTransfer() {
	const [step, setStep] = useState(1);
	const [firstName] = useLocalStorage(z.string().nullish(), 'handover-first-name', null);

	useEffect(() => {
		const interval = setInterval(() => {
			if (step === 4) return;
			setStep((currStep) => currStep + 1);
		}, 1400);
		return () => clearInterval(interval);
	}, []);

	return (
		<>
			<Image src={awaitingTransaction} />
			<Text type="title-extra-large-primary">
				<FormattedMessage
					defaultMessage="Hi {firstName}!"
					id="awaiting-transactions.greeting"
					values={{ firstName: firstName ?? '' }}
				/>
			</Text>
			<Text type="title-large-secondary">
				<FormattedMessage
					defaultMessage="Your account is being set up."
					id="handover-data-transfer.account-setup"
				/>
			</Text>
			<Text type="title-ternary">
				<FormattedMessage defaultMessage="Set up in progress" id="awaiting-transactions.set-up-in-progress" />
			</Text>
			<StatusPanel finished={step > 3} type="credit-builder-handover-data-transfer" />
			<Spacing $size="l" />
			<HandoverDataTransferSteps currentStep={step} />
			<Spacing $size="xl" />
			{step > 3 ? (
				<Footer>
					<Button linkTo={links.REGISTRATION.ROOT}>
						<FormattedMessage defaultMessage="Continue" id="common.continue" />
					</Button>
				</Footer>
			) : (
				<>
					<Text color="highlighted">
						<FormattedMessage
							defaultMessage="Receiving your data"
							id="handover-data-transfer.receiving-data"
						/>
					</Text>
					<Spacing $size="s" />
					<Text type="small" color="muted">
						<FormattedMessage
							defaultMessage="As soon as we receive your data we will automatically enter it to your sign up details so that you can quickly complete your setup!"
							id="handover-data-transfer.receiving-data-message"
						/>
					</Text>
				</>
			)}
		</>
	);
}

const StepStatus = ({ stepNumber, currentStep }: { stepNumber: number; currentStep: number }) => {
	const isCompleted = stepNumber < currentStep;
	return (
		<Box justify="between" verticalCenter>
			<SmallerText type="small" color={status ? 'title' : 'highlighted'}>
				{isCompleted ? (
					<FormattedMessage defaultMessage="Completed" id="common.completed" />
				) : (
					<FormattedMessage defaultMessage="In Progress" id="common.in-progress" />
				)}
			</SmallerText>
			{isCompleted ? (
				<StyledCheckmark icon="Checkmark" />
			) : (
				<LoaderMargin>
					<Loader />
				</LoaderMargin>
			)}
		</Box>
	);
};

export const HandoverDataTransferSteps = ({ currentStep }: { currentStep: number }) => {
	return (
		<>
			<Box justify="between" verticalCenter>
				<Text>
					<FormattedMessage defaultMessage="1. Sign up details" id="handover-data-transfer.sign-up-details" />
				</Text>
				<StepStatus stepNumber={1} currentStep={currentStep} />
			</Box>
			<Divider />
			<Box justify="between" verticalCenter>
				<Text>
					<FormattedMessage defaultMessage="2. Address details" id="handover-data-transfer.address-details" />
				</Text>
				<StepStatus stepNumber={2} currentStep={currentStep} />
			</Box>
			<Divider />
			<Box justify="between" verticalCenter>
				<Text>
					<FormattedMessage
						defaultMessage="3. Rent information"
						id="handover-data-transfer.rent-information"
					/>
				</Text>
				<StepStatus stepNumber={3} currentStep={currentStep} />
			</Box>
		</>
	);
};
const StyledCheckmark = styled(Icon).attrs({ icon: 'Checkmark', color: colors.white })`
	background-color: ${colors.green};
	border-radius: 50%;
	height: 3.2rem;
	width: 3.2rem;
	margin-left: 1rem;
`;

const SmallerText = styled(Text)`
	font-size: 1.6rem;
	@media (max-width: 450px) {
		font-size: 1.2rem;
	}
`;

const LoaderMargin = styled.div`
	margin-left: 1rem;
`;
