import styled from 'styled-components';

import { RadioGroup as BUIRadioGroup, Text as BUIText } from '../../../base-ui/components';
import { RadioButton, RadioItem, RadioLabel } from '../../../base-ui/components/Radio/Radio';
import { colors } from '../../../base-ui/variables';

export const RadioGroup = styled(BUIRadioGroup)`
	grid-template-columns: 1fr 1fr 1fr 1fr;
	grid-column-gap: 3rem;
	@media (max-width: 450px) {
		grid-template-columns: 1fr 1fr;
	}
`;
export const Wrap = styled.div`
	display: flex;
	justify-content: center;
`;

export const RadioWrapper = styled.div`
	display: flex;
	border-radius: 1rem;
	height: 9rem;
	width: 100%;
	border: 1px solid ${colors.green};
	box-shadow: 0px 0px 20px 0px #1717170d;
	position: relative;

	${RadioItem} {
		position: relative;
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	${RadioButton} {
		position: absolute;
		right: 1.2rem;
		top: 0.9rem;
		margin: 0;
	}

	${RadioLabel} {
		margin: 0;
		font-size: 2rem;
		line-height: 2.4rem;
		color: ${colors.green};
		font-weight: bold;
	}
`;

export const Text = styled(BUIText)`
	position: absolute;
	top: -3.5rem;
	left: -0.5rem;
	font-weight: bold;
	white-space: nowrap;
	color: ${colors.green};
`;
