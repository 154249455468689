import { DeepMap, DeepPartial } from 'react-hook-form';

const isObject = <T>(
	dirtyFields: boolean | DeepPartial<DeepMap<T, boolean>> | boolean,
): dirtyFields is DeepPartial<DeepMap<T, true>> => !(dirtyFields === true || Array.isArray(dirtyFields));

// Map RHF's dirtyFields over the `data` received by `handleSubmit` and return the changed subset of that data.
export function dirtyValues<T = Record<string, string>>(
	dirtyFields: DeepPartial<DeepMap<T, boolean>> | boolean | undefined,
	allValues: T,
): Partial<T> {
	if (!dirtyFields) return allValues;
	// If *any* item in an array was modified, the entire array must be submitted, because there's no way to indicate
	// "placeholders" for unchanged elements. `dirtyFields` is `true` for leaves.
	if (!isObject(dirtyFields)) return allValues;
	// Here, we have an object

	return Object.fromEntries(
		Object.keys(dirtyFields).map((key) => [key, dirtyValues(dirtyFields[key], allValues[key])]),
	) as Partial<T>;
}

export const clearFilters = (filters: Record<string, unknown>) =>
	Object.entries(filters).reduce((acc, [key, value]) => {
		if (value === '' || typeof value === 'undefined') return acc;
		return { ...acc, [key]: value };
	}, {});
