import React from 'react';
import { addDays, isBefore, startOfMonth } from 'date-fns';
import { FormattedMessage, FormattedNumber } from 'react-intl';

import { getLastEftPaymentDate } from '../../../../../functions/src/shared/date-utils';
import { CustomRentConfigForMonth } from '../../../../../functions/src/shared/user-rent';

import { Text } from '../../../base-ui/components';

import {
	getInstallmentData,
	isOneInstallment,
	splitInstallment,
	updateConfigInstallmentDate,
} from '../../utils/user-rent';
import { PaymentDatePanel } from '../payment-date-panel/payment-date-panel';
import { ConfigDatesInfoTest } from './config-dates-info-test';
import { FirstRentPaymentInfoText, SecondRentPaymentInfoText } from './rent-info-text';
import { SplitPayments } from './split-payments';

interface Props {
	setConfig: (config: CustomRentConfigForMonth) => void;
	config: CustomRentConfigForMonth;
	onSelectDateOpen?: (paymentNumber: number) => void;
}

export const SelectConfigDates = ({ config, setConfig, onSelectDateOpen }: Props) => {
	const handleSelectDate = (installment: 0 | 1) => (selectedDay?: Date) => {
		if (!selectedDay) return;

		setConfig(updateConfigInstallmentDate(config, installment, selectedDay));
	};

	const { date: firstDate, amount: firstAmount, includeFee: firstAmountHasFee } = getInstallmentData(0, config);
	const { date: secondDate, amount: secondAmount, includeFee: secondAmountHasFee } = getInstallmentData(1, config);
	const displaySplitPaymentLink = isOneInstallment(config);

	const firstInstallmentMaxDay = config.firstInstallmentMaxDay;
	const getFirstInstallmentConfigMaxDate = (date: Date) => {
		return addDays(startOfMonth(date), firstInstallmentMaxDay - 1);
	};
	const firstInstallmentEditable = firstInstallmentMaxDay > 1;

	return (
		<>
			<PaymentDatePanel
				date={firstDate}
				handleUpdateSelect={async (newDate) => {
					handleSelectDate(0)(newDate);
				}}
				amount={<FormattedNumber value={firstAmount} currency="CAD" style="currency" />}
				label={
					<>
						<FormattedMessage defaultMessage="You pay for rent" id="select-config-dates.you-pay-for-rent" />
						{firstAmountHasFee && (
							<FormattedMessage defaultMessage=" + fee" id="select-config-dates.you-pay-for-rent-fee" />
						)}
					</>
				}
				isEditable={firstInstallmentEditable}
				canChangeDateSince={startOfMonth(firstDate)}
				canChangeDateUntil={
					isBefore(secondDate, getFirstInstallmentConfigMaxDate(firstDate))
						? secondDate
						: getFirstInstallmentConfigMaxDate(firstDate)
				}
				infoText={<FirstRentPaymentInfoText />}
				testIdEdit="select-schedule-button-edit-rent-panel-1"
				testIdCalendar="select-schedule-calendar-1"
				needToConfirmFromWeek={4}
				onSelectDateOpen={() => {
					if (onSelectDateOpen) {
						onSelectDateOpen(0);
					}
				}}
			/>

			{!displaySplitPaymentLink && (
				<PaymentDatePanel
					date={secondDate}
					handleUpdateSelect={async (newDate) => {
						handleSelectDate(1)(newDate);
					}}
					amount={<FormattedNumber value={secondAmount} currency="CAD" style="currency" />}
					label={
						<>
							<FormattedMessage
								defaultMessage="You pay for rent"
								id="select-config-dates.you-pay-for-rent"
							/>{' '}
							{secondAmountHasFee && (
								<FormattedMessage
									defaultMessage="+ fee"
									id="select-config-dates.you-pay-for-rent-fee"
								/>
							)}
						</>
					}
					canChangeDateSince={firstDate}
					canChangeDateUntil={getLastEftPaymentDate(secondDate)}
					infoText={<SecondRentPaymentInfoText config={config} />}
					testIdEdit="select-schedule-button-edit-rent-panel-2"
					testIdCalendar="select-schedule-calendar-2"
					needToConfirmFromWeek={4}
					onSelectDateOpen={() => {
						if (onSelectDateOpen) {
							onSelectDateOpen(1);
						}
					}}
				/>
			)}

			<Text type="tiny" color="muted">
				<ConfigDatesInfoTest />
			</Text>

			{displaySplitPaymentLink && (
				<SplitPayments onClick={() => setConfig(splitInstallment(config, firstDate))} />
			)}
		</>
	);
};
