import React from 'react';
import { eachWeekOfInterval, endOfMonth, startOfMonth } from 'date-fns';
// but in date-fns + parce = format cannot be imported as named import
import format from 'date-fns/format';
import { FormattedMessage } from 'react-intl';
import reschedule from 'url:../../../app/static/shapes/reschedule-warning.png';

import { Modal } from '../../../base-ui/components';

type Props = {
	date: Date;
	needToConfirmFromWeek: number;
	buttonHandler: () => void;
	altButtonHandler: () => void;
};

export const ConfirmFromWeekModal = ({ date, needToConfirmFromWeek, buttonHandler, altButtonHandler }: Props) => {
	const startOfConfirmFromWeek = eachWeekOfInterval(
		{ start: startOfMonth(date), end: endOfMonth(date) },
		{ weekStartsOn: 1 },
	)[needToConfirmFromWeek - 1];

	return (
		<Modal
			titleIllustration={<img src={reschedule} />}
			titleProps={{ color: 'error' }}
			headerText={
				<FormattedMessage
					defaultMessage="Don't risk delinquent payments"
					id="confirm-from-week-modal.dont-risk-delinquent-payments"
				/>
			}
			contentText={
				<FormattedMessage
					defaultMessage={`People who complete their rent payments before the {date} are more likely to split their rent with Zenbase in the coming month and build credit.`}
					id="confirm-from-week-modal.content-text"
					// @ts-expect-error parcel invalid import https://github.com/parcel-bundler/parcel/issues/9676
					values={{ date: format(startOfConfirmFromWeek, 'do') }}
				/>
			}
			buttonText={
				<FormattedMessage
					defaultMessage={`Select a date before the {date}`}
					id="confirm-from-week-modal.select-date-before"
					// @ts-expect-error parcel invalid import https://github.com/parcel-bundler/parcel/issues/9676
					values={{ date: format(startOfConfirmFromWeek, 'do') }}
				/>
			}
			buttonHandler={buttonHandler}
			altButtonText={
				<FormattedMessage
					defaultMessage={`Select a date after the {date}`}
					id="confirm-from-week-modal.select-date-after"
					// @ts-expect-error parcel invalid import https://github.com/parcel-bundler/parcel/issues/9676
					values={{ date: format(startOfConfirmFromWeek, 'do') }}
				/>
			}
			altButtonHandler={altButtonHandler}
		/>
	);
};
