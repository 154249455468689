import React from 'react';

export const IconError = () => {
	return (
		<svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
			<circle opacity="0.7" cx="30" cy="30" r="30" fill="#FFB9AA" />
			<path
				d="M33.0526 42.6807V39.6296C33.0526 39.2983 32.7903 39.0222 32.4452 39.0222H27.2818C26.9504 39.0222 26.6743 39.2845 26.6743 39.6296V42.6807C26.6743 42.8464 26.7433 42.9983 26.8538 43.1087L29.4355 45.6904C29.6702 45.9251 30.0567 45.9251 30.2914 45.6904L32.8731 43.1087C32.9836 42.9983 33.0526 42.8464 33.0526 42.6807Z"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M27.2044 14.1926L29.4888 11.9082C29.7231 11.6739 30.1038 11.6642 30.3478 11.9082L32.6224 14.1828C32.7396 14.3 32.7982 14.4562 32.7982 14.6124L32.8169 36.6482H27.038L27.0287 14.6221C27.0287 14.4659 27.0873 14.3097 27.2044 14.1926Z"
				fill="white"
			/>
		</svg>
	);
};
