'use strict';

var TRPCError = require('../error/TRPCError.js');
var utils = require('../utils.js');
var createDeferred = require('./utils/createDeferred.js');
var createReadableStream = require('./utils/createReadableStream.js');

// ---------- types
const CHUNK_VALUE_TYPE_PROMISE = 0;
const CHUNK_VALUE_TYPE_ASYNC_ITERABLE = 1;
const PROMISE_STATUS_FULFILLED = 0;
const PROMISE_STATUS_REJECTED = 1;
const ASYNC_ITERABLE_STATUS_RETURN = 0;
const ASYNC_ITERABLE_STATUS_VALUE = 1;
const ASYNC_ITERABLE_STATUS_ERROR = 2;
function isPromise(value) {
    return (utils.isObject(value) || utils.isFunction(value)) && typeof value?.['then'] === 'function' && typeof value?.['catch'] === 'function';
}
class MaxDepthError extends Error {
    constructor(path){
        super('Max depth reached at path: ' + path.join('.'));
        this.path = path;
    }
}
function createBatchStreamProducer(opts) {
    const { data  } = opts;
    let counter = 0;
    const placeholder = 0;
    const stream = createReadableStream.createReadableStream();
    const pending = new Set();
    function maybeClose() {
        if (pending.size === 0 && !stream.cancelled()) {
            stream.controller.close();
        }
    }
    function dehydratePromise(promise, path) {
        //
        const error = checkMaxDepth(path);
        if (error) {
            promise.catch(()=>{
            // ignore
            });
            promise = Promise.reject(error);
        }
        const idx = counter++;
        pending.add(idx);
        Promise.race([
            promise,
            stream.cancelledPromise
        ]).then((it)=>{
            if (createReadableStream.isCancelledStreamResult(it)) {
                return;
            }
            stream.controller.enqueue([
                idx,
                PROMISE_STATUS_FULFILLED,
                dehydrate(it, path)
            ]);
        }).catch((cause)=>{
            opts.onError?.({
                error: cause,
                path
            });
            stream.controller.enqueue([
                idx,
                PROMISE_STATUS_REJECTED,
                opts.formatError?.({
                    error: cause,
                    path
                })
            ]);
        }).finally(()=>{
            pending.delete(idx);
            maybeClose();
        });
        return idx;
    }
    function dehydrateAsyncIterable(iterable, path) {
        const error = checkMaxDepth(path);
        if (error) {
            iterable = {
                [Symbol.asyncIterator] () {
                    throw error;
                }
            };
        }
        const idx = counter++;
        pending.add(idx);
        utils.run(async ()=>{
            const iterator = iterable[Symbol.asyncIterator]();
            while(true){
                const next = await Promise.race([
                    iterator.next().catch(TRPCError.getTRPCErrorFromUnknown),
                    stream.cancelledPromise
                ]);
                if (next instanceof Error) {
                    opts.onError?.({
                        error: next,
                        path
                    });
                    stream.controller.enqueue([
                        idx,
                        ASYNC_ITERABLE_STATUS_ERROR,
                        opts.formatError?.({
                            error: next,
                            path
                        })
                    ]);
                    return;
                }
                if (createReadableStream.isCancelledStreamResult(next)) {
                    await iterator.return?.();
                    break;
                }
                if (next.done) {
                    stream.controller.enqueue([
                        idx,
                        ASYNC_ITERABLE_STATUS_RETURN,
                        dehydrate(next.value, path)
                    ]);
                    break;
                }
                stream.controller.enqueue([
                    idx,
                    ASYNC_ITERABLE_STATUS_VALUE,
                    dehydrate(next.value, path)
                ]);
            }
            pending.delete(idx);
            maybeClose();
        }).catch((cause)=>{
            // this shouldn't happen, but node crashes if we don't catch it
            opts.onError?.({
                error: new Error('You found a bug - please report it on https://github.com/trpc/trpc', // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore https://github.com/tc39/proposal-error-cause
                {
                    cause
                }),
                path
            });
        });
        return idx;
    }
    function checkMaxDepth(path) {
        if (opts.maxDepth && path.length > opts.maxDepth) {
            return new MaxDepthError(path);
        }
        return null;
    }
    function dehydrateAsync(value, path) {
        if (isPromise(value)) {
            return [
                CHUNK_VALUE_TYPE_PROMISE,
                dehydratePromise(value, path)
            ];
        }
        if (utils.isAsyncIterable(value)) {
            if (opts.maxDepth && path.length >= opts.maxDepth) {
                throw new Error('Max depth reached');
            }
            return [
                CHUNK_VALUE_TYPE_ASYNC_ITERABLE,
                dehydrateAsyncIterable(value, path)
            ];
        }
        return null;
    }
    function dehydrate(value, path) {
        if (value === undefined) {
            return [
                []
            ];
        }
        if (!utils.isObject(value)) {
            return [
                [
                    value
                ]
            ];
        }
        const reg = dehydrateAsync(value, path);
        if (reg) {
            return [
                [
                    placeholder
                ],
                [
                    null,
                    ...reg
                ]
            ];
        }
        const newObj = {};
        const asyncValues = [];
        for (const [key, item] of Object.entries(value)){
            const transformed = dehydrateAsync(item, [
                ...path,
                key
            ]);
            if (!transformed) {
                newObj[key] = item;
                continue;
            }
            newObj[key] = placeholder;
            asyncValues.push([
                key,
                ...transformed
            ]);
        }
        return [
            [
                newObj
            ],
            ...asyncValues
        ];
    }
    const newHead = {};
    for (const [key, item] of Object.entries(data)){
        newHead[key] = dehydrate(item, [
            key
        ]);
    }
    return [
        newHead,
        stream.readable
    ];
}
/**
 * JSON Lines stream producer
 * @see https://jsonlines.org/
 */ function jsonlStreamProducer(opts) {
    let [head, stream] = createBatchStreamProducer(opts);
    const { serialize  } = opts;
    if (serialize) {
        head = serialize(head);
        stream = stream.pipeThrough(new TransformStream({
            transform (chunk, controller) {
                controller.enqueue(serialize(chunk));
            }
        }));
    }
    return stream.pipeThrough(new TransformStream({
        start (controller) {
            controller.enqueue(JSON.stringify(head) + '\n');
        },
        transform (chunk, controller) {
            controller.enqueue(JSON.stringify(chunk) + '\n');
        }
    })).pipeThrough(new TextEncoderStream());
}
class StreamInterruptedError extends Error {
    constructor(cause){
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore https://github.com/tc39/proposal-error-cause
        super('Invalid response or stream interrupted', {
            cause
        });
    }
}
class AsyncError extends Error {
    constructor(data){
        super('Received error from server');
        this.data = data;
    }
}
const nodeJsStreamToReaderEsque = (source)=>{
    return {
        getReader () {
            const { readable , controller  } = createReadableStream.createReadableStream();
            source.on('data', (chunk)=>{
                controller.enqueue(chunk);
            });
            source.on('end', ()=>{
                controller.close();
            });
            source.on('error', (error)=>{
                controller.error(error);
            });
            return readable.getReader();
        }
    };
};
function createLineAccumulator(from) {
    const reader = 'getReader' in from ? from.getReader() : nodeJsStreamToReaderEsque(from).getReader();
    let lineAggregate = '';
    return new ReadableStream({
        async pull (controller) {
            const { done , value  } = await reader.read();
            if (done) {
                controller.close();
            } else {
                controller.enqueue(value);
            }
        },
        cancel () {
            return reader.cancel();
        }
    }).pipeThrough(new TextDecoderStream()).pipeThrough(new TransformStream({
        transform (chunk, controller) {
            lineAggregate += chunk;
            const parts = lineAggregate.split('\n');
            lineAggregate = parts.pop() ?? '';
            for (const part of parts){
                controller.enqueue(part);
            }
        }
    }));
}
function createConsumerStream(from) {
    const stream = createLineAccumulator(from);
    let sentHead = false;
    return stream.pipeThrough(new TransformStream({
        transform (line, controller) {
            if (!sentHead) {
                const head = JSON.parse(line);
                controller.enqueue(head);
                sentHead = true;
            } else {
                const chunk = JSON.parse(line);
                controller.enqueue(chunk);
            }
        }
    }));
}
/**
 * JSON Lines stream consumer
 * @see https://jsonlines.org/
 */ async function jsonlStreamConsumer(opts) {
    const { deserialize =(v)=>v  } = opts;
    let source = createConsumerStream(opts.from);
    if (deserialize) {
        source = source.pipeThrough(new TransformStream({
            transform (chunk, controller) {
                controller.enqueue(deserialize(chunk));
            }
        }));
    }
    let headDeferred = createDeferred.createDeferred();
    const chunkDeferred = new Map();
    const controllers = new Map();
    const maybeAbort = ()=>{
        if (chunkDeferred.size === 0 && Array.from(controllers.values()).every((it)=>it.returned)) {
            // nothing is listening to the stream anymore
            opts.abortController?.abort();
        }
    };
    function hydrateChunkDefinition(value) {
        const [_path, type, chunkId] = value;
        const { readable , controller  } = createReadableStream.createReadableStream();
        const wrapper = {
            controller,
            returned: false
        };
        controllers.set(chunkId, wrapper);
        // resolve chunk deferred if it exists
        const deferred = chunkDeferred.get(chunkId);
        if (deferred) {
            deferred.resolve(wrapper);
            chunkDeferred.delete(chunkId);
        }
        switch(type){
            case CHUNK_VALUE_TYPE_PROMISE:
                {
                    return new Promise((resolve, reject)=>{
                        // listen for next value in the stream
                        const reader = readable.getReader();
                        reader.read().then((it)=>{
                            if (it.done) {
                                reject(new Error('Promise chunk ended without value'));
                                return;
                            }
                            if (it.value instanceof StreamInterruptedError) {
                                reject(it.value);
                                return;
                            }
                            const value = it.value;
                            const [_chunkId, status, data] = value;
                            switch(status){
                                case PROMISE_STATUS_FULFILLED:
                                    resolve(hydrate(data));
                                    break;
                                case PROMISE_STATUS_REJECTED:
                                    reject(opts.formatError?.({
                                        error: data
                                    }) ?? new AsyncError(data));
                                    break;
                            }
                        }).catch(reject).finally(()=>{
                            // reader.releaseLock();
                            controllers.delete(chunkId);
                            maybeAbort();
                        });
                    });
                }
            case CHUNK_VALUE_TYPE_ASYNC_ITERABLE:
                {
                    return {
                        [Symbol.asyncIterator]: ()=>{
                            const reader = readable.getReader();
                            const iterator = {
                                next: async ()=>{
                                    const { done , value  } = await reader.read();
                                    if (value instanceof StreamInterruptedError) {
                                        throw value;
                                    }
                                    if (done) {
                                        controllers.delete(chunkId);
                                        maybeAbort();
                                        return {
                                            done: true,
                                            value: undefined
                                        };
                                    }
                                    const [_chunkId, status, data] = value;
                                    switch(status){
                                        case ASYNC_ITERABLE_STATUS_VALUE:
                                            return {
                                                done: false,
                                                value: hydrate(data)
                                            };
                                        case ASYNC_ITERABLE_STATUS_RETURN:
                                            controllers.delete(chunkId);
                                            maybeAbort();
                                            return {
                                                done: true,
                                                value: hydrate(data)
                                            };
                                        case ASYNC_ITERABLE_STATUS_ERROR:
                                            controllers.delete(chunkId);
                                            maybeAbort();
                                            throw opts.formatError?.({
                                                error: data
                                            }) ?? new AsyncError(data);
                                    }
                                },
                                return: async ()=>{
                                    wrapper.returned = true;
                                    maybeAbort();
                                    return {
                                        done: true,
                                        value: undefined
                                    };
                                }
                            };
                            return iterator;
                        }
                    };
                }
        }
    }
    function hydrate(value) {
        const [[data], ...asyncProps] = value;
        for (const value1 of asyncProps){
            const [key] = value1;
            const hydrated = hydrateChunkDefinition(value1);
            if (key === null) {
                return hydrated;
            }
            data[key] = hydrated;
        }
        return data;
    }
    const closeOrAbort = (reason)=>{
        const error = new StreamInterruptedError(reason);
        headDeferred?.reject(error);
        for (const deferred of chunkDeferred.values()){
            deferred.reject(error);
        }
        chunkDeferred.clear();
        for (const { controller  } of controllers.values()){
            controller.enqueue(error);
            controller.close();
        }
        controllers.clear();
    };
    source.pipeTo(new WritableStream({
        async write (chunkOrHead) {
            if (headDeferred) {
                const head = chunkOrHead;
                for (const [key, value] of Object.entries(chunkOrHead)){
                    const parsed = hydrate(value);
                    head[key] = parsed;
                }
                headDeferred.resolve(head);
                headDeferred = null;
                return;
            }
            const chunk = chunkOrHead;
            const [idx] = chunk;
            let wrapper = controllers.get(idx);
            if (!wrapper) {
                let deferred = chunkDeferred.get(idx);
                if (!deferred) {
                    deferred = createDeferred.createDeferred();
                    chunkDeferred.set(idx, deferred);
                }
                wrapper = await deferred.promise;
            }
            wrapper.controller.enqueue(chunk);
        },
        close: closeOrAbort,
        abort: closeOrAbort
    })).catch((error)=>{
        opts.onError?.({
            error
        });
        closeOrAbort(error);
    });
    return [
        await headDeferred.promise,
        {
            controllers
        }
    ];
}

exports.isPromise = isPromise;
exports.jsonlStreamConsumer = jsonlStreamConsumer;
exports.jsonlStreamProducer = jsonlStreamProducer;
