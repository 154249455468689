import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';

import { FeatureSwitch, userHasFeatureEnabled } from '../../../../../functions/src/shared/user';
import { CustomRentConfigForMonth } from '../../../../../functions/src/shared/user-rent';

import { trpc } from '../../../shared/trpc/client';

import { Button, Footer, Spacing, Text } from '../../../base-ui/components';
import { UnexpectedErrorAlert } from '../../../base-ui/components/Alert/Alert';

import { BackButton } from '../../components/back-button';
import { SelectConfigDates } from '../../components/custom-rent/select-config-dates';
import { useUserData } from '../../contexts/user-data-context';
import { links } from '../../Router/paths';
import { unsafeGetFieldFromState } from '../../utils/field-from-state';
import { accountManageCustomRentConfigStateField } from './AccountConfirmCustomRent';

export const AccountSelectSchedule = () => {
	const { home, hasLineOfCreditReportingEnabled } = useUserData();
	const location = useLocation();
	const navigate = useNavigate();

	const showDisable =
		Boolean(home?.rentThatCanBeDisabled) &&
		!(home && userHasFeatureEnabled(home.user, FeatureSwitch.UnifiedPayments));

	const stateConfig = unsafeGetFieldFromState<CustomRentConfigForMonth>(
		location.state,
		accountManageCustomRentConfigStateField,
	);
	const backLink = unsafeGetFieldFromState<string>(location.state, 'backLink');

	const [config, setConfig] = useState<CustomRentConfigForMonth | null>(stateConfig ?? null);

	const getConfig = trpc.user.customRent.getConfig.useQuery(undefined, {
		enabled: !stateConfig,
	});
	useEffect(() => {
		if (getConfig.data) {
			setConfig(getConfig.data);
		}
	}, [getConfig.data]);

	const navigateToConfirm = () => {
		if (home?.user) {
			const hasLocConsent = hasLineOfCreditReportingEnabled;
			if (!hasLocConsent && !home.rentThatCanBeDisabled) {
				return navigate(links.ACCOUNT.CONFIGURE.CUSTOM_RENT_LOC, {
					state: {
						onSuccessNavigate: links.ACCOUNT.CONFIGURE.CONFIRM_CUSTOM_RENT,
						[accountManageCustomRentConfigStateField]: config,
					},
				});
			}
		}
		return navigate(links.ACCOUNT.CONFIGURE.CONFIRM_CUSTOM_RENT, {
			state: {
				[accountManageCustomRentConfigStateField]: config,
			},
		});
	};
	const navigateToDisable = () => navigate(links.ACCOUNT.DISABLE_CUSTOM_RENT);

	if (getConfig.isError) {
		return <UnexpectedErrorAlert />;
	}

	return (
		<>
			<BackButton to={backLink ?? links.ACCOUNT.DASHBOARD} />
			<Text type="title">
				<FormattedMessage
					defaultMessage="These will be your payment dates"
					id="account-select-schedule-title"
				/>
			</Text>

			<Spacing $size="s" />
			<Text type="small">
				<FormattedMessage
					defaultMessage="You can update this schedule anytime you’d like!"
					id="account-select-schedule-update-schedule"
				/>
			</Text>
			<Spacing $size="m" />

			{config && <SelectConfigDates config={config} setConfig={setConfig} />}

			<Spacing $size="l" />

			<Text color="title">
				<b>
					<FormattedMessage
						defaultMessage="What if you don’t have funds on these dates?"
						id="account-select-schedule-no-funds"
					/>
				</b>
			</Text>
			<Spacing $size="m" />

			<Text type="small" color="muted">
				<FormattedMessage
					defaultMessage="Don’t worry, on your scheduled payment date please contact us by 11:30am Mountain Time to update your rent payment date."
					id="account-select-schedule-contact-us"
				/>
			</Text>

			<Spacing $size="xl" />

			<Footer>
				<Button onClick={navigateToConfirm} testId="account-select-schedule-button-continue">
					<FormattedMessage defaultMessage="Continue" id="common.continue" />
				</Button>
				{showDisable && (
					<Button type="link" onClick={navigateToDisable} testId="account-select-schedule-button-continue">
						<FormattedMessage defaultMessage="Disable CustomRent" id="account-select-schedule-disable" />
					</Button>
				)}
			</Footer>
		</>
	);
};
