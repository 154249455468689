import { ReactNode, useEffect } from 'react';
import { useIntl } from 'react-intl';
import z from 'zod';

export const LocalesZodSetup = ({ children }: { children?: ReactNode }) => {
	const intl = useIntl();
	useEffect(() => {
		const errorMap: z.ZodErrorMap = (issue) => {
			const invalidValue = intl.formatMessage({
				id: 'form.errors.invalid-value',
				defaultMessage: 'Invalid value',
			});
			switch (issue.code) {
				case z.ZodIssueCode.invalid_type:
					return {
						message: intl.formatMessage({
							id: 'common.required',
							defaultMessage: 'Required',
						}),
					};
				case z.ZodIssueCode.custom:
					return {
						message: issue.message || invalidValue,
					};
				case z.ZodIssueCode.invalid_date:
					return {
						message: intl.formatMessage({
							id: 'form.errors.invalid-date',
							defaultMessage: 'Invalid date',
						}),
					};
				case z.ZodIssueCode.invalid_string:
					return {
						message: intl.formatMessage({
							id: 'form.errors.invalid-format',
							defaultMessage: 'Invalid format',
						}),
					};
				case z.ZodIssueCode.too_small:
					if (issue.inclusive) {
						return {
							message: intl.formatMessage(
								{
									id: 'form.errors.too-small-inclusive',
									defaultMessage: 'Value should be greater than or equal to {limit}',
								},
								{
									limit: issue.minimum.toString(),
								},
							),
						};
					}
					return {
						message: intl.formatMessage(
							{
								id: 'form.errors.too-small',
								defaultMessage: 'Value should be greater than {limit}',
							},
							{
								limit: issue.minimum.toString(),
							},
						),
					};
				case z.ZodIssueCode.too_big:
					if (issue.inclusive) {
						return {
							message: intl.formatMessage(
								{
									id: 'form.errors.too-big-inclusive',
									defaultMessage: 'Value should be less than or equal to {limit}',
								},
								{
									limit: issue.maximum.toString(),
								},
							),
						};
					}
					return {
						message: intl.formatMessage(
							{
								id: 'form.errors.too-big',
								defaultMessage: 'Value should be less than {limit}',
							},
							{
								limit: issue.maximum.toString(),
							},
						),
					};
				case z.ZodIssueCode.not_finite:
					return {
						message: intl.formatMessage({
							id: 'form.errors.invalid-number',
							defaultMessage: 'Invalid number',
						}),
					};
				default:
					return {
						message: invalidValue,
					};
			}
		};
		z.setErrorMap(errorMap);
	}, []);
	return children;
};
