import React from 'react';
import styled from 'styled-components';
import avenueLiving from 'url:../static/shapes/landlord-connected-avenue-living-simple@2x.png';
import mainstreet from 'url:../static/shapes/landlord-connected-mainstreet-simple@2x.png';

import { Spacing } from '../../base-ui/components';

import { useUserData } from '../contexts/user-data-context';

const Wrapper = styled.div`
	text-align: center;
`;

export const useConnectedLandlord = () => {
	const { home } = useUserData();

	const landlordName = home?.landlord?.propertyManagerName;
	const landlordConnectedImageSrc =
		landlordName === 'Avenue Living' ? avenueLiving : landlordName === 'Mainstreet' ? mainstreet : avenueLiving;

	return {
		landlordName,
		landlordConnectedImageSrc,
		landlordConnectedImage: (
			<Wrapper>
				<img alt="" src={landlordConnectedImageSrc} width="150px" />
				<Spacing $size="xl" />
			</Wrapper>
		),
	};
};
