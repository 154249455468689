import React from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { generatePath, useNavigate } from 'react-router-dom';

import { centsToAmount } from '../../../../../../functions/src/shared/monetary';
import { FeatureType } from '../../../../../../functions/src/shared/user';

import { trpc } from '../../../../shared/trpc/client';

import { Button } from '../../../../base-ui/components';

import { links } from '../../../Router/paths';
import * as S from '../dashboard-card.styles';

export const ScheduledDirectDebitPayment = () => {
	const payments = trpc.user.listDirectDebitTransactions.useQuery(undefined);
	const navigate = useNavigate();

	if (!payments.data?.length) {
		return null;
	}

	const payment = payments.data[0];

	return (
		<S.Card type="positive" data-testid="direct-debit-pending-payment">
			<S.Content>
				<S.Header>
					<S.Title>
						<b>
							<FormattedMessage
								defaultMessage="Pending Direct Debit"
								id="dashboard-pending-direct-debit.title"
							/>
						</b>
					</S.Title>
				</S.Header>
				<S.Text>
					<FormattedMessage
						defaultMessage="You have pending {featureType} Direct Debit payment for {amount} waiting for you to complete it."
						id="dashboard-pending-direct-debit.text"
						values={{
							featureType: FeatureType[payment.featureId],
							amount: (
								<FormattedNumber
									value={centsToAmount(payment.amountCents)}
									style="currency"
									currency="CAD"
								/>
							),
						}}
					/>
				</S.Text>

				<S.ButtonBox>
					<Button
						type="large-ternary"
						onClick={() =>
							navigate(
								generatePath(links.ACCOUNT.DIRECT_DEBIT.CHARGE, {
									ptid: payment.id,
								}),
							)
						}
					>
						<FormattedMessage defaultMessage="Pay now" id="dashboard-pending-direct-debit.pay" />
					</Button>
				</S.ButtonBox>
				<S.Footer />
			</S.Content>
		</S.Card>
	);
};
