import React from 'react';

import { useResidencyOnboarding } from '../../contexts/hooks';
import { RecommendedUnits as RecommendedUnitsTemplate } from '../../templates/recommended-units';

export const RecommendedUnits = () => {
	const { recommendedUnits, handleSelectedUnit, gotToManualAddress, isPending } = useResidencyOnboarding();

	return (
		<RecommendedUnitsTemplate
			recommendedUnits={recommendedUnits}
			isPending={isPending}
			handleContinue={(unitId) => {
				if (unitId) {
					handleSelectedUnit(unitId);
				} else {
					gotToManualAddress();
				}
			}}
		/>
	);
};
