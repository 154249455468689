export function identify(token: string) {
	if (typeof window.zE !== 'undefined') {
		window.zE('messenger', 'loginUser', (callback) => {
			callback(token);
		});
	}
}

export function anonymize() {
	if (typeof window.zE !== 'undefined') {
		window.zE('messenger', 'logoutUser');
	}
}

export const triggerChatWidget = () => {
	if (typeof window.zE !== 'undefined') {
		window.zE('messenger', 'open');
	} else {
		// eslint-disable-next-line no-console
		console.log('would trigger chat widget (are we on local?)');
	}
};
