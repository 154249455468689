'use strict';

// zod / typeschema
function getParseFn(procedureParser) {
    const parser = procedureParser;
    if (typeof parser === 'function') {
        // ParserValibotEsque (>= v0.31.0)
        // ParserCustomValidatorEsque
        return parser;
    }
    if (typeof parser.parseAsync === 'function') {
        // ParserZodEsque
        return parser.parseAsync.bind(parser);
    }
    if (typeof parser.parse === 'function') {
        // ParserZodEsque
        // ParserValibotEsque (< v0.13.0)
        return parser.parse.bind(parser);
    }
    if (typeof parser.validateSync === 'function') {
        // ParserYupEsque
        return parser.validateSync.bind(parser);
    }
    if (typeof parser.create === 'function') {
        // ParserSuperstructEsque
        return parser.create.bind(parser);
    }
    if (typeof parser.assert === 'function') {
        // ParserScaleEsque
        return (value)=>{
            parser.assert(value);
            return value;
        };
    }
    throw new Error('Could not find a validator fn');
}

exports.getParseFn = getParseFn;
