import React from 'react';
import styled from 'styled-components';

import { LogoSvg } from './logo';

const Wrapper = styled.div`
	position: absolute;
	height: 100vh;
	width: 100vw;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	pointer-events: none;

	& > svg {
		width: 570px;
		height: 99px;
		@media (max-width: 450px) {
			transform: scale(0.5);
		}
	}
`;

export const SplashScreen = () => {
	return (
		<Wrapper test-id="splash-overlay">
			<LogoSvg />
		</Wrapper>
	);
};
