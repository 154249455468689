import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';

import { getAddressFromResidency } from '../../../../functions/src/shared/address';
import { publicConfig } from '../../../../functions/src/shared/config';
import { isoToday } from '../../../../functions/src/shared/iso-date';
import { RentMonth } from '../../../../functions/src/shared/rent-month';
import {
	calculateDefaultBlockingAmountCents,
	CustomRentConfigForMonth,
	feeCentsForRentAmountCents,
} from '../../../../functions/src/shared/user-rent';

import { FormattedCents } from '../../shared/components/formatted-cents';
import { buildLineOfCreditAgreementLink } from '../../shared/line-of-credit-agreement-service';

import { Spacing, Text } from '../../base-ui/components';

import { Confirm, ConfirmList } from '../components/confirm';
import { OnboardingCloseButton } from '../components/onboarding-close-button';
import { useUserData } from '../contexts/hooks';
import { links } from '../Router/paths';

const StrikeThrough = styled.span`
	text-decoration: line-through;
`;

type Props = {
	config: CustomRentConfigForMonth;
};

function getFeeCents(config: CustomRentConfigForMonth) {
	return feeCentsForRentAmountCents(
		config.feeStrategy,
		config.rentAmountCents,
		undefined,
		calculateDefaultBlockingAmountCents(config.rentAmountCents),
	);
}

export const ConfirmRentContent = ({ config }: Props) => {
	const { user, home } = useUserData();
	const intl = useIntl();

	const formattedRentMonthStart = intl.formatDate(RentMonth.fromDbParams(config.rentMonth).firstDayAsDate(), {
		month: 'long',
	});

	return (
		<>
			<OnboardingCloseButton to={links.ACCOUNT.DASHBOARD} stopOnboardingReminders />
			<Text type="title">
				<FormattedMessage
					id="confirm-rent.payment-terms-title"
					defaultMessage="Confirm your rent payment terms"
				/>
			</Text>
			<Spacing $size="l" />

			<Text>
				<FormattedMessage
					id="confirm-rent.confirm-details"
					defaultMessage="Almost set! Please confirm the details below."
				/>
			</Text>
			<Spacing $size="l" />

			<Confirm />
			<Spacing $size="l" />

			<ConfirmList
				items={[
					{
						label: (
							<>
								<FormattedMessage
									id="confirm-rent.schedule-start"
									defaultMessage="Your CustomRent schedule will start in {formattedRentMonthStart}."
									values={{ formattedRentMonthStart }}
								/>
							</>
						),
					},
					{
						label: (
							<FormattedMessage
								id="confirm-rent.two-repayments"
								defaultMessage="You will make two repayments during the month."
							/>
						),
					},
					{
						label: (
							<FormattedMessage
								id="confirm-rent.landlord-verification"
								defaultMessage="Your landlord will verify your residency each month for your eligibility."
							/>
						),
					},
				]}
			/>

			<CustomRentFooter config={config} />

			<Text style={{ marginTop: '3rem' }} color="title" type="small">
				<FormattedMessage
					id="confirm-rent.agreement"
					defaultMessage="By confirming you agree to the {padAgreementLink} and the {paymentTermsLink}."
					values={{
						padAgreementLink: (
							<strong>
								<a href={links.DEFAULT.AGREEMENT.PAD} target="_blank" rel="noreferrer">
									<FormattedMessage
										id="confirm-rent.pad-agreement"
										defaultMessage="Pre-authorized Debit(PAD) Agreement"
									/>
								</a>
							</strong>
						),
						paymentTermsLink: (
							<strong>
								<a
									href={buildLineOfCreditAgreementLink({
										rentAmountCents: config.rentAmountCents.toString(),
										feeCents: config.hasFreeRentPromoCode ? '0' : getFeeCents(config).toString(),
										signDate: isoToday(),
										fullName: user?.fullName ?? '',
										userEmail: user?.email ?? '',
										address: getAddressFromResidency(home?.residency ?? undefined),
										version: publicConfig.padAgreementVersion,
									})}
									target="_blank"
									rel="noreferrer"
								>
									<FormattedMessage
										id="confirm-rent.payment-terms-link"
										defaultMessage="CustomRent payment terms"
									/>
								</a>
							</strong>
						),
					}}
				/>
			</Text>
		</>
	);
};

const CustomRentFooter = ({ config }: { config: CustomRentConfigForMonth }) => {
	return (
		<>
			<Spacing $size="l" />

			<Text>
				<FormattedMessage
					id="custom-rent.late-fees"
					defaultMessage="Your landlord will not charge late fees as long as you start your Zenbase payments on the first of the month or during their grace period."
				/>
			</Text>
			<br />
			{config.hasFreeRentPromoCode ? (
				<Text>
					<FormattedMessage
						id="custom-rent.membership-fee-free-promo-code"
						defaultMessage="A {membershipFee} $0 membership fee (First month fee waived with promo code: FREERENT) will be added to your second monthly repayment, allowing us to offer CustomRent payments to your communities."
						values={{
							membershipFee: (
								<StrikeThrough>
									<FormattedCents value={getFeeCents(config)} />
								</StrikeThrough>
							),
						}}
					/>
				</Text>
			) : (
				<Text>
					<FormattedMessage
						id="custom-rent.membership-fee"
						defaultMessage="A {membershipFee} in fees including your monthly membership fees will be added to your second monthly repayment, allowing us to offer CustomRent payments to your communities."
						values={{
							membershipFee: <FormattedCents value={getFeeCents(config)} />,
						}}
					/>
				</Text>
			)}
			<br />
			<Text>
				<FormattedMessage
					id="custom-rent.no-fee"
					defaultMessage="If you pay your rent in full by the 1st day of the month there is no fee. You can cancel anytime with no penalty."
				/>
			</Text>
		</>
	);
};
