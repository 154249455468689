import { RouterOutput } from '../../shared/trpc/client';

type RentHistoryUpsell = NonNullable<RouterOutput['creditReporting']['getUserInfo']>['rentHistoryUpsell'];

export const rentHistoryStatusesAvailableForUpsell: RentHistoryUpsell[] = [
	'declined',
	'not-available',
	'not-eligible',
	'pending',
];
