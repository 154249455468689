import React from 'react';

export const AvenueLivingListIcon3 = () => {
	return (
		<svg width="46" height="45" viewBox="0 0 46 45" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M28.0448 5.81162C27.5457 5.63339 27.1465 5.49082 26.6474 5.31259C18.2845 2.88882 9.09481 7.82264 6.07216 15.9717C2.8784 24.2847 6.54323 34.8219 15.1842 38.3578C25.0871 42.457 36.216 37.7655 39.5523 27.4776C40.7357 23.8487 40.7784 20.2626 39.4094 16.8477C37.3703 11.8429 33.064 7.82911 28.0448 5.81162Z"
				stroke="#0A6375"
				strokeWidth="3"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M17.8157 26.6103C15.129 25.4403 13.9491 22.275 15.2143 19.6319L17.3067 15.2608C18.6244 12.5082 22.0257 11.4879 24.6409 13.0607L28.3382 15.2843C30.5637 16.6227 31.4524 19.4036 30.4155 21.7846L28.7065 25.7091C27.5654 28.3293 24.5164 29.5283 21.8963 28.3873L17.8157 26.6103Z"
				fill="#F5F5F5"
			/>
			<path
				d="M12.2329 24.1735C11.2602 21.533 12.5759 18.5992 15.1947 17.5693L23.9873 14.1115C26.6469 13.0656 29.6507 14.3737 30.6966 17.0333L32.6503 22.001C33.7011 24.6731 32.3754 27.6898 29.6965 28.7228L20.7738 32.1635C18.0789 33.2026 15.055 31.8344 14.0566 29.1241L12.2329 24.1735Z"
				fill="#FFF5CF"
			/>
			<path
				d="M30.065 16.9494L29.701 15.8576C29.1747 14.5191 27.26 13.3333 25.9216 13.8597L13.3194 18.8157C11.9809 19.342 11.3226 20.8538 11.849 22.1922L14.0525 27.867"
				stroke="#0A6375"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M25.9029 13.8141L22.4084 11.659C21.4673 11.0786 20.2367 11.3345 19.605 12.242L15.674 17.8891"
				stroke="#0A6375"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M26.0731 21.6914C25.494 20.3829 25.9935 18.8484 27.2318 18.1314L28.2097 17.5651C29.7523 16.6719 31.7278 17.3787 32.3537 19.0478V19.0478C32.903 20.5126 32.1838 22.1482 30.7331 22.7336L29.6851 23.1565C28.2831 23.7222 26.6849 23.074 26.0731 21.6914V21.6914Z"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M27.3936 18.0887L30.2837 17.0548C30.8191 16.8443 31.4238 17.1076 31.6343 17.643L32.778 20.5512C32.9885 21.0866 32.7252 21.6913 32.1898 21.9018L29.7663 22.8549C28.4279 23.3812 26.4496 22.8037 25.9232 21.4652C25.3968 20.1268 26.0552 18.615 27.3936 18.0887Z"
				stroke="#0A6375"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeDasharray="3 3"
			/>
			<path
				d="M22.059 31.1419L31.3822 28.276C32.755 27.7361 33.5495 25.8141 33.0231 24.4757L32.2653 21.9979"
				stroke="#0A6375"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<circle
				cx="17.9964"
				cy="29.2899"
				r="4.16667"
				transform="rotate(-21.4679 17.9964 29.2899)"
				stroke="#0A6375"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M19.2014 26.9892C19.4848 27.092 19.6312 27.4051 19.5284 27.6885L18.3905 30.8253C18.288 31.1081 17.976 31.2546 17.6929 31.1529L16.0513 30.5635C15.7676 30.4616 15.6201 30.149 15.722 29.8652C15.8239 29.5815 16.1365 29.4341 16.4203 29.536L17.6917 30.0499L18.5021 27.3162C18.6049 27.0328 18.918 26.8863 19.2014 26.9892Z"
				fill="#0A6375"
			/>
		</svg>
	);
};
