import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { CloseButton } from '../components/close-button';
import { links } from '../Router/paths';
import { UploadLeaseAgreement } from '../templates/upload-lease-agreement';

export function AccountUploadLeaseAgreements() {
	const navigate = useNavigate();
	const { state } = useLocation();

	return (
		<>
			<CloseButton to={links.ACCOUNT.DASHBOARD} />
			<UploadLeaseAgreement
				onNext={() => {
					navigate(links.ACCOUNT.DASHBOARD);
				}}
				manualResidencyId={state?.manualResidencyId}
			/>
		</>
	);
}
