import React, { useCallback, useEffect, useRef } from 'react';
import lottie, { AnimationItem } from 'lottie-web';

import { Box, SxProps } from '@mui/material';

type Props = {
	sx?: SxProps;
	animation: {
		path?: string;
		animationData?: unknown;
		loop?: boolean;
	};
};

export const Animation = ({ sx = {}, animation }: Props) => {
	const animationBoxRef = useRef<HTMLDivElement>();
	const animationRef = useRef<AnimationItem>();

	const mount = useCallback(
		(ref: HTMLDivElement) => {
			if (!ref) return;
			animationBoxRef.current = ref;

			if (animationRef.current) {
				animationRef.current.destroy();
			}

			animationRef.current = lottie.loadAnimation({
				container: animationBoxRef.current,
				renderer: 'svg',
				loop: false,
				autoplay: true,
				rendererSettings: {
					preserveAspectRatio: 'xMidYMid slice',
				},
				...animation,
			});
		},
		[animation],
	);

	// unmount
	useEffect(
		() => () => {
			if (animationRef.current) animationRef.current.destroy();
		},
		[],
	);

	return <Box sx={{ ...sx }} ref={mount}></Box>;
};
