import React, { useEffect, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import onboarding1 from 'url:../../static/shapes/verify-email.png';

import { BusinessEventType } from '../../../../../functions/src/shared/business-events';

import { Anchor, Button, Center, Spacing, Text } from '../../../base-ui/components';

import { useFirebase } from '../../contexts/hooks';
import { links } from '../../Router/paths';
import { useAsyncHandler } from '../../utils/hooks/useAsyncHandler';
import { useTrackOnboardingEvent } from '../../utils/track-onboarding-event';

const ImagePanel = styled.div`
	margin-bottom: 3rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: 44.6rem;
	top: 8rem;
`;

enum Mode {
	RESET_PASSWORD = 'resetPassword',
	// RECOVER_EMAIL = 'recoverEmail', not used yet
	VERIFY_EMAIL = 'verifyEmail',
}

const useAuthActions = () => {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();

	const { handlers, isUserAuthenticated, isEmailVerified } = useFirebase();

	const [handleVerifyEmailCode, { loading, error, data: verificationSuccess }] = useAsyncHandler(
		handlers.handleVerifyEmailCode,
	);
	const trackOnboardingEvent = useTrackOnboardingEvent();

	const { mode, code, continueUrl } = useMemo(() => {
		const modeMemo = searchParams.get('mode') as Mode | null;
		const codeMemo = searchParams.get('oobCode');
		const continueUrlMemo = searchParams.get('continueUrl') || undefined;
		return { mode: modeMemo, code: codeMemo, continueUrl: continueUrlMemo };
	}, [searchParams]);

	useEffect(() => {
		if (!(mode && Object.values(Mode).includes(mode))) return;
		if (!code) return;

		if (mode === Mode.VERIFY_EMAIL) {
			handleVerifyEmailCode(code, continueUrl)
				.then(() => {
					trackOnboardingEvent.mutate({ type: BusinessEventType.OnboardingEmailVerificationEnd });
				})
				.catch(() => null);
		} else if (mode === Mode.RESET_PASSWORD) {
			navigate(links.DEFAULT.RESET_PASSWORD, { replace: true, state: { code, continueUrl } });
		}
	}, [mode, code, continueUrl]);

	useEffect(() => {
		if (mode === Mode.VERIFY_EMAIL && isUserAuthenticated && isEmailVerified) {
			navigate(links.REGISTRATION.ROOT, { replace: true });
		}
	}, [mode, isUserAuthenticated, isEmailVerified]);

	return {
		verifying: loading,
		verifyError: error,
		verifiedButNotLogged: verificationSuccess && !isUserAuthenticated,
	};
};

export const AuthActions = () => {
	const { verifying, verifyError, verifiedButNotLogged } = useAuthActions();

	return (
		<Center>
			<ImagePanel>
				<img src={onboarding1} />
				<Text type="title" center>
					{verifying && (
						<FormattedMessage
							defaultMessage="Verifying redirect from email..."
							id="auth-actions.verifying-redirect-from-email"
						/>
					)}
					{!!verifyError && (
						<FormattedMessage
							defaultMessage="Failed to verify email code"
							id="auth-actions.failed-to-verify-email-code"
						/>
					)}
					{verifiedButNotLogged && (
						<FormattedMessage
							defaultMessage="Verification was successful"
							id="auth-actions.verification-was-successful"
						/>
					)}
				</Text>

				{!!verifyError && (
					<Button type="link" linkTo={links.DEFAULT.ROOT}>
						<FormattedMessage defaultMessage="Return home" id="auth-actions.return-home" />
					</Button>
				)}

				{verifiedButNotLogged && (
					<>
						<Spacing $size="s" />
						<Text center>
							<FormattedMessage
								defaultMessage="Your email is verified. Navigate back to browser or "
								id="auth-actions.your-email-is-verified"
							/>
							<Anchor href={links.DEFAULT.SIGN_IN}>
								<FormattedMessage defaultMessage="login again." id="auth-actions.login-again" />
							</Anchor>
						</Text>
					</>
				)}
			</ImagePanel>
		</Center>
	);
};
