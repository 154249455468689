import React from 'react';

export const Profile = () => (
	<svg width="1em" height="1em" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M28.1369 5.86307C34.2877 12.0138 34.2877 21.9862 28.1369 28.1369C21.9861 34.2877 12.0138 34.2877 5.86307 28.1369C-0.28769 21.9861 -0.28769 12.0138 5.86307 5.86307C12.0138 -0.28769 21.9862 -0.28769 28.1369 5.86307"
			stroke="#0A6375"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M20.4808 10.5668C22.4032 12.4893 22.4032 15.6062 20.4808 17.5286C18.5584 19.4511 15.4414 19.4511 13.519 17.5286C11.5965 15.6062 11.5965 12.4893 13.519 10.5668C15.4414 8.64439 18.5584 8.64439 20.4808 10.5668"
			stroke="#0A6375"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M26.9872 29.1765C24.4759 26.5322 20.9339 24.875 16.9999 24.875C13.0659 24.875 9.52395 26.5322 7.0127 29.1782"
			stroke="#0A6375"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);
