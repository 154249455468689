import { To } from 'react-router-dom';

import { links } from '../../Router/paths';

export const createCheckoutLink = ({ ptid, successPath }: { ptid: string; successPath?: string }): To => {
	const search = new URLSearchParams();
	search.append('ptid', ptid);
	if (successPath) search.append('successPath', successPath);

	return {
		pathname: links.DEFAULT.CHECKOUT,
		search: `?${search.toString()}`,
	};
};
