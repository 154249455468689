import React from 'react';
import { useNavigate } from 'react-router-dom';

import { BusinessEventType } from '../../../../../functions/src/shared/business-events';

import { trpc } from '../../../shared/trpc/client';

import { MonerisCheckout } from '../../components/moneris/moneris-checkout';
import { useInterface } from '../../contexts/hooks';
import { paths } from '../../Router/paths';
import { useTrackOnboardingEvent } from '../../utils/track-onboarding-event';
import { DirectDebitCardCancelModal } from './direct-debit-card-cancel-modal';

export const DirectDebitCardVerification = () => {
	const trackEvent = useTrackOnboardingEvent();
	const { setModal } = useInterface();
	const navigate = useNavigate();
	const utils = trpc.useUtils();

	function handleSuccess() {
		trackEvent.mutate(
			{ type: BusinessEventType.OnboardingCustomRentDirectDebitSuccess },
			{
				onSuccess: () => {
					utils.user.listOnboardingEvents.invalidate();
					utils.user.home.invalidate();
					navigate(`../${paths.REGISTRATION.DIRECT_DEBIT.SUCCESS}`, {
						replace: true,
					});
				},
			},
		);
	}

	function handleCancel() {
		setModal(<DirectDebitCardCancelModal />);
	}

	return <MonerisCheckout onSuccess={handleSuccess} onCancel={handleCancel} />;
};
