import { css } from 'styled-components';

import { colors } from '../../variables';

export const largeTitleSecondaryCss = css`
	font-size: 2.8rem;
	line-height: 4.3rem;
	max-width: 30rem;
	color: ${colors.green};
	margin-bottom: 5rem;

	@media (max-width: 450px) {
		margin-bottom: 4rem;
		font-size: 1.6rem;
		line-height: 2.4rem;
		max-width: 20rem;
	}
`;
