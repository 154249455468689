import React, { PropsWithChildren } from 'react';
import styled, { css } from 'styled-components';

import { colors } from '../../variables';
import { bodyCss } from './body.style';
import { extraLargeTitleCss } from './extraLargeTitle.style';
import { largeTitleSecondaryCss } from './largeTitleSecondary.style';
import { smallCss } from './small.style';
import { tinyCss } from './tiny.style';
import { titleCss } from './title.style';
import { ternaryTitleCss } from './titleTernary.style';

type MainTextTypes = 'title' | 'body' | 'small' | 'tiny';
type ExtraTextTypes = 'title-extra-large-primary' | 'title-large-secondary' | 'title-ternary';
export interface TextProps {
	type?: MainTextTypes | ExtraTextTypes;
	center?: boolean;
	color?: 'body' | 'title' | 'error' | 'highlighted' | 'muted'; // black | green | red | teal
	className?: string;
	as?: 'span' | 'div';
	style?: React.CSSProperties;
	testId?: string;
}

const mapTextColors = {
	body: colors.textBody,
	title: colors.green,
	error: colors.error,
	highlighted: colors.teal,
	muted: colors.textLight,
};

export const Element = styled.p<TextProps>`
	${bodyCss}
	text-align: ${({ center }) => (center ? 'center' : 'left')};
	${({ type }) => type === 'title' && titleCss}
	${({ type }) => type === 'small' && smallCss}
	${({ type }) => type === 'tiny' && tinyCss}
	${({ type }) => type === 'title-extra-large-primary' && extraLargeTitleCss} 
	${({ type }) => type === 'title-large-secondary' && largeTitleSecondaryCss} 
	${({ type }) => type === 'title-ternary' && ternaryTitleCss} 
	${({ color }) =>
		color &&
		css`
			color: ${mapTextColors[color]};
		`}
`;

export const Wrapper = ({
	center,
	children,
	type,
	color,
	as,
	className,
	testId,
	style,
}: PropsWithChildren<TextProps>) => {
	return (
		<Element
			type={type}
			className={className}
			as={as}
			center={center}
			color={color}
			data-testid={testId}
			style={style}
		>
			{children}
		</Element>
	);
};

export const Text = styled(Wrapper)``;
export const TextDiv = styled(Element).attrs({ as: 'div' })``;
