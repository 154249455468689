import React from 'react';

export const BankReconnect = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
			<g>
				<rect width="32" height="32" rx="16" fill="#0A6375" />
			</g>
			<path
				d="M21.2705 6.04065C20.5332 6.15362 19.896 6.41628 19.3159 6.84558C19.1958 6.93314 18.3957 7.69853 17.5384 8.54584C16.3467 9.72076 15.9609 10.119 15.9095 10.2207C15.718 10.6161 15.8809 11.1075 16.2724 11.3052C16.4667 11.4041 16.8382 11.4069 17.0211 11.3109C17.0954 11.2713 17.7184 10.6782 18.6814 9.72641C20.2217 8.20691 20.3789 8.0657 20.7561 7.87929C21.262 7.63075 21.9935 7.57709 22.5594 7.74937C24.2168 8.25493 24.8684 10.232 23.831 11.6018C23.771 11.678 22.4879 12.9603 20.9819 14.4487C19.0787 16.3297 18.1842 17.194 18.0527 17.2815C17.2583 17.804 16.2895 17.852 15.4551 17.4114C15.2608 17.3069 15.1436 17.225 14.8893 17.0047C14.5035 16.6715 14.0491 16.6743 13.709 17.0076C13.5747 17.1431 13.5433 17.194 13.4976 17.355C13.4033 17.7023 13.5033 17.9707 13.8348 18.2644C14.6635 18.9959 15.5751 19.3433 16.6696 19.3405C17.5241 19.3376 18.2299 19.1258 18.9701 18.6457C19.213 18.4903 19.5388 18.1797 22.1736 15.5728C25.3342 12.4491 25.2542 12.5366 25.5542 11.9379C25.8171 11.4154 26 10.6641 26 10.1162C26 9.84221 25.9143 9.26039 25.8314 8.98078C25.5199 7.92448 24.7655 7.00092 23.7939 6.48972C23.0166 6.08019 22.0878 5.91637 21.2705 6.04065Z"
				fill="white"
				stroke="#0A6375"
				strokeWidth="0.5"
			/>
			<path
				d="M14.7264 13.1154C13.8376 13.1578 13.0518 13.4374 12.3402 13.974C12.0601 14.183 7.062 19.1228 6.86768 19.3798C6.42187 19.9701 6.17325 20.5519 6.05609 21.2749C5.98179 21.7325 5.98179 22.0177 6.05323 22.4725C6.13896 23.0204 6.23898 23.3282 6.48474 23.7943C6.8591 24.506 7.51066 25.1499 8.22795 25.5199C8.69947 25.76 9.07954 25.8814 9.62822 25.9605C10.0397 26.017 10.3369 26.0142 10.7799 25.9436C11.5143 25.825 12.1659 25.551 12.7346 25.116C12.8231 25.0483 13.4947 24.4015 14.2263 23.6785C15.2036 22.7154 15.5751 22.3284 15.6237 22.2296C15.7694 21.9415 15.6894 21.5122 15.4494 21.2975C15.2722 21.1394 15.1036 21.0772 14.8693 21.0772C14.5235 21.0772 14.5178 21.0829 13.0861 22.4922C12.3859 23.1842 11.7486 23.7971 11.6686 23.8564C11.3086 24.1247 10.897 24.2857 10.4312 24.3365C9.18814 24.4721 8.0622 23.6954 7.73642 22.4809C7.65069 22.1674 7.65355 21.5913 7.73642 21.2721C7.81072 20.9982 7.92503 20.7496 8.09649 20.4982C8.27653 20.2271 13.2089 15.3523 13.4804 15.1715C13.709 15.0247 13.8976 14.9371 14.1977 14.8439C14.3892 14.7846 14.4635 14.7761 14.8978 14.779C15.3522 14.779 15.3979 14.7846 15.6123 14.858C16.0209 14.9964 16.2638 15.1376 16.5982 15.4257C16.8439 15.6376 16.9868 15.6997 17.2269 15.6997C17.5412 15.7025 17.827 15.5331 17.9785 15.2563C18.0728 15.084 18.067 14.714 17.9699 14.5248C17.7927 14.1802 16.9268 13.5758 16.2981 13.3555C16.1067 13.2905 15.7351 13.1945 15.5465 13.1663C15.3665 13.138 14.8921 13.1098 14.7264 13.1154Z"
				fill="white"
				stroke="#0A6375"
				strokeWidth="0.5"
			/>
		</svg>
	);
};
