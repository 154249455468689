import React from 'react';

import { CommonIconProps } from './types';

export const Configuration = ({ fill }: CommonIconProps) => {
	return (
		<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M9.77821 20.2822C8.36605 20.2822 7.22266 21.4256 7.22266 22.8378C7.22266 23.3901 6.77494 23.8378 6.22266 23.8378C5.67037 23.8378 5.22266 23.3901 5.22266 22.8378C5.22266 20.3211 7.26148 18.2822 9.77821 18.2822C12.2949 18.2822 14.3338 20.3211 14.3338 22.8378C14.3338 23.3901 13.8861 23.8378 13.3338 23.8378C12.7815 23.8378 12.3338 23.3901 12.3338 22.8378C12.3338 21.4256 11.1904 20.2822 9.77821 20.2822Z"
				fill={fill}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M9.77821 7.83789C8.36605 7.83789 7.22266 8.98129 7.22266 10.3934V22.8379C7.22266 24.2501 8.36605 25.3934 9.77821 25.3934C11.1904 25.3934 12.3338 24.2501 12.3338 22.8379V10.3934C12.3338 8.98129 11.1904 7.83789 9.77821 7.83789ZM9.77821 27.3934C7.26148 27.3934 5.22266 25.3546 5.22266 22.8379V10.3934C5.22266 7.87672 7.26148 5.83789 9.77821 5.83789C12.2949 5.83789 14.3338 7.87672 14.3338 10.3934V22.8379C14.3338 25.3546 12.2949 27.3934 9.77821 27.3934Z"
				fill={fill}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M18.668 9.39355C19.2203 9.39355 19.668 9.84127 19.668 10.3936C19.668 11.8057 20.8114 12.9491 22.2235 12.9491C23.6357 12.9491 24.7791 11.8057 24.7791 10.3936C24.7791 9.84127 25.2268 9.39355 25.7791 9.39355C26.3314 9.39355 26.7791 9.84127 26.7791 10.3936C26.7791 12.9103 24.7403 14.9491 22.2235 14.9491C19.7068 14.9491 17.668 12.9103 17.668 10.3936C17.668 9.84127 18.1157 9.39355 18.668 9.39355Z"
				fill={fill}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M22.2235 7.83789C20.8114 7.83789 19.668 8.98129 19.668 10.3934V22.8379C19.668 24.2501 20.8114 25.3934 22.2235 25.3934C23.6357 25.3934 24.7791 24.2501 24.7791 22.8379V10.3934C24.7791 8.98129 23.6357 7.83789 22.2235 7.83789ZM22.2235 27.3934C19.7068 27.3934 17.668 25.3546 17.668 22.8379V10.3934C17.668 7.87672 19.7068 5.83789 22.2235 5.83789C24.7403 5.83789 26.7791 7.87672 26.7791 10.3934V22.8379C26.7791 25.3546 24.7403 27.3934 22.2235 27.3934Z"
				fill={fill}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M5.33333 30H26.6667C28.5079 30 30 28.5079 30 26.6667V5.33333C30 3.49212 28.5079 2 26.6667 2H5.33333C3.49212 2 2 3.49212 2 5.33333V26.6667C2 28.5079 3.49212 30 5.33333 30ZM5.33333 32H26.6667C29.6124 32 32 29.6124 32 26.6667V5.33333C32 2.38756 29.6124 0 26.6667 0H5.33333C2.38756 0 0 2.38756 0 5.33333V26.6667C0 29.6124 2.38756 32 5.33333 32Z"
				fill={fill}
			/>
		</svg>
	);
};
