export enum PropertyManagerCreditBuilder {
	Disabled = 'disabled',
	PaidByLandlord = 'paid-by-landlord',
	PaidByResident = 'paid-by-resident',
}

export enum LandlordCustomRent {
	Enabled = 'enabled',
	Disabled = 'disabled',
}
