import { endOfMonth, startOfDay } from 'date-fns';
import * as format from 'date-fns/format';
import { z } from 'zod';

import { IsoDate, isoDateToJsDate, jsDateToIsoDate } from './iso-date';

function getFunctionOrUndefined(caller: unknown) {
	if (!caller) {
		return;
	}
	if (typeof caller === 'function') {
		return caller;
	}
	return;
}

/**
 * Combination of year and month that identifies rent
 *
 * Date.prototype.getMonth() returns 0 - 11, but we work with month
 * numbers 1 - 12 in database and code. So this class aims to wrap make it
 * more explicit which one we work with.
 */
export class RentMonth {
	readonly year: number;
	private readonly month: number;
	constructor(year: number, month: number) {
		this.year = year;
		this.month = month;
	}

	static current() {
		return RentMonth.fromDate(new Date());
	}

	static fromDate(date: Date) {
		return new RentMonth(date.getFullYear(), date.getMonth() + 1);
	}

	static fromIsoDate(isoDate: IsoDate) {
		return RentMonth.fromDate(isoDateToJsDate(isoDate));
	}

	static fromDbParams(rentMonthLike: RentMonthLike) {
		return new RentMonth(rentMonthLike.year, rentMonthLike.month);
	}

	equals(other: RentMonth): boolean {
		return this.year === other.year && this.month === other.month;
	}

	asDbParams(): RentMonthLike {
		return { year: this.year, month: this.month };
	}

	firstDayAsDate() {
		return new Date(this.year, this.month - 1, 1);
	}

	firstDayAsIsoDate() {
		return jsDateToIsoDate(this.firstDayAsDate());
	}

	lastDayAsIsoDate() {
		return jsDateToIsoDate(endOfMonth(this.firstDayAsDate()));
	}

	lastDayAsDate() {
		return startOfDay(endOfMonth(this.firstDayAsDate()));
	}

	toString() {
		return `${this.year}-${String(this.month).padStart(2, '0')}`;
	}

	toHumanString() {
		/**
		 * format.format = parcel dev
		 * format = backend
		 * format.default = parcel build
		 */
		return (
			// @ts-expect-error - type mess
			(
				getFunctionOrUndefined(format?.format) ??
				// @ts-expect-error - type mess
				getFunctionOrUndefined(format.default) ??
				format
			)(isoDateToJsDate(this.firstDayAsIsoDate()), 'MMMM yyyy')
		);
	}

	// It is wordy, but I think it is better to be explicit
	monthNumberForDb() {
		return this.month;
	}

	next() {
		if (this.month < 12) {
			return new RentMonth(this.year, this.month + 1);
		}
		return new RentMonth(this.year + 1, 1);
	}

	prev() {
		if (this.month === 1) {
			return new RentMonth(this.year - 1, 12);
		}
		return new RentMonth(this.year, this.month - 1);
	}

	isBefore(other: RentMonth) {
		return this.toString() < other.toString();
	}
}

export interface RentMonthLike {
	// I should have implemented RentMonth as this interface, plus helper methods,
	// instead of the class. That way other interfaces could extend this,
	// and the helpers would have worked for them too.
	year: number;
	month: number;
}

export const rentMonthSchema = z.object({
	year: z.number().int().min(2000).max(3000),
	month: z.number().int().min(1).max(12),
});
