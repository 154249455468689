import { CanadianProvincialCodes } from './state-codes';

export function getAddressFromResidency(residency?: {
	apartment?: string | null;
	streetAddress?: string | null;
	city?: string | null;
	state?: string | null;
	postalCode?: string | null;
}): string {
	if (!residency) return '';

	let result = '';
	if (residency.apartment) result += `${residency.apartment}, `;
	if (residency.streetAddress) result += `${residency.streetAddress}, `;
	if (residency.city) result += `${residency.city}, `;
	if (residency.state)
		result += `${
			CanadianProvincialCodes[residency.state as keyof typeof CanadianProvincialCodes] || residency.state
		}, `;
	if (residency.postalCode) result += `${residency.postalCode}`;
	return result;
}
