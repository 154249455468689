import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';

import { Table, TableContainer } from '@mui/material';

import { isoDate } from '../../../../../functions/src/shared/iso-date';

import { FormattedCents } from '../../../shared/components/formatted-cents';
import { parseLineOfCreditAgreementPadParams } from '../../../shared/line-of-credit-agreement-service';

import { Paragraph, Text } from '../../../base-ui/components';

import { FormattedIsoDate } from '../../../admin/components/formatted-iso-date';
import { AgreementLineOfCreditTerms } from './pad/agreement-line-of-credit-terms';

const Td = styled.td`
	padding: 0.5rem 0.5rem 1rem 0.5rem;
	vertical-align: top;
`;
const Tr = styled.tr`
	border: 1px solid #000;
	padding: 0 1rem;
`;

const CREDIT_LIMIT_PERCENTAGE = 0.75;

export const AgreementLineOfCredit = () => {
	const { userEmail, address, signDate, fullName, rentAmountCents, version } = parseLineOfCreditAgreementPadParams();

	const amountCents = +(parseInt(rentAmountCents) * CREDIT_LIMIT_PERCENTAGE).toFixed(2);

	const intl = useIntl();
	const supportEmail = intl.formatMessage({
		id: 'agreement-line-of-credit.support-email',
		defaultMessage: 'support@myzenbase.com',
	});
	const supportPhone = intl.formatMessage({
		id: 'agreement-line-of-credit.support-phone',
		defaultMessage: '866 611-8706',
	});

	const DEFAULT_MEMBERSHIP_PRICE_CENTS = 990;
	return (
		<div>
			<Paragraph>
				<Text type="tiny">
					<FormattedMessage
						defaultMessage="Canadian line of credit Agreement Version {version}"
						id="agreement-line-of-credit.canadian-line-of-credit-agreement-version"
						values={{ version }}
					/>
				</Text>
			</Paragraph>

			<h2 style={{ margin: '3rem 0', textAlign: 'center', textTransform: 'uppercase' }}>
				<FormattedMessage
					defaultMessage="Line of credit agreement and disclosure statement"
					id="agreement-line-of-credit.line-of-credit-agreement-and-disclosure-statement"
				/>
			</h2>

			<h3 style={{ marginTop: '1rem' }}>
				<FormattedMessage
					defaultMessage="Borrower Information:"
					id="agreement-line-of-credit.borrower-information"
				/>
			</h3>
			<Paragraph>
				{fullName}
				<br />
				{address}
				<br />
				{userEmail}
			</Paragraph>
			<h3 style={{ marginTop: '1rem' }}>
				<FormattedMessage
					defaultMessage="Lender Information:"
					id="agreement-line-of-credit.lender-information"
				/>
			</h3>

			<Paragraph>
				<FormattedMessage defaultMessage="Zenbase Inc." id="agreement-line-of-credit.zenbase-inc" />
				<br />
				<FormattedMessage
					defaultMessage="7347 Avenue SW, Suite 810 Calgary AB, T2P3P8b"
					id="agreement-line-of-credit.address"
				/>
				<br />
				<a href={`mailto:${supportEmail}`}>{supportEmail}</a>
				<br />
				<a href={`tel:${supportPhone}`}>{supportPhone}</a>
			</Paragraph>

			<Paragraph style={{ marginTop: '3rem' }}>
				<FormattedMessage
					defaultMessage='This line of credit agreement (this "Agreement") is made between you, the Borrower named above, as borrower ("you", and "your") and Zenbase Inc. and/or its designated agents, affiliates and assigns, as lender (“Zenbase”, "us", "we", and "our"). This Agreement governs the terms and outlines the conditions under which a line of credit will be made available to you. The disclosure statement (the "Disclosure Statement") below, which includes key information about your line of credit, forms an integral part of this Agreement. All amounts referenced in the Disclosure Statement are in Canadian currency and capitalized terms used but not defined have the meanings given to them in the Terms and Conditions below. Please read the Agreement in full for all terms that apply. The term "housing provider" shall mean the person or company who will be providing and/or delivering the housing services to you.'
					id="agreement-line-of-credit.intro-paragraph"
				/>
			</Paragraph>
			<Paragraph>
				<strong>
					<FormattedMessage
						defaultMessage="By entering into and accepting this Agreement (which includes the Disclosure Statement and Terms and Conditions below), you confirm that you have read, understood, and agreed to the terms and conditions of this Agreement and you acknowledge that you have been provided with an express opportunity to accept or decline this Agreement and to correct any errors. You also agree to accept a copy of the Disclosure Statement in electronic form and agree that any electronic disclosures have the same meaning and effect as if provided to you in writing, in paper form. You also agree to carefully review the Disclosure Statement before entering into the Agreement and to retain a copy of the Agreement for your records and future reference."
						id="agreement-line-of-credit.entering-into-agreement-paragraph"
					/>
				</strong>
			</Paragraph>

			<TableContainer>
				<Table size="small">
					<thead>
						<Tr>
							<th colSpan={2}>
								<FormattedMessage
									defaultMessage="DISCLOSURE STATEMENT"
									id="agreement-line-of-credit.disclosure-statement"
								/>
							</th>
						</Tr>
					</thead>
					<tbody>
						<Tr>
							<Td>
								<FormattedMessage
									defaultMessage="Statement Effective Date:"
									id="agreement-line-of-credit.statement-effective-date"
								/>
							</Td>
							<Td>
								<FormattedIsoDate value={isoDate(signDate)} />
							</Td>
						</Tr>
						<Tr>
							<Td>
								<FormattedMessage
									defaultMessage="Credit Limit:"
									id="agreement-line-of-credit.line-of-credit-amount"
								/>
							</Td>
							<Td>
								<strong>
									<FormattedCents value={amountCents} />
								</strong>
								<br />
								<FormattedMessage
									defaultMessage="Your credit limit is the maximum amount of credit that you can borrow under this Agreement."
									id="agreement-line-of-credit.credit-limit-description"
								/>
							</Td>
						</Tr>
						<Tr>
							<Td>
								<FormattedMessage
									defaultMessage="Annual Interest Rate:"
									id="agreement-line-of-credit.annual-interest-rate"
								/>
							</Td>
							<Td>
								0 % <br />
								<FormattedMessage
									defaultMessage="The annual interest rate is a fixed interest rate per year. It is not compounded. Interest, if applicable, is charged from the date we make an advance under the line of credit until you repay the amounts owing in full. <strong>There are no interest-free grace periods.</strong>"
									id="agreement-line-of-credit.annual-interest-rate-description"
								/>
							</Td>
						</Tr>
						<Tr>
							<Td>
								<FormattedMessage
									defaultMessage="Fees and Other Charges (including Default Charges)"
									id="agreement-line-of-credit.fees-and-other-charges-including-default-charges"
								/>
							</Td>
							<Td>
								<strong>
									<FormattedMessage
										defaultMessage="Other than interest, the following fees apply:"
										id="agreement-line-of-credit.other-fees"
									/>
								</strong>
								<ul style={{ listStyle: 'initial', paddingLeft: '2rem' }}>
									<li style={{ marginTop: '1rem' }}>
										<FormattedMessage
											defaultMessage="membership fee: {amount}. Charged once per month, but only for any month in which you use or have an amount owing under the line of credit."
											id="agreement-line-of-credit.membership-fee"
											values={{
												amount: <FormattedCents value={DEFAULT_MEMBERSHIP_PRICE_CENTS} />,
											}}
										/>
									</li>
									<li style={{ marginTop: '1rem' }}>
										<FormattedMessage
											defaultMessage="admin fee: 0.75%. Charged once per month as % of your lease charges amount, but only for any month in which you use or have an amount owing under the line of credit."
											id="agreement-line-of-credit.admin-fee"
										/>
									</li>
									<li style={{ margin: '1rem 0' }}>
										<FormattedMessage
											defaultMessage="NSF Fees of {amount}. Charged each time a payment is returned unpaid for any reason"
											id="agreement-line-of-credit.nsf-fees"
											values={{
												amount: <FormattedCents value={0} />,
											}}
										/>
									</li>
								</ul>
								<FormattedMessage
									defaultMessage="If you are in default under this Agreement, you may be liable for reasonable charges for our legal costs incurred in collecting or attempting to collect the amounts due and payable by you under this Agreement. incurred by us for any dishonored cheque or other payment instrument used by you to make a payment under this Agreement."
									id="agreement-line-of-credit.default-agreement"
								/>
							</Td>
						</Tr>
						<Tr>
							<Td>
								<FormattedMessage
									defaultMessage="Statement Frequency:"
									id="agreement-line-of-credit.statement-frequency"
								/>
							</Td>
							<Td>
								<FormattedMessage
									defaultMessage="You will receive a line of credit statement at least monthly when you make use of the line of credit, unless there have been no advances or payments during the statement period, and we are not otherwise required under applicable law to provide a statement to you."
									id="agreement-line-of-credit.line-of-credit-statement"
								/>
							</Td>
						</Tr>
						<Tr>
							<Td>
								<FormattedMessage
									defaultMessage="Payment Due Date and Frequency"
									id="agreement-line-of-credit.payment-due-date-and-frequency"
								/>
							</Td>
							<Td>
								<FormattedMessage
									defaultMessage="Each Payment is due upon the earlier of demand or within 30 calendar days of the draw date."
									id="agreement-line-of-credit.each-payment"
								/>{' '}
								<br />
								<FormattedMessage
									defaultMessage="Your Minimum Payment due date will be shown on your monthly statement."
									id="agreement-line-of-credit.minimum-payment-due-date"
								/>
							</Td>
						</Tr>
						<Tr>
							<Td>
								<FormattedMessage
									defaultMessage="Minimum Payment"
									id="agreement-line-of-credit.minimum-payment"
								/>
							</Td>
							<Td>
								<FormattedMessage
									defaultMessage="Your minimum payment represents the full amount of any amount advanced to you from the line of credit. Your minimum payment will be shown on your monthly statement, and it must be paid in full by the payment due date shown on your statement."
									id="agreement-line-of-credit.represented-amount"
								/>
							</Td>
						</Tr>
						<Tr>
							<Td>
								<FormattedMessage
									defaultMessage="Prepayments"
									id="agreement-line-of-credit.prepayments"
								/>
							</Td>
							<Td>
								<FormattedMessage
									defaultMessage="You are entitled to prepay the full outstanding balance at any time without any prepayment charge or penalty."
									id="agreement-line-of-credit.entitlement"
								/>
							</Td>
						</Tr>
						<Tr>
							<Td>
								<FormattedMessage
									defaultMessage="Contact"
									id="agreement-line-of-credit.contact-title"
								/>
							</Td>
							<Td>
								<FormattedMessage
									defaultMessage="You can contact us at no charge at {email} to obtain information about your line of credit during regular business hours."
									id="agreement-line-of-credit.contact-information"
									values={{ email: <a href="mailto:support@myzenbase.com">support@myzenbase.com</a> }}
								/>
							</Td>
						</Tr>
					</tbody>
				</Table>
			</TableContainer>

			<AgreementLineOfCreditTerms />

			<Paragraph>
				<FormattedMessage
					defaultMessage="By confirming this line of credit Agreement, <strong>{fullName}</strong> electronically accepted/signed on {signDate} and agreed to the following:"
					id="agreement-line-of-credit.confirmation-paragraph"
					values={{
						fullName,
						signDate: <FormattedIsoDate value={isoDate(signDate)} />,
					}}
				/>
			</Paragraph>
			<Paragraph>
				<FormattedMessage
					defaultMessage="1) You represent and warrant that all information you have given or will give to us, including about your financial position and as indicated in the representations and warranties set out in [ Section [11] (Representations and warranties) above] , is true and complete at the time you give it to us."
					id="agreement-line-of-credit.representations-and-warranties"
				/>
			</Paragraph>
			<Paragraph>
				<FormattedMessage
					defaultMessage="2) You understand and agree to comply and be bound by the terms and conditions set out above."
					id="agreement-line-of-credit.compliance"
				/>
			</Paragraph>
			<Paragraph>
				<FormattedMessage
					defaultMessage="3) You have received a completed copy of this Agreement, including the Disclosure Statement and agree that delivery of an electronic copy of this Agreement and the Disclosure Statement constitutes delivery under the consumer protection legislation applicable in your province or territory."
					id="agreement-line-of-credit.delivery"
				/>
			</Paragraph>
			<Paragraph>
				<b>
					<FormattedMessage
						defaultMessage="4) You consent to the collection, use and disclosure of your personal information as described in
					Section &nbsp;[ 18 (Personal Information )] &nbsp;above and, in particular, you expressly authorize
					us to obtain or refer to reports containing credit or personal information from any credit bureau or
					credit reporting agency from time to time in connection with this Agreement and any other services
					you may obtain from us. &nbsp;You expressly authorize us to use the information that we obtain from
					such reports to, among other things, market refinancing and our current and future products and
					services to you (both during and after the term of your line of credit and the term of any other
					services you may obtain from us), provide you with other services (including financial management
					tools and credit profile tools) you may request, make decisions related to the servicing and
					collection of your account, measure how the line of credit you obtained from us impacts your credit,
					perform other statistical analysis, and share information with you about your credit profile. You
					understand that we may report information about your line of credit to consumer/credit reporting
					agencies in accordance with applicable law. We will provide you with the name of the credit bureau
					or credit reporting agency on request. &nbsp;This consent is effective as of the date you accept
					this Agreement and will continue for as long as required to fulfill the purposes described above in
					connection with the use of our services. You may withdraw your authorization by emailing us at {email}."
						id="agreement-line-of-credit.consent"
						values={{ email: <a href="mailto:support@myzenbase.com">support@myzenbase.com</a> }}
					/>
				</b>
			</Paragraph>
		</div>
	);
};
