import React from 'react';

export const ConfigurationCircle = () => {
	return (
		<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
			<circle cx="16" cy="16" r="16" fill="#0A6375" />
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12.5002 18.4087C11.7059 18.4087 11.0627 19.0519 11.0627 19.8462C11.0627 20.1569 10.8109 20.4087 10.5002 20.4087C10.1896 20.4087 9.93774 20.1569 9.93774 19.8462C9.93774 18.4305 11.0846 17.2837 12.5002 17.2837C13.9159 17.2837 15.0627 18.4305 15.0627 19.8462C15.0627 20.1569 14.8109 20.4087 14.5002 20.4087C14.1896 20.4087 13.9377 20.1569 13.9377 19.8462C13.9377 19.0519 13.2946 18.4087 12.5002 18.4087Z"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12.5002 11.4092C11.7059 11.4092 11.0627 12.0523 11.0627 12.8467V19.8467C11.0627 20.641 11.7059 21.2842 12.5002 21.2842C13.2946 21.2842 13.9377 20.641 13.9377 19.8467V12.8467C13.9377 12.0523 13.2946 11.4092 12.5002 11.4092ZM12.5002 22.4092C11.0846 22.4092 9.93774 21.2623 9.93774 19.8467V12.8467C9.93774 11.431 11.0846 10.2842 12.5002 10.2842C13.9159 10.2842 15.0627 11.431 15.0627 12.8467V19.8467C15.0627 21.2623 13.9159 22.4092 12.5002 22.4092Z"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M17.5007 12.2837C17.8114 12.2837 18.0632 12.5355 18.0632 12.8462C18.0632 13.6405 18.7064 14.2837 19.5007 14.2837C20.2951 14.2837 20.9382 13.6405 20.9382 12.8462C20.9382 12.5355 21.1901 12.2837 21.5007 12.2837C21.8114 12.2837 22.0632 12.5355 22.0632 12.8462C22.0632 14.2619 20.9164 15.4087 19.5007 15.4087C18.0851 15.4087 16.9382 14.2619 16.9382 12.8462C16.9382 12.5355 17.1901 12.2837 17.5007 12.2837Z"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M19.5007 11.4092C18.7064 11.4092 18.0632 12.0523 18.0632 12.8467V19.8467C18.0632 20.641 18.7064 21.2842 19.5007 21.2842C20.2951 21.2842 20.9382 20.641 20.9382 19.8467V12.8467C20.9382 12.0523 20.2951 11.4092 19.5007 11.4092ZM19.5007 22.4092C18.0851 22.4092 16.9382 21.2623 16.9382 19.8467V12.8467C16.9382 11.431 18.0851 10.2842 19.5007 10.2842C20.9164 10.2842 22.0632 11.431 22.0632 12.8467V19.8467C22.0632 21.2623 20.9164 22.4092 19.5007 22.4092Z"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M10 23.875H22C23.0357 23.875 23.875 23.0357 23.875 22V10C23.875 8.96432 23.0357 8.125 22 8.125H10C8.96432 8.125 8.125 8.96432 8.125 10V22C8.125 23.0357 8.96432 23.875 10 23.875ZM10 25H22C23.657 25 25 23.657 25 22V10C25 8.343 23.657 7 22 7H10C8.343 7 7 8.343 7 10V22C7 23.657 8.343 25 10 25Z"
				fill="white"
			/>
		</svg>
	);
};
