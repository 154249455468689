import React from 'react';
import styled from 'styled-components';
import houseRight from 'url:./houseRight@2x.png';

export const StyledHouseRight = styled.div`
	position: absolute;
`;

export const HouseRight = () => {
	return (
		<StyledHouseRight>
			<img src={houseRight} width="475px" height="441px" />
		</StyledHouseRight>
	);
};
