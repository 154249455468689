import React from 'react';
import { useIntl } from 'react-intl';

import { ProductPaymentsHistory } from '../../components/product-payment-history';

export const AccountInstaMoneyHistory = () => {
	const intl = useIntl();

	return (
		<ProductPaymentsHistory
			title={intl.formatMessage({ id: 'account.advance-history', defaultMessage: 'Advance history' })}
			type="insta-money"
		/>
	);
};
