import React from 'react';
import { FormattedDate, FormattedMessage, FormattedNumber } from 'react-intl';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { isoDateToJsDate } from '../../../../functions/src/shared/iso-date';
import { centsToAmount } from '../../../../functions/src/shared/monetary';

import { RouterInput, trpc } from '../../shared/trpc/client';

import { Anchor, Flex, Icon, Paper, PaperSection, PaperTitle, Spacing, Text } from '../../base-ui/components';
import { colors } from '../../base-ui/variables';

import { links } from '../Router/paths';
import { ContainerBackground, ContainerContent } from './app-container';
import { BackButton } from './back-button';

const Title = styled(Text)`
	font-size: 2rem;
	font-weight: 700;
	color: ${colors.green};
	display: flex;
	align-items: center;
`;

const HistoryIcon = styled(Icon).attrs(() => ({ icon: 'History' }))`
	display: inline-block;
	width: 2.4rem;
	height: 2.4rem;
	font-size: 2.4rem;
	margin-left: 0.5rem;
`;

const Background = styled(ContainerBackground)`
	height: 25rem;
`;

type Props = {
	title: string;
	type: RouterInput['user']['listPaymentsHistory']['type'];
};

export const ProductPaymentsHistory = ({ title, type }: Props) => {
	const { data, isPending, isError } = trpc.user.listPaymentsHistory.useQuery({ type });

	return (
		<>
			<ContainerContent>
				<Flex alignItems="center" justifyContent="space-between">
					<BackButton to={links.ACCOUNT.SETTINGS} />
					<Link to={links.ACCOUNT.DASHBOARD}>
						<Icon icon="Close" />
					</Link>
				</Flex>
				<Title>
					{title}
					<HistoryIcon icon="History" />
				</Title>
				<Spacing $size="m" />

				<Paper>
					{data?.map(({ effectiveDate, amountCents, feeAmountCents }, index) => {
						return (
							<PaperSection
								label={
									<>
										<FormattedMessage
											defaultMessage="Paid on {date}"
											id="product-payments-history.paid-on"
											values={{
												date: (
													<FormattedDate
														value={isoDateToJsDate(effectiveDate)}
														day="numeric"
														month="short"
														year="numeric"
													/>
												),
											}}
										/>
									</>
								}
								key={index}
								divider={index < data.length - 1}
							>
								<FormattedNumber value={centsToAmount(amountCents)} style="currency" currency="CAD" />{' '}
								{Boolean(feeAmountCents) && amountCents !== amountCents && (
									<>
										<FormattedMessage
											defaultMessage="(including {feeAmount} administration fees)"
											id="product-payments-history.including-administration-fees"
											values={{
												feeAmount: (
													<FormattedNumber
														value={centsToAmount(feeAmountCents)}
														style="currency"
														currency="CAD"
													/>
												),
											}}
										/>
									</>
								)}
							</PaperSection>
						);
					})}
					{data?.length === 0 && (
						<PaperTitle>
							<FormattedMessage
								defaultMessage="History is empty"
								id="product-payments-history.history-empty"
							/>
						</PaperTitle>
					)}
					{isPending && (
						<PaperTitle>
							<FormattedMessage defaultMessage="Loading..." id="product-payments-history.loading" />
						</PaperTitle>
					)}
					{isError && (
						<PaperTitle>
							<FormattedMessage
								defaultMessage="Error fetching data"
								id="product-payments-history.error-fetching-data"
							/>
						</PaperTitle>
					)}
				</Paper>
				<Spacing $size="l" />

				<Text type="small">
					<FormattedMessage
						defaultMessage="For all your questions and feedback please email us at {email}"
						id="product-payments-history.questions-feedback"
						values={{
							email: (
								<Anchor href="mailto:support@myzenbase.com" target="_blank">
									support@myzenbase.com
								</Anchor>
							),
						}}
					/>
				</Text>
			</ContainerContent>
			<Background />
		</>
	);
};
