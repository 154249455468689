import React from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { Modal } from '../../../base-ui/components';

import { useInterface } from '../../contexts/hooks';
import { links } from '../../Router/paths';

export const DirectDebitChargeCancel = () => {
	const { closeModal } = useInterface();
	const navigate = useNavigate();
	const intl = useIntl();

	return (
		<Modal
			type="warning"
			headerText={intl.formatMessage({
				defaultMessage: 'Cancel debit card payment',
				id: 'debit-card-charge-cancel-modal.are-you-sure',
			})}
			contentText={intl.formatMessage({
				defaultMessage: 'Are you sure that you want to cancel the debit card payment?',
				id: 'debit-card-charge-cancel-modal.content',
			})}
			buttonText={intl.formatMessage({
				defaultMessage: 'Continue with the debit card payment',
				id: 'debit-card-charge-cancel-modal.ok',
			})}
			buttonHandler={closeModal}
			buttonTestId="custom-rent-setup-modal-cancel-button"
			altButtonTestId="custom-rent-setup-modal-decline-button"
			altButtonText={intl.formatMessage({
				defaultMessage: 'No, cancel debit card payment',
				id: 'debit-card-charge-cancel-modal.cancel',
			})}
			altButtonHandler={async () => {
				navigate(links.ACCOUNT.DASHBOARD);
			}}
		/>
	);
};
