import React from 'react';

export const InstaMoneyCircle = () => (
	<svg width="1em" height="1em" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
		<rect width="40" height="40" rx="20" fill="#0A6375" />
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M23.437 15.9471C21.5391 14.0492 18.4603 14.0492 16.5623 15.9471C14.6644 17.845 14.6644 20.9239 16.5623 22.8218C18.4603 24.7197 21.5391 24.7197 23.437 22.8218C25.335 20.9239 25.335 17.845 23.437 15.9471ZM24.4191 14.965C21.9788 12.5247 18.0206 12.5247 15.5803 14.965C13.1399 17.4053 13.1399 21.3636 15.5803 23.8039C18.0206 26.2442 21.9788 26.2442 24.4191 23.8039C26.8595 21.3636 26.8595 17.4053 24.4191 14.965Z"
			fill="white"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M14.3682 24.9386C14.3682 24.5551 14.6791 24.2441 15.0626 24.2441H24.9392C25.3227 24.2441 25.6336 24.5551 25.6336 24.9386C25.6336 25.3221 25.3227 25.633 24.9392 25.633H15.0626C14.6791 25.633 14.3682 25.3221 14.3682 24.9386Z"
			fill="white"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M12.5924 29.722H27.4072C28.6858 29.722 29.722 28.6858 29.722 27.4072V12.5924C29.722 11.3138 28.6858 10.2776 27.4072 10.2776H12.5924C11.3138 10.2776 10.2776 11.3138 10.2776 12.5924V27.4072C10.2776 28.6858 11.3138 29.722 12.5924 29.722ZM12.5924 31.1109H27.4072C29.4529 31.1109 31.1109 29.4529 31.1109 27.4072V12.5924C31.1109 10.5467 29.4529 8.88867 27.4072 8.88867H12.5924C10.5467 8.88867 8.88867 10.5467 8.88867 12.5924V27.4072C8.88867 29.4529 10.5467 31.1109 12.5924 31.1109Z"
			fill="white"
		/>
	</svg>
);
