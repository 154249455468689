import React, { AnchorHTMLAttributes, PropsWithChildren } from 'react';
import styled from 'styled-components';

import { colors } from '../../variables';
import { bodyCss } from '../Text/body.style';

type AnchorAttributes = Pick<AnchorHTMLAttributes<HTMLAnchorElement>, 'href' | 'rel' | 'target' | 'onClick'>;

export type AnchorProps = PropsWithChildren<AnchorAttributes>;

const StyledAnchor = styled.a`
	${bodyCss}
	text-decoration: none;
	color: ${colors.green};
	font: inherit;

	&:hover,
	&:focus {
		color: ${colors.greenHover};
	}
`;

export const Element = ({ rel, target, href, children, onClick }: AnchorProps) => (
	<StyledAnchor rel={rel} target={target} href={href} onClick={onClick}>
		{children}
	</StyledAnchor>
);

export const Anchor = styled(Element)``;
