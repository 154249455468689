import styled from 'styled-components';

import { colors } from '../../variables';
import { Text } from '../Text/Text';

export const ModalWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 2rem 3rem;
	@media (max-width: 450px) {
		padding: 1rem 1.5rem;
	}
`;

export const ModalTitle = styled(Text).attrs(({ color }) => ({
	color: color || 'highlighted',
	center: true,
}))`
	font-weight: bold;
	font-size: 2rem;
	line-height: 2.7rem;
	margin-bottom: 1rem;
	margin-top: 2rem;
`;

export const ModalText = styled(Text).attrs({
	type: 'small',
	center: true,
})`
	margin-bottom: 4rem;
`;

export const ModalIllustration = styled.div`
	width: 6rem;
	height: 6rem;
	background: ${colors.teal};
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	margin: 0 auto;
`;
