import { trpc } from '../../shared/trpc/client';

export const useTrackOnboardingEvent = () => {
	const utils = trpc.useUtils();
	const trackOnboardingEvent = trpc.user.trackOnboardingEvent.useMutation({
		onSuccess(onboardingEvents) {
			utils.user.listOnboardingEvents.setData(undefined, onboardingEvents);
		},
	});

	return trackOnboardingEvent;
};
