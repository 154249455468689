import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import congrats from 'url:../static/shapes/congrats.png';
import learning from 'url:../static/shapes/learning.png';
import onboarding1 from 'url:../static/shapes/onboarding-1.png';
import onboarding2 from 'url:../static/shapes/onboarding-2.png';
import payToLandlord from 'url:../static/shapes/pay-to-landlord.png';
import reschedule from 'url:../static/shapes/reschedule.png';
import support from 'url:../static/shapes/support.png';

import { BusinessEventType } from '../../../../functions/src/shared/business-events';

import { RouterInput, trpc } from '../../shared/trpc/client';

import { Box, Button, Icon, Spacing, Text } from '../../base-ui/components';

import { ImgPreload } from '../components/img-preload';
import { LearnMoreAboutCreditImpactModal } from '../components/modals/learn-more-about-credit-impact-modal';
import { useInterface } from '../contexts/hooks';
import { useUserData } from '../contexts/user-data-context';
import { links } from '../Router/paths';
import { useTrackOnboardingEvent } from '../utils/track-onboarding-event';
import { OnboardingSuccess } from './onboarding-success';

type State = null | 'step-2' | 'step-3' | 'step-4' | 'step-5' | 'step-6' | 'step-7' | 'step-waitlist';

type Props = {
	variant: 'onboarding' | 'account';
};

export const CreditBuilderPaymentCompleted = ({ variant }: Props) => {
	const location = useLocation();
	const navigate = useNavigate();
	const [search] = useSearchParams();
	const { setModal, closeModal } = useInterface();

	const { customRentSupported } = useUserData();
	const trackOnboardingEvent = useTrackOnboardingEvent();

	const info = trpc.creditReporting.getUserInfo.useQuery();

	const [confirmingAfterPayment, setConfirmingAfterPayment] = useState(false);

	const state = (location.state || 'step-2') as State;

	const confirm = trpc.creditReporting.confirmRental.useMutation({
		onSuccess() {
			navigate('', { state: 'step-2' });
			setConfirmingAfterPayment(false);
		},
	});

	useEffect(() => {
		if (search.get('payment') === 'success' && !confirm.isPending) {
			setConfirmingAfterPayment(true);
			confirm.mutate();
		}
	}, [search, confirm.isPending]);

	const handleEnd = () => {
		trackOnboardingEvent.mutate(
			{ type: BusinessEventType.OnboardingCreditBuilderRentalEnd },
			{
				onSuccess() {
					navigate(links.ACCOUNT.DASHBOARD);
				},
			},
		);
	};

	if (info.isPending) return null;

	if (confirmingAfterPayment && !confirm.isError) {
		return null;
	}

	if (state === 'step-2') {
		return (
			<>
				<Box isColumn verticalCenter>
					<img src={congrats} alt="" width="335" />
					<Spacing $size="m" />

					<OnboardingSuccess
						title={
							<FormattedMessage
								defaultMessage="Congrats for building credit with ease!"
								id="credit-builder-payment-completed-congrats-title"
							/>
						}
						subtitle={
							<FormattedMessage
								defaultMessage="We will start reporting your next month's rent payments with ease so that you get credit for the biggest expense of the month."
								id="credit-builder-payment-completed-congrats-subtitle"
							/>
						}
						beforeCta={
							<>
								<img src={learning} alt="" width="180" />
								<Spacing $size="m" />
								<Text center color="title">
									<FormattedMessage
										defaultMessage="Here are a few things to remember."
										id="credit-builder-payment-completed-learning-title"
									/>
								</Text>
								<Spacing $size="l" />
								<Icon icon="DownArrow" color="green" />
							</>
						}
						cta={<FormattedMessage defaultMessage="Next" id="common.next" />}
						ctaTestId="onboarding-success-cb-step2-continue-button"
						onNext={() => navigate('', { state: 'step-3' })}
						backButton={false}
					/>
				</Box>

				<ImgPreload src={support} />
				<ImgPreload src={reschedule} />
			</>
		);
	}

	if (state === 'step-3') {
		return (
			<OnboardingSuccess
				imageSrc={reschedule}
				title={
					<FormattedMessage
						defaultMessage="Check your reported rent payments"
						id="credit-builder-payment-completed-check-rent-payments-title"
					/>
				}
				subtitle={
					<FormattedMessage
						defaultMessage="It may take as long as 3 months for your rent payments to appear on your Equifax credit report."
						id="credit-builder-payment-completed-check-rent-payments-subtitle"
					/>
				}
				ctaTestId="onboarding-success-cb-step3-continue-button"
				onNext={() => navigate('', { state: 'step-4' })}
			/>
		);
	}

	if (state === 'step-4') {
		return (
			<OnboardingSuccess
				imageSrc={onboarding2}
				title={
					<FormattedMessage
						defaultMessage="Monitor your credit impact"
						id="credit-builder-payment-completed-monitor-credit-impact-title"
					/>
				}
				subtitle={
					<FormattedMessage
						defaultMessage="Positive payments and credit history are very important to improve your credit. By reporting rent payments consistently and adding past payments, you will build your credit faster."
						id="credit-builder-payment-completed-monitor-credit-impact-subtitle"
					/>
				}
				ctaTestId="onboarding-success-cb-step4-continue-button"
				secondaryCta={
					<div>
						<Button
							type="link"
							onClick={() => {
								setModal(
									<LearnMoreAboutCreditImpactModal
										buttonHandler={() => {
											closeModal();
										}}
									/>,
								);
							}}
						>
							<FormattedMessage
								defaultMessage="Learn more on the credit impact"
								id="credit-builder-payment-completed-learn-more.secondary-cta"
							/>
							<Icon icon="Info" />
						</Button>
					</div>
				}
				onNext={() => navigate('', { state: 'step-5' })}
			/>
		);
	}

	if (state === 'step-5') {
		return (
			<OnboardingSuccess
				imageSrc={support}
				title={
					<FormattedMessage
						defaultMessage="Always here to assist"
						id="credit-builder-payment-completed-always-here-title"
					/>
				}
				subtitle={
					<FormattedMessage
						defaultMessage="If you have any questions around CreditBuilder and other solutions like CustomRent payments feel free to text message your Zenbase team."
						id="credit-builder-payment-completed-always-here-subtitle"
					/>
				}
				ctaTestId="onboarding-success-cb-step5-continue-button"
				onNext={() => {
					if (variant === 'onboarding') {
						navigate('', { state: 'step-6' });
					} else {
						handleEnd();
					}
				}}
			/>
		);
	}

	if (state === 'step-6') {
		return (
			<OnboardingSuccess
				imageSrc={onboarding1}
				title={
					<FormattedMessage
						defaultMessage="The most flexible rent payments ever"
						id="credit-builder-payment-completed-flexible-rent-payments-title"
					/>
				}
				subtitle={
					<FormattedMessage
						defaultMessage="Smart rent payments that let you split your rent if you'd like and improve your cashflow."
						id="credit-builder-payment-completed-flexible-rent-payments-subtitle"
					/>
				}
				ctaTestId="onboarding-success-cb-step6-continue-button"
				onNext={() => {
					const events: RouterInput['user']['trackOnboardingEvent']['type'] = [
						BusinessEventType.OnboardingCreditBuilderRentalEnd,
					];
					if (!customRentSupported) {
						events.push(BusinessEventType.OnboardingCustomRentWaitlistStart);
					} else {
						events.push(BusinessEventType.OnboardingCustomRentStart);
					}

					trackOnboardingEvent.mutate({
						type: events,
					});
				}}
				ctaDisabled={trackOnboardingEvent.isPending}
				cta={
					<FormattedMessage
						defaultMessage="Set up flexible rent payments"
						id="credit-builder-payment-completed-flexible-rent-payments-button"
					/>
				}
				secondaryCta={
					<FormattedMessage defaultMessage="Not now" id="credit-builder-payment-completed-not-now-button" />
				}
				onSecondaryCta={() => {
					navigate('', { state: 'step-7' });
				}}
			/>
		);
	}

	if (state === 'step-7') {
		return (
			<OnboardingSuccess
				imageSrc={payToLandlord}
				title={
					<FormattedMessage
						defaultMessage="Make rent payments directly to your landlord"
						id="credit-builder-payment-completed-make-rent-payments-title"
					/>
				}
				ctaTestId="onboarding-success-cb-step7-continue-button"
				subtitle={
					<FormattedMessage
						defaultMessage="Please make your payments directly to your landlord with your preferred method of payment. You can always enable CustomRent on your Zenbase dashboard."
						id="credit-builder-payment-completed-make-rent-payments-subtitle"
					/>
				}
				onNext={() => {
					trackOnboardingEvent.mutate({
						type: BusinessEventType.OnboardingCreditBuilderRentalEnd,
					});
				}}
				ctaDisabled={trackOnboardingEvent.isPending}
				cta={
					<FormattedMessage
						defaultMessage="Ok, I Understand"
						id="credit-builder-payment-completed-ok-button"
					/>
				}
			/>
		);
	}

	return null;
};
