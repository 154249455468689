import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { EligibilityRules } from '../../../../../../functions/src/shared/eligibility';

import { triggerChatWidget } from '../../../../shared/zendesk';

import { Button, WarningModal } from '../../../../base-ui/components';
import { colors } from '../../../../base-ui/variables';

import { useInterface } from '../../../contexts/hooks';
import { links } from '../../../Router/paths';
import { formatEligibilityResults, ProfileErrorsModalReviewingDetails } from '../profile-errors/profile-errors-modal';

export function CreditBuilderInactiveButton({
	hasOpenRentTradeline,
	onClickLinkHandler,
	indirectVerificationPending,
}: {
	hasOpenRentTradeline: boolean;
	indirectVerificationPending: boolean;
	onClickLinkHandler: (() => void) | null;
}) {
	const navigate = useNavigate();
	const { setModal, closeModal } = useInterface();

	const verificationPendingHandler = () => {
		const errors = [
			{
				key: EligibilityRules.ResidentVerification,
				rule: EligibilityRules.ResidentVerification,
				success: false,
				details: null,
			},
		];
		setModal(
			<ProfileErrorsModalReviewingDetails
				errors={formatEligibilityResults(errors, false)}
				secondaryCta={null}
				cta={
					<Button
						onClick={() => {
							closeModal();
							triggerChatWidget();
						}}
					>
						<FormattedMessage defaultMessage="Contact Support" id="profile.contact-support" />
					</Button>
				}
			/>,
		);
	};

	const noOpenTradelineHandler = () => {
		setModal(
			<WarningModal
				headerText={
					<FormattedMessage
						defaultMessage="Not eligible for CreditBuilder"
						id="credit-builder-card.not-eligible"
					/>
				}
				contentText={
					<FormattedMessage
						defaultMessage="Your tradeline was closed, therefore you will not able be to report your rent payments again for your current lease."
						id="credit-builder-card.not-eligible-content"
					/>
				}
				buttonText={<FormattedMessage defaultMessage="Ok" id="common.ok" />}
				buttonHandler={() => closeModal()}
				altButtonText={
					<FormattedMessage defaultMessage="My lease changed" id="credit-builder-card.my-lease-changed" />
				}
				altButtonHandler={() => {
					closeModal();
					navigate(links.ACCOUNT.DID_YOU_MOVE_OUT);
				}}
			/>,
		);
	};

	if (onClickLinkHandler) {
		return (
			<Button type="large-error" onClick={onClickLinkHandler}>
				<FormattedMessage defaultMessage="Finish setup" id="credit-builder-card.finish-setup" />
			</Button>
		);
	}
	if (indirectVerificationPending) {
		return (
			<Button type="link" onClick={verificationPendingHandler}>
				<ShowDetails>
					<FormattedMessage defaultMessage="Show Details" id="profile-errors.show-details" />
				</ShowDetails>
			</Button>
		);
	}
	if (!hasOpenRentTradeline) {
		return (
			<Button type="large-error" onClick={noOpenTradelineHandler}>
				<FormattedMessage defaultMessage="Learn more" id="learn-more.learn-more" />
			</Button>
		);
	}
	return null;
}

const ShowDetails = styled.u`
	color: ${colors.error};
`;
