// import type here, because technically these are backend deps that should not be available in frontend code
import type { DecodedIdToken } from 'firebase-admin/lib/auth/token-verifier';
import type { ParsedToken } from 'firebase/auth';
import { z } from 'zod';

import { AccountSource } from './account';
import { portfolioTypeSchemaAll } from './credit-reporting';
import { EligibilityRules } from './eligibility';
import { isoDate } from './iso-date';
import { PaymentProvider } from './payment-provider';
import { PropertyResident } from './pms';

export type UserDetail = {
	userId: string;
	fullName: string;
	firstName: string;
	lastName: string;
	email: string;
	phone: string;
	createdAt: Date;
	status: number;
	verified: boolean;
};

/**
 * Feature IDs - used mainly in payment_transactions as a product identifier
 *
 * Values from `features` table also used for feature switches in `user_features` table,
 * so numbers must not overlap.
 * @see FeatureSwitch
 */
export enum FeatureType {
	BalanceBoost = 1,
	CustomRent = 2,
	InstaMoney = 3, // CashAdvance
	LandlordPayment = 6,
	/**
	 * Entry in user ledger, not connected to any other feature
	 *
	 * e.g. reimbursements of late fees when it is our fault, or other
	 * ad hoc cases.
	 */
	OtherPayment = 10,
	UnifiedPayments = 12,
	CreditReportingRental = 13, // CreditBuilder
	CreditReportingLineOfCredit = 16,
	MicrodepositVerification = 17,
	ZumWallet = 18,
	CreditReportingRentHistoryUpsell = 19, // Credit builder rent history reporting upsell

	SecuredLineOfCredit = 20, // Secured Line of Credit - User Rent feature
}

export const featureNames: Record<FeatureType, string> = {
	[FeatureType.BalanceBoost]: 'Balance Boost',
	[FeatureType.CustomRent]: 'Custom Rent',
	[FeatureType.InstaMoney]: 'InstaMoney',
	[FeatureType.LandlordPayment]: 'Landlord Payment',
	[FeatureType.OtherPayment]: 'Other Payment',
	[FeatureType.UnifiedPayments]: 'Unified Payments',
	[FeatureType.CreditReportingRental]: 'Credit Reporting - Rental',
	[FeatureType.CreditReportingLineOfCredit]: 'Credit Reporting - Line Of Credit',
	[FeatureType.CreditReportingRentHistoryUpsell]: 'Credit Reporting - Rent History Upsell',
	[FeatureType.MicrodepositVerification]: 'Microdeposit Verification',
	[FeatureType.ZumWallet]: 'Zum Wallet',
	[FeatureType.SecuredLineOfCredit]: 'Secured Line of Credit',
};

export const featureNameToId = (featureName: string): FeatureType => {
	return Number(Object.entries(featureNames).find(([, name]) => name === featureName)?.[0]) as unknown as FeatureType;
};

/**
 * Active feature switches.
 *
 * Remove from this enum once feature is deployed for everybody.
 *
 * Should be roughly kept in sync with db table `features` (with deployed = false)
 *
 * @see FeatureType - for permanent feature_ids that are also used in database tables
 */
export enum FeatureSwitch {
	// TabaPay = 4,
	// TabaPayUseSubClient = 5,
	// Plaid = 8,
	Flinks = 7,
	ZumRails = 11,
	CreditReportingRental = FeatureType.CreditReportingRental,
	DemoNotifications = 14,
	Stripe = 15,
	CreditReportingLineOfCredit = FeatureType.CreditReportingLineOfCredit,
	/* @deprecated */
	UnifiedPayments = FeatureType.UnifiedPayments,
}

export const demoOnlyFeatures = [
	FeatureSwitch.ZumRails,
	FeatureSwitch.UnifiedPayments,
	FeatureSwitch.DemoNotifications,
	FeatureSwitch.Stripe,
] as const;

export const cashAdvanceFeatureTypes = [FeatureType.BalanceBoost, FeatureType.InstaMoney];

export const relevantMarketingFeatureTypes = [
	FeatureType.InstaMoney,
	FeatureType.CustomRent,
	FeatureType.CreditReportingRental,
];

export type UserType = 'direct' | 'indirect';

export interface User {
	userId: string;
	email: string;
	firstName: string;
	lastName: string;
	fullName: string;
	phone: string;
	status: number;
	createdAt: Date;
	previousStatusId?: number;
	maxPercentDiffBeetwenPayrollOverride?: number;
	verified: boolean;
	eligRuleOverrides: Record<string, UserEligibilityRuleOverride>;
	pmsResidency?: PropertyResident;
	messageBirdContactId?: string;
	zendeskId?: string | null;
	/**
	 * Default account to use for bank account transfers
	 */
	paymentAccountId?: string | null;
	/**
	 * Default debit card to use for card transactions
	 */
	paymentCardId?: string | null;

	enroll2fa?: boolean;
	claims: UserClaims;
	enabledFeatures: FeatureSwitch[];
	inviteId?: string;
	riskDecile?: number;
	landlordPaymentMethod?: string;
	paymentPortal?: UserPaymentPortal;
	employer?: string;
	messagebirdNumber?: string;
	messagebirdNumberName?: string;
	userType?: UserType;
	referralPartnerId: string | null;
	locale: UserLocale;
	timezone?: string | null;
	countryCode?: string | null;
}

export enum UserStatus {
	NewUser = 0,
	Active = 1,
	Inactive = 2,
	Deleted = 3,
	Fraudulent = 4,
}

export function isValidUserStatus(status: UserStatus) {
	return [UserStatus.Active, UserStatus.NewUser].includes(status);
}

export function userHasFeatureEnabled(user: User, feature: FeatureSwitch) {
	return user.enabledFeatures.includes(feature);
}

export interface UserEligibilityRuleOverride {
	userId: string;
	rule: EligibilityRules;
	action: EligibilityRuleOverridesAction;
	expirationDate: Date;
}

export enum EligibilityRuleOverridesAction {
	Skip = 1,
}

export type UserDisableCustomRentSurvey = {
	reason: string;
	reasonOther?: string;
};

export type UserNote = {
	contents?: string;
	userId: string;
};

export const UserClaimsSchema = z.object({
	admin: z.boolean().optional(),
	superadmin: z.boolean().optional(),
	partner: z.literal('admin').optional(),
});

export type UserClaims = z.infer<typeof UserClaimsSchema>;

export function isUserAdminByClaims(claims: UserClaims | ParsedToken | DecodedIdToken) {
	return claims?.admin === true;
}

export function isUserSuperadminByClaims(claims: UserClaims | ParsedToken | DecodedIdToken | undefined) {
	return claims?.admin === true && claims?.superadmin === true;
}

export enum UserLandlordPaymentMethod {
	PreauthorizedDebit = 'preauthorized-debit',
	Check = 'check',
	Etransfer = 'e-transfer',
	InPersonAtTheOffice = 'in-person-at-the-office',
	CreditCard = 'credit-card',
	Other = 'other',
	NotSet = 'not-set',
}

export const userPaymentPortalNotSetNameValue = 'not-set';

export const adminUserListFiltersSchema = z
	.object({
		search: z.string(),
		userStatus: z.nativeEnum(UserStatus),
		landlord: z.string(),
		landlords: z.array(z.string()),
		propertyManager: z.string(),
		propertyManagers: z.array(z.string()),
		propertyName: z.string().trim(),
		propertyEarlyMoveInSupported: z.boolean(),
		propertyVerified: z.boolean(),
		bankAccountProvider: z.nativeEnum(PaymentProvider),
		hasModernTreasuryUnverified: z.boolean(),
		delinquentBalance: z.union([z.literal('any'), z.literal('customRent'), z.literal('cashAdvance')]),
		unscheduledBalance: z.union([z.literal('any'), z.literal('customRent'), z.literal('cashAdvance')]),
		postalCode: z.string(),
		city: z.string(),
		country: z.string(),
		employer: z.string(),
		hasUserRiskScore: z.boolean(),
		accountSource: z.nativeEnum(AccountSource),
		userPmsResidencyIsNotInRecommendedResidents: z.boolean(),
		userIds: z.array(z.string()),
		isEligible: z.boolean(),
		eligibilityBankConnected: z.boolean(),
		eligibilityConstantIncome: z.boolean(),
		eligibilitySufficientIncome: z.boolean(),
		eligibilityNotOutstandingPayments: z.boolean(),
		eligibilityResidentVerification: z.boolean(),
		eligibilityArrears: z.boolean(),
		createdAt: z.string().refine(isoDate),
		hasBankAccount: z.boolean(),
		creditSubjectPortfolioType: portfolioTypeSchemaAll,
		everHadCustomRent: z.boolean(),
		hasPaymentPlan: z.boolean(),
		propertyResidentDeleted: z.boolean(),
		referralPartnerId: z.string(),
		indirectLandlordIds: z.array(z.string()),
	})
	.partial();

export type AdminUserListFilters = z.infer<typeof adminUserListFiltersSchema>;

export enum SignUpType {
	Default = 'default',
	CreditBuilder = 'credit_builder',
}

export type UserPaymentPortal = {
	name: string;
	username?: string;
	password?: string;
};

export enum UserLocale {
	EnglishCa = 'en-CA',
	FrenchCa = 'fr-CA',
}

export const allUserLocales = [UserLocale.EnglishCa, UserLocale.FrenchCa] as const;
