'use strict';

/* eslint-disable @typescript-eslint/no-non-null-assertion */ function createDeferred() {
    let resolve;
    let reject;
    const promise = new Promise((res, rej)=>{
        resolve = res;
        reject = rej;
    });
    return {
        promise,
        resolve: resolve,
        reject: reject
    };
}
const createTimeoutPromise = (timeoutMs, value)=>{
    let deferred = createDeferred();
    deferred = deferred;
    let timeout = null;
    const clear = ()=>{
        if (timeout) {
            clearTimeout(timeout);
            timeout = null;
        }
    };
    const resolve = ()=>{
        deferred.resolve(value);
        clear();
    };
    if (timeoutMs !== Infinity) {
        timeout = setTimeout(resolve, timeoutMs);
        timeout.unref?.();
    }
    return {
        promise: deferred.promise,
        /**
     * Clear the timeout without resolving the promise
     */ clear,
        /**
     * Resolve the promise with the value
     */ resolve
    };
};

exports.createDeferred = createDeferred;
exports.createTimeoutPromise = createTimeoutPromise;
