import styled from 'styled-components';

import { colors } from '../../base-ui/variables';

export const HighlightTeal = styled.span`
	color: ${colors.teal};
`;

export const Highlight = styled.span`
	font-weight: 700;
	color: ${colors.green};
`;
