import styled from 'styled-components';

import { colors } from '../../variables';

export const Wrapper = styled.div`
	padding: 0 0 4rem;
	position: relative;
	display: flex;
	align-items: flex-start;
`;

export const ErrorMessage = styled.div`
	color: ${colors.error};
	position: absolute;
	bottom: 0;
	left: 0;
`;
