import React from 'react';
import { Controller } from 'react-hook-form';

import { AutoSuggestInput } from '../../base-ui/components';

type Props = {
	name: string;
	label: string;
	options: string[];
	testId?: string;
	getOptionLabel: (value: string) => string;
};

export const FormAutoSuggestInput = (props: Props) => (
	<Controller
		name={props.name}
		render={({ field: { value, onChange }, fieldState: { error } }) => (
			<AutoSuggestInput
				label={props.label}
				error={error?.message}
				testId={props.testId}
				autocomplete={{
					value: value || '',
					onChange: (_e, v) => onChange(v),
					options: props.options,
					getOptionLabel: props.getOptionLabel,
				}}
			/>
		)}
	/>
);
