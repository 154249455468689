import React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import { BankOnboardingProvider } from '../../contexts';
import { useUserData } from '../../contexts/user-data-context';
import { links } from '../paths';

export const AccountBankConnectWrapper = () => {
	const navigate = useNavigate();
	const { home } = useUserData();

	const indirectModel = Boolean(home?.dashboard.indirectModel);

	return (
		<BankOnboardingProvider
			links={links.ACCOUNT.BANK}
			onCompleted={() => {
				if (indirectModel) {
					navigate(links.ACCOUNT.CREDIT_BUILDER.CONFIRM_RENT_PAYMENT);
					return;
				}
				navigate(links.ACCOUNT.DASHBOARD);
			}}
			variant={indirectModel ? 'credit-builder' : 'custom-rent'}
		>
			<Outlet />
		</BankOnboardingProvider>
	);
};
