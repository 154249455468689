import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import connectBank from 'url:../../static/shapes/connect-bank.png';

import { trpc } from '../../../shared/trpc/client';
import { triggerChatWidget } from '../../../shared/zendesk';

import { Anchor, Box, Button, Footer, Spacing, Text } from '../../../base-ui/components';

import { BackButton } from '../../components/back-button';
import { useBankOnboarding } from '../../contexts/hooks';

export const StartVerification = () => {
	const { manualOnboardingValues, handleVerificationDone, links } = useBankOnboarding();
	const navigate = useNavigate();
	const startManualBankVerification = trpc.user.startAccountVerification.useMutation();

	useEffect(() => {
		if (manualOnboardingValues === null) {
			navigate(links.MANUAL_ENTER_BANK_DETAILS);
		}
	}, [manualOnboardingValues]);

	const start = async () => {
		if (manualOnboardingValues === null) {
			throw new Error('This should not happen');
		}
		const result = await startManualBankVerification.mutateAsync(manualOnboardingValues);
		if (result.code === 'verification-started') {
			handleVerificationDone();
		}
	};

	if (startManualBankVerification.data?.code === 'verification-already-started') {
		return (
			<>
				<BackButton to={links.CONNECT_BANK} />
				<Text type="title">
					<FormattedMessage
						defaultMessage="Account already connected"
						id="start-verification.account-already-connected"
					/>
				</Text>
				<Spacing $size="l" />
				<Text>
					<FormattedMessage
						defaultMessage="Same account is already waiting for verification."
						id="start-verification.same-account-waiting-verification"
					/>
				</Text>
			</>
		);
	} else if (startManualBankVerification.data?.code === 'same-account-is-default') {
		return (
			<>
				<BackButton to={links.CONNECT_BANK} />
				<Text>
					<FormattedMessage
						defaultMessage="You are already using this account."
						id="start-verification.already-using-account"
					/>
				</Text>
			</>
		);
	} else if (startManualBankVerification.data?.code === 'same-account-exist') {
		// we could handle this automatically, lets see if this comes up often
		return (
			<>
				<BackButton to={links.CONNECT_BANK} />
				<Text type="title">
					<FormattedMessage
						defaultMessage="Account already connected"
						id="start-verification.account-already-connected"
					/>
				</Text>
				<Spacing $size="l" />
				<Text>
					<FormattedMessage
						defaultMessage="Same account is already connected ot your account. Please {contactSupport} to use it for your payments."
						id="start-verification.same-account-connected"
						values={{
							contactSupport: (
								<Anchor onClick={triggerChatWidget}>
									<FormattedMessage
										defaultMessage="contact support"
										id="alert-error.contact-support"
									/>
								</Anchor>
							),
						}}
					/>
				</Text>
			</>
		);
	} else if (
		startManualBankVerification.isSuccess &&
		startManualBankVerification.data.code !== 'verification-started'
	) {
		return (
			<>
				<BackButton to={links.CONNECT_BANK} />
				<Text type="title">
					<FormattedMessage
						defaultMessage="Something went wrong with this request"
						id="cannot-validate-earnings.something-went-wrong"
					/>
				</Text>
				<Spacing $size="l" />
				<Text>
					<FormattedMessage
						defaultMessage="Error code: {errorCode}"
						id="cannot-validate-earnings.error-code"
						values={{
							errorCode: startManualBankVerification.data?.code ?? 'FWKHM',
						}}
					/>
				</Text>
				<Spacing $size="m" />
				<Text>
					<FormattedMessage
						defaultMessage="Please {contactSupport} with the above error code to continue."
						id="cannot-validate-earnings.contact-support"
						values={{
							contactSupport: (
								<Anchor onClick={triggerChatWidget}>
									<FormattedMessage
										defaultMessage="contact support"
										id="alert-error.contact-support"
									/>
								</Anchor>
							),
						}}
					/>
				</Text>
			</>
		);
	}

	return (
		<Box isColumn verticalCenter>
			<BackButton to={links.MANUAL_ENTER_BANK_DETAILS} />
			<Text type="title">
				<FormattedMessage
					defaultMessage="Start account verification"
					id="start-verification.start-account-verification"
				/>
			</Text>

			<img alt="Connect bank" src={connectBank} width="295px" height="177px" />

			<Text type="small">
				<FormattedMessage
					defaultMessage="Your account at {bankName} with the account number {accountNumber} is ready to be verified."
					id="start-verification.account-ready-to-be-verified"
					values={{
						bankName: manualOnboardingValues?.bankName,
						accountNumber: manualOnboardingValues?.accountNumber,
					}}
				/>
			</Text>

			<Footer>
				<Button disabled={startManualBankVerification.isPending} onClick={start}>
					<FormattedMessage defaultMessage="Start Verification" id="start-verification.start-verification" />
				</Button>
			</Footer>
		</Box>
	);
};
