import React, { ReactNode } from 'react';
import styled, { css } from 'styled-components';

type TShirtSizes = 's' | 'm' | 'l' | 'xl';

type PaddingObjectProps = {
	vertical?: TShirtSizes;
	horizontal?: TShirtSizes;
};

type MarginObjectProps = {
	vertical?: TShirtSizes;
	horizontal?: TShirtSizes;
};

interface BoxProps {
	children: ReactNode;
	justify?: 'center' | 'between'; // horizontal center
	verticalCenter?: boolean; //vertical center
	isColumn?: boolean; // center and $verticalCenter properties switched
	padding?: TShirtSizes | PaddingObjectProps;
	margin?: TShirtSizes | MarginObjectProps;
}

interface BoxStyledComponentProps {
	children: ReactNode;
	$justify?: 'center' | 'between'; // horizontal center
	$verticalCenter?: boolean; //vertical center
	$isColumn?: boolean; // center and $verticalCenter properties switched
	$padding?: TShirtSizes | PaddingObjectProps;
	$margin?: TShirtSizes | MarginObjectProps;
}

const spacingMap = {
	s: { desktop: 1, mobile: 0.8 },
	m: { desktop: 2, mobile: 1.5 },
	l: { desktop: 3, mobile: 2 },
	xl: { desktop: 5, mobile: 3 },
};

const Wrapper = styled.div<BoxStyledComponentProps>`
	display: flex;
	${({ $justify }) =>
		$justify === 'center' &&
		css`
			justify-content: center;
		`};
	${({ $justify }) =>
		$justify === 'between' &&
		css`
			justify-content: space-between;
		`};

	${({ $verticalCenter }) =>
		$verticalCenter &&
		css`
			align-items: center;
		`};

	${({ $isColumn }) =>
		$isColumn &&
		css`
			flex-direction: column;
		`};

	${({ $padding }) =>
		$padding &&
		css`
			/* flex-direction: column; */
			/* @media (max-width: 450px) {
			} */
		`};
`;

const Element = (props: BoxProps) => {
	return (
		<Wrapper
			$isColumn={props.isColumn}
			$verticalCenter={props.verticalCenter}
			$justify={props.justify}
			$padding={props.padding}
			$margin={props.margin}
		>
			{props.children}
		</Wrapper>
	);
};

export const Box = styled(Element)``;

interface SpacingProps {
	$size: TShirtSizes;
}

const SpacingElement = styled.div<SpacingProps>`
	height: ${({ $size }) => `${spacingMap[$size].desktop}rem`};
	@media (max-width: 450px) {
		height: ${({ $size }) => `${spacingMap[$size].mobile}rem`};
	}
`;

export const Spacing = styled(SpacingElement)``;
