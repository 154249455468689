import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import balanceBoost from 'url:../../static/shapes/balanceboost.png';

import { Modal } from '../../../base-ui/components';

type Props = {
	buttonHandler: () => void;
};
export const LearnMoreAboutCreditImpactModal = (props: Props) => {
	return (
		<Wrapper>
			<Modal
				titleIllustration={<img src={balanceBoost} />}
				headerText={
					<FormattedMessage
						defaultMessage="Credit impact may vary"
						id="credit-impact-info-modal.header-text"
					/>
				}
				contentText={
					<FormattedMessage
						defaultMessage="Individual results vary. Factors other than on time payments, including interactions with other creditors, also play a role and may impact results. Payment history will be reported to Equifax. Past performance does not guarantee future results, and a credit score increase cannot be guaranteed."
						id="credit-impact-info-modal.content-text"
					/>
				}
				buttonText={<FormattedMessage defaultMessage="I understand" id="credit-impact-info-modal.ok-button" />}
				buttonHandler={props.buttonHandler}
			/>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	padding: 4rem 3rem;
	@media (max-width: 450px) {
		padding: 1rem;
	}
`;
