import React from 'react';
import { FormattedMessage } from 'react-intl';

import { useBankOnboarding } from '../contexts/hooks';
import { ConnectBankAction } from '../templates/connect-bank-action';
import { useFlinksConnectForReconnect } from './flinks-connect';

export function ReconnectFlinks({ itemId }: { itemId: string }) {
	const { links, variant } = useBankOnboarding();
	const { setIsOpen, isOpen, loadIframe } = useFlinksConnectForReconnect(itemId, {
		onSuccessLink: links.AWAITING_TRANSACTIONS,
		onErrorLink: links.CANNOT_VALIDATE_EARNINGS,
	});

	return isOpen ? (
		loadIframe()
	) : (
		<ConnectBankAction
			manuallyConnect={false}
			buttonText={<FormattedMessage defaultMessage="Reconnect" id="reconnect-flinks.reconnect-button" />}
			handleOnClick={() => {
				setIsOpen(true);
			}}
			variant={variant}
		/>
	);
}
