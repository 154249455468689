import React from 'react';
import styled from 'styled-components';
import dashboardSuccess from 'url:../static/shapes/dashboard.png';
import dashboardFail from 'url:../static/shapes/dashboardFail.png';

export const AccountDashboardImage = ({ type }: { type: 'success' | 'error' }) => {
	return <Image src={type === 'success' ? dashboardSuccess : dashboardFail} />;
};

const Image = styled.img`
	width: 270px;
	position: absolute;
	right: 0;
	z-index: -1;
	top: 8rem;
	@media (max-width: 450px) {
		width: 170px;
		top: 8.5rem;
	}
`;
