import { formatISO, isValid, parseISO } from 'date-fns';

/**
 * Date as YYYY-MM-DD string
 *
 * Uses branded type, use `isoDate` function to create dates
 * with this type.
 */
export type IsoDate = string & { readonly __brand__: 'iso-date' };

export function isIsoDate(string: string): string is IsoDate {
	if (!/^\d{4}-\d{2}-\d{2}$/.test(string)) {
		return false;
	}
	return isValid(parseISO(string));
}

export function isoDate(dateString: string): IsoDate {
	if (isIsoDate(dateString)) {
		return dateString;
	}
	throw new Error(`Invalid date ${dateString}`);
}

export function jsDateToIsoDate(date: Date): IsoDate {
	return formatISO(date, { representation: 'date' }) as IsoDate;
}

export function isoToday() {
	return jsDateToIsoDate(new Date());
}

export function isoDateToJsDate(string: IsoDate): Date {
	return parseISO(string);
}
