import React, { useState } from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import reschedule from 'url:../../../app/static/shapes/reschedule-warning.png';

import { Modal, Radio, Text } from '../../../base-ui/components';

type Props = {
	date: Date;
	buttonHandler: () => void;
	altButtonHandler: () => void;
};

export const ConfirmUpfrontDeadlineModal = ({ date, buttonHandler, altButtonHandler }: Props) => {
	const [agreementConfirmed, setAgreementConfirmed] = useState(false);
	return (
		<Modal
			titleIllustration={<img src={reschedule} />}
			titleProps={{ color: 'error' }}
			headerText={
				<FormattedMessage
					defaultMessage="Are you sure you want to risk paying late fees and/or receiving a legal notice?"
					id="confirm-upfront-deadline-modal.are-you-sure"
				/>
			}
			contentText={
				<>
					<div style={{ margin: '1rem 0' }}>
						<FormattedMessage
							defaultMessage="Your landlord might charge late fees in case you make your initial payment on {date} and covered by Zenbase on that date."
							id="confirm-upfront-deadline-modal.your-landlord-might-charge-late-fees"
							values={{ date: <FormattedDate value={date} /> }}
						/>
					</div>

					<div style={{ marginTop: '3rem' }}>
						<Radio
							value="Agreement"
							checked={agreementConfirmed}
							onChange={() => setAgreementConfirmed((c) => !c)}
							label={
								<Text type="tiny" color="muted">
									<FormattedMessage
										defaultMessage="By selecting this date, I understand the risk of my landlord charging late fees and/or sending legal notices due to late payment"
										id="confirm-upfront-deadline-modal.by-selecting-this-date"
									/>
								</Text>
							}
						/>
					</div>
				</>
			}
			buttonText={
				<FormattedMessage defaultMessage="Yes, I am sure" id="confirm-upfront-deadline-modal.yes-i-am-sure" />
			}
			buttonHandler={buttonHandler}
			buttonDisabled={!agreementConfirmed}
			altButtonText={<FormattedMessage defaultMessage="Cancel" id="common.cancel" />}
			altButtonHandler={altButtonHandler}
		/>
	);
};
