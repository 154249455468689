import React from 'react';
import { Link } from 'react-router-dom';

import { links } from '../Router/paths';

export const LogoSvg = () => {
	return (
		<svg viewBox="0 0 190 33" fill="none" xmlns="http://www.w3.org/2000/svg" id="zenbase-logo">
			<path
				d="M30.1543 22.5778H25.3566C24.8356 22.5778 24.4014 22.9902 24.4014 23.533V31.6523C24.4014 32.1733 24.8138 32.6075 25.3566 32.6075H30.1543C30.4148 32.6075 30.6537 32.4989 30.8273 32.3252L34.887 28.2656C35.256 27.8965 35.256 27.2887 34.887 26.9196L30.8273 22.86C30.6537 22.6863 30.4148 22.5778 30.1543 22.5778Z"
				fill="#FFB9AA"
			/>
			<path
				d="M4.99358 10.0297H9.79134C10.3124 10.0297 10.7465 9.61723 10.7465 9.0745V0.95521C10.7465 0.434186 10.3341 0 9.79134 0L4.99358 0.0217093C4.73306 0.0217093 4.49426 0.130256 4.32059 0.30393L0.282653 4.36357C-0.0864052 4.73263 -0.0864052 5.34049 0.282653 5.70955L4.3423 9.74748C4.51597 9.92116 4.75477 10.0297 4.99358 10.0297Z"
				fill="#1ECEC9"
			/>
			<path
				d="M32.9115 0.0217285H27.8315C27.571 0.0217285 27.3322 0.130275 27.1585 0.30395L1.58496 25.8992C1.38957 26.0729 1.30273 26.3117 1.30273 26.5722V31.6522C1.30273 32.1732 1.71521 32.6074 2.25794 32.6074H7.33792C7.59844 32.6074 7.83724 32.4989 8.01091 32.3252L33.5845 6.7082C33.7582 6.53452 33.8667 6.29572 33.8667 6.03521V0.976939C33.8667 0.434206 33.4325 0.0217285 32.9115 0.0217285Z"
				fill="#0A6375"
			/>
			<path
				d="M52.7317 22.6212L63.7166 12.548V12.2224H53.0356V8.46667H67.8848V14.0243L56.8999 24.0323V24.3579H68.0584V28.0268H52.71V22.6212H52.7317Z"
				fill="#0A6375"
			/>
			<path
				d="M69.209 18.2793C69.209 12.1139 73.7245 7.96741 78.9999 7.96741C84.97 7.96741 88.5954 12.3093 88.5954 18.1274V19.3648H73.7679C73.8114 22.6429 75.9389 24.7487 79.1519 24.7487C81.3228 24.7487 83.3635 23.6198 84.0799 21.4706L88.1178 22.4475C86.8587 26.5072 83.2549 28.5261 79.1953 28.5261C73.2035 28.5044 69.209 24.2494 69.209 18.2793ZM84.1016 16.1518C83.8845 13.5033 81.6701 11.81 78.9782 11.81C76.1777 11.81 74.0502 13.7855 73.7897 16.1518H84.1016Z"
				fill="#0A6375"
			/>
			<path
				d="M90.4844 8.46672H94.8914V10.7028H95.217C96.0854 9.20484 97.8655 7.96741 100.492 7.96741C104.791 7.96741 107.504 11.1804 107.504 15.2183V28.0268H103.097V16.2604C103.097 13.8289 101.382 12.1573 99.1464 12.1573C96.715 12.1573 94.8914 14.0243 94.8914 16.4775V28.0268H90.4844V8.46672Z"
				fill="#0A6375"
			/>
			<path
				d="M114.3 25.7039H113.974V28.0268H109.61V0.0217285H114.017V10.9632H114.343C115.211 9.83434 116.926 7.96734 120.4 7.96734C125.632 7.96734 129.431 12.4178 129.431 18.2358C129.431 24.1625 125.371 28.5044 120.096 28.5044C116.731 28.5044 115.081 26.7242 114.3 25.7039ZM124.937 18.2793C124.937 14.6538 122.549 12.1138 119.445 12.1138C116.427 12.1138 113.909 14.3933 113.909 18.2793C113.909 21.6008 116.036 24.4013 119.358 24.4013C122.484 24.4013 124.937 21.9699 124.937 18.2793Z"
				fill="#0A6375"
			/>
			<path
				d="M130.863 18.2359C130.863 12.3093 134.749 7.96741 139.981 7.96741C143.259 7.96741 145.061 9.63903 145.908 10.833H146.233V8.44501H150.662V23.4244C150.662 23.9455 150.966 24.2928 151.552 24.2928H152.421V28.0268H149.099C147.341 28.0268 146.451 26.8328 146.299 25.552H145.973C145.235 26.746 143.498 28.5044 140.111 28.5044C134.836 28.5044 130.863 24.1843 130.863 18.2359ZM146.32 18.2793C146.32 14.3934 143.824 12.2007 140.828 12.2007C137.702 12.2007 135.335 14.6756 135.335 18.2793C135.335 21.9699 137.767 24.2494 140.871 24.2494C144.193 24.2494 146.32 21.6009 146.32 18.2793Z"
				fill="#0A6375"
			/>
			<path
				d="M153.571 22.4692L157.761 21.5357C158.174 23.5981 159.585 24.5533 161.647 24.5533C163.775 24.5533 164.904 23.7718 164.904 22.4692C164.904 18.5181 154.309 21.8397 154.309 13.8723C154.309 10.5508 157.066 7.96741 161.517 7.96741C165.294 7.96741 168.247 9.87783 168.941 13.373L165.012 14.4585C164.643 12.7435 163.362 11.9619 161.495 11.9619C159.628 11.9619 158.499 12.7869 158.499 13.9375C158.499 17.6063 169.397 14.1546 169.397 22.035C169.397 25.8776 166.336 28.5044 161.517 28.5044C157.262 28.5044 154.092 26.4637 153.571 22.4692Z"
				fill="#0A6375"
			/>
			<path
				d="M170.613 18.2793C170.613 12.1139 175.129 7.96741 180.404 7.96741C186.374 7.96741 190 12.3093 190 18.1274V19.3648H175.172C175.216 22.6429 177.343 24.7487 180.556 24.7487C182.727 24.7487 184.768 23.6198 185.484 21.4706L189.522 22.4475C188.263 26.5072 184.659 28.5261 180.6 28.5261C174.608 28.5044 170.613 24.2494 170.613 18.2793ZM185.528 16.1518C185.31 13.5033 183.096 11.81 180.404 11.81C177.604 11.81 175.476 13.7855 175.216 16.1518H185.528Z"
				fill="#0A6375"
			/>
		</svg>
	);
};

type Props = {
	className?: string;
	linkToWebsite?: boolean;
};

export const Logo = ({ className, linkToWebsite }: Props) => {
	if (linkToWebsite) {
		return (
			<a href="https://myzenbase.com" className={className}>
				<LogoSvg />
			</a>
		);
	}

	return (
		<Link to={links.DEFAULT.ROOT} className={className}>
			<LogoSvg />
		</Link>
	);
};
