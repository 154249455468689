import React from 'react';

export const DocumentIcon = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M18.25 1.5H1.75C1.61193 1.5 1.5 1.61193 1.5 1.75V18.25C1.5 18.3881 1.61193 18.5 1.75 18.5H18.25C18.3881 18.5 18.5 18.3881 18.5 18.25V1.75C18.5 1.61193 18.3881 1.5 18.25 1.5ZM1.75 0C0.783502 0 0 0.783501 0 1.75V18.25C0 19.2165 0.783501 20 1.75 20H18.25C19.2165 20 20 19.2165 20 18.25V1.75C20 0.783502 19.2165 0 18.25 0H1.75Z"
				fill="#0A6375"
			/>
			<rect x="3.75" y="3.75" width="3.75" height="1.75" rx="0.875" fill="#0A6375" />
			<rect x="8.75" y="3.75" width="7.5" height="1.75" rx="0.875" fill="#0A6375" />
			<rect x="3.75" y="7.5" width="3.75" height="1.75" rx="0.875" fill="#0A6375" />
			<rect x="8.75" y="7.5" width="7.5" height="1.75" rx="0.875" fill="#0A6375" />
			<rect x="3.75" y="11.25" width="3.75" height="1.75" rx="0.875" fill="#0A6375" />
			<rect x="8.75" y="11.25" width="7.5" height="1.75" rx="0.875" fill="#0A6375" />
		</svg>
	);
};
