import { css } from 'styled-components';

import { colors } from '../../variables';

export const titleCss = css`
	font-weight: 700;
	font-size: 3.2rem;
	line-height: 4rem;
	color: ${colors.green};
	margin-bottom: 1rem;

	@media (max-width: 450px) {
		font-size: 2.4rem;
		line-height: 3rem;
	}
`;
