import React from 'react';
import { FormattedMessage } from 'react-intl';
import reschedule from 'url:../../../app/static/shapes/reschedule.png';

import { Modal } from '../../../base-ui/components';

type Props = {
	toggleEdit: () => void;
};

export const SelectBusinessDayModal = ({ toggleEdit }: Props) => {
	return (
		<Modal
			titleIllustration={<img src={reschedule} />}
			headerText={
				<FormattedMessage defaultMessage="Select a business day" id="select-business-day-modal.button-text" />
			}
			contentText={
				<FormattedMessage
					defaultMessage="Banks don't process payments during the day you selected. Please select a business day instead."
					id="select-business-day-modal.content-text"
				/>
			}
			buttonText={
				<FormattedMessage defaultMessage="Select a business day" id="select-business-day-modal.button-text" />
			}
			buttonHandler={toggleEdit}
		/>
	);
};
