import React from 'react';
import styled from 'styled-components';

type Props = {
	src: string;
};

const Img = styled.img`
	opacity: 0;
	width: 0;
	height: 0;
`;

export const ImgPreload = ({ src }: Props) => {
	return <Img alt="" src={src} />;
};
