import { IsoDate } from './iso-date';
import { PropertyResidentStatus } from './pms';

export enum EligibilityRules {
	BankConnected = 'BankConnected',
	ConstantIncome = 'ConstantIncome',
	NotOutstandingPayments = 'NotOutstandingPayments',
	ResidentVerification = 'ResidentVerification',
	SufficientIncome = 'SufficientIncome',
	Arrears = 'Arrears',
}

export function eligibilityRuleNameForOutput(rule: EligibilityRules) {
	if (rule === 'NotOutstandingPayments') {
		return 'DelinquentBalance';
	}
	return rule;
}

export interface NormalizedEligibilityRuleResponse {
	key: EligibilityRules;
	success: boolean;
}

export interface DenormalizedEligibilityRuleResponse extends NormalizedEligibilityRuleResponse {
	rule: EligibilityRules;
	details: unknown;
}

export type EligibilityRuleResponse = DenormalizedEligibilityRuleResponse;

export interface EligibilityRulesCheckResponse {
	ruleResponses: EligibilityRuleResponse[];
	success: boolean;
}

export interface NormalizedEligibilityRulesCheckResponse {
	ruleResponses: NormalizedEligibilityRuleResponse[];
	success: boolean;
}

export interface AdminUserListEligibilityInfo {
	isEligible: boolean | null;
	eligibilityResultId: string | null;
	eligibilityNote: string | null;
	eligibilityCreatedAt: Date | null;
	eligibilityFullResult: NormalizedEligibilityRulesCheckResponse | null;
}

// it is "Probable" because it is in database as json with historic mess
export type ProbableResidentVerificationEligibilityResultDetails = {
	pmsResidency?: {
		pmsTenantId: string;
		// IsoDate (newer) or date string "2023-07-31T00:00:00.000Z"
		moveOutDate: IsoDate | string | null;
		status: PropertyResidentStatus | string;
	};
	verified?: boolean;
	landlordConnected?: boolean;
};
