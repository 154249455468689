import React from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { RouterInput } from '../../../../../functions/src/api/trpc/types';
import { BusinessEventType } from '../../../../../functions/src/shared/business-events';

import { Modal } from '../../../base-ui/components';

import { links } from '../../Router/paths';
import { useTrackOnboardingEvent } from '../../utils/track-onboarding-event';

type Props = {
	variant: 'onboarding' | 'account';
	indirectModel: boolean;
	closeModal: () => void;
};
type OnboardingEvents = RouterInput['user']['trackOnboardingEvent']['type'];

export const CreditBuilderConfirmModal = ({ variant, indirectModel, closeModal }: Props) => {
	const trackOnboardingEvent = useTrackOnboardingEvent();
	const navigate = useNavigate();
	const intl = useIntl();

	return (
		<Modal
			type="configuration"
			headerText={intl.formatMessage({
				defaultMessage: "Are you sure that you don't want to build your credit?",
				id: 'credit-builder-confirm-modal.are-you-sure',
			})}
			contentText={intl.formatMessage({
				defaultMessage:
					'You can build credit history with each rent payment regardless of how you pay your rent.',
				id: 'credit-builder-confirm-modal.build-credit-history',
			})}
			buttonText={intl.formatMessage({ defaultMessage: 'Not now', id: 'credit-builder-confirm-modal.not-now' })}
			buttonHandler={() => {
				const events: OnboardingEvents = [BusinessEventType.OnboardingCreditBuilderRentalEnd];
				if (variant === 'onboarding' && !indirectModel) {
					events.push(BusinessEventType.OnboardingCustomRentStart);
				}
				trackOnboardingEvent.mutate(
					{
						type: events,
					},
					{
						onSuccess() {
							closeModal();

							if (variant === 'account' || indirectModel) {
								navigate(links.ACCOUNT.DASHBOARD);
							}
						},
					},
				);
			}}
			altButtonText={intl.formatMessage({
				defaultMessage: "Let's build credit",
				id: 'credit-builder-confirm-modal.build-credit',
			})}
			altButtonHandler={closeModal}
		/>
	);
};
