import { useState } from 'react';
import { formatISO } from 'date-fns';
import {
	FirebaseStorage,
	ref,
	StorageError,
	StorageReference,
	uploadBytesResumable,
	UploadTaskSnapshot,
} from 'firebase/storage';
import { last } from 'lodash';

type Options = {
	files: File[];
	storage: FirebaseStorage;
	pathPrefix: string;
	metadata: Record<string, string>;
};

export type UploadWithProgressOptions = Options;

export const useUploadFilesWithProgress = ({ files, storage, pathPrefix, metadata }: Options) => {
	const [progresses, setProgresses] = useState<number[]>(Array(files.length).fill(0));
	const [errors, setErrors] = useState<StorageError[]>(Array(files.length));

	const onProgress = (fileIndex: number) => (snapshot: UploadTaskSnapshot) => {
		const percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
		setProgresses((prevProgresses) => {
			const newProgresses = [...prevProgresses];
			newProgresses[fileIndex] = percentage;
			return newProgresses;
		});
	};

	const onError = (fileIndex: number) => (error: StorageError) => {
		setErrors((prevErrors) => {
			const newErrors = [...prevErrors];
			newErrors[fileIndex] = error;
			return newErrors;
		});
	};

	const uploadFiles = async (opts?: { customMetadata?: Record<string, string> }) => {
		const uploadPromises = files.map((file, index) => {
			if (progresses[index] === 100) return;
			const parts = file.name.split('.');
			const suffix = last(parts);
			const dateStr = formatISO(new Date());
			const randomNum = Math.floor(Math.random() * 1000);
			const filename = `${parts.slice(0, parts.length - 1)}-${dateStr}-${randomNum}.${suffix}`;
			const storageRef = ref(storage, `${pathPrefix}${filename}`);
			return new Promise<StorageReference>((resolve, reject) => {
				const task = uploadBytesResumable(storageRef, file, {
					customMetadata: {
						contentType: file.type,
						...metadata,
						...(opts?.customMetadata ?? {}),
					},
				});

				task.on('state_changed', {
					next: onProgress(index),
					error: (error) => {
						onError(index)(error);
						reject(error);
					},
				});

				task.then((finishedTask) => resolve(finishedTask.ref));
			});
		});

		await Promise.all(uploadPromises);
	};

	return { uploadFiles, progresses, errors };
};
