import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import imageStep4 from 'url:../static/shapes/onboarding-2.png';
import imageStep3 from 'url:../static/shapes/onboarding-3.png';
import imageStep2 from 'url:../static/shapes/reschedule-2.png';

import { BusinessEventType } from '../../../../functions/src/shared/business-events';
import { publicConfig } from '../../../../functions/src/shared/config';
import { CreditSubjectRentReportingStatus } from '../../../../functions/src/shared/credit-reporting';
import { TransactionStatus } from '../../../../functions/src/shared/payment-transaction';

import { FormattedCents } from '../../shared/components/formatted-cents';
import { trpc } from '../../shared/trpc/client';

import { BackButton } from '../components/back-button';
import { OnboardingCloseButton } from '../components/onboarding-close-button';
import { OnboardingNavigationRow } from '../components/onboarding-navigation-row';
import { useUserData } from '../contexts/user-data-context';
import { CreditBuilderBeforeCta } from '../pages/credit-builder-onboarding/credit-builder-before-cta';
import { CreditBuilderConfirmRentReporting } from '../pages/credit-builder-onboarding/credit-builder-confirm-rent-reporting';
import {
	creditBuilderOnboardingService,
	CreditBuilderSetupState,
} from '../pages/credit-builder-onboarding/credit-builder-onboarding.service';
import { CreditBuilderStep1 } from '../pages/credit-builder-onboarding/credit-builder-step1';
import { links } from '../Router/paths';
import { useTrackOnboardingEvent } from '../utils/track-onboarding-event';
import { IndirectSelectLeaseDates } from './indirect-select-lease-dates';
import { IndirectSelectRentAmount } from './indirect-select-rent-amount';
import { LandlordVerification } from './landlord-verification';
import { OnboardingSuccess } from './onboarding-success';
import { UploadLeaseAgreement } from './upload-lease-agreement';

type Props = {
	variant: 'onboarding' | 'account';
};

export const CreditBuilderOnboarding = ({ variant }: Props) => {
	const location = useLocation();
	const navigate = useNavigate();

	const { home } = useUserData();

	const { data: creditSubject } = trpc.creditReporting.getUserInfo.useQuery();

	const listOnboardingEvents = trpc.user.listOnboardingEvents.useQuery();
	const onboardingEvents = listOnboardingEvents.data ?? {};

	let state = location.state as CreditBuilderSetupState;
	const landlord = home?.landlord;
	const indirectModel = Boolean(home?.dashboard.indirectModel);

	const isVerifiedReferral = home?.isReferral && home?.user.verified;

	if (!state && indirectModel) {
		const newState = creditBuilderOnboardingService.resolveState({
			onboardingEvents,
			isReferral: isVerifiedReferral,
			home,
			creditSubject,
		});
		if (newState) {
			state = newState;
		}
	}

	const trackOnboardingEvent = useTrackOnboardingEvent();
	useEffect(() => trackOnboardingEvent.mutate({ type: BusinessEventType.OnboardingCreditBuilderRentalStart }), []);

	if (state === 'step-2') {
		return (
			<OnboardingSuccess
				imageSrc={imageStep2}
				title={
					<FormattedMessage
						defaultMessage="Take control of financial expenses"
						id="credit-builder-onboarding.take-control-of-expenses-title"
					/>
				}
				subtitle={
					<FormattedMessage
						defaultMessage="Reduce your interest payments and access better financial products simply by building credit with your rent payments."
						id="credit-builder-onboarding.take-control-of-expenses-subtitle"
					/>
				}
				ctaTestId="onboarding-cb-setup-step2-continue-button"
				cta={<FormattedMessage defaultMessage="Continue" id="common.continue" />}
				onNext={() => navigate('', { state: 'step-3' } satisfies { state: CreditBuilderSetupState })}
				beforeCta={<CreditBuilderBeforeCta landlord={landlord} />}
			/>
		);
	}

	if (state === 'step-3') {
		return (
			<OnboardingSuccess
				imageSrc={imageStep3}
				title={
					<FormattedMessage
						defaultMessage="Stand out with positive rental history"
						id="credit-builder-onboarding.stand-out-with-rental-history-title"
					/>
				}
				subtitle={
					<FormattedMessage
						defaultMessage="Housing providers and mortgage providers take into account past rent payments and track record."
						id="credit-builder-onboarding.stand-out-with-rental-history-subtitle"
					/>
				}
				ctaTestId="onboarding-cb-setup-step3-continue-button"
				cta={<FormattedMessage defaultMessage="Continue" id="common.continue" />}
				onNext={() => navigate('', { state: 'step-4' } satisfies { state: CreditBuilderSetupState })}
				beforeCta={<CreditBuilderBeforeCta landlord={landlord} />}
			/>
		);
	}

	if (state === 'step-4') {
		return (
			<OnboardingSuccess
				imageSrc={imageStep4}
				title={
					<FormattedMessage
						defaultMessage="Build credit with ease and peace of mind"
						id="credit-builder-onboarding.build-credit-with-ease-title"
					/>
				}
				subtitle={
					<FormattedMessage
						defaultMessage="Effortlessly build a verified & official credit history with each rent payment that you are already making."
						id="credit-builder.build-history-description"
					/>
				}
				ctaTestId="onboarding-cb-setup-step4-continue-button"
				cta={<FormattedMessage defaultMessage="Continue" id="common.continue" />}
				onNext={() => {
					if (landlord) {
						// direct model
						navigate('', { state: 'step-confirm-rent-reporting-5' } satisfies {
							state: CreditBuilderSetupState;
						});
					} else {
						// indirect model
						navigate('', { state: 'step-indirect-housing-provider-1' } satisfies {
							state: CreditBuilderSetupState;
						});
					}
				}}
				beforeCta={<CreditBuilderBeforeCta landlord={landlord} />}
			/>
		);
	}

	// housing provider information
	if (state === 'step-indirect-housing-provider-1') {
		return (
			<>
				<OnboardingNavigationRow>
					<BackButton
						onClick={() =>
							navigate('', { state: 'step-3' } satisfies {
								state: CreditBuilderSetupState;
							})
						}
					/>
					<OnboardingCloseButton />
				</OnboardingNavigationRow>
				<LandlordVerification
					onNext={() => {
						navigate('', { state: 'step-indirect-select-rent-amount-2' } satisfies {
							state: CreditBuilderSetupState;
						});
					}}
				/>
			</>
		);
	}
	//
	// Select rent amount
	//
	if (state === 'step-indirect-select-rent-amount-2') {
		return (
			<>
				<OnboardingNavigationRow>
					<BackButton
						onClick={() =>
							navigate('', { state: 'step-indirect-housing-provider-1' } satisfies {
								state: CreditBuilderSetupState;
							})
						}
					/>
					<OnboardingCloseButton />
				</OnboardingNavigationRow>
				<IndirectSelectRentAmount
					onNext={() => {
						navigate('', { state: 'step-indirect-lease-dates-3' } satisfies {
							state: CreditBuilderSetupState;
						});
					}}
				/>
			</>
		);
	}

	if (state === 'step-indirect-lease-dates-3') {
		return (
			<>
				<OnboardingNavigationRow>
					<BackButton
						onClick={() =>
							navigate('', { state: 'step-indirect-select-rent-amount-2' } satisfies {
								state: CreditBuilderSetupState;
							})
						}
					/>
					<OnboardingCloseButton />
				</OnboardingNavigationRow>
				<IndirectSelectLeaseDates
					onNext={() => {
						if (isVerifiedReferral) {
							navigate('', { state: 'step-confirm-rent-reporting-5' } satisfies {
								state: CreditBuilderSetupState;
							});
						} else {
							navigate('', { state: 'step-indirect-lease-agreement-5-1' } satisfies {
								state: CreditBuilderSetupState;
							});
						}
					}}
				/>
			</>
		);
	}

	const leaseAgreementOnNext = () => {
		// skip confirmation step if confirmed before
		if (creditSubject?.rentReportingConsentedAt) {
			if (creditSubject?.rentReportingStatus === CreditSubjectRentReportingStatus.Unpaid) {
				if (creditSubject.payments.some((p) => p.status === TransactionStatus.Executing)) {
					navigate(links.ACCOUNT.DASHBOARD);
					return;
				}
				navigate(links.ACCOUNT.CREDIT_BUILDER.SUBSCRIPTION_TYPE);
			} else if (creditSubject?.rentReportingStatus === CreditSubjectRentReportingStatus.Active) {
				navigate(links.ACCOUNT.CREDIT_BUILDER.SUCCESS);
			}
			return;
		}
		navigate('', { state: 'step-confirm-rent-reporting-5' } satisfies {
			state: CreditBuilderSetupState;
		});
	};

	// lease agreements
	if (state === 'step-indirect-lease-agreement-5-1') {
		return (
			<>
				<OnboardingNavigationRow>
					<BackButton
						onClick={() =>
							navigate('', { state: 'step-indirect-lease-dates-3' } satisfies {
								state: CreditBuilderSetupState;
							})
						}
					/>
					<OnboardingCloseButton />
				</OnboardingNavigationRow>
				<UploadLeaseAgreement onNext={leaseAgreementOnNext} />
			</>
		);
	}

	// confirm rent reporting
	if (state === 'step-confirm-rent-reporting-5') {
		return <CreditBuilderConfirmRentReporting variant={variant} indirectModel={indirectModel} />;
	}

	const priceText = (
		<FormattedCents
			value={home?.creditBuilderPricing?.monthlyPriceCents ?? publicConfig.creditBuilder.monthlyPriceCents}
		/>
	);

	return <CreditBuilderStep1 home={home} priceText={priceText} />;
};
