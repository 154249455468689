import React from 'react';

export const CloseCircle = ({ fill = '#FB5530' }: { fill?: string }) => {
	return (
		<svg width="1em" height="1em" viewBox="0 4 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<circle cx="12" cy="16" r="11.5" fill={fill} stroke="white" />
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M15.4498 13.8264C15.7036 13.5726 15.7036 13.161 15.4498 12.9072C15.196 12.6533 14.7844 12.6533 14.5306 12.9072L11.929 15.5088L9.32739 12.9072C9.07355 12.6533 8.66199 12.6533 8.40815 12.9072C8.15431 13.161 8.15431 13.5726 8.40815 13.8264L11.0097 16.428L8.40815 19.0296C8.15431 19.2834 8.15431 19.695 8.40815 19.9488C8.66199 20.2027 9.07355 20.2027 9.32739 19.9488L11.929 17.3472L14.5306 19.9488C14.7844 20.2027 15.196 20.2027 15.4498 19.9488C15.7036 19.695 15.7036 19.2834 15.4498 19.0296L12.8482 16.428L15.4498 13.8264Z"
				fill="white"
			/>
		</svg>
	);
};
