import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { Button, Footer, Spacing, Text } from '../../../base-ui/components';

import { userStatusPartnerSkip, useUserData } from '../../contexts/user-data-context';
import { links } from '../../Router/paths';

export const AccountPartner = () => {
	const { user } = useUserData();
	const navigate = useNavigate();

	return (
		<>
			<Text type="title" center>
				<FormattedMessage defaultMessage="Where do you want to go?" id="account-partner-where-to-go" />
			</Text>
			<Spacing $size="s" />
			<Text center>
				<FormattedMessage
					defaultMessage="We see you're a partner portal member. Do you want to continue to the partner app or to the Zenbase member app?"
					id="account-partner-continue-message"
				/>
			</Text>

			<Spacing $size="m" />
			<Footer>
				<Button
					onClick={() => {
						location.href = '/partner';
					}}
				>
					<FormattedMessage
						defaultMessage="Continue to Partner Portal"
						id="account-partner-continue-partner"
					/>
				</Button>
				<Button
					type="link"
					onClick={() => {
						if (!user?.userId) return;

						userStatusPartnerSkip.skip(user.userId);
						navigate(links.DEFAULT.ROOT);
					}}
				>
					<FormattedMessage
						defaultMessage="Continue as Zenbase member"
						id="account-partner-continue-zenbase"
					/>
				</Button>
			</Footer>
		</>
	);
};
