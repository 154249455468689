import { amountToCents } from './monetary';

export const LOAN_AMOUNT_IN_USD = 100;
export const LOAN_AMOUNT_IN_CENTS = amountToCents(LOAN_AMOUNT_IN_USD);

export enum CashAdvanceSimplifiedStatus {
	LoanProcessing = 'loan-processing',
	PendingRepayment = 'pending-repayment',
	Repaid = 'repaid',
	Aborted = 'aborted',
}

export enum CashAdvanceCannotRequestReasons {
	NotEligible = 'not_eligible',
	ExistingCashAdvance = 'existing_cash_advance',
	BankAccountNotSupported = 'bank_account_not_supported',
	NoRentForCurrentMonth = 'no_rent_for_current_month',
	RentNotPaid = 'rent_not_paid',
	LeaseEndsSoon = 'lease_ends_soon',
}
