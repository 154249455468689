import { useRef } from 'react';
import { RecaptchaVerifier } from 'firebase/auth';

import { auth } from './firebaseApi';

export const useRecaptcha = () => {
	const verifier = useRef<RecaptchaVerifier | null>();
	const container = useRef<HTMLDivElement | null>();

	const getRecaptchaVerifier = () => {
		if (verifier.current) return verifier.current;

		// create container
		container.current = document.createElement('div');
		container.current.id = 'recaptcha-container';
		document.body.append(container.current);

		// factory RecaptchaVerifier
		verifier.current = new RecaptchaVerifier(auth, container.current, { size: 'invisible' });

		return verifier.current;
	};
	const destroyRecaptcha = () => {
		if (verifier.current) {
			verifier.current.clear();
			verifier.current = null;
		}
		if (container.current) {
			container.current.remove();
			container.current = null;
		}
	};

	return { getRecaptchaVerifier, destroyRecaptcha };
};
