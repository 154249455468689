import React from 'react';

import { CommonIconProps } from './types';

export const Checkmark = ({ fill }: CommonIconProps) => {
	return (
		<svg width="15" height="10" viewBox="0 0 15 10" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M14.1105 0.94873L6.33268 8.91428L1.66602 4.13495"
				stroke={fill}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};
