import { startCase } from 'lodash';

export const omitBy = <T extends Record<string, unknown>, K extends keyof T>(obj: T, ...toOmit: K[]) => {
	return Object.entries(obj).reduce(
		(acc, [k, v]) => {
			if (toOmit.includes(k as K)) return acc;
			acc[k] = v;
			return acc;
		},
		{} as Record<string, unknown>,
	) as Omit<T, K>;
};

export const notNullish = <T>(x: T): x is NonNullable<T> => x !== null && x !== undefined;

export const notEmpty = <T>(x: readonly T[] | null | undefined): x is T[] & [T] => (x?.length || 0) !== 0;

export type Nullable<T> = { [K in keyof T]+?: T[K] };

export type LooseAutocomplete<T extends string> = T | Omit<string, T>;

export type NullableProperties<T, NullableKeys extends keyof T> = {
	[P in NullableKeys]-?: T[P] | null;
} & Omit<T, NullableKeys>;

export type NonNullableProperties<T, NonNullableKeys extends keyof T> = {
	[P in NonNullableKeys]-?: NonNullable<T[P]>;
} & Omit<T, NonNullableKeys>;

export const typedIncludes = <S, T extends readonly S[]>(items: T, item: S): item is T[number] => items.includes(item);

export const typedValues = <T extends Record<string, unknown>>(items: T) => Object.values(items) as Array<T[keyof T]>;

export type AddEmptyString<T, Keys extends keyof T = keyof T> = {
	[K in Keys]: T[K] | '';
};

export const omitUndefined = <T extends Record<string, unknown>>(obj: T) => {
	return Object.entries(obj).reduce(
		(acc, [k, v]) => {
			if (v === undefined) return acc;
			acc[k] = v;
			return acc;
		},
		{} as Record<string, unknown>,
	) as T;
};

export const omitEmptyArray = <T extends Record<string, unknown>>(obj: T) => {
	return Object.entries(obj).reduce(
		(acc, [k, v]) => {
			if (Array.isArray(v) && v.length === 0) return acc;
			acc[k] = v;
			return acc;
		},
		{} as Record<string, unknown>,
	) as T;
};

export const auditLogPath = (recordId: string) =>
	`/admin/audit?filters=${encodeURIComponent(JSON.stringify({ recordId }))}`;

// https://stackoverflow.com/a/31615643
export function getNumberWithOrdinal(n: number) {
	const s = ['th', 'st', 'nd', 'rd'],
		v = n % 100;
	return n + (s[(v - 20) % 10] || s[v] || s[0]);
}

// https://stackoverflow.com/a/68533063
export function getEnumKeyByEnumValue<TEnumKey extends string, TEnumVal extends string | number>(
	myEnum: { [key in TEnumKey]: TEnumVal },
	enumValue: TEnumVal,
): string {
	const keys = (Object.keys(myEnum) as TEnumKey[]).filter((x) => myEnum[x] === enumValue);
	return keys.length > 0 ? keys[0] : '';
}

export function isKeyOfObject<T extends object>(key: string | number | symbol, obj: T): key is keyof T {
	return key in obj;
}

export function plural(count: number, singular: string, plural?: string) {
	return `${count} ${count === 1 ? singular : (plural ?? `${singular}s`)}`;
}

export const splitCamelCase = (str: string) =>
	str
		.split(/(?=[A-Z])/)
		.map(startCase)
		.join(' ');

export function ensureExhaustiveCheck(exhaustedValue: never): never {
	throw new Error(`Unhandled exhaustive check value: ${exhaustedValue}`);
}

const normalizeEmail = (email: string): string => {
	const [localPart, domain] = email.split('@');
	const normalizedLocalPart = localPart.split('+')[0];
	return `${normalizedLocalPart}@${domain}`.toLowerCase();
};

export function areEmailsEqual(left: string, right: string): boolean {
	const normalizedLeft = normalizeEmail(left);
	const normalizedRight = normalizeEmail(right);

	return normalizedLeft === normalizedRight;
}
