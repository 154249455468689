import React, { useEffect, useMemo, useState } from 'react';
import * as Sentry from '@sentry/react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { BusinessEventType } from '../../../../functions/src/shared/business-events';
import { publicConfig } from '../../../../functions/src/shared/config';
import { ItemProvider } from '../../../../functions/src/shared/payment-accounts';
import { UserLocale } from '../../../../functions/src/shared/user';

import { getEnvironment } from '../../shared/environment';
import { RouterInput, trpc } from '../../shared/trpc/client';

import { useBankOnboarding, useInterface, useUserData } from '../contexts/hooks';
import { links } from '../Router/paths';

const FlinksWrapper = styled.div`
	position: fixed;
	display: flex;
	inset: 0px;
	height: 100%;
	z-index: 2147483647;
	border-width: 0px;
	overflow: hidden auto;
	background-color: white;
	align-items: center;
	justify-content: center;
	align-items: center;
`;

const FlinksConnectContainer = styled.div<{ $active?: boolean }>`
	${({ $active }) => ($active ? '' : 'display: none;')}
	max-width: 400px;
	padding: 10px;
	width: 100%;
`;

const FlinksConnect = styled.iframe`
	border: none;
	box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
	padding: 5px;
	width: inherit;
	@media (min-width: 768px) {
		background: white;
	}
`;

type Props = {
	setIsOpen: (t: boolean) => void;
	isOpen: boolean;
	navigateOnSuccess: string;
	navigateOnError: string;
} & (
	| {
			action: 'connect';
	  }
	| ({
			action: 'reconnect';
	  } & Pick<Extract<RouterInput['user']['flinks']['connectBankAccount'], { action: 'reconnect' }>, 'itemId'>)
);

// https://docs.flinks.com/docs/events#types-of-events-and-their-meanings
const handleFlinksEvent = <T extends Record<string, string>>(
	event: T,
	cbs: {
		onSuccess: (e: T) => void;
		onError: (e: T) => void;
		reportError: (e: T) => void;
		onMount: () => void;
	},
) => {
	let errorReported = false;
	if (event.flinksCode) {
		cbs.reportError(event);
		errorReported = true;
	}

	switch (event.step) {
		case 'APP_MOUNTED':
			cbs.onMount();
			break;
		case 'REDIRECT':
			cbs.onSuccess(event);
			break;
		case 'INVALID_INSTITUTION':
		case 'INSTITUTION_NOT_AVAILABLE':
		case 'INVALID_SECURITY_RESPONSE':
		case 'DISABLED_INSTITUTION':
		case 'APP_OFFLINE':
		case 'MAXIMUM_RETRY_REACHED':
		case 'SESSION_NONEXISTENT':
		case 'SESSION_EXPIRED':
		case 'COMPONENT_CLOSE_SESSION':
			if (!errorReported) {
				cbs.reportError(event);
			}
			cbs.onError(event);
			break;
		default:
			break;
	}
};

const ConnectFlinks = ({ setIsOpen, isOpen, navigateOnSuccess, navigateOnError, ...rest }: Props) => {
	const navigate = useNavigate();
	const connectAccount = trpc.user.flinks.connectBankAccount.useMutation({
		onError(error, variables) {
			Sentry.captureException(error, (scope) => {
				scope.setContext('user', { id: user?.userId });
				scope.setTag('flinks-setup', 'onError');
				scope.setExtra('error', error);
				scope.setExtra('metadata', variables);
				return scope;
			});

			setIsOpen(false);
			if (error.message === 'XVBNM') {
				navigate(navigateOnError, { state: { errorCode: 'XVBNM' } });
			} else {
				navigate(navigateOnError);
			}
		},
	});

	const trackInstantConnectionEvent = trpc.user.trackEvent.useMutation();

	const { setLoading, isPending } = useInterface();
	const { user } = useUserData();
	const { onBankAccountConnected } = useBankOnboarding();
	const intl = useIntl();

	const enableDemoInstitution =
		(user?.email.endsWith('@myzenbase.com') || getEnvironment() !== 'production') ?? false;

	const onMessage = (e: MessageEvent) => {
		if (e.origin.includes(publicConfig.flinks[getEnvironment()].iframeOrigin)) {
			handleFlinksEvent(e.data, {
				onMount() {
					setLoading(false);
				},
				onSuccess(event) {
					trackInstantConnectionEvent.mutate({
						institution: event.institution,
						result: 'success',
						type: BusinessEventType.InstantBankConnectionResult,
						provider: ItemProvider.Flinks,
					});

					onBankAccountConnected();

					if (event.loginId && event.accountId && event.requestId) {
						connectAccount.mutate({
							...rest,
							loginId: event.loginId,
							selectedAccountId: event.accountId,
							requestId: event.requestId,
						});
						// This is a bit hacky
						// redirect even when the request is still pending
						navigate(navigateOnSuccess);
					}
				},
				onError() {
					setIsOpen(false);
					navigate(links.REGISTRATION.BANK.CONNECT_BANK);
				},
				reportError(event) {
					trackInstantConnectionEvent.mutate({
						institution: event.institution,
						reason: event.step ?? event.flinksCode ?? 'UNKNOWN',
						result: 'error',
						type: BusinessEventType.InstantBankConnectionResult,
						provider: ItemProvider.Flinks,
					});
				},
			});
		}
	};

	useEffect(() => {
		setLoading(true);
		window.addEventListener('message', onMessage);
		return () => {
			window.removeEventListener('message', onMessage);
			setLoading(false);
			setIsOpen(false);
		};
	}, []);

	const params = useMemo(() => {
		if (intl.locale === UserLocale.FrenchCa) {
			return new URLSearchParams({
				...publicConfig.flinks.iframeParams,
				language: 'fr',
			});
		}
		return new URLSearchParams(publicConfig.flinks.iframeParams);
	}, [intl]);
	return isOpen ? (
		<>
			<FlinksWrapper>
				<FlinksConnectContainer $active={!isPending}>
					<FlinksConnect
						height="760"
						src={`${publicConfig.flinks[getEnvironment()].iframeOrigin}/v2/?${params.toString()}${
							enableDemoInstitution ? '&demo=true' : ''
						}`}
					></FlinksConnect>
				</FlinksConnectContainer>
			</FlinksWrapper>
		</>
	) : (
		<></>
	);
};

type HookParams<T extends Props['action']> = Omit<Extract<Props, { action: T }>, 'setIsOpen' | 'isOpen' | 'action'>;

const useFlinksConnect = <T extends Props['action'] = 'connect'>(action: T, params: HookParams<typeof action>) => {
	const [isOpen, setIsOpen] = useState(false);

	return {
		loadIframe: () => {
			return (
				<ConnectFlinks
					setIsOpen={setIsOpen}
					isOpen={isOpen}
					{...params}
					action={action as 'connect' /* Narrow to make TS happy */}
				/>
			);
		},
		setIsOpen,
		isOpen,
	};
};

export const useFlinksConnectForConnect = (handlerLinks: { onSuccessLink: string; onErrorLink: string }) =>
	useFlinksConnect('connect', {
		navigateOnSuccess: handlerLinks.onSuccessLink,
		navigateOnError: handlerLinks.onErrorLink,
	});

export const useFlinksConnectForReconnect = (
	itemId: string,
	handlerLinks: { onSuccessLink: string; onErrorLink: string },
) =>
	useFlinksConnect('reconnect', {
		itemId,
		navigateOnSuccess: handlerLinks.onSuccessLink,
		navigateOnError: handlerLinks.onErrorLink,
	});
