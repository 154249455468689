import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { colors } from '../../base-ui/variables';

interface ProgressBarProps {
	progress: number;
	error?: boolean;
}

const ProgressBarContainer = styled.div`
	position: relative;
	width: 100%;
	height: 30px;
	border: 1px solid ${colors.green};
	border-radius: 5px;
	margin-right: 5px;
`;

const Progress = styled.div<{ $fillColor: string }>`
	position: absolute;
	width: ${({ $fillColor }) => $fillColor}%;
	height: 100%;
	background-color: #24badb7c;
	transition: width 0.5s;
	border-radius: 4px;
`;

const ProgressText = styled.span`
	position: absolute;
	width: 100%;
	height: 100%;
	line-height: 30px;
	text-align: center;
	font-weight: 550;
	font-size: 12px;
	z-index: 1;
	color: ${colors.green};
`;

const UploadProgressBar: React.FC<ProgressBarProps> = ({ progress, error }) => {
	const [fillWidth, setFillWidth] = useState(0);
	const intl = useIntl();

	useEffect(() => {
		setFillWidth(progress ?? 0);
	}, [progress]);

	const progressLabel = error
		? intl.formatMessage({ defaultMessage: 'Error', id: 'common.error' })
		: progress === undefined
			? intl.formatMessage({ defaultMessage: 'Ready to upload', id: 'upload-progress-bar.ready-to-upload' })
			: progress >= 100
				? intl.formatMessage({ defaultMessage: 'Completed', id: 'common.completed' })
				: intl.formatMessage({ defaultMessage: 'In Progress', id: 'common.in-progress' });

	return (
		<ProgressBarContainer>
			<Progress $fillColor={fillWidth.toString()}></Progress>
			<ProgressText>{`${progressLabel}`}</ProgressText>
		</ProgressBarContainer>
	);
};

export default UploadProgressBar;
