import React from 'react';

export const Info = () => {
	return (
		<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M10.0654 18.961C15.0364 18.9454 19.0527 14.9037 19.0371 9.93275C19.0214 4.96178 14.9798 0.945456 10.0088 0.961073C5.03786 0.976689 1.02154 5.01833 1.03716 9.9893C1.05277 14.9603 5.09441 18.9766 10.0654 18.961Z"
				stroke="#0A6375"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M10.036 9.46134L10.0518 14.4613"
				stroke="#0A6375"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M10.0236 5.96132C9.88561 5.96176 9.77326 5.85011 9.77382 5.71211C9.77339 5.57411 9.88504 5.46176 10.023 5.46132C10.161 5.46089 10.2734 5.57254 10.2738 5.71054C10.2743 5.84854 10.1626 5.96089 10.0236 5.96132"
				stroke="#0A6375"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};
