import styled from 'styled-components';

import { colors } from '../../base-ui/variables';

export const AppWrapper = styled.div`
	min-height: 100vh;
	width: 100vw;
	position: relative;
	padding-bottom: 200px;
	display: flex;
	justify-content: center;
	align-items: flex-start;
	@media (max-width: 450px) {
		height: auto;
		overflow: auto;
		background: ${colors.white};
		padding-bottom: 0;
	}
`;
