import styled, { css } from 'styled-components';

import { colors } from '../../variables';

interface InputStyleProps {
	hasError: boolean;
	value: string;
}

export const InputWrapper = styled.div`
	position: relative;
	padding: 2.2rem 0;
	min-width: 33.5rem;

	@media (max-width: 450px) {
		padding: 2rem 0;
		min-width: 0;
		width: 100%;
		max-width: 100%;
	}
	&,
	& input {
		font-size: 1.8rem;
		line-height: 2.8rem;
		@media (max-width: 450px) {
			font-size: 1.4rem;
			line-height: 2.2rem;
		}
	}
`;

const inputPlaceholderStyles = css`
	top: 0;
	left: 0;
	font-size: 1.2rem;
	line-height: 1.8rem;
	@media (max-width: 450px) {
		top: 0rem;
		left: 0rem;
	}
`;

export const InputPlaceholder = styled.label<{ $hasValue: boolean }>`
	color: ${colors.textLight};
	position: absolute;
	top: 4.2rem;
	left: 4rem;
	transition: all 150ms ease-in-out;
	pointer-events: none;
	opacity: 0.5;
	@media (max-width: 450px) {
		top: 3.4rem;
		left: 3rem;
	}

	${({ $hasValue }) => ($hasValue ? inputPlaceholderStyles : '')}
`;

export const InputError = styled.p`
	font-size: 1.4rem;
	color: ${colors.error};
	position: absolute;
	bottom: 0;
	left: 0;
`;

export const InputPrefix = styled.div<{ $hasValue: boolean }>`
	position: absolute;
	display: none;
	top: 0;
	left: 2.9rem;
	bottom: 0;
	align-items: center;
	padding-top: 1px;

	font-size: 1.8rem;
	@media (max-width: 450px) {
		padding-top: 0px;
		margin-top: -3px;
		left: 2.1rem;
		font-size: 1.4rem;
	}

	${({ $hasValue }) => ($hasValue ? 'display: flex' : '')}
`;

export const InputElement = styled.input<InputStyleProps>`
	color: ${colors.textBody};
	background-color: ${colors.white};
	display: block;
	border-radius: 1rem;
	min-height: 5rem;
	width: 100%;

	border: 1px solid ${({ hasError }) => (hasError ? colors.error : colors.borderGray)};

	pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
	cursor: ${(props) => (props.disabled ? 'not-allowed' : 'text')};

	transition: border-color 150ms ease-in-out;
	padding: 2rem 4rem 1.8rem;

	@media (max-width: 450px) {
		padding: 1rem 3rem 1.2rem 3rem;
	}

	&:focus {
		outline: none;
		border: 1px solid ${colors.green};
	}
	&:focus ~ ${InputPlaceholder} {
		top: 0;
		left: 0;
		font-size: 1.2rem;
		line-height: 1.8rem;
	}
	&:focus ~ ${InputError} {
		display: none;
	}
	&:focus ~ ${InputPrefix} {
		display: flex;
	}

	/* Hide arrows for number input field */
	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	/* Firefox */

	&[type='number'] {
		-moz-appearance: textfield;
	}
`;
