export const centsToAmount = (cents: number) => cents / 100;
export const amountToCents = (amount: number) => Math.round(amount * 100);
export const centsToAmountAndFloor = (cents: number) => Math.floor(centsToAmount(cents));

// Splits amountCents so that the last installment has the remainingCents
export function splitAmountCents(amountCents: number, n: number): number[] {
	const result: number[] = [];
	const centsPerPayment = Math.floor(amountCents / n);
	const remainingCents = amountCents - centsPerPayment * n;

	for (let i = 0; i < n; i++) {
		if (i === n - 1) {
			result.push(centsPerPayment + remainingCents);
		} else {
			result.push(centsPerPayment);
		}
	}
	return result;
}
