import React from 'react';
import styled from 'styled-components';

import { RouterOutput } from '../../../../../functions/src/api/trpc/types';

import { LandlordConnectedLogo } from '../../../shared/components/landlord-logo';

import { Spacing } from '../../../base-ui/components';

import { EquifaxBubble } from '../../components/icons/equifax-bubble';

const EquifaxBubbleCustom = styled(EquifaxBubble)`
	font-size: 10rem;
`;
const LogosWrapper = styled.div`
	display: flex;
	justify-content: space-around;
	width: 100%;
	align-items: center;
`;

export const CreditBuilderBeforeCta = ({
	landlord,
}: {
	landlord: RouterOutput['user']['home']['landlord'] | null | undefined;
}) => {
	return (
		<>
			<LogosWrapper>
				{landlord && <LandlordConnectedLogo propertyManagerName={landlord.propertyManagerName} />}
				<EquifaxBubbleCustom />
			</LogosWrapper>
			<Spacing $size="xl" />
		</>
	);
};
