'use strict';

var createDeferred = require('./createDeferred.js');

// ---------- utils
const cancelledStreamSymbol = Symbol('cancelledReadableStream');
/**
 * One-off readable stream
 */ function createReadableStream() {
    let controller = null;
    const deferred = createDeferred.createDeferred();
    let cancelled = false;
    const readable = new ReadableStream({
        start (c) {
            controller = c;
        },
        cancel () {
            deferred.resolve(cancelledStreamSymbol);
            cancelled = true;
        }
    });
    return {
        readable,
        controller,
        cancelledPromise: deferred.promise,
        cancelled () {
            return cancelled;
        }
    };
}
function isCancelledStreamResult(v) {
    return v === cancelledStreamSymbol;
}

exports.createReadableStream = createReadableStream;
exports.isCancelledStreamResult = isCancelledStreamResult;
