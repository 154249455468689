import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { Icon, Spacing, Text } from '../../../base-ui/components';
import { colors } from '../../../base-ui/variables';

import { Carousel, ControlButton, Controls, Dot, Indicators, Panel, PanelContent } from './carousel.styles';

const testimonials = [
	{
		text: (
			<FormattedMessage
				defaultMessage="I have my rent paid without stressing about the money. The split payments work so well and they are very accommodating."
				id="testimonials-carousel.testimonial-1"
			/>
		),
		author: <FormattedMessage defaultMessage="Michelle, Calgary" id="testimonials-carousel.author-1" />,
	},
	{
		text: (
			<FormattedMessage
				defaultMessage="My stress level has been reduced ten fold because I now don't have to worry about rent and payments."
				id="testimonials-carousel.testimonial-2"
			/>
		),
		author: <FormattedMessage defaultMessage="Claude, Regina" id="testimonials-carousel.author-2" />,
	},
	{
		text: (
			<FormattedMessage
				defaultMessage="Splitting rent into 2 payments just makes a world of difference when you live paycheck to paycheck."
				id="testimonials-carousel.testimonial-3"
			/>
		),
		author: <FormattedMessage defaultMessage="Ella, Saskatoon" id="testimonials-carousel.author-3" />,
	},
	{
		text: (
			<FormattedMessage
				defaultMessage="This program has been a true gift! It's such a piece of mind not worrying about full rent on the first of the month."
				id="testimonials-carousel.testimonial-4"
			/>
		),
		author: <FormattedMessage defaultMessage="Mandy, Calgary" id="testimonials-carousel.author-4" />,
	},
	{
		text: (
			<FormattedMessage
				defaultMessage="Fantastic concept for budgeting and very personalized service with quick email and text responses."
				id="testimonials-carousel.testimonial-5"
			/>
		),
		author: <FormattedMessage defaultMessage="Kathy, Calgary" id="testimonials-carousel.author-5" />,
	},
];

const Wrapper = styled.div`
	@media (min-width: 450px) {
		margin: 0 auto;
	}

	${PanelContent}, ${Carousel} {
		height: 20rem;
		@media (max-width: 450px) {
			height: 25rem;
		}
	}
`;

const Title = styled(Text).attrs({ type: 'title' })`
	margin: 3rem 0;
	text-align: center;
	color: ${colors.green};
	span:first-child {
		color: ${colors.pink};
	}
	span:last-child {
		color: ${colors.teal};
	}
`;

const Quote = styled(Text).attrs({ center: true })`
	&:before {
		content: open-quote;
	}
	&:after {
		content: close-quote;
	}
`;

export const TestimonialsCarousel = () => {
	const [activeIndex, setActiveIndex] = useState(0);

	const handleNext = () => {
		if (activeIndex === 4) {
			setActiveIndex(0);
		} else {
			setActiveIndex(activeIndex + 1);
		}
	};
	const handlePrev = () => {
		if (activeIndex === 0) {
			setActiveIndex(4);
		} else {
			setActiveIndex(activeIndex - 1);
		}
	};

	useEffect(() => {
		const interval = setInterval(() => {
			handleNext();
		}, 15000);

		return () => {
			clearInterval(interval);
		};
	}, [activeIndex]);

	return (
		<>
			<Title>
				<FormattedMessage
					defaultMessage="<segment>Why</segment> Avenue Living Communities <segment>love Zenbase.</segment>"
					id="testimonials-carousel.why"
					values={{
						segment: (text) => <span>{text}</span>,
					}}
				/>
			</Title>

			<Wrapper>
				<Carousel>
					{testimonials.map(({ text, author }, i) => (
						<Panel key={i}>
							<PanelContent $visible={activeIndex === i} onClick={handleNext}>
								<Quote>{text}</Quote>
								<Spacing $size="l" />
								<Text center>{author}</Text>
							</PanelContent>
						</Panel>
					))}
				</Carousel>
				<Controls>
					<ControlButton onClick={handlePrev}>
						<Icon icon="LeftArrow" color="white" />
					</ControlButton>
					<Indicators>
						{testimonials.map((_, i) => (
							<Dot $active={activeIndex === i} onClick={() => setActiveIndex(i)} key={i} />
						))}
					</Indicators>
					<ControlButton onClick={handleNext}>
						<Icon icon="RightArrow" color="white" />
					</ControlButton>
				</Controls>
			</Wrapper>
		</>
	);
};
