import React from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { z } from 'zod';

import { BusinessEventType } from '../../../../functions/src/shared/business-events';

import { trpc } from '../../shared/trpc/client';

import { Button, Footer, FormInput, Spacing, Text } from '../../base-ui/components';

import { useUserData } from '../contexts/hooks';
import { useTrackOnboardingEvent } from '../utils/track-onboarding-event';

const schema = z.object({
	username: z.string(),
	password: z.string(),
});

type FormData = z.infer<typeof schema>;

type Props = {
	onSuccess: () => void;
};

export const PaymentPortalConnect = ({ onSuccess }: Props) => {
	const {
		user,
		handlers: { silentRefetch },
	} = useUserData();

	const update = trpc.user.update.useMutation();
	const trackOnboardingEvent = useTrackOnboardingEvent();

	const form = useForm<FormData>({
		resolver: zodResolver(schema),
	});

	const submit = async ({ username, password }: FormData) => {
		const name = user?.paymentPortal?.name;
		if (!name) throw new Error('missing payment portal name in user');

		await update.mutateAsync({ paymentPortal: { name, username, password } });
		await trackOnboardingEvent.mutateAsync({ type: BusinessEventType.OnboardingPaymentPortalEnd });

		await silentRefetch();
		onSuccess();
	};

	const { formatMessage } = useIntl();

	return (
		<FormProvider {...form}>
			<form onSubmit={form.handleSubmit(submit)}>
				<Text type="title">
					<FormattedMessage
						defaultMessage="Connect to your payment portal"
						id="payment-portal-connect-title"
					/>
				</Text>

				<Spacing $size="s" />
				<Text type="small">
					<FormattedMessage
						defaultMessage="Please enter your portal credentials"
						id="payment-portal-connect-instructions"
					/>
				</Text>
				<Spacing $size="m" />

				<FormInput
					name="username"
					placeholder={formatMessage({
						defaultMessage: 'Username',
						id: 'payment-portal-connect-username-placeholder',
					})}
					testId="onboarding-landlord-payment-portal-connect-username"
				/>
				<FormInput
					name="password"
					placeholder={formatMessage({
						defaultMessage: 'Password',
						id: 'payment-portal-connect-password-placeholder',
					})}
					type="password"
					testId="onboarding-landlord-payment-portal-connect-password"
				/>

				<Footer>
					<Button
						htmlType="submit"
						disabled={form.formState.isSubmitting}
						testId="onboarding-landlord-payment-portal-connect-confirm-button"
					>
						<FormattedMessage defaultMessage="Confirm" id="common.confirm" />
					</Button>
				</Footer>
			</form>
		</FormProvider>
	);
};
