import React, { useEffect, useState } from 'react';
import format from 'date-fns/format';
import { cloneDeep } from 'lodash';
import { FormattedMessage, useIntl } from 'react-intl';
import { Navigate, useNavigate } from 'react-router-dom';

import { isoDateToJsDate } from '../../../../../functions/src/shared/iso-date';
import { RentMonth } from '../../../../../functions/src/shared/rent-month';
import { CustomRentConfigForMonth } from '../../../../../functions/src/shared/user-rent';

import { trpc } from '../../../shared/trpc/client';

import { Button, Footer, Radio, Spacing, Text, WarningModal } from '../../../base-ui/components';
import { LoaderCentered } from '../../../base-ui/components/Loader/Loader';

import { BackButton } from '../../components/back-button';
import { SelectConfigDates } from '../../components/custom-rent/select-config-dates';
import { useInterface } from '../../contexts/hooks';
import { links } from '../../Router/paths';

export const SelectSchedule = () => {
	const navigate = useNavigate();
	const { setModal, closeModal } = useInterface();

	const [configForSelectDates, setConfigForSelectDates] = useState<typeof config>();
	const [selectSecondDateDialogOpened, setSelectSecondDateDialogOpened] = useState(false);

	const utils = trpc.useUtils();
	const { data: home } = trpc.user.home.useQuery(undefined);
	const { data: config } = trpc.user.customRent.getConfig.useQuery(undefined, {
		staleTime: Infinity,
	});
	const saveDates = trpc.user.customRent.saveInstallmentDates.useMutation({
		onSuccess(savedConfig) {
			utils.user.customRent.getConfig.setData(undefined, savedConfig);
		},
	});
	const confirmConfig = trpc.user.customRent.confirmInstallmentDates.useMutation({
		onSuccess: ({ config: newConfig, onboardingEvents }) => {
			utils.user.customRent.getConfig.setData(undefined, newConfig);
			utils.user.listOnboardingEvents.setData(undefined, onboardingEvents);
		},
	});

	useEffect(() => {
		if (config && !configForSelectDates) {
			setConfigForSelectDates(cloneDeep(config));
		}
	}, [config, configForSelectDates]);

	if (config === null) {
		return <Navigate to={links.REGISTRATION.CONFIGURE.SELECT_RENT_AMOUNT} />;
	}

	const handleSetConfig = (newConfig: CustomRentConfigForMonth) => {
		setConfigForSelectDates(cloneDeep(newConfig));
		saveDates.mutate({
			installments: newConfig.installments,
			startingRentMonth: newConfig.rentMonth,
		});
	};

	const handleContinue = async (skipOpenedDateDialogCheck?: boolean) => {
		const isSplittingPayments = config?.installments?.length === 2;
		if (!selectSecondDateDialogOpened && !skipOpenedDateDialogCheck && isSplittingPayments) {
			return setModal(
				<SecondDateSelectDialogNotOpenedConfirmationModal
					onContinue={() => {
						handleContinue(true);
						closeModal();
					}}
				/>,
			);
		}

		const userHasCompletedBankOnboarding = home?.user.paymentAccountId || home?.userHasUnverifiedBankAccount;
		await confirmConfig.mutateAsync();
		if (userHasCompletedBankOnboarding) {
			navigate(links.REGISTRATION.CONFIGURE.CONFIRM_CUSTOM_RENT);
		} else {
			navigate(links.REGISTRATION.BANK.CONNECT_BANK);
		}
	};

	const goBack = () => {
		navigate(-1);
	};

	let configRentMonth = config ? RentMonth.fromDbParams(config.rentMonth) : RentMonth.current().next();
	let gracePeriodRentMonth = configRentMonth.prev();

	const currentRentMonth = RentMonth.current();
	if (gracePeriodRentMonth.equals(currentRentMonth.prev()) && configRentMonth.equals(currentRentMonth)) {
		gracePeriodRentMonth = gracePeriodRentMonth.next();
		configRentMonth = configRentMonth.next();
	}

	return (
		<>
			<BackButton onClick={goBack} />
			{home?.isInGracePeriod && home?.canSignUpAfterCustomRentCutoff ? (
				<>
					<Text type="title">
						<FormattedMessage
							defaultMessage="When would you like to start using Zenbase?"
							id="select-schedule.start-using-zenbase"
						/>
					</Text>
					<div>
						{[gracePeriodRentMonth, configRentMonth].map((rm) => {
							const isoDate = rm.firstDayAsIsoDate();
							// @ts-expect-error parcel invalid import https://github.com/parcel-bundler/parcel/issues/9676
							const label = format(isoDateToJsDate(isoDate), 'MMMM');
							return (
								<Radio
									value={isoDate}
									key={rm.monthNumberForDb()}
									label={<>{label}</>}
									onChange={() => {
										if (configForSelectDates) {
											handleSetConfig({ ...configForSelectDates, rentMonth: rm.asDbParams() });
										}
									}}
									checked={
										configForSelectDates
											? rm.equals(RentMonth.fromDbParams(configForSelectDates.rentMonth))
											: false
									}
								/>
							);
						})}
					</div>
				</>
			) : (
				<></>
			)}
			<Text type="title">
				<FormattedMessage
					defaultMessage="Select your payment dates"
					id="select-schedule.select-payment-dates"
				/>
			</Text>

			<Spacing $size="s" />
			<Text type="small">
				<FormattedMessage
					defaultMessage="Simply tap on these payments to select your own custom dates!"
					id="select-schedule.tap-to-select-dates"
				/>
			</Text>
			<Spacing $size="m" />

			{configForSelectDates ? (
				<SelectConfigDates
					config={configForSelectDates}
					setConfig={handleSetConfig}
					onSelectDateOpen={(paymentNumber) => {
						if (paymentNumber === 1) {
							setSelectSecondDateDialogOpened(true);
						}
					}}
				/>
			) : (
				<>
					<Spacing $size="xl" />
					<LoaderCentered />
				</>
			)}

			<Spacing $size="xl" />

			<Footer>
				<Button
					disabled={!config || saveDates.isPending || confirmConfig.isPending}
					onClick={handleContinue}
					testId="select-schedule-button-continue"
				>
					<FormattedMessage defaultMessage="Continue" id="common.continue" />
				</Button>
			</Footer>
		</>
	);
};

const SecondDateSelectDialogNotOpenedConfirmationModal = ({ onContinue }: { onContinue: () => void }) => {
	const { closeModal } = useInterface();
	const intl = useIntl();

	return (
		<WarningModal
			headerText={intl.formatMessage({
				defaultMessage: "Are you sure that you don't want to adjust your 2nd payment date?",
				id: 'select-schedule.confirmation-modal-header',
			})}
			contentText={intl.formatMessage({
				defaultMessage:
					'People like you match their 2nd payment with when they get paid next. You can adjust that date if you need.',
				id: 'select-schedule.confirmation-modal-content',
			})}
			buttonText={intl.formatMessage({
				defaultMessage: "I'm sure",
				id: 'select-schedule.confirmation-modal-button',
			})}
			buttonHandler={onContinue}
			buttonTestId="select-schedule-modal-confirm-button"
			altButtonText={intl.formatMessage({
				defaultMessage: 'Adjust my date',
				id: 'select-schedule.confirmation-modal-alt-button',
			})}
			altButtonTestId="select-schedule-modal-cancel-button"
			altButtonHandler={closeModal}
		/>
	);
};
