import { isUndefined } from 'lodash';
import { z } from 'zod';

import { AccountSource, AccountStatus } from './account';
import { AdminBalancesInfo } from './balance';
import { publicConfig } from './config';
import type { AdminUserListEligibilityInfo, DenormalizedEligibilityRuleResponse } from './eligibility';
import { isIsoDate, isoDate, IsoDate } from './iso-date';
import { PaymentProvider } from './payment-provider';
import { Installment, PaymentMethod, UserLedgerItem } from './payment-transaction';
import type { Landlord } from './pms';
import { RentMonthLike, rentMonthSchema } from './rent-month';
import { FeatureType, UserLandlordPaymentMethod, UserLocale, UserStatus } from './user';

const FLAT_FEE_CENTS = 990;

const TIERED_FEE_TIERS = [
	{ lessThanCents: 100000, feeCents: 990 },
	{ lessThanCents: 150000, feeCents: 1490 },
];
const TIERED_FEE_MAX_CENTS = 1990;

export enum FeeStrategy {
	Flat = 'flat',
	Tiered = 'tiered',
	Variable = 'variable',
	/** AKA Variable V2 */
	VariableByBlockingAmount = 'variable-by-blocking-amount',
	VariableV3 = 'variable-v3',
	Free = 'free',
	Custom = 'custom',
}

export enum RentStatus {
	Created = 'created',
	Ready = 'ready',
	Suspended = 'suspended',
	Disabled = 'disabled',
	Active = 'active',
}

export const overridableRentStatuses = [RentStatus.Created, RentStatus.Suspended, RentStatus.Disabled] as const;

export const enabledRentStatuses = [RentStatus.Created, RentStatus.Ready, RentStatus.Active] as const;

export const editableRentStatuses = [RentStatus.Created, RentStatus.Ready, RentStatus.Suspended] as const;

export type OverridableRentStatus = (typeof overridableRentStatuses)[number];

export interface UserRent {
	id: string;
	userId: string;
	status: RentStatus;
	featureId: FeatureType.CustomRent | FeatureType.UnifiedPayments;
	year: number;
	month: number;
	feeAmountCents: number;
	amountCents: number;
	coveredCents: number;
	previousRentId: string | null;
	hasActivePreviousRent: boolean;
	blockingPaymentAmountCents: number | null;
	blockingAmountPercentage: number | null;
	residentId: string | null;
	roommateId: string | null;
	isDue: boolean;
}

export interface UserRentLedgerItem extends UserLedgerItem {
	rent?: {
		amount: string;
		fee: string;
	};
	missedCount?: number;
}

export type AdminUserRentCommunicationItem = {
	id: string;
	sentAt: Date;
	type: number;
	status: number;
	contents: string;
	tag: string;
};

export const blockingAmountStatuses = ['paid', 'unpaid', 'partially-paid', 'none'] as const;
export type BlockingAmountStatus = (typeof blockingAmountStatuses)[number];

export const previousRentBalanceFilters = ['paid', 'unpaid'] as const;

export enum UserRentLandlordPaymentStatus {
	NotCovered = 'not-covered',
	WaitingForUpfrontPayment = 'waiting-for-upfront-payment',
	NextBatch = 'next-batch',
	Covered = 'covered',
}

export interface AdminCustomRentListItemCommon
	extends AdminUserListEligibilityInfo,
		AdminBalancesInfo,
		Omit<UserRent, 'id' | 'previousRentId' | 'coveredCents'> {
	userRentId: string;
	feeAmountCents: number;
	feeStrategy: FeeStrategy | null;
	createdAt: Date;
	fullName: string;
	firstName: string;
	lastName: string;
	email: string;
	phone: string;
	locale: UserLocale;
	userStatus: UserStatus;
	userCreatedAt: Date;
	propertyVerified: boolean;
	landlordName?: string;
	userRentLandlordName?: string | null;
	landlordId?: string;
	propertyManagerId?: string;
	propertyManagerName?: string;
	tenantId?: string;
	residentId: string | null;
	roommateId: string | null;
	coveredCents: number;
	coveredDirectlyCents: number;
	pmsRoommateId: string | null;
	userLandlordPaymentMethod?: UserLandlordPaymentMethod;
	property?: {
		id: string;
		name: string;
		city: string;
		state: string;
		postalCode: string;
		unit: string;
		residentDeleted: boolean;
	};
	account?: {
		id: string;
		provider: string;
		status: AccountStatus;
		bankName: string;
		mask: string;
		sourceId: string;
		source: AccountSource;
	};
	item?: {
		id: string;
		status: number;
		institutionName: string;
	};
	installments?: Installment[];
	communication?: AdminUserRentCommunicationItem[];
	blockingAmountStatus: BlockingAmountStatus;
	landlordPaymentStatus: UserRentLandlordPaymentStatus;
	landlordPayments?: Array<{
		landlordPaymentBatchId: string;
		amountCents: number;
		createdAt: Date;
	}>;
	baseRentAmountCents: number;
	denormalizedEligibilityResults?: DenormalizedEligibilityRuleResponse[];
	hasPaymentPlan: boolean;
	userVerified: boolean;
}

export type AdminCustomRentListItem = AdminCustomRentListItemCommon;

export interface CustomRentInstallmentConfigInput {
	dayOfMonth: number;
}

export interface CustomRentConfigInput {
	rentAmountCents: number;
	installments: CustomRentInstallmentConfigInput[];
}

export interface LandlordDatesConfig {
	landlordId: Landlord['id'] | null;
	landlordName: Landlord['name'] | null;
	newUserSignUpCutoffDay: number;
	newUserSignUpDeadlineDay: number | null; // null means no exception
	firstInstallmentMaxDay: number;
	upfrontPaymentDeadlineDay: number;
	allowAfterCutoffSignUp: boolean;
}

export interface UserCustomRentConfig extends LandlordDatesConfig {
	rentAmountCents: number;
	feeStrategy: FeeStrategy;
	installments: Array<{
		installmentAmountCents: number;
		dayOfMonth: number;
	}>;
	hasFreeRentPromoCode: boolean;
	startingYear: number | null;
	startingMonth: number | null;
	oneOffChargesEnabled: boolean;
	responsibleForFullRentAmount: boolean;
	firstInstallmentPaymentMethod: PaymentMethod;
	secondInstallmentPaymentMethod: PaymentMethod;
}

export interface CustomRentConfigForMonth extends UserCustomRentConfig {
	rentMonth: RentMonthLike;
	newUserSignUpCutoff: Date;
	newUserSignUpDeadline: Date;
	firstInstallmentMaxDate: IsoDate;
	upfrontPaymentDeadline: IsoDate;
}

function tieredFeeCentsByRentAmountCents(rentAmountCents: number) {
	const matchingTier = TIERED_FEE_TIERS.find((tier) => rentAmountCents < tier.lessThanCents);
	return matchingTier ? matchingTier.feeCents : TIERED_FEE_MAX_CENTS;
}

function variableFeeCentsByRentAmountCents(rentAmountCents: number) {
	return 9_90 + Math.floor(rentAmountCents * 0.0075);
}

function variableByBlockingAmountFeeCentsByRentAmountCents(
	rentAmountCents: number,
	currentBlockingAmountCents: number,
) {
	const defaultBlockingAmountCents = calculateDefaultBlockingAmountCents(rentAmountCents);

	const riskAmountCents = Math.max(rentAmountCents - currentBlockingAmountCents, defaultBlockingAmountCents);

	return 9_90 + Math.floor(0.015 * riskAmountCents);
}

function variableV3FeeCents(rentAmountCents: number, currentBlockingAmountCents: number) {
	const defaultBlockingAmountCents = calculateDefaultBlockingAmountCents(rentAmountCents);

	return (
		9_90 +
		Math.floor(0.0225 * rentAmountCents - 0.03 * Math.min(currentBlockingAmountCents, defaultBlockingAmountCents))
	);
}

export function calculateDefaultBlockingAmountCents(rentAmountCents: number) {
	return rentAmountCents * (publicConfig.customRent.defaultBlockingAmountPercentage / 100);
}

export const feeCentsForRentAmountCents = (
	feeStrategy: FeeStrategy,
	rentAmountCents: number,
	currentFeeCents: number | undefined,
	blockingAmountCents: number,
) =>
	({
		[FeeStrategy.Free]: 0,
		// This is lying. On recalculateFee, the current rent amount fee is used if it's Custom
		// If this gets reached from a different place, the default behaviour is used.
		[FeeStrategy.Custom]: isUndefined(currentFeeCents)
			? tieredFeeCentsByRentAmountCents(rentAmountCents)
			: currentFeeCents,
		[FeeStrategy.Flat]: FLAT_FEE_CENTS,
		[FeeStrategy.Tiered]: tieredFeeCentsByRentAmountCents(rentAmountCents),
		[FeeStrategy.Variable]: variableFeeCentsByRentAmountCents(rentAmountCents),
		[FeeStrategy.VariableByBlockingAmount]: variableByBlockingAmountFeeCentsByRentAmountCents(
			rentAmountCents,
			blockingAmountCents,
		),
		[FeeStrategy.VariableV3]: variableV3FeeCents(rentAmountCents, blockingAmountCents),
	})[feeStrategy];

// params is compatible with CustomRentConfigForMonth, but it does not use some fields
// and the function is used on places where CustomRentConfigForMonth does not make sense
export function calculateFeeCents(config: {
	rentAmountCents: number;
	feeStrategy: FeeStrategy;
	installments: Array<{
		dayOfMonth: number;
	}>;
	currentFeeCents?: number;
	blockingPaymentAmountCents: number | null;
}): number {
	const hasInstallmentAfter1st = config.installments.some((i) => i.dayOfMonth > 1);
	if (!hasInstallmentAfter1st) {
		return 0;
	}

	return feeCentsForRentAmountCents(
		config.feeStrategy,
		config.rentAmountCents,
		config.currentFeeCents,
		config.blockingPaymentAmountCents ?? calculateDefaultBlockingAmountCents(config.rentAmountCents),
	);
}

const eligibilityFiltersSchema = z.object({
	isEligible: z.boolean(),
	eligibilityBankConnected: z.boolean(),
	eligibilityConstantIncome: z.boolean(),
	eligibilitySufficientIncome: z.boolean(),
	eligibilityNotOutstandingPayments: z.boolean(),
	eligibilityResidentVerification: z.boolean(),
	eligibilityArrears: z.boolean(),
});

export const adminUserRentFiltersSchema = z
	.object({
		userId: z.string(),

		userRentIds: z.array(z.string()),

		search: z.string(),
		status: z.nativeEnum(RentStatus),
		statusList: z.array(z.nativeEnum(RentStatus)),
		landlordId: z.string(),
		landlordIds: z.array(z.string()),
		bankAccountProvider: z.nativeEnum(PaymentProvider),
		hasActivePreviousRent: z.boolean(),
		lastInstallmentEffectiveDate: z.date(),
		propertyManagerName: z.string(),
		propertyManagerNames: z.array(z.string()),
		landlordName: z.string(),
		landlordNames: z.array(z.string()),
		propertyVerified: z.boolean(),
		postalCode: z.string(),
		city: z.string(),
		country: z.string(),

		eligibleEmailSent: z.boolean(),
		confirmationSmsSent: z.boolean(),

		notificationTag: z.string(),
		blockingAmountStatus: z.enum(['paid', 'unpaid', 'partially-paid', 'none']),
		blockingAmountStatuses: z.array(z.enum(['paid', 'unpaid', 'partially-paid', 'none'])),

		unscheduledCurrentRent: z.boolean(),
		delinquentCurrentRent: z.boolean(),

		userRentLandlordPaymentStatus: z.nativeEnum(UserRentLandlordPaymentStatus),
		landlordPayments: z.boolean(),
		latestRentStatusChangeCreatedAt: z.string().refine(isIsoDate),
		userPmsResidencyIsNotInRecommendedResidents: z.boolean(),
		previousRentBalance: z.enum(['paid', 'unpaid']),
		hasPositiveBalance: z.boolean(),
		userCreatedAt: z.string().refine(isoDate),
		hasBankAccount: z.boolean(),
		hasPaymentPlan: z.boolean(),
		propertyResidentDeleted: z.boolean(),

		bankStatementUploadedSinceAt: z.string().refine(isIsoDate),
		lastSuccessfulSyncSinceAt: z.string().refine(isIsoDate),
	})
	.merge(eligibilityFiltersSchema)
	.merge(rentMonthSchema)
	.partial();

export type AdminUserRentFilters = z.infer<typeof adminUserRentFiltersSchema>;

// Keep in sync with filters in getAdminCustomRentOverviewStats
export const statsItems: Record<string, Omit<AdminUserRentFilters, 'year' | 'month'>> = {
	all: {},

	eligible: { isEligible: true },
	inEligible: { isEligible: false },

	rentActive: { status: RentStatus.Active },
	rentCreated: { status: RentStatus.Created },
	rentDisabled: { status: RentStatus.Disabled },
	rentReady: { status: RentStatus.Ready },
	rentSuspended: { status: RentStatus.Suspended },
};
