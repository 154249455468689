{
	"v": "4.8.0",
	"meta": { "g": "LottieFiles AE 1.0.0", "a": "", "k": "", "d": "", "tc": "" },
	"fr": 60,
	"ip": 0,
	"op": 121,
	"w": 1400,
	"h": 1400,
	"nm": "Zenbase_Logo_Loading_Animation_1",
	"ddd": 0,
	"assets": [],
	"layers": [
		{
			"ddd": 0,
			"ind": 1,
			"ty": 4,
			"nm": "Artwork 1 Outlines",
			"sr": 1,
			"ks": {
				"o": { "a": 0, "k": 100, "ix": 11 },
				"r": { "a": 0, "k": 0, "ix": 10 },
				"p": { "a": 0, "k": [700, 700, 0], "ix": 2 },
				"a": { "a": 0, "k": [382.5, 354, 0], "ix": 1 },
				"s": { "a": 0, "k": [75, 75, 100], "ix": 6 }
			},
			"ao": 0,
			"shapes": [
				{
					"ty": "gr",
					"it": [
						{
							"ind": 0,
							"ty": "sh",
							"ix": 1,
							"ks": {
								"a": 0,
								"k": {
									"i": [
										[11.349, 0],
										[0, 0],
										[3.854, -3.853],
										[0, 0],
										[0, -5.45],
										[0, 0],
										[-11.348, 0],
										[0, 0],
										[-3.854, 3.854],
										[0, 0],
										[0, 5.45],
										[0, 0]
									],
									"o": [
										[0, 0],
										[-5.45, 0],
										[0, 0],
										[-3.853, 3.854],
										[0, 0],
										[0, 11.348],
										[0, 0],
										[5.45, 0],
										[0, 0],
										[3.854, -3.854],
										[0, 0],
										[0, -11.348]
									],
									"v": [
										[333.308, -353.859],
										[223.042, -353.857],
										[208.512, -347.839],
										[-347.839, 208.512],
										[-353.857, 223.042],
										[-353.857, 333.312],
										[-333.309, 353.86],
										[-223.036, 353.86],
										[-208.506, 347.841],
										[347.838, -208.503],
										[353.857, -223.033],
										[353.857, -333.311]
									],
									"c": true
								},
								"ix": 2
							},
							"nm": "Path 1",
							"mn": "ADBE Vector Shape - Group",
							"hd": false
						},
						{
							"ty": "fl",
							"c": { "a": 0, "k": [0.039215686275, 0.388235324037, 0.458823559331, 1], "ix": 4 },
							"o": { "a": 0, "k": 100, "ix": 5 },
							"r": 1,
							"bm": 0,
							"nm": "Fill 1",
							"mn": "ADBE Vector Graphic - Fill",
							"hd": false
						},
						{
							"ty": "tr",
							"p": { "a": 0, "k": [382.073, 353.86], "ix": 2 },
							"a": { "a": 0, "k": [0, 0], "ix": 1 },
							"s": {
								"a": 1,
								"k": [
									{
										"i": { "x": [0.24, 0.24], "y": [1, 1] },
										"o": { "x": [0.76, 0.76], "y": [0, 0] },
										"t": 0,
										"s": [100, 100]
									},
									{
										"i": { "x": [0.24, 0.24], "y": [1, 1] },
										"o": { "x": [0.76, 0.76], "y": [0, 0] },
										"t": 55,
										"s": [101, 101]
									},
									{ "t": 65, "s": [100, 100] }
								],
								"ix": 3
							},
							"r": { "a": 0, "k": 0, "ix": 6 },
							"o": { "a": 0, "k": 100, "ix": 7 },
							"sk": { "a": 0, "k": 0, "ix": 4 },
							"sa": { "a": 0, "k": 0, "ix": 5 },
							"nm": "Transform"
						}
					],
					"nm": "Group 1",
					"np": 4,
					"cix": 2,
					"bm": 0,
					"ix": 1,
					"mn": "ADBE Vector Group",
					"hd": false
				},
				{
					"ty": "gr",
					"it": [
						{
							"ind": 0,
							"ty": "sh",
							"ix": 1,
							"ks": {
								"a": 0,
								"k": {
									"i": [
										[-5.45, 0],
										[0, 0],
										[0, 11.348],
										[0, 0],
										[11.348, 0],
										[0, 0],
										[3.854, -3.854],
										[0, 0],
										[-8.025, -8.025],
										[0, 0]
									],
									"o": [
										[0, 0],
										[11.348, 0],
										[0, 0],
										[0, -11.349],
										[0, 0],
										[-5.45, 0],
										[0, 0],
										[-8.025, 8.025],
										[0, 0],
										[3.854, 3.853]
									],
									"v": [
										[-8.158, 108.726],
										[96.336, 108.726],
										[116.884, 88.178],
										[116.884, -88.177],
										[96.336, -108.726],
										[-8.158, -108.726],
										[-22.688, -102.707],
										[-110.865, -14.53],
										[-110.865, 14.53],
										[-22.688, 102.708]
									],
									"c": true
								},
								"ix": 2
							},
							"nm": "Path 1",
							"mn": "ADBE Vector Shape - Group",
							"hd": false
						},
						{
							"ty": "fl",
							"c": {
								"a": 1,
								"k": [
									{
										"i": { "x": [0.16], "y": [1] },
										"o": { "x": [0.84], "y": [0] },
										"t": 0,
										"s": [0.117647066712, 0.807843208313, 0.788235366344, 1]
									},
									{
										"i": { "x": [0.16], "y": [1] },
										"o": { "x": [0.84], "y": [0] },
										"t": 58,
										"s": [0.117647066712, 0.807843208313, 0.788235366344, 1]
									},
									{ "t": 60, "s": [1, 0.72549021244, 0.666666686535, 1] }
								],
								"ix": 4
							},
							"o": { "a": 0, "k": 100, "ix": 5 },
							"r": 1,
							"bm": 0,
							"nm": "Fill 1",
							"mn": "ADBE Vector Graphic - Fill",
							"hd": false
						},
						{
							"ty": "tr",
							"p": { "a": 0, "k": [383.366, 354.042], "ix": 2 },
							"a": { "a": 0, "k": [266.482, 247.408], "ix": 1 },
							"s": { "a": 0, "k": [100, 100], "ix": 3 },
							"r": {
								"a": 1,
								"k": [
									{
										"i": { "x": [0.16], "y": [1] },
										"o": { "x": [0.84], "y": [0] },
										"t": 0,
										"s": [0]
									},
									{ "t": 120, "s": [-180] }
								],
								"ix": 6
							},
							"o": { "a": 0, "k": 100, "ix": 7 },
							"sk": { "a": 0, "k": 0, "ix": 4 },
							"sa": { "a": 0, "k": 0, "ix": 5 },
							"nm": "Transform"
						}
					],
					"nm": "Group 2",
					"np": 4,
					"cix": 2,
					"bm": 0,
					"ix": 2,
					"mn": "ADBE Vector Group",
					"hd": false
				},
				{
					"ty": "gr",
					"it": [
						{
							"ind": 0,
							"ty": "sh",
							"ix": 1,
							"ks": {
								"a": 0,
								"k": {
									"i": [
										[5.45, 0],
										[0, 0],
										[0, -11.348],
										[0, 0],
										[-11.348, 0],
										[0, 0],
										[-3.854, 3.854],
										[0, 0],
										[8.025, 8.025],
										[0, 0]
									],
									"o": [
										[0, 0],
										[-11.348, 0],
										[0, 0],
										[0, 11.349],
										[0, 0],
										[5.45, 0],
										[0, 0],
										[8.025, -8.025],
										[0, 0],
										[-3.854, -3.853]
									],
									"v": [
										[8.158, -108.726],
										[-96.336, -108.726],
										[-116.884, -88.178],
										[-116.884, 88.177],
										[-96.336, 108.726],
										[8.158, 108.726],
										[22.688, 102.707],
										[110.865, 14.53],
										[110.865, -14.53],
										[22.688, -102.708]
									],
									"c": true
								},
								"ix": 2
							},
							"nm": "Path 1",
							"mn": "ADBE Vector Shape - Group",
							"hd": false
						},
						{
							"ty": "fl",
							"c": {
								"a": 1,
								"k": [
									{
										"i": { "x": [0.16], "y": [1] },
										"o": { "x": [0.84], "y": [0] },
										"t": 0,
										"s": [1, 0.725490196078, 0.666666666667, 1]
									},
									{
										"i": { "x": [0.16], "y": [1] },
										"o": { "x": [0.84], "y": [0] },
										"t": 58,
										"s": [1, 0.725490196078, 0.666666666667, 1]
									},
									{ "t": 60, "s": [0.117647059262, 0.807843148708, 0.78823530674, 1] }
								],
								"ix": 4
							},
							"o": { "a": 0, "k": 100, "ix": 5 },
							"r": 1,
							"bm": 0,
							"nm": "Fill 1",
							"mn": "ADBE Vector Graphic - Fill",
							"hd": false
						},
						{
							"ty": "tr",
							"p": { "a": 0, "k": [383.355, 353.905], "ix": 2 },
							"a": { "a": 0, "k": [-263.907, -245.088], "ix": 1 },
							"s": { "a": 0, "k": [100, 100], "ix": 3 },
							"r": {
								"a": 1,
								"k": [
									{
										"i": { "x": [0.16], "y": [1] },
										"o": { "x": [0.84], "y": [0] },
										"t": -2,
										"s": [0]
									},
									{ "t": 120, "s": [-180] }
								],
								"ix": 6
							},
							"o": { "a": 0, "k": 100, "ix": 7 },
							"sk": { "a": 0, "k": 0, "ix": 4 },
							"sa": { "a": 0, "k": 0, "ix": 5 },
							"nm": "Transform"
						}
					],
					"nm": "Group 3",
					"np": 4,
					"cix": 2,
					"bm": 0,
					"ix": 3,
					"mn": "ADBE Vector Group",
					"hd": false
				}
			],
			"ip": 0,
			"op": 300,
			"st": 0,
			"bm": 0
		},
		{
			"ddd": 0,
			"ind": 2,
			"ty": 4,
			"nm": "Shape Layer 1",
			"sr": 1,
			"ks": {
				"o": { "a": 0, "k": 100, "ix": 11 },
				"r": { "a": 0, "k": 0, "ix": 10 },
				"p": { "a": 0, "k": [699.573, 699.86, 0], "ix": 2 },
				"a": { "a": 0, "k": [97.203, 61.49, 0], "ix": 1 },
				"s": { "a": 0, "k": [100, 100, 100], "ix": 6 }
			},
			"ao": 0,
			"shapes": [
				{
					"ty": "gr",
					"it": [
						{
							"d": 1,
							"ty": "el",
							"s": { "a": 0, "k": [1143.259, 1143.259], "ix": 2 },
							"p": { "a": 0, "k": [0, 0], "ix": 3 },
							"nm": "Ellipse Path 1",
							"mn": "ADBE Vector Shape - Ellipse",
							"hd": false
						},
						{
							"ty": "fl",
							"c": { "a": 0, "k": [1, 1, 1, 1], "ix": 4 },
							"o": { "a": 0, "k": 100, "ix": 5 },
							"r": 1,
							"bm": 0,
							"nm": "Fill 1",
							"mn": "ADBE Vector Graphic - Fill",
							"hd": false
						},
						{
							"ty": "tr",
							"p": { "a": 0, "k": [97.63, 61.63], "ix": 2 },
							"a": { "a": 0, "k": [0, 0], "ix": 1 },
							"s": { "a": 0, "k": [99, 99], "ix": 3 },
							"r": { "a": 0, "k": 0, "ix": 6 },
							"o": { "a": 0, "k": 100, "ix": 7 },
							"sk": { "a": 0, "k": 0, "ix": 4 },
							"sa": { "a": 0, "k": 0, "ix": 5 },
							"nm": "Transform"
						}
					],
					"nm": "Ellipse 1",
					"np": 3,
					"cix": 2,
					"bm": 0,
					"ix": 1,
					"mn": "ADBE Vector Group",
					"hd": false
				}
			],
			"ip": 0,
			"op": 300,
			"st": 0,
			"bm": 0
		}
	],
	"markers": []
}
