import { css } from 'styled-components';

export const tinyCss = css`
	font-size: 1.6rem;
	line-height: 2.4rem;

	@media (max-width: 450px) {
		font-size: 1.4rem;
		line-height: 1.4; // this is intentional according to design, but wrong, ~ 2.24rem
	}
`;
