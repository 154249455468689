import React, { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import { triggerChatWidget } from '../../shared/zendesk';

import { Alert, AlertText, AlertTitle, Anchor } from '../../base-ui/components';

type Props = {
	title?: ReactNode;
	subtitle?: ReactNode;
};

export const AlertError = ({
	title = <FormattedMessage defaultMessage="An error ocurred" id="alert-error.an-error-occurred" />,
	subtitle = (
		<>
			<FormattedMessage
				defaultMessage="Please try again, if errors keep happening {contactSupport}."
				id="alert-error.please-try-again"
				values={{
					contactSupport: (
						<Anchor onClick={triggerChatWidget}>
							<FormattedMessage defaultMessage="contact support" id="alert-error.contact-support" />
						</Anchor>
					),
				}}
			/>
		</>
	),
}: Props) => {
	return (
		<Alert>
			<AlertTitle>{title}</AlertTitle>
			<AlertText>{subtitle}</AlertText>
		</Alert>
	);
};
