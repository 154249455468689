import { createGlobalStyle } from 'styled-components';

import { colors } from '../base-ui/variables';

export const GlobalStyles = createGlobalStyle`

html {
  font-size: 10px;
  background: #f5f5f5;
  @media (max-width: 1600px) {
      font-size: 8px;
  }

  @media (max-width: 450px) {
      font-size: 10px;
  }
}

body {
  font-size: 1.6rem;
  background: #f5f5f5;
	font-weight: 400;
  font-weight: normal;
}

/* Modern CSS Reset */
/* https://alligator.io/css/minimal-css-reset/ */
body, h1, h2, h3, h4, h5, h6, p, ol, ul, input, button, p {
  margin: 0;
  padding: 0;
  font-family: 'Satoshi', sans-serif ;
}

h1{ 
  font-size: 6.8rem;
  font-style: normal;
  font-weight: 400;
  line-height: 10.4rem;
  letter-spacing: 0.01em;
  text-align: center;
}
*, *:before, *:after {
  box-sizing: border-box;
}
ol, ul {
  list-style: none;
}
img {
  max-width: 100%;
  height: auto;
}
/* Links */
a {
  text-decoration: underline;
  color: inherit;
  cursor: pointer;
  
&.active {
    text-decoration: none;
  }
}
[id^="plaid-link-iframe"]{
  background: white;
}
:root {
  --rdp-cell-size: 45px;
  --rdp-accent-color: #0a6375;
  --rdp-background-color: ${colors.white};
  /* Switch to dark colors for dark themes */
  --rdp-accent-color-dark: #0a6375;
  --rdp-background-color-dark: #0a6375;
  /* Outline border for focused elements */
  --rdp-outline: 1px solid white;
  /* Outline border for focused and selected elements */
  --rdp-outline-selected: 2px solid #0a6375;
}
`;
