import React from 'react';
import { useIntl } from 'react-intl';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import { UserLocale } from '../../../../../functions/src/shared/user';

import { Button } from '../../../base-ui/components/Button/Button';

import { LANGUAGE_KEY } from '../../utils/i18n/locales-provider-service';

const Language = styled.span`
	color: #0a6375;

	:hover {
		color: #1ecec9;
	}
`;

export const LanguageSwitcher = () => {
	const intl = useIntl();
	const [searchParams, setSearchParams] = useSearchParams();

	function handleSetLocale(l: UserLocale) {
		searchParams.set(LANGUAGE_KEY, l);
		// we just update parameter here, it will be picked up by the provider
		setSearchParams(searchParams);
	}

	return (
		<div>
			{intl.locale === UserLocale.EnglishCa && (
				<Button
					onClick={() => handleSetLocale(UserLocale.FrenchCa)}
					type="icon-transparent"
					testId="home-language-switcher"
				>
					<Language>EN</Language>
				</Button>
			)}
			{intl.locale === UserLocale.FrenchCa && (
				<Button
					onClick={() => handleSetLocale(UserLocale.EnglishCa)}
					type="icon-transparent"
					testId="home-language-switcher"
				>
					<Language>FR</Language>
				</Button>
			)}
		</div>
	);
};
