import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import reschedule from 'url:../../static/shapes/reschedule-etransfer.png';

import { PaymentMethod } from '../../../../../functions/src/shared/payment-transaction';

import { useUserData } from '../../contexts/user-data-context';
import { links } from '../../Router/paths';
import { OnboardingSuccess } from '../../templates/onboarding-success';

export const CustomRentOnboardingSuccess3 = () => {
	const navigate = useNavigate();
	const { home } = useUserData();

	const handleNext = () => {
		navigate(links.REGISTRATION.ONBOARDING_SUCCESS.SUPPORT);
	};

	const firstInstallmentMethod = home?.activeRentConfig?.firstInstallmentPaymentMethod;
	const secondInstallmentMethod = home?.activeRentConfig?.secondInstallmentPaymentMethod;

	if (firstInstallmentMethod === PaymentMethod.DirectDebit) {
		if (secondInstallmentMethod === PaymentMethod.DirectDebit) {
			return (
				<OnboardingSuccess
					imageSrc={reschedule}
					title={
						<FormattedMessage
							defaultMessage="Direct debit for both payments of the month"
							id="custom-rent-onboarding-success3.title-direct-debit-both"
						/>
					}
					subtitle={
						<>
							<FormattedMessage
								defaultMessage="You will be charged for both your <color>1st</color> and <color>2nd repayment</color> via debit card on the dates specified."
								id="custom-rent-onboarding-success3.subtitle-direct-debit-both"
							/>
						</>
					}
					ctaTestId="onboarding-success-cr-step3-continue-button"
					onNext={handleNext}
				/>
			);
		}
		return (
			<OnboardingSuccess
				imageSrc={reschedule}
				title={
					<FormattedMessage
						defaultMessage="Direct debit for the 1st payment of the month"
						id="custom-rent-onboarding-success3.title-direct-debit"
					/>
				}
				subtitle={
					<>
						<FormattedMessage
							defaultMessage="You will be charged for your <color>1st repayment</color> via debit card on the date specified. <color>2nd repayment</color> will be <color>automatically withdrawn</color> from your connected bank account."
							id="custom-rent-onboarding-success3.subtitle-direct-debit"
						/>
					</>
				}
				ctaTestId="onboarding-success-cr-step3-continue-button"
				onNext={handleNext}
			/>
		);
	}

	return (
		<OnboardingSuccess
			imageSrc={reschedule}
			title={
				<FormattedMessage
					defaultMessage="e-Transfer the 1st payment of the month"
					id="custom-rent-onboarding-success3.title"
				/>
			}
			subtitle={
				<>
					<FormattedMessage
						defaultMessage="You need to e-Transfer your <color>1st repayment</color> to <color>payments@myzenbase.com</color> on the date specified. <color>2nd repayment</color> will be <color>automatically withdrawn</color> from your connected bank account."
						id="custom-rent-onboarding-success3.subtitle"
					/>
				</>
			}
			ctaTestId="onboarding-success-cr-step3-continue-button"
			onNext={handleNext}
		/>
	);
};
