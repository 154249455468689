import React, { ReactNode } from 'react';
import styled from 'styled-components';

type Props = {
	children: ReactNode;
};

const Row = styled.div`
	display: flex;
	justify-content: space-between;
`;

export const OnboardingNavigationRow = ({ children }: Props) => {
	return <Row>{children}</Row>;
};
