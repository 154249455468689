import React from 'react';
import styled, { keyframes } from 'styled-components';

import { colors } from '../../variables';

const height = '1.6rem';

const ProgressWrapper = styled.div`
	position: relative;
	width: 100%;
	background-color: ${colors.lightGray};
	height: ${height};
	border-radius: 2rem;
	overflow: hidden;
`;

const indeterminateAnimation = keyframes`
  0% {
    left: -100%;
  }

  100% {
    left: 100%;
  }
`;

export const IndeterminateProgress = styled.div`
	width: 50%;
	height: ${height};
	position: absolute;
	top: 0;
	left: 0;
	background: ${colors.teal};
	border-radius: 2rem;
	animation: ${indeterminateAnimation} 2s linear infinite;
`;

export const DeterminateProgress = styled.div<{
	$value?: number;
}>`
	width: ${({ $value }) => $value}%;
	height: ${height};
	position: absolute;
	top: 0;
	left: 0;
	background: ${colors.teal};
	border-top-left-radius: 2rem;
	border-bottom-left-radius: 2rem;
	transition: width 0.25s ease-in-out;
`;

interface ProgressBarProps {
	type?: 'determinate' | 'indeterminate';
	value?: number;
}

export const ProgressBar = ({ type = 'indeterminate', value = 0 }: ProgressBarProps) => {
	return (
		<ProgressWrapper>
			{type === 'determinate' ? <DeterminateProgress $value={value} /> : <IndeterminateProgress />}
		</ProgressWrapper>
	);
};
