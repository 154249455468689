import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { Button, Icon } from '../../../base-ui/components';

import { useInterface, useUserData } from '../../contexts/hooks';
import { LearnMoreAboutFeaturesModalContent } from '../modals/learn-more-about-features-modal-content';

const LearnMoreLink = styled.div`
	display: flex;
	justify-content: center;
	width: 100%;
	margin-top: 2rem;
`;

export const LearnMore = () => {
	const { setModal } = useInterface();

	const userDataState = useUserData();
	return (
		<LearnMoreLink
			onClick={() => {
				setModal(<LearnMoreAboutFeaturesModalContent userDataState={userDataState} />);
			}}
		>
			<Button type="link">
				<FormattedMessage defaultMessage="Learn more" id="learn-more.learn-more" />
				<Icon icon="Info" />
			</Button>
		</LearnMoreLink>
	);
};
