export const fonts = {
	// TODO: remove
	button: 'normal 700 1.6rem/2.4rem Satoshi, sans-serif',
	buttonSmall: 'normal 700 1.4rem/2.2rem Satoshi, sans-serif',
	title: 'normal 700 3.2rem/4rem Satoshi, sans-serif',
	largeText: 'normal 400 2.8rem/3.6rem Satoshi, sans-serif',

	text: 'normal 400 2rem/2.8rem Satoshi, sans-serif',
	infoText: 'normal 400 1.8rem/2.8rem Satoshi, sans-serif',
	smallText: 'normal 400 1.6rem/2.8rem Satoshi, sans-serif',

	input: 'normal 400 1.4rem/2.2rem Satoshi, sans-serif',

	smallerText: 'normal 400 1.4rem/1.8rem Satoshi, sans-serif',
	tinyText: 'normal 700 1rem/1.3rem Satoshi, sans-serif',
	font2: 'normal 700 1.8rem/2.8rem Satoshi, sans-serif', //wip
	font3: 'normal 400 1.6rem/2.8rem Satoshi, sans-serif', //wip
} as const;

export const colors = {
	white: '#ffffff',
	green: '#0a6375',
	greenHover: '#004958',
	bgDisabled: '#e2e2e2',
	lightGray: '#f5f5f5',
	borderGray: '#c4c4c4',
	textBody: '#171717',
	textLight: '#5d5d5d',
	textDisabled: '#E2E2E2',
	error: '#FB5530',
	teal: '#1ecec9',
	tealHover: '#1bbbb7',
	pink: '#ffb9aa',
	blue: '#d2f5f4',
	red: '#fb5530',
	redHover: '#fa3d13',
	alertBackground: '#fff1ee',
} as const;
