import styled from 'styled-components';

import { colors } from '../../variables';

type Props = {
	$borderRadius?: number;
};

export const Paper = styled.div<Props>`
	position: relative;

	background: ${colors.white};

	border-radius: ${({ $borderRadius = 1 }) => `${$borderRadius}rem`};
	box-shadow: 0px 5px 20px rgba(23, 23, 23, 0.08);

	display: flex;
	flex-direction: column;

	padding: 3rem;
`;
