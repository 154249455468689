import styled from 'styled-components';

export const Center = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`;

export const trimWhiteSpace = (s: string) => {
	if (s && typeof s === 'string') {
		return s.replace(/\s+/g, '');
	}
	return s;
};

export const normalizeString = (s: string) => {
	if (s && typeof s === 'string') {
		return trimWhiteSpace(s).toLowerCase();
	}
	return s;
};

export const cloneDeep = <T,>(data: T): T => JSON.parse(JSON.stringify(data));

type SearchableObject = {
	[key: string]: unknown;
};

export const partialObjectSearch = <T extends SearchableObject>(items: T[], query: string) => {
	// Split up the query by space
	const search = query.trim().split(',');
	const foundObjects: T[] = [];

	items.forEach((item) => {
		const copiedItem = { ...item };
		delete copiedItem.id; // it makes the function a bit less reusable, but we use uuids in entity ids and generaly they should not be searchable
		let matches = 0;

		const stringifiedObjectProperties = Object.values(copiedItem).join().toLowerCase();

		search.forEach((searchPartial) => {
			if (stringifiedObjectProperties.includes(searchPartial.toLowerCase())) {
				matches++;
			}
		});

		if (matches >= search.length) {
			foundObjects.push(item);
		}
	});

	return foundObjects;
};
