import React from 'react';

import { CommonIconProps } from './types';

export const RightChevron = ({ fill }: CommonIconProps) => {
	return (
		<svg width="11" height="18" viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M2.00002 1.63725L9.66016 8.81863L2.00002 16"
				stroke={fill}
				strokeWidth="2.15522"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};
