import React from 'react';

export const BankLogoBmo = () => (
	<svg width="180" height="90" viewBox="0 0 180 90" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M127.455 20C113.705 20 102.455 31.1377 102.455 45C102.455 58.7504 113.593 70 127.455 70C141.318 70 152.455 58.8623 152.455 45C152.455 31.2496 141.318 20 127.455 20Z"
			fill="#666666"
		/>
		<path d="M139.272 48.4116H115.639V55.914H139.272V48.4116Z" fill="white" />
		<path
			d="M126.656 37.274C127 37.6175 127.224 37.7294 127.455 37.8412C127.679 37.8412 128.023 37.7294 128.254 37.274C128.254 37.274 132.233 32.0487 132.457 31.817C133.024 31.1378 133.592 31.1378 134.159 31.817L139.16 38.1768V48.4037L134.159 42.0439C133.592 41.3647 132.912 41.3647 132.337 42.0439C132.113 42.2676 128.358 47.3811 128.358 47.3811C127.791 48.18 127.224 48.18 126.656 47.3811C126.656 47.3811 122.678 42.2676 122.566 42.0439C121.998 41.3647 121.319 41.3647 120.744 42.0439L115.742 48.4037V38.1768C115.742 38.1768 120.512 32.1525 120.856 31.817C121.423 31.1378 122.102 31.1378 122.558 31.817C122.678 32.1605 126.656 37.274 126.656 37.274Z"
			fill="white"
		/>
		<path
			d="M39.8396 50.7926C39.8396 53.637 38.9287 54.9953 35.6369 54.9953H33.5916V47.1573H35.7488C38.705 47.0454 39.8396 48.0681 39.8396 50.7926ZM44.2739 51.0243C44.2739 47.7326 41.5494 46.59 38.3615 46.4782V46.3663C41.086 46.1426 43.3631 45 43.3631 42.1637C43.3631 39.0956 40.7504 38.5283 37.906 38.5283C34.4944 38.5283 30.4036 38.6402 27 38.5283V39.4392C27.6791 39.4392 29.5008 39.4392 29.5008 40.35V53.9885C29.5008 54.8994 27.6791 55.0112 27 55.0112V55.8102C30.2918 55.6983 34.2707 55.8102 37.5705 55.8102C41.094 55.7942 44.2739 54.6597 44.2739 51.0243ZM39.1604 42.7309C39.1604 44.8882 38.2496 46.1426 35.7488 46.1426H33.5916V39.4392C33.8153 39.4392 34.7261 39.4392 35.0697 39.4392H35.7488C38.2496 39.4312 39.1604 40.5657 39.1604 42.7309Z"
			fill="#666666"
		/>
		<path
			d="M66.4296 55.7942C64.8396 55.7942 63.3615 55.7942 61.7715 55.7942V54.8833C62.4507 54.8833 64.3842 54.8833 64.3842 53.8606V41.1329L57.3372 55.906H56.6581L49.723 41.1329V53.8606C49.723 54.7715 51.7683 54.8833 52.4475 54.8833V55.7942C50.514 55.6823 47.9013 55.6823 45.9678 55.7942V54.8833C46.6469 54.8833 48.4686 54.7715 48.4686 53.8606V40.2221C48.4686 39.3113 46.6469 39.1994 45.9678 39.1994V38.2886C48.125 38.4004 50.514 38.4004 52.6712 38.2886L58.5836 50.6727L64.6079 38.2886C66.7652 38.4004 69.0422 38.4004 71.0876 38.2886V39.1994C70.4085 39.1994 68.5868 39.1994 68.5868 40.2221V53.8606C68.5868 54.7715 70.4085 54.8833 71.0876 54.8833V55.7942C69.6175 55.7942 68.0195 55.7942 66.4296 55.7942Z"
			fill="#666666"
		/>
		<path
			d="M91.8849 47.1573C91.8849 41.4765 88.3614 38.1768 82.1134 38.1768C75.8654 38.1768 72.3419 41.3567 72.3419 47.1573C72.3419 52.9499 75.8654 56.1378 82.1134 56.1378C88.4733 56.1378 91.8849 52.9578 91.8849 47.1573ZM87.3387 47.1573C87.3387 50.5689 86.9952 55.2269 82.1134 55.2269C77.2317 55.2269 76.8881 50.457 76.8881 47.1573C76.8881 43.7456 77.2317 39.0876 82.1134 39.0876C87.0031 39.0876 87.3387 43.8655 87.3387 47.1573Z"
			fill="#666666"
		/>
	</svg>
);
