import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import { z } from 'zod';

import { useMyZenbaseLinks } from '../../../shared/my-zenbase-links';
import { triggerChatWidget } from '../../../shared/zendesk';

import { Anchor, Box, Button, Divider, ErrorBanner, Spacing, Text } from '../../../base-ui/components';

const stateSchema = z.object({
	errorCode: z.string().optional(),
});

type Props = {
	reconnectBankLink: string;
};

export const CannotValidateEarnings = ({ reconnectBankLink }: Props) => {
	const location = useLocation();
	const navigate = useNavigate();
	const stateParsed = stateSchema.safeParse(location.state);
	const myZenbaseLinks = useMyZenbaseLinks();
	return (
		<>
			<ErrorBanner />
			<Spacing $size="l" />
			<Text type="title">
				<FormattedMessage defaultMessage="Cannot validate your earnings" id="cannot-validate-earnings.title" />
			</Text>
			<Spacing $size="s" />
			{stateParsed.success ? (
				<>
					<Text>
						<FormattedMessage
							defaultMessage="Something went wrong with this request"
							id="cannot-validate-earnings.something-went-wrong"
						/>
					</Text>
					<Spacing $size="l" />
					<Text>
						<FormattedMessage
							defaultMessage="Error code: {errorCode}"
							id="cannot-validate-earnings.error-code"
							values={{ errorCode: stateParsed.data.errorCode ?? 'FWKHM' }}
						/>
					</Text>
					<Spacing $size="m" />
					<Text>
						<FormattedMessage
							defaultMessage="Please {contactSupport} with the above error code to continue."
							id="cannot-validate-earnings.contact-support"
							values={{
								contactSupport: (
									<Anchor onClick={triggerChatWidget}>
										<FormattedMessage
											defaultMessage="contact support"
											id="alert-error.contact-support"
										/>
									</Anchor>
								),
							}}
						/>
					</Text>
				</>
			) : (
				<>
					<Text>
						<FormattedMessage
							defaultMessage="We haven’t received the transaction details from your bank account for us to validate your paycheck or cannot determine the next paycheck date."
							id="cannot-validate-earnings.transaction-details"
						/>
					</Text>
					<Spacing $size="l" />
					<Text>
						<FormattedMessage
							defaultMessage="Please reconnect with the same bank account or connect with a different bank account where you are receiving your paycheck."
							id="cannot-validate-earnings.reconnect-bank-account"
						/>
					</Text>
					<Spacing $size="m" />
					<Divider />
					<Text type="small">
						<FormattedMessage
							defaultMessage="We're always here to help. Please email us at {supportEmail} or message us on {website}."
							id="cannot-validate-earnings.help"
							values={{
								supportEmail: (
									<Anchor href="mailto:support@myzenbase.com" target="_blank">
										support@myzenbase.com
									</Anchor>
								),
								website: (
									<Anchor href={myZenbaseLinks.home} target="_blank" rel="noopener noreferrer">
										<FormattedMessage
											defaultMessage="myzenbase.com"
											id="cannot-validate-earnings.website"
										/>
									</Anchor>
								),
							}}
						/>
					</Text>
					<Spacing $size="xl" />
					<Box justify="center">
						<Button
							testId="cannot-validate-earning-button-reconnect"
							onClick={() => navigate(reconnectBankLink)}
						>
							<FormattedMessage
								defaultMessage="Reconnect Bank Account"
								id="cannot-validate-earnings.reconnect-bank-account-button"
							/>
						</Button>
					</Box>
				</>
			)}
		</>
	);
};
