import React from 'react';

import { CommonIconProps } from './types';

export const EyeOpen = ({ fill }: CommonIconProps) => {
	return (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M3.44702 11.4302C4.16447 10.4937 5.37378 9.07563 6.88948 7.89804C8.41362 6.71389 10.1711 5.83203 12.0002 5.83203C13.8292 5.83203 15.5867 6.71389 17.1108 7.89804C18.6262 9.07539 19.8353 10.4931 20.5528 11.4297C20.8159 11.7737 20.8154 12.2273 20.5533 12.5697C19.8358 13.5063 18.6265 14.9244 17.1108 16.102C15.5867 17.2861 13.8292 18.168 12.0002 18.168C10.1711 18.168 8.41362 17.2861 6.88948 16.102C5.37378 14.9244 4.16447 13.5063 3.44702 12.5697L3.44703 12.5697L3.44392 12.5657C3.31823 12.4039 3.25 12.2049 3.25 12C3.25 11.7951 3.31823 11.5961 3.44392 11.4343L3.44393 11.4343L3.44702 11.4302ZM12.0002 4.33203C9.69624 4.33203 7.61718 5.43316 5.96919 6.71353C4.31376 7.99968 3.01589 9.52662 2.25764 10.5163C1.92859 10.9408 1.75 11.4628 1.75 12C1.75 12.5372 1.92862 13.0592 2.2577 13.4838C3.01596 14.4735 4.31381 16.0003 5.96919 17.2865C7.61718 18.5668 9.69624 19.668 12.0002 19.668C14.3041 19.668 16.3831 18.5668 18.0311 17.2865C19.6876 15.9995 20.986 14.4715 21.7441 13.4819L21.7441 13.4818C22.4188 12.6008 22.4183 11.4001 21.7445 10.5186L21.7441 10.5181C20.986 9.52849 19.6876 8.00047 18.0311 6.71353C16.3831 5.43316 14.3041 4.33203 12.0002 4.33203ZM9.78711 12C9.78711 10.7768 10.7787 9.78516 12.002 9.78516C13.2252 9.78516 14.2168 10.7768 14.2168 12C14.2168 13.2232 13.2252 14.2148 12.002 14.2148C10.7787 14.2148 9.78711 13.2232 9.78711 12ZM12.002 8.28516C9.9503 8.28516 8.28711 9.94835 8.28711 12C8.28711 14.0517 9.9503 15.7148 12.002 15.7148C14.0536 15.7148 15.7168 14.0517 15.7168 12C15.7168 9.94835 14.0536 8.28516 12.002 8.28516Z"
				fill={fill}
			/>
		</svg>
	);
};
