import React from 'react';

export const InstaMoney = () => {
	return (
		<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M20.9493 10.1621C18.2163 7.42905 13.7828 7.42905 11.0498 10.1621C8.31674 12.8951 8.31674 17.3286 11.0498 20.0616C13.7828 22.7947 18.2163 22.7947 20.9493 20.0616C23.6824 17.3286 23.6824 12.8951 20.9493 10.1621ZM22.3636 8.74786C18.8495 5.23378 13.1496 5.23378 9.63556 8.74786C6.12148 12.2619 6.12148 17.9618 9.63556 21.4759C13.1496 24.9899 18.8495 24.9899 22.3636 21.4759C25.8776 17.9618 25.8776 12.2619 22.3636 8.74786Z"
				fill="#0A6375"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M7.89062 23.1113C7.89062 22.559 8.33834 22.1113 8.89063 22.1113H23.1128C23.6651 22.1113 24.1128 22.559 24.1128 23.1113C24.1128 23.6636 23.6651 24.1113 23.1128 24.1113H8.89063C8.33834 24.1113 7.89062 23.6636 7.89062 23.1113Z"
				fill="#0A6375"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M5.33333 30H26.6667C28.5079 30 30 28.5079 30 26.6667V5.33333C30 3.49212 28.5079 2 26.6667 2H5.33333C3.49212 2 2 3.49212 2 5.33333V26.6667C2 28.5079 3.49212 30 5.33333 30ZM5.33333 32H26.6667C29.6124 32 32 29.6124 32 26.6667V5.33333C32 2.38756 29.6124 0 26.6667 0H5.33333C2.38756 0 0 2.38756 0 5.33333V26.6667C0 29.6124 2.38756 32 5.33333 32Z"
				fill="#0A6375"
			/>
		</svg>
	);
};
