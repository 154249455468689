import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import z from 'zod';

export const useEnterAccountSchema = () => {
	const intl = useIntl();

	const schema = useMemo(
		() =>
			z.object({
				accountNumber: z
					.string()
					.regex(
						/^[0-9]*$/,
						intl.formatMessage({
							defaultMessage: 'Please insert only numbers',
							id: 'enter-bank-details.please-insert-only-numbers',
						}),
					)

					.min(
						5,
						intl.formatMessage({
							defaultMessage: 'Minimum length of Account Number is 5',
							id: 'enter-bank-details.account-number-min-length-5',
						}),
					),
				institutionNumber: z
					.string()
					.regex(/^[0-9]*$/, 'Please insert only numbers')
					.regex(
						/^[0-9]*$/,
						intl.formatMessage({
							defaultMessage: 'Please insert only numbers',
							id: 'enter-bank-details.please-insert-only-numbers',
						}),
					)
					.length(
						3,
						intl.formatMessage({
							defaultMessage: 'Institution number has to be exactly 3 numbers',
							id: 'enter-bank-details.institution-number-has-to-be-exactly-3-numbers',
						}),
					),
				transitNumber: z
					.string()
					.regex(
						/^[0-9]*$/,
						intl.formatMessage({
							defaultMessage: 'Please insert only numbers',
							id: 'enter-bank-details.please-insert-only-numbers',
						}),
					)
					.length(
						5,
						intl.formatMessage({
							defaultMessage: 'Transit number has to be exactly 5 numbers',
							id: 'enter-bank-details.transit-number-has-to-be-exactly-5-numbers',
						}),
					),
			}),
		[intl],
	);

	return {
		schema,
	};
};
