import React from 'react';
import { useSnackbar } from 'notistack';

import { ContentCopy } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';

import { copyToClipboard } from '../copy-to-clipboard';

export const CopyToClipboardButton = (props: { text: string }) => {
	const { enqueueSnackbar } = useSnackbar();

	return (
		<Tooltip title="Copy to clipboard">
			<IconButton
				size="small"
				onClick={() => {
					copyToClipboard(props.text);
					enqueueSnackbar('Copied to clipboard');
				}}
			>
				<ContentCopy />
			</IconButton>
		</Tooltip>
	);
};
