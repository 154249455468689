import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import styled, { CSSProperties } from 'styled-components';
import apolloLogo from 'url:../../static/shapes/apollo-logo.svg';
import zenbaseLogo from 'url:../../static/shapes/icon.png';
import uploadStatements from 'url:../../static/shapes/upload-statements.png';
import { z } from 'zod';

import { InviteType } from '../../../../../functions/src/shared/partner';

import { trpc } from '../../../shared/trpc/client';
import { useLocalStorage } from '../../../shared/use-local-storage';

import { Button, Center, Spacing, Text } from '../../../base-ui/components';
import { LoaderCentered } from '../../../base-ui/components/Loader/Loader';

import { AlertError } from '../../components/alert-error';
import { links } from '../../Router/paths';
import { setInvite } from '../../utils/invite-storage';

const ImagePanel = styled.div`
	margin-bottom: 3rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: 44.6rem;
	top: 8rem;
`;

const referralLogoById = {
	rp_apollo: apolloLogo,
};

export const Handover = () => {
	const { inviteId } = useParams<{ inviteId: string }>();
	const [searchParams] = useSearchParams();
	const secret = searchParams.get('s') ?? undefined;
	const [, setFirstName] = useLocalStorage(z.string().nullish(), 'handover-first-name', null);

	const navigate = useNavigate();
	const intl = useIntl();

	useEffect(() => {
		if (!inviteId) return;
		setInvite(inviteId, secret);
	}, [inviteId, secret]);

	if (!inviteId) throw new Error('inviteId url param missing');

	const { data, isLoading, isError } = trpc.public.getInviteDetails.useQuery(
		{ invite: inviteId, secret: secret ?? '' },
		{ refetchOnWindowFocus: false },
	);

	useEffect(() => {
		if (!data) return;
		if (data.status === 'found' && data.type === InviteType.CreditBuilderHandover) {
			setFirstName(data.firstName);
		}
	}, [data]);

	if (isLoading || !data) {
		return <LoaderCentered />;
	}

	if (isError) {
		return <AlertError />;
	}

	if (data.status === 'found' && data.type !== InviteType.CreditBuilderHandover) {
		navigate(links.REGISTRATION.ROOT);
		return null;
	}
	const referralPartnerName = 'referralPartnerName' in data && data.referralPartnerName;
	const referralPartnerId = 'referralPartnerId' in data && data.referralPartnerId;
	const referralLogo = referralPartnerId && referralLogoById[referralPartnerId];

	return (
		<Center>
			<ImagePanel>
				<img src={uploadStatements} />

				<Text type="title" center style={{ marginTop: '3rem' }}>
					<FormattedMessage
						defaultMessage="Almost ready to start building credit with rent payments!"
						id="credit-builder-handover.invited-to-zenbase"
					/>
				</Text>
				<Spacing $size="m" />
				<Text center>
					<FormattedMessage
						defaultMessage="We received your rent information from {referralPartnerName} to start building credit with your rent payments!"
						id="credit-builder-handover.subtitle"
						values={{
							referralPartnerName: referralPartnerName
								? data.referralPartnerName
								: intl.formatMessage({
										defaultMessage: 'our partner',
										id: 'credit-builder-handover.referral-partner-name-fallback',
									}),
						}}
					/>
				</Text>
				<Spacing $size="m" />
				{referralLogo && (
					<div style={{ display: 'flex', justifyContent: 'space-between' }}>
						<Image height="40px" src={referralLogo} />
						<div style={{ width: '8rem' }} />
						<Image height="40px" src={zenbaseLogo} />
					</div>
				)}
			</ImagePanel>

			<Button linkTo={links.DEFAULT.HANDOVER.DATA_TRANSFER}>
				<FormattedMessage defaultMessage="Continue" id="common.continue" />
			</Button>
		</Center>
	);
};

const Image = styled.img<Pick<CSSProperties, 'height'>>`
	max-height: ${({ height }) => height ?? 'unset'};
	height: ${({ height }) => height ?? 'unset'};
`;
