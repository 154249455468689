import React, { Fragment } from 'react';
import styled from 'styled-components';

import { copyToClipboard } from '../../../shared/copy-to-clipboard';

export const StripeTestData = () => {
	return (
		<TestCardsGrid>
			{[
				{ number: '4242 4242 4242 4242', label: 'Valid VISA' },
				{ number: '4000 0000 0000 9995', label: 'Insufficient funds decline' },
				{ number: '4100 0000 0000 0019', label: 'Always blocked' },
				{ number: 'S0E 0A0', label: 'Postal code' },
			].map(({ number, label }) => {
				const copy = () => copyToClipboard(number);
				return (
					<Fragment key={label}>
						<TestCardItem onClick={copy}>{label}</TestCardItem>
						<TestCardItem onClick={copy}>{number}</TestCardItem>
					</Fragment>
				);
			})}
		</TestCardsGrid>
	);
};

const TestCardsGrid = styled.div`
	display: grid;
	grid-template-columns: auto 1fr;
	gap: 2rem;
`;

const TestCardItem = styled.div`
	cursor: pointer;
`;
