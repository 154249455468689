import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';

import { BusinessEventType } from '../../../../../functions/src/shared/business-events';
import { CustomRentConfigForMonth } from '../../../../../functions/src/shared/user-rent';

import { getEnvironment } from '../../../shared/environment';
import { trpc } from '../../../shared/trpc/client';

import { Button, Footer, Spacing } from '../../../base-ui/components';
import { UnexpectedErrorAlert } from '../../../base-ui/components/Alert/Alert';

import { useUserData } from '../../contexts/hooks';
import { links } from '../../Router/paths';
import { ConfirmRentContent } from '../../templates/confirm-rent-content';
import { unsafeGetFieldFromState } from '../../utils/field-from-state';

export const accountManageCustomRentConfigStateField = 'rentConfig';

export const AccountConfirmCustomRent = () => {
	const navigate = useNavigate();
	const location = useLocation();

	const listOnboardingEvents = trpc.user.listOnboardingEvents.useQuery();

	const {
		user,
		home,
		handlers: { forceRefetch },
	} = useUserData();
	const saveConfigAndEnable = trpc.user.customRent.saveConfigAndEnable.useMutation({
		async onSuccess() {
			await forceRefetch();

			const reconnectDueToItem = Boolean(
				home?.item?.reconnectRequired || home?.item?.transactionsConsideredStale,
			);
			// unified payments user might not have bank at this point
			if (!user?.paymentAccountId) {
				navigate(links.ACCOUNT.BANK.CONNECT_BANK);
				// or reconnect due to item being in invalid state
			} else if (reconnectDueToItem) {
				navigate(links.ACCOUNT.BANK.RECONNECT_BANK);
				// or go to direct debit setup
			} else if (
				listOnboardingEvents.data &&
				!listOnboardingEvents.data[BusinessEventType.OnboardingCustomRentDirectDebitSuccessConfirmed] &&
				!listOnboardingEvents.data[BusinessEventType.OnboardingCustomRentDirectDebitDeclined] &&
				(getEnvironment() !== 'production' || user?.email.includes('directdebitenabled@myzenbase.com'))
			) {
				navigate(links.ACCOUNT.DIRECT_DEBIT.SETUP);
			} else {
				navigate(links.ACCOUNT.DASHBOARD);
			}
		},
	});

	const config = unsafeGetFieldFromState<CustomRentConfigForMonth>(
		location.state,
		accountManageCustomRentConfigStateField,
	);
	if (!config) return <Navigate to={links.ACCOUNT.CONFIGURE.SELECT_SCHEDULE} />;

	const handleEnableCustomRent = () => {
		saveConfigAndEnable.mutate({
			rentAmountCents: config.rentAmountCents,
			installments: config.installments,
			rentMonth: config.rentMonth,
		});
	};

	if (saveConfigAndEnable.isError) {
		return <UnexpectedErrorAlert />;
	}

	return (
		<>
			<ConfirmRentContent config={config} />
			<Spacing $size="xl" />
			<Footer stickyOnMobile>
				<Button onClick={handleEnableCustomRent} disabled={saveConfigAndEnable.isPending}>
					<FormattedMessage defaultMessage="Confirm" id="common.confirm" />
				</Button>
			</Footer>
		</>
	);
};
