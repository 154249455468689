import React from 'react';
import { startCase } from 'lodash';
import { FormProvider, UseFormReturn } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';

import { CopyToClipboardButton } from '../../../shared/components/copy-to-clipboard-button';
import { getEnvironment } from '../../../shared/environment';
import { useMyZenbaseLinks } from '../../../shared/my-zenbase-links';

import { Anchor, Button, Footer, FormInput, Spacing, Text } from '../../../base-ui/components';

import { Loader } from '../../contexts/Interface/Loader/Loader';
import { links } from '../../Router/paths';
import { SignUpFormSchema } from './sign-up-form-service';

export type Props = {
	isInviteInitialLoading: boolean;
	onSubmit: (formData: SignUpFormSchema) => void;
	form: UseFormReturn<SignUpFormSchema>;
};

function convertToTitleCase(str?: string) {
	return startCase(str?.toLowerCase?.());
}

export const SignUpForm = ({ form, isInviteInitialLoading, onSubmit }: Props) => {
	const intl = useIntl();
	const myZenbaseLinks = useMyZenbaseLinks();
	return (
		<>
			<FormProvider {...form}>
				<form onSubmit={form.handleSubmit(onSubmit)}>
					<FormInput
						name="firstName"
						placeholder={intl.formatMessage({
							defaultMessage: 'First Name',
							id: 'sign-up-form.first-name',
						})}
						autoComplete="given-name"
						testId="sign-up-first-name-input"
						transform={convertToTitleCase}
						disabled={isInviteInitialLoading}
					/>
					<FormInput
						name="lastName"
						placeholder={intl.formatMessage({ defaultMessage: 'Last Name', id: 'sign-up-form.last-name' })}
						testId="sign-up-last-name-input"
						transform={convertToTitleCase}
						autoComplete="family-name"
						disabled={isInviteInitialLoading}
					/>
					<FormInput
						name="phone"
						placeholder={intl.formatMessage({
							defaultMessage: 'Phone number',
							id: 'change-phone-number.phone-number',
						})}
						type="tel"
						testId="sign-up-phone-input"
						disabled={isInviteInitialLoading}
					/>
					{getEnvironment() !== 'production' && (
						<Text>
							Test phone number: 555 222 2222 <CopyToClipboardButton text="5552222222" />
						</Text>
					)}
					<FormInput
						name="email"
						placeholder={intl.formatMessage({
							defaultMessage: 'Email',
							id: 'forgot-password.email-placeholder',
						})}
						type="email"
						testId="sign-up-input-email"
						disabled={isInviteInitialLoading}
					/>
					<FormInput
						name="password"
						placeholder={intl.formatMessage({
							defaultMessage: 'Password',
							id: 'payment-portal-connect-password-placeholder',
						})}
						type="password"
						testId="sign-up-input-password"
						disabled={isInviteInitialLoading}
					/>

					<FormInput
						name="terms"
						type="checkbox"
						testId="sign-up-terms-of-use"
						label={
							<>
								<FormattedMessage
									defaultMessage="I have read and agree to the Zenbase {termsLink} and {privacyLink}."
									id="sign-up-form.terms-of-use"
									values={{
										termsLink: (
											<StyledAnchor href={myZenbaseLinks.terms} target="_blank">
												<FormattedMessage
													defaultMessage="Terms of Use"
													id="custom-rent-credit-builder.terms-of-use"
												/>
											</StyledAnchor>
										),
										privacyLink: (
											<StyledAnchor href={myZenbaseLinks.privacy} target="_blank">
												<FormattedMessage
													defaultMessage="Privacy Policy"
													id="sign-up-form.privacy-policy-link"
												/>
											</StyledAnchor>
										),
									}}
								/>
							</>
						}
					/>
					<Spacing $size="l" />
					<Footer>
						<Button
							htmlType="submit"
							disabled={isInviteInitialLoading || form.formState.isSubmitting}
							testId="sign-up-button-continue"
						>
							<FormattedMessage defaultMessage="Continue" id="common.continue" />
						</Button>
						<Button type="link" linkTo={links.DEFAULT.SIGN_IN} testId="sign-up-sign-in-link">
							<FormattedMessage
								defaultMessage="Already have an account? Click here to Sign In"
								id="sign-up-form.sign-in-link"
							/>
						</Button>
					</Footer>
				</form>
			</FormProvider>

			<Loader isOpen={isInviteInitialLoading || form.formState.isSubmitting} />
		</>
	);
};

const StyledAnchor = styled(Anchor)`
	font-size: 1.6rem;
	line-height: 2.4rem;
`;
