import { IsoDate } from './iso-date';

export enum Country {
	ca = 'ca',
}

type HolidaysCollection = Record<string, string>;

const holidays: Record<Country, HolidaysCollection> = {
	ca: {
		'2021-01-01': 'New Years',
		'2021-04-02': 'Good Friday',
		'2021-05-24': 'Victoria Day',
		'2021-07-01': 'Canada Day',
		'2021-09-06': 'Labour Day',
		'2021-09-30': 'Truth Day',
		'2021-11-11': 'Thanksgiving Day',
		'2021-12-25': 'Christmas Day',
		'2021-12-26': 'Boxing Day',
		// eslint-disable-next-line quotes
		'2022-01-03': "New Year's Day",
		'2022-04-15': 'Good Friday',
		'2022-05-23': 'Victoria Day',
		'2022-07-01': 'Canada Day',
		'2022-09-05': 'Labour Day',
		'2022-09-30': 'National Day for Truth and Reconciliation',
		'2022-10-10': 'Thanksgiving Day',
		'2022-11-11': 'Remembrance Day',
		'2022-12-26': 'Christmas Day',
		'2022-12-27': 'Boxing Day',

		// 2023
		// https://www.bankofcanada.ca/press/upcoming-events/bank-of-canada-holiday-schedule/
		'2023-01-02': "New Year's Day",
		'2023-04-07': 'Good Friday',
		'2023-05-22': 'Victoria Day',
		'2023-07-03': 'Canada Day',
		'2023-09-04': 'Labour Day',
		'2023-10-02': 'National Day for Truth and Reconciliation',
		'2023-10-09': 'Thanksgiving Day',
		'2023-11-13': 'Remembrance Day',
		'2023-12-25': 'Christmas Day',
		'2023-12-26': 'Boxing Day',

		// 2024
		// https://www.statutoryholidays.com/2024.php
		'2024-01-01': "New Year's Day",
		'2024-03-29': 'Good Friday',
		'2024-05-20': 'Victoria Day',
		'2024-07-01': 'Canada Day',
		'2024-09-02': 'Labour Day',
		'2024-09-30': 'National Day for Truth and Reconciliation',
		'2024-10-14': 'Thanksgiving Day',
		'2024-11-11': 'Remembrance Day',
		'2024-12-25': 'Christmas Day',
		'2024-12-26': 'Boxing Day',

		// 2025
		// https://www.statutoryholidays.com/2025.php
		'2025-01-01': "New Year's Day",
		'2025-03-28': 'Good Friday',
		'2025-05-19': 'Victoria Day',
		'2025-07-01': 'Canada Day',
		'2025-09-01': 'Labour Day',
		'2025-09-30': 'National Day for Truth and Reconciliation',
		'2025-10-13': 'Thanksgiving Day',
		'2025-11-11': 'Remembrance Day',
		'2025-12-25': 'Christmas Day',
		'2025-12-26': 'Boxing Day',
	},
};

export function isIsoDateHoliday(isoDateToCheck: IsoDate, country: Country) {
	return isoDateToCheck in holidays[country];
}
