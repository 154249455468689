import { IntlShape } from 'react-intl';
import { z } from 'zod';

import { zodPhoneNumberDefaultUs } from '../../../shared/zod-schemas';

export function getSignUpFormSchema(intl: IntlShape) {
	return z.object({
		firstName: z.string().trim().max(50).min(1),
		lastName: z.string().trim().max(50).min(1),
		phone: zodPhoneNumberDefaultUs(intl),
		email: z.string().max(50).trim().email(),
		password: z.string().min(6).max(30),
		terms: z.literal(true, {
			errorMap: () => ({
				message: intl.formatMessage({
					defaultMessage: 'Please accept terms and conditions',
					id: 'add-debit-card.please-accept-terms-and-conditions',
				}),
			}),
		}),
	});
}
export type SignUpFormSchema = z.infer<ReturnType<typeof getSignUpFormSchema>>;
