import React from 'react';

export const IconCheck = () => {
	return (
		<svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
			<circle opacity="0.7" r="30" transform="matrix(-1 0 0 1 30 30)" fill="#1ECEC9" />
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M47.8871 22.7021L47.8871 19.4715C47.8871 19.1402 47.6248 18.8641 47.2797 18.8641L44.0629 18.8641C43.8972 18.8641 43.7454 18.9331 43.6349 19.0436L28.0398 34.6123L32.126 38.6985L47.7077 23.1301C47.8181 23.0197 47.8871 22.8678 47.8871 22.7021Z"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M13.4667 26.2704V29.501C13.4667 29.6667 13.5357 29.8185 13.6462 29.929L27.3642 43.6354L31.4497 39.5499L17.7189 25.8424C17.6085 25.732 17.4566 25.663 17.2909 25.663H14.0742C13.729 25.663 13.4667 25.9391 13.4667 26.2704Z"
				fill="white"
			/>
		</svg>
	);
};
