import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Block, Button, Footer, Input, Spacing, Text } from '../../../base-ui/components';
import { ScrollableBlockList } from '../../../base-ui/components/Block/Block';
import { partialObjectSearch } from '../../../base-ui/utils';

import { BackButton } from '../../components/back-button';
import { useResidencyOnboarding } from '../../contexts/hooks';

export const SelectUnit = () => {
	const [selectedUnitId, setSelectedUnitId] = useState<string | null>();
	const [search, setSearch] = useState('');
	const [noneSelected, setNoneSelected] = useState(false);

	const { unitsList, handleSelectedUnit, gotToManualAddressOrRecommended } = useResidencyOnboarding();
	const intl = useIntl();

	const handleNoneSelected = () => {
		setSelectedUnitId(null);
		setNoneSelected(true);
	};

	const handleContinue = () => {
		if (noneSelected) {
			gotToManualAddressOrRecommended();
		}
		if (selectedUnitId) {
			handleSelectedUnit(selectedUnitId);
		}
	};

	const handleUnitSearch = (value: string) => {
		setSearch(value);
	};

	const filteredUnitsList = partialObjectSearch(unitsList || [], search);

	return (
		<>
			<BackButton testId="address-select-unit-button-back" />
			<Text type="title">
				<FormattedMessage defaultMessage="Select your unit" id="select-unit.select-your-unit" />
			</Text>
			<Spacing $size="s" />
			<Text>
				<FormattedMessage
					defaultMessage="You can filter results by entering your unit."
					id="select-unit.filter-results"
				/>
			</Text>
			<Input
				placeholder={intl.formatMessage({
					defaultMessage: 'Search for your Unit',
					id: 'select-unit.search-for-your-unit',
				})}
				value={search}
				onInputChange={handleUnitSearch}
				testId="address-select-unit-input-search"
			></Input>
			<Spacing $size="s" />
			<ScrollableBlockList data-testid="address-select-unit-list-unit">
				{filteredUnitsList.map(({ id, pmsUniqueId, name, address, city, state, postalCode }) => {
					return (
						<Block
							key={pmsUniqueId}
							title={name}
							line1={address || ''}
							line2={[city, state, postalCode].join(', ')}
							line3={''}
							onClick={() => setSelectedUnitId(id)}
							selected={id === selectedUnitId}
							testId="address-select-unit-option-unit"
						/>
					);
				})}
				<Block
					selected={noneSelected}
					title={intl.formatMessage({
						defaultMessage: 'None of the above',
						id: 'select-unit.none-of-the-above',
					})}
					onClick={handleNoneSelected}
					testId="address-select-unit-option-none"
				/>
			</ScrollableBlockList>
			<Footer>
				<Button
					type="large"
					onClick={handleContinue}
					disabled={!selectedUnitId && !noneSelected}
					testId="address-select-unit-button-continue"
				>
					<FormattedMessage defaultMessage="Continue" id="common.continue" />
				</Button>
			</Footer>
		</>
	);
};
