import React from 'react';
import styled from 'styled-components';

import { Icon } from '../Icon/Icon';

const Wrapper = styled.div`
	background-color: rgba(252, 228, 223, 0.5);
	border-radius: 1rem;
	min-height: 12rem;

	display: flex;
	justify-content: center;
	align-items: center;
`;

export const ErrorBanner = () => (
	<Wrapper>
		<Icon icon="Error" />
	</Wrapper>
);
