import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { Box, Divider, Icon, Loader, Text } from '../../base-ui/components';
import { colors } from '../../base-ui/variables';

export const AwaitingTransactionSteps = ({ status }: { status: 'pending' | 'retry-exhausted' | 'finished' }) => {
	return (
		<>
			<Box justify="between" verticalCenter>
				<Text>
					<FormattedMessage
						defaultMessage="1. Sign up with Zenbase"
						id="awaiting-transaction-steps.sign-up-with-zenbase"
					/>
				</Text>
				<Box justify="between" verticalCenter>
					<SmallerText type="small" color="title">
						<FormattedMessage defaultMessage="Completed" id="common.completed" />
					</SmallerText>
					<StyledCheckmark icon="Checkmark" />
				</Box>
			</Box>
			<Divider />
			<Box justify="between" verticalCenter>
				<Text>
					<FormattedMessage
						defaultMessage="2. Connect Bank Account"
						id="awaiting-transaction-steps.connect-bank-account"
					/>
				</Text>
				<Box justify="between" verticalCenter>
					<SmallerText type="small" color="title">
						<FormattedMessage defaultMessage="Completed" id="common.completed" />
					</SmallerText>
					<StyledCheckmark icon="Checkmark" />
				</Box>
			</Box>
			<Divider />
			<Box justify="between" verticalCenter>
				<Text>
					<FormattedMessage
						defaultMessage="3. Verify Transactions"
						id="awaiting-transaction-steps.verify-transactions"
					/>
				</Text>
				<Box justify="between" verticalCenter>
					<SmallerText type="small" color={status ? 'title' : 'highlighted'}>
						{status === 'finished' ? (
							<FormattedMessage defaultMessage="Completed" id="common.completed" />
						) : status === 'pending' ? (
							<FormattedMessage defaultMessage="In Progress" id="common.in-progress" />
						) : (
							<FormattedMessage defaultMessage="Error" id="common.error" />
						)}
					</SmallerText>
					{status === 'finished' ? (
						<StyledCheckmark icon="Checkmark" />
					) : status === 'pending' ? (
						<LoaderMargin>
							<Loader />
						</LoaderMargin>
					) : (
						<StyledWarning icon="CloseCircle" />
					)}
				</Box>
			</Box>
		</>
	);
};
const StyledCheckmark = styled(Icon).attrs({ icon: 'Checkmark', color: colors.white })`
	background-color: ${colors.green};
	border-radius: 50%;
	height: 3.2rem;
	width: 3.2rem;
	margin-left: 1rem;
`;
const StyledWarning = styled(Icon).attrs({ icon: 'CloseCircle', color: colors.white })`
	background-color: ${colors.error};
	border-radius: 50%;
	height: 3.2rem;
	width: 3.2rem;
	margin-left: 1rem;
`;

const SmallerText = styled(Text)`
	font-size: 1.6rem;
	@media (max-width: 450px) {
		font-size: 1.2rem;
	}
`;

const LoaderMargin = styled.div`
	margin-left: 1rem;
`;
