import React, { ComponentProps } from 'react';
import styled from 'styled-components';

const Svg = styled.svg``;

export const EquifaxBubble = (props: ComponentProps<typeof Svg>) => (
	<Svg height="1em" viewBox="0 0 113 83" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			d="M113 50.1594C113 70.9838 78.5682 82.6041 50.6146 82.6041C10.5938 86.1116 0 65.7225 0 44.8981C0 24.0737 8.53592 5 36.4896 5C64.4432 5 113 29.335 113 50.1594Z"
			fill="#FFEFE4"
		/>
		<g clipPath="url(#clip0_416_243)">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M24.5123 55.8596L25.0889 53.1513H13.121L11.5347 60.6004L10.1888 66.5989H22.4934L23.1182 63.7451H16.2935L16.9182 60.8422H22.6856L23.3104 58.1334H17.5425L18.0232 55.8596H24.5123Z"
				fill="#B32541"
				stroke="#B32541"
				strokeWidth="0.6211"
				strokeMiterlimit="2.6131"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M34.5571 60.8422H38.7388C38.8867 59.9678 39.1616 58.6633 39.2195 57.6011C39.2862 56.3579 38.7986 55.4426 37.7296 54.8436C36.1978 53.9892 33.9411 54.035 32.2502 53.9732C29.9322 53.8885 27.1738 54.1892 25.7135 56.2465C24.9603 57.3077 24.5365 58.9518 24.3701 60.2374C24.1643 61.8061 23.3775 64.4385 24.8002 65.7269C25.8581 66.687 28.2248 67.0062 29.6008 66.9872C31.0022 66.9672 32.3626 66.8753 33.7401 66.5984L37.3447 70.9995H42.9681L34.5571 60.8422ZM33.9323 60.939C33.4725 63.0245 32.8463 64.7791 30.4236 64.7601C27.9912 64.7421 28.7984 62.1253 29.0301 60.6008C29.3575 58.4346 30.1896 56.1628 32.6828 56.3438C35.2067 56.5277 34.3158 59.2048 33.9323 60.939Z"
				fill="#B32541"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M57.003 54.6505L55.7053 60.6004L54.4076 66.5989H59.3099L61.9535 54.6505H57.003Z"
				fill="#B32541"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M73.9695 57.0206L74.5461 54.6505H63.8277L61.2323 66.5989H66.1832L67.2883 61.4708H72.1428L72.335 60.6004L72.6236 59.2457H67.8172L68.2979 57.0206H73.9695Z"
				fill="#B32541"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M41.2378 54.6505H46.2364L44.3621 63.116C44.133 64.1509 45.0983 64.6905 45.926 64.7621C46.9906 64.855 47.9458 64.186 48.2071 63.0678L50.1777 54.651H55.1282L53.1575 63.6001C52.3138 65.9333 50.3592 66.8495 48.2013 67.0782C46.8632 67.2207 45.4467 67.1001 44.1695 66.8889L39.7479 61.5677L41.2378 54.6505Z"
				fill="#B32541"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M85.0718 54.6505H91.3681L93.2906 57.7466L96.4144 54.6505H101.221L94.925 60.213L99.0585 66.5989H92.9062L90.8874 62.9705L87.4273 66.5989H80.0737L79.7851 63.9865H74.6902L72.9122 66.5989H69.4517L77.8145 54.6505H83.8223L84.9759 64.3246L88.9649 60.6485L85.0718 54.6505ZM76.3246 61.4227L79.0159 57.3593L79.4966 61.4227H76.3246Z"
				fill="#B32541"
			/>
		</g>
		<circle cx="9.98235" cy="9.98235" r="9.98235" transform="matrix(-1 0 0 1 36.6901 18.0576)" fill="#FFF5CF" />
		<path
			d="M27.3195 44.9553C27.6712 44.9553 28.0254 44.9458 28.3814 44.9277C36.4229 44.5132 43.3331 37.8245 43.7851 30.0183C44.076 24.9982 42.5464 20.4824 39.3606 16.9595C36.1491 13.4072 31.5054 11.2501 26.6207 11.0405C26.1744 11.0191 25.719 11.0167 25.2693 11.0334C17.9237 11.34 9.81994 16.7105 9.26236 26.0587C8.96479 31.0494 10.9137 36.1722 14.4755 39.763C17.8182 43.1332 22.3468 44.9553 27.3195 44.9553ZM25.8611 12.6215C26.0897 12.6215 26.3189 12.6272 26.5466 12.6381C31.011 12.8287 35.2483 14.7948 38.1755 18.0324C41.0671 21.2306 42.4556 25.3433 42.1898 29.9266C41.7829 36.9447 35.5516 42.9583 28.2992 43.3319C21.9756 43.6609 17.9133 40.959 15.6107 38.6388C12.3635 35.366 10.5872 30.699 10.8576 26.1552C11.3591 17.7429 18.6895 12.9091 25.332 12.6315C25.5074 12.6243 25.6838 12.6215 25.8611 12.6215Z"
			fill="#444444"
		/>
		<path
			d="M27.0452 39.3715C27.2805 39.3715 27.5173 39.3653 27.7554 39.353C33.1568 39.0749 37.7981 34.5795 38.1019 29.3321C38.2972 25.9695 37.271 22.9434 35.1343 20.5805C32.9828 18.2004 29.8726 16.7553 26.6013 16.6146C26.3004 16.6004 25.9957 16.5985 25.6933 16.6099C20.7616 16.8157 15.3222 20.4241 14.9476 26.7068C14.7484 30.0513 16.0537 33.4843 18.4405 35.8905C20.6817 38.1499 23.7154 39.3711 27.0452 39.3715ZM26.0917 18.2013C26.2367 18.2013 26.3826 18.2047 26.5266 18.2113C29.3783 18.3335 32.082 19.5875 33.9492 21.6524C35.7917 23.6907 36.6758 26.3141 36.5066 29.2394C36.248 33.6982 32.2855 37.5191 27.6732 37.7568C23.6398 37.9664 21.043 36.2447 19.5756 34.7649C17.504 32.6767 16.3703 29.7001 16.5429 26.8023C16.8618 21.4561 21.5279 18.3839 25.7561 18.2071C25.8673 18.2032 25.979 18.2013 26.0917 18.2013Z"
			fill="#444444"
		/>
		<path
			d="M26.7694 33.7864C26.8882 33.7864 27.0066 33.7836 27.1264 33.7774C29.8772 33.6352 32.2411 31.3407 32.3971 28.6626C32.4954 26.9642 31.9749 25.4336 30.8916 24.2352C29.8035 23.0321 28.2325 22.301 26.5812 22.2297C26.4267 22.2226 26.2736 22.2216 26.1191 22.2273C23.6093 22.3324 20.8442 24.1729 20.654 27.3777C20.5532 29.0705 21.2135 30.8069 22.4204 32.0238C23.5551 33.1675 25.089 33.7864 26.7694 33.7864ZM26.3188 23.8217C26.3801 23.8217 26.4423 23.8231 26.5051 23.8259C27.7377 23.8787 28.9023 24.4182 29.7056 25.3066C30.4957 26.1799 30.8745 27.3083 30.8013 28.5694C30.6915 30.459 29.0054 32.079 27.0437 32.1802C25.3115 32.2667 24.1849 31.5318 23.5541 30.8968C22.6628 29.9979 22.1747 28.7178 22.2488 27.4714C22.3843 25.203 24.3755 23.8987 26.1804 23.8231C26.2261 23.8226 26.2722 23.8217 26.3188 23.8217Z"
			fill="#444444"
		/>
		<path
			d="M26.1799 28.0062C26.37 28.0062 26.5611 27.9387 26.7137 27.8013C27.0422 27.5061 27.0688 27.0009 26.7736 26.6729C26.4294 26.2907 26.1247 25.8581 25.8015 25.4004C25.3946 24.8242 24.9739 24.2281 24.4487 23.7029C24.3959 23.6411 24.0522 23.2152 23.4999 22.5316C21.0542 19.506 14.559 11.4679 9.22985 5.54689C8.93371 5.21843 8.42889 5.19228 8.1009 5.48747C7.77291 5.78267 7.74676 6.28796 8.04148 6.61595C13.3421 12.5055 19.8183 20.5195 22.2568 23.536C23.1633 24.6579 23.2527 24.7672 23.3183 24.8328C23.7466 25.2611 24.1102 25.7764 24.4952 26.3221C24.8308 26.7974 25.1778 27.2894 25.5847 27.7415C25.7426 27.9173 25.9607 28.0062 26.1799 28.0062Z"
			fill="#444444"
		/>
		<path
			d="M11.4219 11.6717C10.8472 11.6845 10.2744 11.6973 9.70016 11.6973C9.17918 11.6973 8.71762 11.6863 8.28885 11.6654C8.02693 11.6521 7.78783 11.5109 7.64998 11.2875C6.45781 9.35997 5.0327 7.33023 3.16553 4.90072C2.97016 4.6464 2.94544 4.29987 3.10373 4.02037C3.26107 3.74134 3.5691 3.58067 3.88997 3.61965C3.97125 3.62868 4.05253 3.63343 4.13429 3.63343C6.44211 3.63343 7.12187 1.53904 7.30725 0.638728C7.36524 0.353518 7.57487 0.122499 7.85295 0.0364609C8.13293 -0.0510034 8.43526 0.0217235 8.64441 0.224223C10.1698 1.69495 11.9785 3.42808 13.8272 5.13506C14.004 5.29858 14.0981 5.5334 14.0824 5.77393C14.0106 6.8853 13.8799 7.98763 13.7535 9.05337C13.6798 9.6756 13.6061 10.2988 13.5424 10.9281C13.502 11.3341 13.1621 11.644 12.7548 11.6478C12.3113 11.6516 11.8687 11.6616 11.4276 11.6716L11.4219 11.6717Z"
			fill="#FFB9AA"
		/>
		<path
			d="M9.70016 11.6973C10.2763 11.6973 10.851 11.6844 11.4276 11.6716C11.8687 11.6616 12.3113 11.6516 12.7548 11.6478C13.1621 11.644 13.502 11.3341 13.5424 10.9281C13.6061 10.2988 13.6798 9.6756 13.7535 9.05337C13.8799 7.98763 14.0106 6.8853 14.0824 5.77393C14.0981 5.5334 14.004 5.29858 13.8272 5.13506C11.9785 3.42808 10.1698 1.69495 8.64441 0.224223C8.43526 0.0217235 8.13293 -0.0510034 7.85295 0.0364609C7.57487 0.122499 7.36524 0.353518 7.30725 0.638728C7.12187 1.53904 6.44211 3.63343 4.13429 3.63343C4.05253 3.63343 3.97125 3.62868 3.88996 3.61965C3.5691 3.58067 3.26107 3.74134 3.10373 4.02037C2.94544 4.29987 2.97016 4.6464 3.16553 4.90072C5.0327 7.33023 6.45781 9.35997 7.64998 11.2875C7.78783 11.5109 8.02693 11.6521 8.28885 11.6654C8.71762 11.6863 9.17918 11.6973 9.70016 11.6973ZM12.028 10.0597C11.8155 10.064 11.6039 10.0687 11.3919 10.073C10.5225 10.0925 9.65501 10.112 8.78464 10.0854C7.81207 8.53904 6.69025 6.92285 5.31981 5.09323C6.73921 4.74575 7.8425 3.75845 8.47044 2.2744C9.70064 3.45565 11.0654 4.75478 12.4615 6.04868C12.3892 6.9932 12.2761 7.94295 12.1668 8.86513C12.1192 9.26205 12.0726 9.65992 12.028 10.0597Z"
			fill="#444444"
		/>
		<defs>
			<clipPath id="clip0_416_243">
				<rect width="91.2213" height="18" fill="white" transform="translate(10 53)" />
			</clipPath>
		</defs>
	</Svg>
);
