export type Environment = 'local' | 'demo' | 'production';

const allowedBanks = ['ROYAL BANK OF CANADA', 'BANK OF MONTREAL', 'THE BANK OF NOVA SCOTIA'] as const;
const allowedFlinksBanks: Record<(typeof allowedBanks)[number], string> = {
	'ROYAL BANK OF CANADA': 'RBC',
	'BANK OF MONTREAL': 'BMO',
	'THE BANK OF NOVA SCOTIA': 'Scotia',
};
const flinksIframeParams = {
	institutionsLocation: 'ca',
	language: 'en',
	accountSelectorEnable: 'true',
	fetchAllAccounts: 'true',
	closeEnable: 'true',
	consentEnable: 'true',
	features: 'TransactionHistory,AccountNumber',
	enhancedMFA: 'true',
	skipEnhancedMFA: 'false',
	maximumRetry: '3',
	accountSelectorCurrency: 'cad',
	scheduleRefresh: 'true',
	daysOfTransactions: 'Days365',
	customerName: 'Zenbase',
};
/**
 * Values from this config are available to frontend code!
 */
export const publicConfig = {
	fakeAuthTokenPrefix: 'fakeAuth',
	adminImpersonatesUserHeader: 'x-admin-impersonates-user',
	superadminModeHeader: 'x-superadmin-mode',
	sentry: {
		local: { dsn: null },
		// same as production
		demo: { dsn: 'https://7ec37db930ef4e79b9042d69e0657fb6@o571767.ingest.sentry.io/5720384' },
		production: { dsn: 'https://7ec37db930ef4e79b9042d69e0657fb6@o571767.ingest.sentry.io/5720384' },
	},
	bankStatements: {
		bucketName: {
			local: 'bf-demo-a24dc-bank-statements',
			demo: 'bf-demo-a24dc-bank-statements',
			production: 'bf-production-bank-statements',
		},
		uploadMetadata: {
			userId: 'user-id',
			accountId: 'account-id',
		},
		allowedBanks,
		allowedFlinksBanks,
	},
	leaseAgreements: {
		bucketName: {
			local: 'bf-demo-a24dc-lease-agreements',
			demo: 'bf-demo-a24dc-lease-agreements',
			production: 'bf-production-lease-agreements',
		},
		uploadMetadata: {
			userId: 'user-id',
			manualResidencyId: 'manual-residency-id',
			isLeaseRenewal: 'isLeaseRenewal',
		},
	},
	flinks: {
		iframeParams: flinksIframeParams,
		local: {
			iframeOrigin: 'https://zenbase1009.connect.flinks.dev',
		},
		demo: {
			iframeOrigin: 'https://zenbase1009.connect.flinks.dev',
		},
		production: {
			iframeOrigin: 'https://myzenbase-iframe.private.fin.ag',
		},
	},
	instantBankConnection: {
		provider: 'flinks',
		awaitingTransactionsRetryCount: {
			local: 40,
			demo: 40,
			production: 100,
		},
	},
	creditBuilder: {
		// if you update pricing, make sure that UI shows correct value for existing payments and not this hardcoded value
		yearlyPriceCents: 24_40,
		monthlyPriceCents: 2_90,
		handoverPath: 'h/:inviteId',
		historyReportingUpsellPriceCents: 19_90,
	},
	indirectCreditBuilder: {
		yearlyPriceCents: 24_40,
		monthlyPriceCents: 2_90,
		historyReportingUpsellPriceCents: 19_90,
	},
	customRent: {
		newUserSignUpCutoffDay: 26,
		firstInstallmentMaxDay: 1,
		upfrontPaymentDeadlineDay: 4,
		upfrontPaymentMinimumPercent: 30,
		// controls notification text. The notification contains rent month,
		// so it is custom rent specific
		overpaymentRefundDescription: 'Rent overpayment refund',
		defaultBlockingAmountPercentage: 50,
	},
	stripe: {
		local: {
			publicKey:
				'pk_test_51N0WInHEOZVnl7WL1aBHeIHoddATC8EkwgDpNMTDkjUhjDEywSqLiGQAWPwZ1MtGuDk2Udz2B2FwUdepwdZwOBtc00D2lMrwiZ',
		},
		demo: {
			publicKey:
				'pk_test_51N0WInHEOZVnl7WL1aBHeIHoddATC8EkwgDpNMTDkjUhjDEywSqLiGQAWPwZ1MtGuDk2Udz2B2FwUdepwdZwOBtc00D2lMrwiZ',
		},
		production: {
			publicKey:
				'pk_live_51N0WInHEOZVnl7WLPEl57EBSp7s4hLnk72OkPU35eD7xivdsaVXvj63J7aEMrNyICLwSUOntk2QDuEvZijn5tyxW00Ai7xBIan',
		},
	},
	tasks: {
		appUrl: 'https://zenbase.retool.com/apps/cb02ac4c-ffa4-11ed-8083-cf19de63926a/Tasks',
		local: {
			retoolEnvironment: 'staging',
		},
		demo: {
			retoolEnvironment: 'staging',
		},
		production: {
			retoolEnvironment: null,
		},
	},
	moneris: {
		scriptUrl: 'https://gatewayt.moneris.com/chktv2/js/chkt_v2.00.js',
		local: {
			portalUrl: 'https://esqa.moneris.com/mpg',
			scriptUrl: 'https://gatewayt.moneris.com/chktv2/js/chkt_v2.00.js',
		},
		demo: {
			portalUrl: 'https://esqa.moneris.com/mpg',
			scriptUrl: 'https://gatewayt.moneris.com/chktv2/js/chkt_v2.00.js',
		},
		production: {
			portalUrl: 'https://www3.moneris.com/mpg',
			scriptUrl: 'https://gateway.moneris.com/chktv2/js/chkt_v2.00.js',
		},
	},
	errorMessages: {
		cannotEnrollForMonth: 'Can not enroll for',
		firstInstallmentOrderChangeForbidden: 'Please schedule this payment transaction on or after',
	},
	padAgreementVersion: '2023-12-22',
} as const;
