import styled from 'styled-components';

import { colors } from '../../../../base-ui/variables';

export const Wrapper = styled.div`
	background: ${colors.alertBackground};
	border-radius: 10px;
	width: 100%;
	padding: 3rem 2rem;
	margin-bottom: 2rem;
	position: relative;
	z-index: 1;
	overflow: hidden;
	@media (max-width: 450px) {
		padding: 2rem 1.5rem;
	}
`;

export const Title = styled.p`
	color: ${colors.error};
	font-style: normal;
	font-weight: 700;
	font-size: 2rem;
	line-height: 3.1rem;

	@media (max-width: 450px) {
		font-size: 1.6rem;
		line-height: 2.4rem;
	}
`;

export const Link = styled.span`
	cursor: pointer;
	border-bottom: 1px solid ${colors.error};
`;
