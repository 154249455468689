import { createEnum } from '../utils/create-enum';

export const CreditBuilderSubscriptionPeriodType = createEnum('yearly', 'monthly', 'none');
export type CreditBuilderSubscriptionPeriodType = createEnum<typeof CreditBuilderSubscriptionPeriodType>;

export const formatCreditBuilderSubscriptionPeriodTypeText = (
	subscriptionType?: CreditBuilderSubscriptionPeriodType,
): string | undefined => {
	if (!subscriptionType) {
		return;
	}
	switch (subscriptionType) {
		case CreditBuilderSubscriptionPeriodType.Monthly:
			return 'Monthly';
		case CreditBuilderSubscriptionPeriodType.Yearly:
			return 'Annual';
		case CreditBuilderSubscriptionPeriodType.None:
			return 'None';
		default:
			const type: never = subscriptionType;
			throw new Error(`Unknown subscription type: ${type}`);
	}
};
