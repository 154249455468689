import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { BackButton } from '../components/back-button';
import { links } from '../Router/paths';
import { PaymentPortalConnect } from '../templates/payment-portal-connect';
import { PaymentPortalSelection } from '../templates/payment-portal-selection';

type State = 'selection' | 'connect';

export const PaymentPortal = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const state = (location.state ?? 'selection') as State;

	if (state === 'selection') {
		return (
			<>
				<BackButton to={links.ACCOUNT.DASHBOARD} />
				<PaymentPortalSelection
					onSuccess={() => {
						navigate('', { state: 'connect' });
					}}
					onSkip={() => {
						navigate(links.ACCOUNT.UPLOAD_LEASE_AGREEMENTS);
					}}
				/>
			</>
		);
	} else if (state === 'connect') {
		return (
			<>
				<BackButton onClick={() => navigate('', { state: 'selection' })} />
				<PaymentPortalConnect
					onSuccess={() => {
						navigate(links.ACCOUNT.DASHBOARD);
					}}
				/>
			</>
		);
	}

	return null;
};
