import React from 'react';

import { useUserData } from '../../../contexts/user-data-context';
import { CustomRentEarlyMoveInModal } from './custom-rent-early-move-in-modal';

const Hidden = ({ children }: { children: React.JSX.Element }) => {
	return <div style={{ display: 'none' }}>{children}</div>;
};

export const AccountModals = () => {
	const { onboardingEvents, home } = useUserData();

	if (!home || !onboardingEvents) {
		return null;
	}

	const customRentFutureModal = <CustomRentEarlyMoveInModal />;

	return <Hidden>{customRentFutureModal}</Hidden>;
};
