import { useQuery } from '@tanstack/react-query';

import { UserLocale } from '../../../../../functions/src/shared/user';

import { localesProviderService } from './locales-provider-service';

export const useLocaleMessages = (locale: UserLocale | undefined) => {
	const { data, isPending } = useQuery({
		gcTime: Infinity,
		staleTime: Infinity,
		refetchOnWindowFocus: false,
		queryKey: ['i18n.messages', locale],
		queryFn: async (): Promise<Record<string, string> | null> => {
			if (!locale) {
				return null;
			}
			const response = await fetch(localesProviderService.localeUrls[locale]).catch((e) => {
				console.error('Failed to fetch language file', e);
				throw e;
			});
			return response.json();
		},
	});

	return {
		data,
		isPending,
	};
};
