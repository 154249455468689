import { ReactNode, useEffect, useMemo } from 'react';
import { debounce } from 'lodash';

import { UserLocale } from '../../../../../functions/src/shared/user';

import { trpc } from '../../../shared/trpc/client';

import { useUserData } from '../../contexts/hooks';
import { useLocaleContext } from './locales-provider';

/**
 * Takes current locale from the context and saves it to DB
 */
export const LocaleSync = ({ children }: { children?: ReactNode }) => {
	const { mutate: updateLocale } = trpc.user.setLocale.useMutation();

	const { user } = useUserData();
	const utils = trpc.useUtils();
	const localeContext = useLocaleContext();

	const updateLocaleDebounced = useMemo(
		() =>
			debounce((locale: UserLocale) => {
				updateLocale({ locale });
				// save locale to storage as well, so we use it if update did not run yet
				utils.user.home.invalidate();
			}, 1000),
		[],
	);

	useEffect(() => {
		const updatedLocale = localeContext?.setUserSettingsLocale(user?.locale as UserLocale);

		if (updatedLocale && user?.locale && updatedLocale !== user?.locale) {
			updateLocaleDebounced(updatedLocale);
		}
	}, [user?.locale]);

	return children;
};
