import { Component, PropsWithChildren, ReactNode } from 'react';
import * as Sentry from '@sentry/react';

import { getEnvironment, isDevelopment } from '../shared/environment';

type Props = PropsWithChildren<{
	errorPage: ReactNode;
}>;

type State = {
	error?: unknown;
};

export class ErrorBoundary extends Component<Props, State> {
	constructor(props) {
		super(props);
		this.state = { error: false };
	}

	static getDerivedStateFromError(error) {
		return { error };
	}

	componentDidCatch(error, errorInfo) {
		console.error(error);
		if (!isDevelopment() && getEnvironment() !== 'local') {
			Sentry.withScope((scope) => {
				Object.entries(errorInfo).forEach(([key, value]) => {
					scope.setExtra(key, value);
				});
				Sentry.captureException(error);
			});
		}
	}

	render() {
		if (this.state.error) {
			return this.props.errorPage;
		}
		return this.props.children;
	}
}
