import { UserPmsResidencyType } from '../../../../functions/src/shared/pms';

import { RouterOutput } from '../../shared/trpc/client';

import { UserStatus } from '../contexts/user-data-context';

type HomeData = RouterOutput['user']['home'];

export function shouldPrefill(input: { userStatus: UserStatus; home: HomeData | null }) {
	const { userStatus, home } = input;
	return userStatus !== UserStatus.ValidUser && home?.residency?.type === UserPmsResidencyType.Indirect;
}
