const ImpersonateLocalStorageItem = 'impersonate';

export function isImpersonating() {
	return localStorage.getItem(ImpersonateLocalStorageItem) !== null;
}

export function getImpersonatedUserId() {
	return localStorage.getItem(ImpersonateLocalStorageItem);
}

export function stopImpersonation() {
	const currentImpersonatedUserId = getImpersonatedUserId();
	localStorage.removeItem(ImpersonateLocalStorageItem);
	return currentImpersonatedUserId;
}

export function stopImpersonationAndRedirectBackToAdmin() {
	const wasImpersonatingUserId = stopImpersonation();
	if (wasImpersonatingUserId) {
		window.location.href = `/admin/users/${wasImpersonatingUserId}/profile`;
	} else {
		alert('Not impersonating');
	}
}

export function startImpersonation(userId: string) {
	localStorage.setItem(ImpersonateLocalStorageItem, userId);
}
