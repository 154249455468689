import { useMemo } from 'react';
import { parse } from 'date-fns';
import { IntlShape } from 'react-intl';
import { z } from 'zod';

import { jsDateToIsoDate } from '../../../../functions/src/shared/iso-date';
import { ManualResidencyLeaseType } from '../../../../functions/src/shared/pms';

export const useIndirectLeaseDateSchema = (intl: IntlShape) => {
	return useMemo(
		() =>
			z
				.object({
					leaseStartDate: z
						.string()
						.min(1)
						.transform((dateStr, ctx) => {
							try {
								return jsDateToIsoDate(parse(dateStr, 'MM/dd/yyyy', new Date())) as string;
							} catch {
								ctx.addIssue({
									code: z.ZodIssueCode.invalid_date,
									message: intl.formatMessage({
										defaultMessage: 'Please enter a valid lease start date.',
										id: 'indirect-select-lease-dates.invalid-lease-start-date',
									}),
								});
								return z.NEVER;
							}
						}),
					leaseEndDate: z
						.string()
						.nullable()
						.transform((dateStr, ctx) => {
							if (!dateStr) return null;
							try {
								return jsDateToIsoDate(parse(dateStr, 'MM/dd/yyyy', new Date())) as string;
							} catch {
								ctx.addIssue({
									code: z.ZodIssueCode.invalid_date,
									message: intl.formatMessage({
										defaultMessage: 'Please enter a valid lease end date.',
										id: 'indirect-select-lease-dates.invalid-lease-end-date',
									}),
								});
								return z.NEVER;
							}
						}),
					leaseType: z.nativeEnum(ManualResidencyLeaseType).default(ManualResidencyLeaseType.Fixed),
				})
				.refine(
					(data) =>
						(data.leaseType === ManualResidencyLeaseType.MonthToMonth && !data.leaseEndDate) ||
						(data.leaseType === ManualResidencyLeaseType.Fixed && data.leaseEndDate),
				)
				.refine((data) => data.leaseType === ManualResidencyLeaseType.MonthToMonth || data.leaseEndDate, {
					path: ['leaseEndDate'],
					message: intl.formatMessage({
						defaultMessage: 'Lease end date is required.',
						id: 'indirect-select-lease-dates.lease-end-date-is-required',
					}),
				})
				.refine(
					(data) =>
						(data.leaseEndDate && data.leaseEndDate > data.leaseStartDate) ||
						data.leaseType === ManualResidencyLeaseType.MonthToMonth,
					{
						path: ['leaseEndDate'],
						message: intl.formatMessage({
							defaultMessage: 'Lease end date must be after lease start date.',
							id: 'indirect-select-lease-dates.lease-end-date-must-be-after-lease-start-date',
						}),
					},
				),
		[intl],
	);
};
