import React, { useEffect } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';

import { BusinessEventType } from '../../../../../functions/src/shared/business-events';
import { amountToCents, centsToAmount } from '../../../../../functions/src/shared/monetary';

import { trpc } from '../../../shared/trpc/client';
import { preprocessFloat } from '../../../shared/zod-schemas';

import { Button, Footer, FormInput, Spacing } from '../../../base-ui/components';

import { AlertError } from '../../components/alert-error';
import { OnboardingCloseButton } from '../../components/onboarding-close-button';
import { SelectRentAmountContent } from '../../components/select-rent-amount-content';
import { useUserData } from '../../contexts/hooks';
import { links } from '../../Router/paths';
import { useTrackOnboardingEvent } from '../../utils/track-onboarding-event';
import { useConnectedLandlord } from '../../utils/use-connected-landlord';

const schema = z.object({
	amount: preprocessFloat(z.number().gt(0).lt(100_000)),
});

type Schema = z.infer<typeof schema>;

export const SelectRentAmount = () => {
	const { home } = useUserData();
	const { landlordName } = useConnectedLandlord();
	const intl = useIntl();

	const navigate = useNavigate();

	const trackOnboardingEvent = useTrackOnboardingEvent();
	useEffect(() => trackOnboardingEvent.mutate({ type: BusinessEventType.OnboardingCustomRentStart }), []);

	const getInitialRentAmount = trpc.user.customRent.getInitialRentAmount.useQuery(undefined, {
		staleTime: Infinity,
	});
	useEffect(() => {
		if (getInitialRentAmount.data) {
			form.reset({ amount: centsToAmount(getInitialRentAmount.data.amountCents) });
		}
	}, [getInitialRentAmount.data]);
	const form = useForm<Schema>({
		resolver: zodResolver(schema),
		defaultValues: {
			amount: getInitialRentAmount.data?.amountCents
				? centsToAmount(getInitialRentAmount.data.amountCents)
				: undefined,
		},
	});

	const saveRentAmount = trpc.user.customRent.saveInitialRentAmount.useMutation({
		onSuccess: ({ config, onboardingEvents }) => {
			utils.user.customRent.getConfig.setData(undefined, config);
			utils.user.customRent.getInitialRentAmount.setData(undefined, { amountCents: config.rentAmountCents });
			utils.user.listOnboardingEvents.setData(undefined, onboardingEvents);
		},
	});
	const utils = trpc.useUtils();

	const submit = async ({ amount }: Schema) => {
		await saveRentAmount.mutateAsync({ rentAmountCents: amountToCents(amount) });

		navigate(
			home?.pmsResidencyHasRoommates
				? links.REGISTRATION.CONFIGURE.ROOMMATES_RENT_AMOUNT_CONFIRM
				: links.REGISTRATION.CONFIGURE.SELECT_SCHEDULE,
		);
	};

	return (
		<FormProvider {...form}>
			<form onSubmit={form.handleSubmit(submit)}>
				{/* Ok to keep removed when it's disabled always? */}
				{/* <BackButton disabled /> */}
				<OnboardingCloseButton to={links.ACCOUNT.DASHBOARD} stopOnboardingReminders />

				{!getInitialRentAmount.isPending && (
					<SelectRentAmountContent
						hasInitialRentAmount={Boolean(getInitialRentAmount.data)}
						landlordName={landlordName}
					/>
				)}

				<Spacing $size="m" />
				<FormInput
					name="amount"
					placeholder={intl.formatMessage({
						defaultMessage: 'Rent amount',
						id: 'account-settings.rent-amount.label',
					})}
					testId="select-rent-input-amount"
					inputMode="decimal"
					type="number"
					prefix="$"
				/>

				{saveRentAmount.isError && <AlertError />}

				<Footer>
					<Button disabled={saveRentAmount.isPending} htmlType="submit" testId="select-rent-button-continue">
						<FormattedMessage defaultMessage="Continue" id="common.continue" />
					</Button>
				</Footer>
			</form>
		</FormProvider>
	);
};
