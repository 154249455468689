'use strict';

var createProxy = require('./createProxy.js');
var formatter = require('./error/formatter.js');
var TRPCError = require('./error/TRPCError.js');
var transformer = require('./transformer.js');
var utils = require('./utils.js');

function isRouter(procedureOrRouter) {
    return procedureOrRouter._def && 'router' in procedureOrRouter._def;
}
const emptyRouter = {
    _ctx: null,
    _errorShape: null,
    _meta: null,
    queries: {},
    mutations: {},
    subscriptions: {},
    errorFormatter: formatter.defaultFormatter,
    transformer: transformer.defaultTransformer
};
/**
 * Reserved words that can't be used as router or procedure names
 */ const reservedWords = [
    /**
   * Then is a reserved word because otherwise we can't return a promise that returns a Proxy
   * since JS will think that `.then` is something that exists
   */ 'then',
    /**
   * `fn.call()` and `fn.apply()` are reserved words because otherwise we can't call a function using `.call` or `.apply`
   */ 'call',
    'apply'
];
/**
 * @internal
 */ function createRouterFactory(config) {
    function createRouterInner(input) {
        const reservedWordsUsed = new Set(Object.keys(input).filter((v)=>reservedWords.includes(v)));
        if (reservedWordsUsed.size > 0) {
            throw new Error('Reserved words used in `router({})` call: ' + Array.from(reservedWordsUsed).join(', '));
        }
        const procedures = utils.omitPrototype({});
        function step(from, path = []) {
            const aggregate = utils.omitPrototype({});
            for (const [key, item] of Object.entries(from ?? {})){
                if (isRouter(item)) {
                    aggregate[key] = step(item._def.record, [
                        ...path,
                        key
                    ]);
                    continue;
                }
                if (!isProcedure(item)) {
                    // RouterRecord
                    aggregate[key] = step(item, [
                        ...path,
                        key
                    ]);
                    continue;
                }
                const newPath = [
                    ...path,
                    key
                ].join('.');
                if (procedures[newPath]) {
                    throw new Error(`Duplicate key: ${newPath}`);
                }
                procedures[newPath] = item;
                aggregate[key] = item;
            }
            return aggregate;
        }
        const record = step(input);
        const _def = {
            _config: config,
            router: true,
            procedures,
            ...emptyRouter,
            record
        };
        return {
            ...record,
            _def,
            createCaller: createCallerFactory()({
                _def
            })
        };
    }
    return createRouterInner;
}
function isProcedure(procedureOrRouter) {
    return typeof procedureOrRouter === 'function';
}
/**
 * @internal
 */ function callProcedure(opts) {
    const { type , path  } = opts;
    const proc = opts.procedures[path];
    if (!proc || !isProcedure(proc) || proc._def.type !== type && !opts.allowMethodOverride) {
        throw new TRPCError.TRPCError({
            code: 'NOT_FOUND',
            message: `No "${type}"-procedure on path "${path}"`
        });
    }
    /* istanbul ignore if -- @preserve */ if (proc._def.type !== type && opts.allowMethodOverride && proc._def.type === 'subscription') {
        throw new TRPCError.TRPCError({
            code: 'METHOD_NOT_SUPPORTED',
            message: `Method override is not supported for subscriptions`
        });
    }
    return proc(opts);
}
function createCallerFactory() {
    return function createCallerInner(router) {
        const _def = router._def;
        return function createCaller(ctxOrCallback, options) {
            return createProxy.createRecursiveProxy(async ({ path , args  })=>{
                const fullPath = path.join('.');
                if (path.length === 1 && path[0] === '_def') {
                    return _def;
                }
                const procedure = _def.procedures[fullPath];
                let ctx = undefined;
                try {
                    ctx = utils.isFunction(ctxOrCallback) ? await Promise.resolve(ctxOrCallback()) : ctxOrCallback;
                    return await procedure({
                        path: fullPath,
                        getRawInput: async ()=>args[0],
                        ctx,
                        type: procedure._def.type
                    });
                } catch (cause) {
                    options?.onError?.({
                        ctx,
                        error: TRPCError.getTRPCErrorFromUnknown(cause),
                        input: args[0],
                        path: fullPath,
                        type: procedure._def.type
                    });
                    throw cause;
                }
            });
        };
    };
}
function mergeRouters(...routerList) {
    const record = utils.mergeWithoutOverrides({}, ...routerList.map((r)=>r._def.record));
    const errorFormatter = routerList.reduce((currentErrorFormatter, nextRouter)=>{
        if (nextRouter._def._config.errorFormatter && nextRouter._def._config.errorFormatter !== formatter.defaultFormatter) {
            if (currentErrorFormatter !== formatter.defaultFormatter && currentErrorFormatter !== nextRouter._def._config.errorFormatter) {
                throw new Error('You seem to have several error formatters');
            }
            return nextRouter._def._config.errorFormatter;
        }
        return currentErrorFormatter;
    }, formatter.defaultFormatter);
    const transformer$1 = routerList.reduce((prev, current)=>{
        if (current._def._config.transformer && current._def._config.transformer !== transformer.defaultTransformer) {
            if (prev !== transformer.defaultTransformer && prev !== current._def._config.transformer) {
                throw new Error('You seem to have several transformers');
            }
            return current._def._config.transformer;
        }
        return prev;
    }, transformer.defaultTransformer);
    const router = createRouterFactory({
        errorFormatter,
        transformer: transformer$1,
        isDev: routerList.every((r)=>r._def._config.isDev),
        allowOutsideOfServer: routerList.every((r)=>r._def._config.allowOutsideOfServer),
        isServer: routerList.every((r)=>r._def._config.isServer),
        $types: routerList[0]?._def._config.$types
    })(record);
    return router;
}

exports.callProcedure = callProcedure;
exports.createCallerFactory = createCallerFactory;
exports.createRouterFactory = createRouterFactory;
exports.mergeRouters = mergeRouters;
