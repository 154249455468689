import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { Button, Center, Spacing, Text } from '../../base-ui/components';

import { Container } from '../components/app-container';
import { Logo } from '../components/logo';
import { handleSignOut } from '../contexts/Firebase/FirebaseContext';

const StyledLogo = styled(Logo)`
	position: absolute;
	left: 5rem;
	top: 3rem;
	z-index: 100;

	width: 190px;
	height: 33px;

	@media (max-width: 450px) {
		left: 2rem;
		top: 2rem;
		width: 117px;
		height: 20px;
	}
`;

export const InternalError = () => {
	const handleClick = async () => {
		await handleSignOut();
		window.location.href = '/';
	};
	return (
		<>
			<StyledLogo />
			<Container>
				<Center>
					<Text type="title">
						<FormattedMessage id="error.internal-error.title" defaultMessage="Internal error" />
					</Text>
					<Spacing $size="m" />
					<Text center>
						<FormattedMessage
							id="error.internal-error.label1"
							defaultMessage="Something went wrong at our end."
						/>
						<br />
						<FormattedMessage
							id="error.internal-error.label2"
							defaultMessage="Don't worry, we'll fix it soon."
						/>
					</Text>
					<Spacing $size="l" />
					<Button type="link" onClick={handleClick}>
						<FormattedMessage
							id="error.internal-error.sign-in-again"
							defaultMessage="Try Signing in again."
						/>
					</Button>
				</Center>
			</Container>
		</>
	);
};
