'use strict';

/** @internal */ const unsetMarker = Symbol('unsetMarker');
/**
 * Ensures there are no duplicate keys when building a procedure.
 * @internal
 */ function mergeWithoutOverrides(obj1, ...objs) {
    const newObj = Object.assign(Object.create(null), obj1);
    for (const overrides of objs){
        for(const key in overrides){
            if (key in newObj && newObj[key] !== overrides[key]) {
                throw new Error(`Duplicate key ${key}`);
            }
            newObj[key] = overrides[key];
        }
    }
    return newObj;
}
/**
 * Check that value is object
 * @internal
 */ function isObject(value) {
    return !!value && !Array.isArray(value) && typeof value === 'object';
}
function isFunction(fn) {
    return typeof fn === 'function';
}
/**
 * Create an object without inheriting anything from `Object.prototype`
 * @internal
 */ function omitPrototype(obj) {
    return Object.assign(Object.create(null), obj);
}
const asyncIteratorsSupported = typeof Symbol === 'function' && !!Symbol.asyncIterator;
function isAsyncIterable(value) {
    return asyncIteratorsSupported && isObject(value) && Symbol.asyncIterator in value;
}
/**
 * Run an IIFE
 */ const run = (fn)=>fn();

exports.isAsyncIterable = isAsyncIterable;
exports.isFunction = isFunction;
exports.isObject = isObject;
exports.mergeWithoutOverrides = mergeWithoutOverrides;
exports.omitPrototype = omitPrototype;
exports.run = run;
exports.unsetMarker = unsetMarker;
