import z from 'zod';

import { useLocalStorage } from '../../../shared/use-local-storage';

import { LANGUAGE_KEY } from './locales-provider-service';

const localeSchema = z.string().nullable().optional();
export const useLocaleFromStorage = () => {
	return useLocalStorage(localeSchema, LANGUAGE_KEY, null);
};
