import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { UserStatus } from '../../contexts/user-data-context';
import { links } from '../paths';

export const UnprotectedRoutes = ({ userStatus }: { userStatus: UserStatus }) => {
	if (userStatus === UserStatus.ValidUser) {
		return <Navigate to={links.ACCOUNT.DASHBOARD} replace />;
	}

	if (userStatus !== UserStatus.Unauthenticated) {
		return <Navigate to={links.REGISTRATION.ROOT} replace />;
	}

	return <Outlet />;
};
