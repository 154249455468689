const inviteIdStorageKey = 'zenbase-invite-id';

export const setInvite = (inviteId: string, inviteSecret?: string) => {
	sessionStorage.setItem(inviteIdStorageKey, JSON.stringify({ inviteId, inviteSecret }));
};

export const getInvite = () => {
	const invite = sessionStorage.getItem(inviteIdStorageKey);
	if (!invite) return;

	try {
		return JSON.parse(invite) as { inviteId: string; inviteSecret?: string };
	} catch {
		return;
	}
};

export const clearInvite = () => {
	sessionStorage.removeItem(inviteIdStorageKey);
};
