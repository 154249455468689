'use strict';

var observable = require('./observable.js');
var operators = require('./operators.js');



exports.isObservable = observable.isObservable;
exports.observable = observable.observable;
exports.observableToPromise = observable.observableToPromise;
exports.map = operators.map;
exports.share = operators.share;
exports.tap = operators.tap;
