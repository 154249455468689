import React, { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { BusinessEventType } from '../../../../../functions/src/shared/business-events';
import { PropertyManagerCreditBuilder } from '../../../../../functions/src/shared/landlords';

import { Button, Divider, Icon } from '../../../base-ui/components';
import { ButtonProps } from '../../../base-ui/components/Button/Button';
import { colors } from '../../../base-ui/variables';

import { useUserData } from '../../contexts/hooks';
import { useInstaMoneyState } from '../../contexts/InstaMoneyContext/InstaMoneyContext';
import { useCreditBuilderDashboard } from '../../contexts/user-data-context';
import { links } from '../../Router/paths';
import { useCreditBuilderRentalDialog } from '../../templates/credit-builder-rental-dialog';
import { useTrackOnboardingEvent } from '../../utils/track-onboarding-event';

const FeatureList = styled.div`
	display: flex;
	flex-direction: column;
	margin: 2rem 0;
	@media (max-width: 450px) {
		margin: 1rem 0;
	}
`;
const Feature = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-radius: 10px;
	button {
		width: auto;
	}
`;
const FeatureName = styled.div`
	font-size: 2.2rem;
	line-height: 1.4;
	color: ${colors.textBody};
	margin-left: 2rem;
	@media (max-width: 450px) {
		font-size: 1.6rem;
		line-height: 2.4rem;
	}
`;

const FeatureLeft = styled.div`
	display: flex;
	align-items: center;
`;

export const Features = () => {
	const { home, customRentSupported } = useUserData();

	const navigate = useNavigate();
	const trackOnboardingEvent = useTrackOnboardingEvent();

	const onlyCreditBuilder = useCreditBuilderDashboard();

	const customRentDisabled = !home?.rentThatCanBeDisabled;

	const { cta: instaMoneyCta, limitAccessToCashAdvance } = useInstaMoneyState();

	const creditBuilderRentalDialog = useCreditBuilderRentalDialog();
	const creditBuilderSupported = home?.landlord?.creditBuilder !== PropertyManagerCreditBuilder.Disabled;

	const indirectModel = Boolean(home?.dashboard.indirectModel);

	return (
		<FeatureList data-testid="account-dashboard-list-features">
			{!indirectModel && customRentSupported && (
				<FeatureItem
					icon="CustomRent"
					label={<FormattedMessage defaultMessage="CustomRent" id="features.custom-rent" />}
					ctaProps={
						onlyCreditBuilder
							? {
									onClick: () => {
										trackOnboardingEvent.mutate(
											{ type: BusinessEventType.OnboardingCustomRentStart },
											{
												onSuccess() {
													navigate(links.REGISTRATION.ROOT, { replace: true });
												},
											},
										);
									},
								}
							: {
									linkTo: customRentDisabled
										? links.ACCOUNT.CONFIGURE.ENABLE_CUSTOM_RENT
										: links.ACCOUNT.CONFIGURE.SELECT_SCHEDULE,
									testId: 'account-dashboard-manage-custom-rent-button',
								}
					}
				/>
			)}
			{creditBuilderSupported && (
				<>
					<Divider />
					<FeatureItem
						icon="Configuration"
						label={<FormattedMessage defaultMessage="CreditBuilder" id="features.credit-builder" />}
						ctaProps={{ onClick: creditBuilderRentalDialog }}
					/>
				</>
			)}
			{!indirectModel && !onlyCreditBuilder && !limitAccessToCashAdvance && (
				<>
					<Divider />
					<FeatureItem
						icon="InstaMoney"
						label={<FormattedMessage defaultMessage="InstaMoney" id="features.insta-money" />}
						ctaProps={{
							...instaMoneyCta,
						}}
					/>
				</>
			)}
			<Divider />
		</FeatureList>
	);
};

const FeatureItem = ({
	icon,
	label,
	ctaLabel = <FormattedMessage defaultMessage="Manage" id="feature-item.manage" />,
	ctaProps = {},
}: {
	icon: 'CustomRent' | 'InstaMoney' | 'Configuration';
	label: ReactNode;
	ctaLabel?: ReactNode;
	ctaProps?: ButtonProps;
}) => {
	return (
		<Feature>
			<FeatureLeft>
				<Icon color={colors.green} icon={icon} />
				<FeatureName>{label}</FeatureName>
			</FeatureLeft>
			<Button {...ctaProps}>{ctaLabel ?? ctaProps.children}</Button>
		</Feature>
	);
};
