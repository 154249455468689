import React from 'react';

import { CommonIconProps } from './types';

export const Close = ({ fill }: CommonIconProps) => {
	return (
		<svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M15.7825 1.70711C16.173 1.3166 16.173 0.683426 15.7825 0.292923C15.3919 -0.097641 14.7588 -0.097641 14.3682 0.292923L8.07478 6.58638L1.78133 0.292923C1.3908 -0.097641 0.757636 -0.097641 0.367112 0.292923C-0.0234123 0.683426 -0.0234123 1.3166 0.367112 1.70711L6.66057 8.00056L0.367112 14.294C-0.0234123 14.6846 -0.0234123 15.3177 0.367112 15.7083C0.757636 16.0988 1.3908 16.0988 1.78133 15.7083L8.07478 9.41481L14.3682 15.7083C14.7588 16.0988 15.3919 16.0988 15.7825 15.7083C16.173 15.3177 16.173 14.6846 15.7825 14.294L9.489 8.00056L15.7825 1.70711Z"
				fill={fill}
			/>
		</svg>
	);
};
