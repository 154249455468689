import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { Text } from '../../base-ui/components';
import { colors } from '../../base-ui/variables';

import { BankLogoBmo } from './bank-logos/bmo';
import { BankLogoCibc } from './bank-logos/cibc';
import { BankLogoRbc } from './bank-logos/rbc';
import { BankLogoScotioBank } from './bank-logos/scotiobank';
import { BankLogoTdBank } from './bank-logos/td-bank';

const Title = styled(Text).attrs({ type: 'title' })`
	margin: 3rem 0;
	text-align: center;
	color: ${colors.textBody};
`;

const Section = styled.section`
	display: grid;
	align-items: center;

	grid-template-columns: 1fr 1fr 1fr 1fr 1fr;

	@media (max-width: 768px) {
		grid-template-columns: 1fr 1fr;
		grid-template-rows: repeat(3, 90px);
	}
	@media (max-width: 400px) {
		grid-template-columns: 1fr;
		grid-template-rows: repeat(5, 90px);
	}

	& svg {
		margin: 0 auto;
	}
`;

export const BankLogosStripe = () => {
	return (
		<>
			<Title>
				<FormattedMessage
					defaultMessage="Zenbase works with the largest financial institutions in Canada."
					id="bank-logos-stripe.title"
				/>
			</Title>
			<Section>
				<BankLogoRbc />
				<BankLogoBmo />
				<BankLogoCibc />
				<BankLogoTdBank />
				<BankLogoScotioBank />
			</Section>
		</>
	);
};
