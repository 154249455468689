import React, { useMemo } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import snakeCase from 'lodash/snakeCase';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import z from 'zod';

import { trpc } from '../../shared/trpc/client';

import { Button, Footer, FormInput, Radio, RadioGroup, Spacing, Text } from '../../base-ui/components';

import { useUserData } from '../contexts/hooks';

export const OnboardingSurvey = () => {
	const {
		handlers: { forceRefetch },
	} = useUserData();
	const { mutate, isError } = trpc.user.signUpSurvey.useMutation({ onSuccess: forceRefetch });
	const intl = useIntl();
	const { options, schema } = useMemo(() => {
		const intlOptions = [
			{
				id: 'Landlord or a property manager',
				label: intl.formatMessage({
					defaultMessage: 'Landlord or a property manager',
					id: 'onboarding-survey.landlord-or-a-property-manager',
				}),
			},
			{
				id: 'Friend or a neighbor',
				label: intl.formatMessage({
					defaultMessage: 'Friend or a neighbor',
					id: 'onboarding-survey.friend-or-a-neighbor',
				}),
			},
			{
				id: 'Email',
				label: intl.formatMessage({
					defaultMessage: 'Email',
					id: 'forgot-password.email-placeholder',
				}),
			},
			{
				id: 'Mail Offer',
				label: intl.formatMessage({
					defaultMessage: 'Mail Offer',
					id: 'onboarding-survey.mail-offer',
				}),
			},
			{
				id: 'Social Media',
				label: intl.formatMessage({
					defaultMessage: 'Social Media',
					id: 'onboarding-survey.social-media',
				}),
			},
			{
				id: 'Poster or Brochure',
				label: intl.formatMessage({
					defaultMessage: 'Poster or Brochure',
					id: 'onboarding-survey.poster-or-brochure',
				}),
			},
			{
				id: 'Other',
				label: intl.formatMessage({
					defaultMessage: 'Other',
					id: 'disable-custom-rent.other-reason',
				}),
			},
		];

		const errorMessage = intl.formatMessage({
			defaultMessage: 'Please choose one of the options',
			id: 'onboarding-survey.please-choose-one-of-the-options',
		});

		const memoSchema = z.object({
			option: z.string({
				required_error: errorMessage,
				invalid_type_error: errorMessage,
			}),
			code: z.string().optional(),
		});
		return { schema: memoSchema, options: intlOptions };
	}, [intl]);
	type Schema = z.infer<typeof schema>;

	const form = useForm<Schema>({ resolver: zodResolver(schema) });

	const submit = ({ option, code }: Schema) => {
		mutate({ source: option, code: code?.trim()?.toUpperCase() });
	};

	return (
		<FormProvider {...form}>
			<form onSubmit={form.handleSubmit(submit)}>
				<Spacing $size="l" />
				<Text type="title">
					<FormattedMessage
						defaultMessage="How did you hear about us?"
						id="onboarding-survey.how-did-you-hear-about-us"
					/>
				</Text>
				<Spacing $size="s" />
				<Text>
					<FormattedMessage
						defaultMessage="Please select the one that resonates most with you."
						id="disable-custom-rent.select-reason-message"
					/>
				</Text>

				<Spacing $size="l" />

				<RadioGroup name="option">
					{options.map(({ id, label }) => (
						<Radio
							{...form.register('option')}
							value={id}
							key={id}
							label={label}
							testId={`onboarding-survey-option-${snakeCase(id)}`}
						/>
					))}
				</RadioGroup>

				<Text>
					<FormattedMessage
						defaultMessage="Do you have a promo code?"
						id="onboarding-survey.do-you-have-a-promo-code"
					/>
				</Text>
				<FormInput
					name="code"
					placeholder={intl.formatMessage({
						defaultMessage: 'Enter promo code',
						id: 'onboarding-survey.enter-promo-code',
					})}
				/>

				{isError && (
					<Text>
						<FormattedMessage
							defaultMessage="Failed to save survey response."
							id="onboarding-survey.failed-to-save-survey-response"
						/>
					</Text>
				)}

				<Spacing $size="m" />

				<Footer>
					<Button
						htmlType="submit"
						disabled={form.formState.isSubmitting}
						testId="onboarding-survey-confirm-button"
					>
						<FormattedMessage defaultMessage="Continue" id="common.continue" />
					</Button>
				</Footer>
			</form>
		</FormProvider>
	);
};
