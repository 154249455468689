// https://help.fullstory.com/hc/en-us/articles/360020828113-FS-identify-Identifying-users

export function identify(userId: string, displayName: string | null, email?: string | null) {
	if (typeof window.FS !== 'undefined') {
		FS.identify(userId, {
			displayName,
			email,
		});
	}
}

export function anonymize() {
	if (typeof window.FS !== 'undefined') {
		FS.anonymize();
	}
}

export function shutdown() {
	if (typeof window.FS !== 'undefined') {
		FS.shutdown();
	}
}
