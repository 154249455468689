import React from 'react';

export const BankLogoCibc = () => (
	<svg width="142" height="37" viewBox="0 0 142 37" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M21.2694 26.7638C19.8419 27.8742 18.0685 28.4493 16.2595 28.3869C11.103 28.3869 7.67999 24.4421 7.67999 18.17C7.67999 11.797 11.0688 7.90337 16.2595 7.90337C17.9518 7.85083 19.6142 8.34783 20.999 9.31769L24.8504 4.09493C22.455 2.49459 20.1066 1.50769 15.5535 1.50769C5.64756 1.50769 0 8.19163 0 18.17C0 28.4039 5.90659 34.7826 15.5535 34.7826C21.7106 34.7826 24.0989 32.6753 25.1948 31.9255L21.2694 26.7638Z"
			fill="#4F4F4F"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M91.1166 26.7126C89.6706 27.8472 87.873 28.445 86.0341 28.4038C80.8776 28.4038 77.4546 24.4591 77.4546 18.1869C77.4546 11.814 80.8719 7.92034 86.0341 7.92034C87.725 7.86922 89.3874 8.3648 90.7722 9.33466L94.625 4.1119C92.2296 2.51156 89.8798 1.52466 85.3282 1.52466C75.4393 1.52466 69.7732 8.2086 69.7732 18.1869C69.7732 28.4209 75.6812 34.7995 85.3282 34.7995C91.4838 34.7995 93.8735 32.6922 94.9694 31.9425L91.1166 26.7126Z"
			fill="#4F4F4F"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M54.3719 2.09412C61.4712 2.09412 65.2216 4.9398 65.2216 10.3216C65.2216 14.2152 62.6853 16.2146 60.2458 17.2072L60.2287 17.2739C63.0867 18.0862 66.4413 19.9393 66.4413 25.0399C66.4413 30.9556 62.0647 34.2131 54.1229 34.2131H42.8278V2.09412H54.3719ZM53.4112 20.2673H50.2943V28.5842H53.5023C56.7389 28.5842 58.8908 27.3162 58.8908 24.5216C58.8908 21.5623 56.7559 20.2673 53.4112 20.2673ZM53.4112 7.43616H50.2943V14.8912H53.2191C56.0443 14.8912 58.0824 13.7637 58.0824 11.2006C58.0824 9.48096 57.0321 7.43616 53.4112 7.43616Z"
			fill="#4F4F4F"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M28.5053 34.2131H35.9434V2.09412H28.5053V34.2131Z"
			fill="#4F4F4F"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M129.636 1.39536C128.97 0.479459 127.888 -0.0431009 126.755 0.00375911H114.781C115.933 -0.0431009 117.027 0.495079 117.69 1.43512C118.899 3.04114 130.032 18.1698 130.032 18.1698C130.032 18.1698 118.899 33.2942 117.69 34.9059C117.026 35.8431 115.931 36.3799 114.781 36.3316H126.755C127.888 36.3799 128.968 35.8588 129.636 34.9457L142 18.1698L129.636 1.39536Z"
			fill="#4F4F4F"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M120.769 5.58776C119.25 3.53728 118.052 1.91422 117.69 1.43426C117.029 0.495645 115.933 -0.0439555 114.783 0.00290452C113.647 -0.0439555 112.564 0.478605 111.896 1.39592L99.5322 18.1704L111.896 34.9406C112.565 35.8551 113.648 36.3762 114.783 36.3265C115.931 36.3748 117.027 35.838 117.69 34.9008L120.769 30.7416L111.5 18.1704L120.769 5.58776Z"
			fill="#3C3C3C"
		/>
	</svg>
);
