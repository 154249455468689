import { getEnvironment } from '../../../shared/environment';

export const firebaseConfig =
	getEnvironment() === 'production'
		? {
				apiKey: 'AIzaSyA3xxaXc7alC9tXHvukmb2idKnWpznkt0M',
				authDomain: 'bf-production.firebaseapp.com',
				databaseURL: 'https://bf-production-default-rtdb.firebaseio.com',
				projectId: 'bf-production',
				storageBucket: 'bf-production.appspot.com',
				messagingSenderId: '511942275158',
				appId: '1:511942275158:web:ea072edcc4f38ac5c98bf7',
				measurementId: 'G-EGX36R3GLD',
			}
		: {
				apiKey: 'AIzaSyAb9Q5vrwGhIcD3RTlx_elR6B7z_84PBKM',
				authDomain: 'bf-demo-a24dc.firebaseapp.com',
				databaseURL: 'https://bf-demo-a24dc.firebaseio.com',
				projectId: 'bf-demo-a24dc',
				storageBucket: 'bf-demo-a24dc.appspot.com',
				messagingSenderId: '867344387372',
				appId: '1:867344387372:web:a2193a84de2b5b84e77089',
				measurementId: 'G-FXLXW4ZNFD',
			};
