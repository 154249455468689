import React, { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

export type StripeApiError = {
	code?: string;
	declineCode?: string;
};

export const GENERIC_STRIPE_ERROR_MESSAGE = (
	<FormattedMessage
		defaultMessage="Please try again or contact support if the problem persists."
		id="checkout-error.try-again-or-contact-support"
	/>
);

export function mapStripeErrorToMessage(error: StripeApiError): ReactNode {
	if (error.code === 'card_declined') {
		if (error.declineCode === 'insufficient_funds') {
			return (
				<FormattedMessage
					defaultMessage="Your card has insufficient funds."
					id="checkout-error.card-declined-insufficient-funds"
				/>
			);
		}

		if (error.declineCode === 'expired_card') {
			return (
				<FormattedMessage
					defaultMessage="Your card has expired."
					id="checkout-error.card-declined-expired-card"
				/>
			);
		}

		return <FormattedMessage defaultMessage="Your card was declined." id="checkout-error.card-declined" />;
	}

	return GENERIC_STRIPE_ERROR_MESSAGE;
}
