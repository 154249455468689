export enum AccountStatus {
	Unknown = 'unknown',
	Unverified = 'unverified',
	Verified = 'verified',
	Disconnected = 'disconnected',
	Fraudulent = 'fraudulent',
	NotLinked = 'not-linked', // We get account information from Plaid,
	// but user did not select it for payments

	// deprecated 'manually-verified' - it now uses 'verified',
	// and once we have audit log, the override reason can be there
}

export enum AccountSource {
	Manual = 'manual',
	Plaid = 'plaid',
	Flinks = 'flinks',
	ZumRails = 'zum-rails',
	Stripe = 'stripe',
	Moneris = 'moneris',
}

type AccountOwner = { userId: string } | { landlordId: string };
export type AccountOwnerWithName = AccountOwner & { counterpartyName: string };

export const accountOwnerFields = (owner: AccountOwner) => ({
	userId: 'userId' in owner ? owner.userId : null,
	landlordId: 'landlordId' in owner ? owner.landlordId : null,
});
