import React from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import instamoneyIllustration from 'url:../../static/shapes/instamoney.svg';

import { Button, Footer, Spacing, Text } from '../../../base-ui/components';

import { links } from '../../Router/paths';

export const InstaMoneyRequest = () => {
	return (
		<>
			<img src={instamoneyIllustration} alt="" />
			<Spacing $size="l" />

			<Text type="title" center>
				<FormattedMessage defaultMessage="Request InstaMoney" id="insta-money-request.request-insta-money" />
			</Text>
			<Spacing $size="m" />

			<Text center>
				<Text as="span">
					<FormattedMessage
						defaultMessage="Get {value} cash advance"
						id="insta-money-request.get-cash-advance"
						values={{
							value: (
								<FormattedNumber
									value={100}
									style="currency"
									currency="CAD"
									maximumFractionDigits={0}
								/>
							),
						}}
					/>
				</Text>{' '}
				<FormattedMessage
					defaultMessage="per paycheck and pay back when you get paid. <highlight>No fees, just tip us what you think is fair.</highlight> That simple."
					id="insta-money-request.per-paycheck"
				/>
			</Text>
			<Spacing $size="l" />

			<Footer>
				<Button linkTo={links.ACCOUNT.INSTA_MONEY.USE} testId="instamoney-request-button-continue">
					<FormattedMessage defaultMessage="Continue" id="common.continue" />
				</Button>
				<Button linkTo={links.ACCOUNT.DASHBOARD} type="link">
					<FormattedMessage defaultMessage="Cancel" id="common.cancel" />
				</Button>
			</Footer>
		</>
	);
};
