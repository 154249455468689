import styled from 'styled-components';

import { colors } from '../../variables';

export const Wrapper = styled.div`
	background: ${colors.white};
	box-shadow: 0 0 20px rgba(23, 23, 23, 0.05);
	border-radius: 1rem;
	display: flex;
	justify-content: center;

	.rdp-button_next:disabled,
	.rdp-button_previous:disabled {
		opacity: 0.25;
	}

	.day-picker-selected {
		button {
			color: ${colors.white};
			background-color: ${colors.green};
			&:hover {
				background-color: ${colors.green};
			}
		}
	}

	.day-picker-disabled {
		button {
			opacity: 0.5;
		}
	}

	.rdp-root button {
		height: 100%;
		width: 100%;
		font-size: 1.9rem;
	}

	.rdp-root table {
		margin-top: 10px;
	}

	.rdp-root .rdp-day {
		width: 40px;
		height: 40px;
	}

	.rdp-head_cell {
		font-weight: 700;
		font-size: 10px;
		line-height: 14px;
		color: #aaa;
	}
	.rdp-root .rdp-caption_label {
		color: ${colors.green};
		font-size: 2.5rem;
	}
	.rdp-root button:hover:not([disabled]) {
		background-color: #cee0e3;
	}
	@media (max-width: 450px) {
		justify-content: flex-start;
		.rdp {
			margin: 1em 0;
		}
	}
`;
