import _ from 'lodash';
import { PascalCase, PascalCasedPropertiesDeep } from 'type-fest';

/** creates a string enum. Keys are converted to PascalCase.
 *
 * @example Runtime & Compile time:
 * export const Fruit = createEnum('apple', 'banana');
 * export type Fruit = createEnum<typeof Fruit>>;
 * @example Compile-time only:
 * export type Fruit = createEnum<'apple' | 'banana'> which is the same for:
 * export type Fruit = 'apple' | 'banana'
 * */
export function createEnum<V extends string>(...values: V[]): PascalCasedPropertiesDeep<{ [K in V]: K }> {
	return values.reduce(
		(res, key) => {
			const keyAsUpperCamelCase = _.upperFirst(_.camelCase(key)) as PascalCase<typeof key>;
			// @ts-expect-error error TS2322: Type 'string' is not assignable to type 'never'.
			res[keyAsUpperCamelCase] = key;
			return res;
		},
		{} as PascalCasedPropertiesDeep<{ [K in V]: K }>,
	);
}

export type createEnum<T extends string | Record<string, string>> =
	T extends Record<string, string> ? WithCreateEnumObject<T> : WithStringLiterals<T>;
/** @example createEnum<typeof enum> */
type WithCreateEnumObject<T extends Record<string, string>> = T extends Record<string, infer Value> ? Value : never;
/** @example createEnum<'apple' | 'banana' | 'kiwi'> */
type WithStringLiterals<T> = T;
