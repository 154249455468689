import { useState } from 'react';
import z, { ZodSchema } from 'zod';

export const useLocalStorage = <T extends ZodSchema>(schema: T, key: string, defaultValue: z.infer<T>) => {
	const [stateValue, setStateValue] = useState(() => {
		const storageValue = localStorage.getItem(key);

		try {
			const objectParsed = storageValue && JSON.parse(storageValue);
			const parsed = schema.safeParse(objectParsed);
			if (parsed.success) return parsed.data as z.infer<T>;
		} catch {
			//
		}

		return defaultValue;
	});

	const setValue = (value: z.infer<T>) => {
		setStateValue(value);
		localStorage.setItem(key, JSON.stringify(value));
	};

	return [stateValue, setValue] as const;
};
