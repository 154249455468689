import { useMemo } from 'react';
import { IntlShape } from 'react-intl';
import { z } from 'zod';

import { areEmailsEqual } from '../../../../functions/src/shared/utils';

export const useLandlordVerificationSchema = (intl: IntlShape, opts?: { omitLandlordName?: boolean }) =>
	useMemo(() => {
		return z
			.object({
				landlordName: z
					.string()
					.trim()
					.refine((n) => (opts?.omitLandlordName ? true : n.length > 0)),
				email: z.union([z.null(), z.undefined(), z.literal(''), z.string().email().trim().toLowerCase()]),
				userEmail: z.string(),
			})
			.refine((data) => !areEmailsEqual(data.userEmail, data.email ?? ''), {
				message: intl.formatMessage({
					id: 'landlord-verification.email-cannot-be-same',
					defaultMessage: 'Housing provider email cannot be the same as your email.',
				}),
				path: ['email'],
			});
	}, [intl]);
