import React, { PropsWithChildren } from 'react';
import { useController } from 'react-hook-form';
import styled from 'styled-components';

import { ErrorMessage, Wrapper } from './styles';

type Props = PropsWithChildren<{
	name: string;
	className?: string;
}>;

const RadioWrapper = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	row-gap: 2rem;
	width: 100%;
`;

export const RadioGroupElement = ({ className, children, name }: Props) => {
	const { fieldState } = useController({ name });

	return (
		<Wrapper>
			<RadioWrapper className={className}>{children}</RadioWrapper>
			{fieldState.error?.message && <ErrorMessage>{fieldState.error.message}</ErrorMessage>}
		</Wrapper>
	);
};

export const RadioGroup = styled(RadioGroupElement)``;
