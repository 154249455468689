import React from 'react';
import { FormattedMessage } from 'react-intl';

import { triggerChatWidget } from '../../../shared/zendesk';

import { Anchor, Spacing, Text } from '../../../base-ui/components';

export const CountryNotSupported = () => {
	return (
		<>
			<Text type="title">
				<FormattedMessage defaultMessage="Country not supported" id="country-not-supported.title" />
			</Text>
			<Spacing $size="m" />
			<Text>
				<FormattedMessage
					defaultMessage="Currently, Zenbase is only available in Canada. If you live in the US and are interested in splitting your rent we’ll notify you once our service is available in your area."
					id="country-not-supported.description"
				/>
			</Text>

			<Spacing $size="m" />
			<Text type="small">
				<FormattedMessage
					defaultMessage="If you have any questions about your address details please {tapHere} to message us"
					id="country-not-supported.contact-us"
					values={{
						tapHere: (
							<Anchor onClick={triggerChatWidget}>
								<FormattedMessage defaultMessage="tap here" id="country-not-supported.tap-here" />
							</Anchor>
						),
					}}
				/>
			</Text>
		</>
	);
};
