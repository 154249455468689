import React from 'react';
import styled from 'styled-components';
import houseLeft from 'url:./houseMobile@2x.png';

export const StyledHouseMobile = styled.div`
	position: absolute;
`;

export const HouseMobile = () => {
	return (
		<StyledHouseMobile>
			<img src={houseLeft} alt="" width="259px" height="245px" />
		</StyledHouseMobile>
	);
};
