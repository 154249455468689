import React from 'react';
import { Navigate, Outlet, useLocation, useMatch } from 'react-router-dom';

import { typedIncludes } from '../../../../../functions/src/shared/utils';

import { UserStatus, useUserData } from '../../contexts/user-data-context';
import { ADMIN_USERS_URL, links } from '../paths';

export const ProtectedRoutes = ({ userStatus }: { userStatus: UserStatus }) => {
	const location = useLocation();
	const { user, home } = useUserData();

	const shouldShowDidYouMoveOut = Boolean(home?.movedOutResidency);

	const partnerRoute = useMatch({
		path: links.ACCOUNT.PARTNER,
		end: true,
	});
	const creditBuilderSetupRoute = useMatch({
		path: links.ACCOUNT.CREDIT_BUILDER.SETUP,
	});
	const creditBuilderSuccessRoute = useMatch({
		path: links.ACCOUNT.CREDIT_BUILDER.SUCCESS,
	});
	const didYouMoveOutRoute = useMatch({
		path: links.ACCOUNT.DID_YOU_MOVE_OUT,
	});
	const residencyRoute = useMatch({
		path: links.ACCOUNT.RESIDENCY.ROOT,
		end: false,
	});

	if (userStatus === UserStatus.Admin) {
		window.location.href = ADMIN_USERS_URL;
		return <></>;
	}

	if (user?.claims.partner && partnerRoute) {
		return <Outlet />;
	}

	if (shouldShowDidYouMoveOut && !(didYouMoveOutRoute || residencyRoute)) {
		return <Navigate to={links.ACCOUNT.DID_YOU_MOVE_OUT} replace />;
	}

	if (userStatus === UserStatus.PromoCreditBuilderSetup && !creditBuilderSetupRoute && !shouldShowDidYouMoveOut) {
		return <Navigate to={links.ACCOUNT.CREDIT_BUILDER.SETUP} replace />;
	}

	if (userStatus === UserStatus.PromoCreditBuilderSuccess && !creditBuilderSuccessRoute && !shouldShowDidYouMoveOut) {
		return <Navigate to={links.ACCOUNT.CREDIT_BUILDER.SUCCESS} replace />;
	}

	if (
		typedIncludes(
			[UserStatus.ValidUser, UserStatus.PromoCreditBuilderSetup, UserStatus.PromoCreditBuilderSuccess],
			userStatus,
		)
	) {
		return <Outlet />;
	}

	return <Navigate to={links.DEFAULT.ROOT} state={{ originalLocation: location }} replace />;
};
