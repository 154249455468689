import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { Icon } from '../../base-ui/components';

const LinkElement = styled.button`
	display: inline-block;
	width: 4rem;
	margin-left: -0.8rem;
	margin-bottom: 1rem;
	margin-top: -1.2rem;
	cursor: pointer;
	border: none;
	background: none;
	align-self: flex-start;
	justify-self: flex-start;
	${(props) =>
		props.disabled
			? css`
					cursor: not-allowed;
					opacity: 0.5;
				`
			: ''}
`;

type Props = {
	testId?: string;
	to?: string;
	disabled?: boolean;
	onClick?: () => void;
	className?: string;
};

export const BackButton = ({ testId, to, disabled, onClick, className }: Props) => {
	const navigate = useNavigate();

	const handleClick = () => {
		if (onClick) {
			onClick();
		} else if (to) {
			navigate(to);
		} else {
			navigate(-1);
		}
	};

	return (
		<LinkElement onClick={handleClick} type="button" data-testid={testId} disabled={disabled} className={className}>
			<Icon icon="LeftArrow" />
		</LinkElement>
	);
};
