export const copyToClipboardDataAsync = async (dataPromise: Promise<string>) => {
	if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
		// safari
		await navigator.clipboard.write([
			new ClipboardItem({ 'text/plain': dataPromise }, { presentationStyle: 'inline' }),
		]);
	} else {
		// other browsers
		await navigator.clipboard.writeText(await dataPromise);
	}
};

export const copyToClipboard = (data: string) => copyToClipboardDataAsync(Promise.resolve(data));

type RichTextData = { html?: string; plain?: string };

export const copyRichTextToClipboard = async (data: RichTextData) => {
	return navigator.clipboard.write([
		new ClipboardItem(
			Object.fromEntries(
				Object.entries(data).map(([key, value]) => {
					const type = `text/${key}`;
					return [type, new Blob([value], { type })];
				}),
			),
		),
	]);
};
