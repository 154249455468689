import React from 'react';

export const CustomRentCircle = () => (
	<svg width="1em" height="1em" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
		<rect width="40" height="40" rx="20" fill="#0A6375" />
		<path
			d="M15.6335 24.3652C13.2224 21.9541 13.2224 18.0467 15.6335 15.6356C17.9928 13.2751 22.0039 13.2751 24.3644 15.6356C26.7755 18.0467 26.7755 21.9541 24.3644 24.3652C21.9533 26.7763 18.0434 26.7763 15.6335 24.3652"
			stroke="white"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M19.9971 20.0012L24.3674 15.6309"
			stroke="white"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M12.5914 30.3609C10.9599 30.3609 9.6377 29.0387 9.6377 27.4072V12.5924C9.6377 10.9609 10.9599 9.63867 12.5914 9.63867H19.9988H27.4062C29.0377 9.63867 30.3599 10.9609 30.3599 12.5924V27.4072C30.3599 29.0387 29.0377 30.3609 27.4062 30.3609H12.5914Z"
			stroke="white"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);
