import React from 'react';

export const CustomRent = () => {
	return (
		<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M21.5787 10.4205C18.57 7.41181 13.4273 7.41208 10.4207 10.4203L10.4205 10.4205C7.33923 13.5018 7.33901 18.4948 10.4198 21.5763C10.4201 21.5766 10.4204 21.5769 10.4207 21.5771C13.5002 24.6583 18.4971 24.6585 21.5787 21.577C24.6602 18.4955 24.6602 13.502 21.5787 10.4205ZM9.00686 22.9918C12.8678 26.8539 19.1308 26.8533 22.9929 22.9912C26.8554 19.1286 26.8554 12.8688 22.9929 9.00629C19.2035 5.21682 12.7943 5.21649 9.00627 9.00629M9.00608 9.00647C5.14374 12.869 5.14381 19.1287 9.00627 22.9912C9.00646 22.9914 9.00666 22.9916 9.00686 22.9918"
				fill="#0A6375"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M22.9965 8.99895C23.3871 9.38947 23.3871 10.0226 22.9965 10.4132L16.7032 16.7065C16.3127 17.097 15.6795 17.097 15.289 16.7065C14.8985 16.316 14.8985 15.6828 15.289 15.2923L21.5823 8.99895C21.9728 8.60842 22.606 8.60842 22.9965 8.99895Z"
				fill="#0A6375"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M2 26.6657C2 28.5069 3.49212 29.999 5.33333 29.999H26.6667C28.5079 29.999 30 28.5069 30 26.6657V5.33236C30 3.49115 28.5079 1.99902 26.6667 1.99902H5.33333C3.49212 1.99902 2 3.49115 2 5.33236V26.6657ZM5.33333 31.999H26.6667C29.6124 31.999 32 29.6115 32 26.6657V5.33236C32 2.38658 29.6124 -0.000976562 26.6667 -0.000976562H5.33333C2.38756 -0.000976562 0 2.38658 0 5.33236V26.6657C0 29.6115 2.38756 31.999 5.33333 31.999Z"
				fill="#0A6375"
			/>
		</svg>
	);
};
