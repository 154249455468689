import styled from 'styled-components';

import { colors } from '../../../base-ui/variables';

export const Wrapper = styled.div`
	max-height: 600px;
	overflow: auto;
	padding: 0 2em;
	margin: 0 -2em;

	@media (max-height: 900px) {
		max-height: 450px;
	}

	@media (max-height: 700px) {
		max-height: 300px;
	}
`;

export const Item = styled.div`
	box-shadow: 0px 0px 20px rgb(23 23 23 / 5%);
	border-radius: 10px;

	cursor: pointer;

	height: 9em;

	margin-bottom: 1.6em;
	padding: 1em;

	background-color: ${colors.white};

	@media (max-width: 450px) {
		height: 6em;
		margin-bottom: 0.8em;
	}
`;

export const ItemLogo = styled.div<{ $src: string }>`
	width: 100%;
	height: 100%;

	max-width: 20em;
	margin: 0 auto;

	background-image: url(${({ $src }) => $src});
	background-position: center center;
	background-size: contain;
	background-repeat: no-repeat;
`;

export const ErrorMessage = styled.p`
	font-size: 1em;
	color: ${colors.error};
	min-height: 2.6em;
	padding-top: 1em;
`;
