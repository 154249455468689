import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import success from 'url:../../static/shapes/connect-bank.png';

import { Box, Spacing } from '../../../base-ui/components';

import { links } from '../../Router/paths';
import { OnboardingSuccess } from '../../templates/onboarding-success';

export const DirectDebitChargeSuccess = () => {
	const navigate = useNavigate();

	function handleNext() {
		navigate(links.ACCOUNT.DASHBOARD);
	}

	return (
		<>
			<Box isColumn verticalCenter>
				<img src={success} alt="" width="335" />
				<Spacing $size="m" />

				<OnboardingSuccess
					title={
						<FormattedMessage defaultMessage="Payment Successful" id="direct-debit-charge-success.title" />
					}
					subtitle={
						<FormattedMessage
							defaultMessage="Congratulations! Your payment has been successfully processed."
							id="direct-debit-charge-success.text"
						/>
					}
					cta={
						<FormattedMessage
							defaultMessage="OK"
							id="custom-rent-onboarding.direct-debit-success.confirm"
						/>
					}
					onNext={handleNext}
					ctaTestId="direct-debit-card-success-continue-button"
					backButton={false}
				/>
			</Box>
		</>
	);
};
