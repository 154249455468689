import React from 'react';
import { useController } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import z from 'zod';

import { createFilterOptions } from '@mui/material/Autocomplete';

import { trpc } from '../../shared/trpc/client';

import { AutoSuggestInput } from '../../base-ui/components';

type Props = {
	name: string;
};

const filter = createFilterOptions();

export const landlordInputZodSchema = z.union([
	z.string(),
	z.object({ indirectLandlordId: z.string(), name: z.string() }),
	z.object({ id: z.string(), name: z.string(), landlordId: z.string(), landlordName: z.string() }),
]);

export const ManualAddressLandlordInput = ({ name }: Props) => {
	const intl = useIntl();
	const {
		field: { value, onChange },
		fieldState: { error },
	} = useController({ name });

	const { data } = trpc.user.listLandlordsForManualAddress.useQuery();

	const typedData: (
		| { id: string; name: string; landlordName: string; landlordId: string; type: 'landlord' }
		| { name: string; indirectLandlordId: string; type: 'indirectLandlord' }
	)[] = [];

	const options = typedData
		.concat(data?.landlords?.map((l) => ({ ...l, type: 'landlord' })) ?? [])
		.concat(data?.indirectLandlords?.map((il) => ({ ...il, type: 'indirectLandlord' })) ?? []);
	return (
		<AutoSuggestInput
			label={intl.formatMessage({
				defaultMessage: 'Property Management Company or Landlord Name',
				id: 'address.property-manager-or-landlord-name',
			})}
			testId="address-manual-input-landlord"
			error={error?.message}
			autocomplete={{
				options: options || [],
				getOptionLabel: (option) =>
					typeof option === 'string' ? option : 'inputValue' in option ? option.inputValue : option.name,
				freeSolo: true,
				value: value || null,
				onChange: (_e, v) => {
					if (v && 'inputValue' in v) return onChange(v.inputValue);
					onChange(v);
				},
				onInputChange: (_e, v) => {
					onChange(v);
				},
				filterOptions: (filterOptions, params) => {
					const { inputValue } = params;
					if (inputValue?.length < 3) return [];

					const filtered = filter(filterOptions, params);

					// Suggest the creation of a new value
					const isExisting = filterOptions.some((option) => inputValue === option.name);
					if (inputValue !== '' && !isExisting && filtered.length) {
						filtered.push({
							inputValue,
							name: (
								<FormattedMessage
									defaultMessage="Add: {inputValue}"
									id="manual-address-landlord-input.add-value"
									values={{ inputValue }}
								/>
							),
						});
					}

					return filtered;
				},
			}}
		/>
	);
};
