import {
	add,
	addWeeks,
	Day,
	endOfMonth,
	getDay,
	getHours,
	getMinutes,
	isEqual as isEqualDate,
	set,
	setDay,
	startOfDay,
	startOfMonth,
	subDays,
} from 'date-fns';

import { RentMonth } from '../shared/rent-month';

import { Country, isIsoDateHoliday } from './date-holidays';
import { jsDateToIsoDate } from './iso-date';

// We currently only support CA users, however we hopefully will be exp[anding to US
// Once we do we can remove the default value for the country code from this funciton
// *********
// We will try to ajdust forward, unless the month rolls over, then we will
// adjust to the first non holiday weekday in the current month
// *********
// Holiday library is time zone aware, however internally
// we our flows run off of UTC
// Thus as a temp hack, until we start saving dates in user's timeszones
// we adjust to noon to make sure in Canada the utc date is the same day
// and we get correct holiday results
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function adjustToNonHolidayWeekday(date: Date, adjustBackOnMonthRoll = true, countryCode = 'CA') {
	// let adjustedDate = convert(localDate).toDate();

	let adjustedDate = new Date(date.getTime());

	//get hours before making the adjustment to add it later.
	const hours = getHours(adjustedDate);
	const minutes = getMinutes(adjustedDate);

	// currently our user base is all US, but we store the times as UTC
	// as temoprary hack we will add 5 hours to make sure the date is in
	// the correct time zone, as the holidat checks are based on timezones
	// A massive hack, until we have a much more robust timezone date
	// calc module
	adjustedDate = add(startOfDay(adjustedDate), { hours: 12 });

	let newDate = adjustToNextNonHolidayWeekday(adjustedDate);

	// If we rolled over to next month, we should
	// adjust backwards to the first non holiday weekday in the current month
	if (adjustBackOnMonthRoll && date.getMonth() !== newDate.getMonth()) {
		newDate = keepTodayOrAdjustToPreviousNonHolidayWeekday(adjustedDate);
	}

	//Now add the hour back
	return set(newDate, { hours, minutes });
}

export function adjustToNextNonHolidayWeekday(date: Date) {
	let newDate = new Date(date.getTime());
	while (true) {
		// If not a weekend and not holiday, then it's a valid weekday
		newDate = keepTodayOrAdjustToNextNonWeekend(newDate);
		if (!isHoliday(newDate)) {
			return newDate;
		}

		newDate = add(newDate, { days: 1 });
	}
}

export function adjustToNDaysAfterWorkingDay(date: Date, daysAfter: number) {
	let newDate = new Date(date.getTime());

	let daysToCheck = 0;
	while (true) {
		newDate = keepTodayOrAdjustToNextNonWeekend(newDate);
		if (!isHoliday(newDate)) {
			if (daysToCheck === daysAfter) return newDate;
			daysToCheck++;
		}

		newDate = add(newDate, { days: 1 });
	}
}

export function adjustToNDaysBeforeWorkingDay(date: Date, daysBefore: number) {
	let newDate = new Date(date.getTime());

	let daysToCheck = 0;
	while (true) {
		newDate = keepTodayOrAdjustToPreviousNonWeekend(newDate);
		if (!isHoliday(newDate)) {
			if (daysToCheck === daysBefore) return newDate;
			daysToCheck++;
		}

		newDate = add(newDate, { days: -1 });
	}
}

export function keepTodayOrAdjustToNextNonWeekend(date: Date) {
	// If saturday or sunday, adjust to next monday
	if (getDay(date) === 6) {
		return setDay(date, 8);
	} else if (getDay(date) === 0) {
		return setDay(date, 1);
	}

	return date;
}

export function getNumberOfWorkingDays(startDate: Date, endDate: Date) {
	let numWorkDays = 0;
	let currentDate = new Date(startDate.getTime());
	while (currentDate <= endDate) {
		if (currentDate.getDay() !== 0 && currentDate.getDay() !== 6 && !isHoliday(currentDate)) {
			numWorkDays++;
		}
		currentDate = add(currentDate, { days: 1 });
	}
	return numWorkDays;
}

export function getLastBusinessDayOfMonth(rentMonth: RentMonth) {
	return getNthLastBusinessDayOfMonth(rentMonth, 1);
}

export function getNthLastBusinessDayOfMonth(rentMonth: RentMonth, n: number) {
	return adjustToNDaysBeforeWorkingDay(adjustToNonHolidayWeekday(rentMonth.next().firstDayAsDate()), n);
}

export function isLastBusinessDayOfMonth(now: Date) {
	return jsDateToIsoDate(getLastBusinessDayOfMonth(RentMonth.current())) === jsDateToIsoDate(now);
}

export function isHoliday(date: Date): boolean {
	return isIsoDateHoliday(jsDateToIsoDate(date), Country.ca);
}

export function keepTodayOrAdjustToPreviousNonWeekend(date: Date) {
	// If saturday or sunday, adjust to previous friday
	if (getDay(date) === 6) {
		return setDay(date, 5);
	} else if (getDay(date) === 0) {
		return setDay(date, -2);
	}

	return date;
}

export function keepTodayOrAdjustToPreviousNonHolidayWeekday(date: Date) {
	let newDate = new Date(date.getTime());
	while (true) {
		// If not a weekend and not holiday, then it's a valid weekday
		newDate = keepTodayOrAdjustToPreviousNonWeekend(newDate);
		if (!isHoliday(newDate)) {
			return newDate;
		}

		newDate = subDays(newDate, 1);
	}
}

export function isWeekendOrHoliday(date: Date) {
	return getDay(date) === 0 || getDay(date) === 6 || isHoliday(date);
}

export const isThirdMondayOfTheMonth = (today: Date) => {
	const thirdWeek = 3;
	const monday = 1;

	const firstOfMonth = startOfMonth(today);
	const firstMonday = setDay(firstOfMonth, monday, { weekStartsOn: getDay(firstOfMonth) as Day });
	const thirdMonday = addWeeks(firstMonday, thirdWeek - 1);

	return isEqualDate(startOfDay(thirdMonday), startOfDay(today));
};

export function getLastEftPaymentDate(date: Date) {
	return adjustToNDaysBeforeWorkingDay(endOfMonth(date), 3);
}

export function getSecondLandlordPaymentEffectiveDate(rentMonth: RentMonth) {
	return jsDateToIsoDate(getNthLastBusinessDayOfMonth(rentMonth, 2));
}
