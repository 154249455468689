import React, { ComponentProps } from 'react';
import { FormattedDate } from 'react-intl';

import { Box, Tooltip } from '@mui/material';

import { IsoDate, isoDateToJsDate } from '../../../../functions/src/shared/iso-date';

type Props = { value: IsoDate } & ComponentProps<typeof FormattedDate>;

export const FormattedIsoDate = ({ value }: Props) => (
	<Tooltip title={value}>
		<Box component="span">
			<FormattedDate value={isoDateToJsDate(value)} />
		</Box>
	</Tooltip>
);
