import React from 'react';
import { startOfMonth } from 'date-fns';
import { FormattedMessage, FormattedNumber } from 'react-intl';

import { publicConfig } from '../../../../../../functions/src/shared/config';
import { isoDateToJsDate, isoToday, jsDateToIsoDate } from '../../../../../../functions/src/shared/iso-date';
import { centsToAmount } from '../../../../../../functions/src/shared/monetary';
import {
	editableStatuses,
	PaymentMethod,
	TransactionStatus,
	UserTransaction,
} from '../../../../../../functions/src/shared/payment-transaction';
import { RentMonth } from '../../../../../../functions/src/shared/rent-month';
import { FeatureType } from '../../../../../../functions/src/shared/user';
import { typedIncludes } from '../../../../../../functions/src/shared/utils';

import { trpc } from '../../../../shared/trpc/client';

import { Box, Paper, Text } from '../../../../base-ui/components';
import { UnexpectedErrorAlert } from '../../../../base-ui/components/Alert/Alert';

import { useUserData } from '../../../contexts/hooks';
import { AlertError } from '../../alert-error';
import { FirstRentPaymentInfoText, SecondRentPaymentInfoText } from '../../custom-rent/rent-info-text';
import { PaymentDatePanel } from '../../payment-date-panel/payment-date-panel';
import { PaymentWrapper } from './scheduled-payments';

type Props = {
	payments: UserTransaction[];
	testId: string;
	isEligible: boolean;
};

export const MonthPaymentsList = ({ payments, testId, isEligible }: Props) => {
	const {
		home,
		handlers: { silentRefetch },
	} = useUserData();

	const updatePaymentDate = trpc.user.updatePaymentTransactionDate.useMutation({
		onSuccess() {
			silentRefetch();
		},
	});

	const handleUpdate = (transactionId: string) => async (date: Date) => {
		updatePaymentDate.mutate({ transactionId, effectiveDate: jsDateToIsoDate(date) });
	};

	if (!home?.activeRentConfig) {
		return <UnexpectedErrorAlert />;
	}
	const { activeRentConfig } = home;
	return (
		<PaymentWrapper data-testid={testId}>
			{payments.length === 0 && (
				<Paper>
					<Box verticalCenter isColumn>
						<Text>
							<FormattedMessage
								defaultMessage="There are no payments for this month"
								id="month-payments-list.no-payments"
							/>
						</Text>
					</Box>
				</Paper>
			)}

			{updatePaymentDate.isError &&
				(updatePaymentDate.error.message.includes(
					publicConfig.errorMessages.firstInstallmentOrderChangeForbidden,
				) ? (
					<AlertError title="Failed to change the payment date" subtitle={updatePaymentDate.error.message} />
				) : (
					<AlertError />
				))}

			{payments.map(
				({
					lastAllowedDate,
					featureId,
					isNeededForUpfrontPayment,
					paymentTransactionId,
					effectiveDate,
					status,
					rentMonth,
					rentYear,
					amountCents,
					paymentMethod,
					customRentUpfrontPaymentDeadline,
				}) => {
					const canChangeDaySince = isEligible
						? isoDateToJsDate(isoToday())
						: startOfMonth(isoDateToJsDate(lastAllowedDate));

					const infoText =
						featureId === FeatureType.CustomRent ? (
							isNeededForUpfrontPayment ? (
								<FirstRentPaymentInfoText />
							) : (
								<SecondRentPaymentInfoText config={activeRentConfig} />
							)
						) : undefined;

					return (
						<PaymentDatePanel
							key={paymentTransactionId}
							date={isoDateToJsDate(effectiveDate)}
							label={getPaymentLabel(paymentMethod, status)}
							paymentMethod={paymentMethod}
							handleUpdateSelect={handleUpdate(paymentTransactionId)}
							amount={
								<FormattedNumber value={centsToAmount(amountCents)} currency="CAD" style="currency" />
							}
							isEditable={typedIncludes(editableStatuses, status)}
							canChangeDateSince={canChangeDaySince}
							canChangeDateUntil={
								customRentUpfrontPaymentDeadline
									? isoDateToJsDate(customRentUpfrontPaymentDeadline)
									: isoDateToJsDate(lastAllowedDate)
							}
							infoText={infoText}
							rentMonth={
								rentMonth && rentYear
									? RentMonth.fromDbParams({ year: rentYear, month: rentMonth })
									: undefined
							}
							needToConfirmFromWeek={featureId === FeatureType.CustomRent ? 4 : undefined}
							needToConfirmUpfrontDeadlineFrom={
								customRentUpfrontPaymentDeadline ? isoDateToJsDate(lastAllowedDate) : undefined
							}
							paymentTransactionId={paymentTransactionId}
						/>
					);
				},
			)}
		</PaymentWrapper>
	);
};

function getPaymentLabel(paymentMethod: PaymentMethod, status: TransactionStatus) {
	switch (paymentMethod) {
		case PaymentMethod.Eft:
			return status === TransactionStatus.Completed ? (
				<FormattedMessage defaultMessage="Your account was debited" id="month-payments-list.account-debited" />
			) : (
				<FormattedMessage
					defaultMessage="Your account will be debited"
					id="month-payments-list.account-will-be-debited"
				/>
			);
		case PaymentMethod.ManualETransfer:
			return status === TransactionStatus.Completed ? (
				<FormattedMessage defaultMessage="You e-Transferred" id="month-payments-list.you-e-transferred" />
			) : (
				<FormattedMessage defaultMessage="You e-Transfer" id="month-payments-list.you-e-transfer" />
			);
		default:
			return status === TransactionStatus.Completed ? (
				<FormattedMessage defaultMessage="You paid" id="month-payments-list.you-paid" />
			) : (
				<FormattedMessage defaultMessage="You pay" id="month-payments-list.you-pay" />
			);
	}
}
