import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import imageStatements from 'url:../../static/shapes/upload-statements.png';

import { Upload } from '../../../shared/components/upload';
import { trpc } from '../../../shared/trpc/client';
import { uploadBankStatementsOptions } from '../../../shared/upload-bank-statements';

import { Button, Spacing, Text } from '../../../base-ui/components';
import { colors } from '../../../base-ui/variables';

import { useUserData } from '../../contexts/user-data-context';
import { links } from '../../Router/paths';

export function UploadBankStatementsApp() {
	const { user } = useUserData();
	const navigate = useNavigate();
	const { home } = useUserData();

	const [searchParams] = useSearchParams();
	const accountId = searchParams.get('ref') ?? home?.accountToVerify?.id;

	const utils = trpc.useUtils();

	if (!home) return null;
	if (!user || !accountId) {
		return <Navigate to={links.DEFAULT.ROOT} />;
	}

	return (
		<>
			<img src={imageStatements} alt="" width="420px" height="250px" />
			<Upload
				{...uploadBankStatementsOptions(user.userId, accountId)}
				header={
					<div style={{ marginTop: '25px' }}>
						<Text type="title" center>
							<FormattedMessage
								defaultMessage="Upload statements"
								id="upload-bank-statements-app.upload-statements"
							/>
						</Text>
						<Spacing $size="s" />
						<Text center>
							<FormattedMessage
								defaultMessage="Please upload 3 months worth of bank statements that include your payroll."
								id="upload-bank-statements-app.upload-instructions"
							/>
						</Text>
						<Spacing $size="l" />
					</div>
				}
				buttonComp={Button}
				backButton={
					<Button
						type="link"
						onClick={() => {
							utils.user.home.invalidate(undefined);
							navigate(links.ACCOUNT.BANK_VERIFICATION_PENDING);
						}}
					>
						<FormattedMessage defaultMessage="Continue" id="common.continue" />
					</Button>
				}
				dragAndDropText={
					<p
						style={{
							padding: '5px 10px 10px 5px',
							textAlign: 'center',
							fontSize: '2rem',
							color: colors.teal,
							fontWeight: '600',
						}}
					>
						<FormattedMessage
							defaultMessage="Browse or {br} drag and drop your files here"
							id="upload-bank-statements-app.drag-and-drop-text"
						/>
					</p>
				}
			/>
		</>
	);
}
