import React from 'react';
import { FormattedDate, FormattedMessage, FormattedNumber } from 'react-intl';
import { Navigate } from 'react-router-dom';

import { PaymentMethod } from '../../../../../functions/src/shared/payment-transaction';

import { Button, Footer, Spacing, Text } from '../../../base-ui/components';

import { BackButton } from '../../components/back-button';
import { PaymentDatePanel } from '../../components/payment-date-panel/payment-date-panel';
import { useInstaMoney } from '../../contexts/InstaMoneyContext/InstaMoneyContext';
import { links } from '../../Router/paths';

export const InstaMoneyConfirmPaybackDate = () => {
	const {
		amount,
		tipAmount,
		use,
		paybackDate,
		setPaybackDate,
		paybackDateSince,
		paybackDateUntil,
		submit,
		submitting,
	} = useInstaMoney();

	if (!use) {
		return <Navigate to={links.ACCOUNT.INSTA_MONEY.USE} replace />;
	}

	if (!tipAmount) {
		return <Navigate to={links.ACCOUNT.INSTA_MONEY.TIP} replace />;
	}

	return (
		<>
			<BackButton to={links.ACCOUNT.INSTA_MONEY.TIP} />

			<Spacing $size="l" />
			<Text type="title">
				<FormattedMessage
					defaultMessage="Confirm your payback date"
					id="insta-money-choose-payback-date.confirm-payback-date"
				/>
			</Text>

			<Spacing $size="m" />

			<PaymentDatePanel
				date={paybackDate}
				canChangeDateSince={paybackDateSince}
				canChangeDateUntil={paybackDateUntil}
				handleUpdateSelect={setPaybackDate}
				label={
					<FormattedMessage
						defaultMessage="You pay for cash advance{tipAmount, select, 0 {} other { + tip}}"
						id="insta-money-confirm-payback-date.pay-for-cash-advance"
						values={{ tipAmount }}
					/>
				}
				amount={
					<FormattedNumber
						value={amount + Math.abs(parseInt(tipAmount))}
						style="currency"
						currency="CAD"
						maximumFractionDigits={0}
					/>
				}
				testIdEdit="instamoney-choose-payback-date-edit"
				paymentMethod={PaymentMethod.Eft}
			/>

			<Spacing $size="m" />
			<Text type="small">
				<FormattedMessage
					defaultMessage="Your payback is scheduled for the {paybackDate}. You will automatically pay back on this date from your bank account."
					id="insta-money-confirm-payback-date.payback-scheduled"
					values={{
						paybackDate: <FormattedDate value={paybackDate} year="numeric" month="long" day="2-digit" />,
					}}
				/>
			</Text>

			<Spacing $size="xl" />
			<Footer>
				<Button
					onClick={submit}
					disabled={submitting}
					htmlType="submit"
					testId="instamoney-choose-payback-date-button-continue"
				>
					<FormattedMessage defaultMessage="Continue" id="common.continue" />
				</Button>
			</Footer>
		</>
	);
};
