import React, { ForwardedRef, forwardRef, HTMLAttributes, ReactNode } from 'react';
import styled from 'styled-components';

import { colors } from '../../variables';
import { TextDiv } from '../Text/Text';

export type RadioProps = HTMLAttributes<HTMLInputElement> & {
	value?: string;
	label: ReactNode | string;
	secondaryLabel?: ReactNode | string;
	type?: 'radio' | 'checkbox';
	checked?: boolean;
	paper?: boolean;
	testId?: string;
};

export const RadioItem = styled.label<{ paper?: boolean; secondaryLabel?: boolean }>`
	display: flex;
	align-items: flex-start;

	cursor: pointer;
	position: relative;

	${({ paper, secondaryLabel }) =>
		paper &&
		`background: ${colors.white};
		border-radius: 2rem;
		box-shadow: 0px 5px 20px rgba(23, 23, 23, 0.08);
		flex-direction: row-reverse;
		justify-content: space-between;
		padding: 2rem;
		gap: 1rem;
		align-items: ${secondaryLabel ? 'baseline' : 'center'};

		@media (max-width: 450px) {
			padding: 1.5rem;
		}`}
`;

export const RadioButton = styled.div<{ $paper?: boolean }>`
	width: 2rem;
	min-width: 2rem;
	height: 2rem;
	border-radius: 50%;
	border: solid 1px ${colors.green};
	margin-top: 5px;
	display: flex;
	align-items: center;
	justify-content: center;

	&:after {
		content: ' ';
		opacity: 0;

		border-radius: 50%;
		width: 10px;
		min-width: 10px;
		height: 10px;

		background-color: ${colors.green};
	}

	${({ $paper }) =>
		$paper &&
		`margin-top: 0;

		&:before {
			content: ' ';
			position: absolute;
			inset: 0;
			border-radius: 2rem;
			border: solid 1px transparent;
			pointer-events: none;
		}`}
`;

const RadioInput = styled.input`
	display: none;

	&:checked {
		& ~ ${RadioButton}:after {
			opacity: 100;
		}
		& ~ ${RadioButton}:before {
			border-color: ${colors.green};
		}
	}
`;

const RadioSecondaryLabel = styled(TextDiv).attrs({ type: 'small' })``;

export const RadioLabel = styled(TextDiv)<{ paper?: boolean }>`
	margin-left: 1rem;
	${({ paper }) =>
		paper &&
		`font-weight: 700;
		color: ${colors.green};
	`}

	& ${RadioSecondaryLabel} {
		margin-top: 1rem;
	}
`;

export const Radio = forwardRef(
	(
		{
			label,
			secondaryLabel,
			type = 'radio',
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			children, // removed to prevent errors, input does not have children
			paper,
			testId,
			...inputProps
		}: RadioProps,
		ref: ForwardedRef<HTMLInputElement>,
	) => {
		return (
			<RadioItem paper={paper} secondaryLabel={Boolean(secondaryLabel)} data-testid={testId}>
				<RadioInput type={type} {...inputProps} ref={ref} />
				<RadioButton $paper={paper} />
				{label && (
					<RadioLabel paper={paper}>
						{label}
						{secondaryLabel && (
							<>
								<br />
								<RadioSecondaryLabel>{secondaryLabel}</RadioSecondaryLabel>
							</>
						)}
					</RadioLabel>
				)}
			</RadioItem>
		);
	},
);

Radio.displayName = 'Radio';
