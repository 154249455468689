'use strict';

var TRPCError = require('../error/TRPCError.js');
var utils = require('../utils.js');
var tracked = require('./tracked.js');
var createDeferred = require('./utils/createDeferred.js');
var createReadableStream = require('./utils/createReadableStream.js');

const SERIALIZED_ERROR_EVENT = 'serialized-error';
/**
 *
 * @see https://html.spec.whatwg.org/multipage/server-sent-events.html
 */ function sseStreamProducer(opts) {
    const stream = createReadableStream.createReadableStream();
    stream.controller.enqueue({
        comment: 'connected'
    });
    const { serialize =(v)=>v  } = opts;
    const ping = {
        enabled: opts.ping?.enabled ?? false,
        intervalMs: opts.ping?.intervalMs ?? 1000
    };
    utils.run(async ()=>{
        const iterator = opts.data[Symbol.asyncIterator]();
        const closedPromise = stream.cancelledPromise.then(()=>'closed');
        const maxDurationPromise = createDeferred.createTimeoutPromise(opts.maxDurationMs ?? Infinity, 'maxDuration');
        let nextPromise = iterator.next();
        while(true){
            const pingPromise = createDeferred.createTimeoutPromise(ping.enabled ? ping.intervalMs : Infinity, 'ping');
            const next = await Promise.race([
                nextPromise.catch(TRPCError.getTRPCErrorFromUnknown),
                pingPromise.promise,
                closedPromise,
                maxDurationPromise.promise
            ]);
            pingPromise.clear();
            if (next === 'closed') {
                break;
            }
            if (next === 'maxDuration') {
                break;
            }
            if (next === 'ping') {
                stream.controller.enqueue({
                    comment: 'ping'
                });
                continue;
            }
            if (next instanceof Error) {
                const data = opts.formatError ? opts.formatError({
                    error: next
                }) : null;
                stream.controller.enqueue({
                    event: SERIALIZED_ERROR_EVENT,
                    data: JSON.stringify(serialize(data))
                });
                break;
            }
            if (next.done) {
                break;
            }
            const value = next.value;
            const chunk = tracked.isTrackedEnvelope(value) ? {
                id: value[0],
                data: value[1]
            } : {
                data: value
            };
            if ('data' in chunk) {
                chunk.data = JSON.stringify(serialize(chunk.data));
            }
            stream.controller.enqueue(chunk);
            if (opts.emitAndEndImmediately) {
                // end the stream in the next tick so that we can send a few more events from the queue
                setTimeout(maxDurationPromise.resolve, 1);
            }
            nextPromise = iterator.next();
        }
        maxDurationPromise.clear();
        await iterator.return?.();
        try {
            stream.controller.close();
        } catch  {}
    }).catch((error)=>{
        return stream.controller.error(error);
    });
    return stream.readable.pipeThrough(new TransformStream({
        transform (chunk, controller) {
            if ('event' in chunk) {
                controller.enqueue(`event: ${chunk.event}\n`);
            }
            if ('data' in chunk) {
                controller.enqueue(`data: ${chunk.data}\n`);
            }
            if ('id' in chunk) {
                controller.enqueue(`id: ${chunk.id}\n`);
            }
            if ('comment' in chunk) {
                controller.enqueue(`: ${chunk.comment}\n`);
            }
            controller.enqueue('\n\n');
        }
    }));
}
/**
 * @see https://html.spec.whatwg.org/multipage/server-sent-events.html
 */ function sseStreamConsumer(opts) {
    const { deserialize =(v)=>v  } = opts;
    const eventSource = opts.from;
    const stream = createReadableStream.createReadableStream();
    const transform = new TransformStream({
        async transform (chunk, controller) {
            const data = deserialize(JSON.parse(chunk.data));
            if (chunk.type === SERIALIZED_ERROR_EVENT) {
                controller.enqueue({
                    ok: false,
                    error: data
                });
                return;
            }
            // console.debug('transforming', chunk.type, chunk.data);
            const def = {
                data
            };
            if (chunk.lastEventId) {
                def.id = chunk.lastEventId;
            }
            controller.enqueue({
                ok: true,
                data: def
            });
        }
    });
    eventSource.addEventListener('message', (msg)=>{
        stream.controller.enqueue(msg);
    });
    eventSource.addEventListener(SERIALIZED_ERROR_EVENT, (msg)=>{
        stream.controller.enqueue(msg);
    });
    eventSource.addEventListener('error', (cause)=>{
        if (eventSource.readyState === EventSource.CLOSED) {
            stream.controller.error(cause);
        }
    });
    const readable = stream.readable.pipeThrough(transform);
    return {
        [Symbol.asyncIterator] () {
            const reader = readable.getReader();
            const iterator = {
                async next () {
                    const value = await reader.read();
                    if (value.done) {
                        return {
                            value: undefined,
                            done: true
                        };
                    }
                    return {
                        value: value.value,
                        done: false
                    };
                },
                async return () {
                    reader.releaseLock();
                    return {
                        value: undefined,
                        done: true
                    };
                }
            };
            return iterator;
        }
    };
}
const sseHeaders = {
    'Content-Type': 'text/event-stream',
    'Cache-Control': 'no-cache, no-transform',
    'X-Accel-Buffering': 'no',
    Connection: 'keep-alive'
};

exports.sseHeaders = sseHeaders;
exports.sseStreamConsumer = sseStreamConsumer;
exports.sseStreamProducer = sseStreamProducer;
