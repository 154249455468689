import React from 'react';
import { useIntl } from 'react-intl';

import { ProductPaymentsHistory } from '../../components/product-payment-history';

export const AccountCreditBuilderHistory = () => {
	const intl = useIntl();

	return (
		<ProductPaymentsHistory
			title={intl.formatMessage({
				id: 'account-credit-builder-history.title',
				defaultMessage: 'CreditBuilder history',
			})}
			type="credit-reporting"
		/>
	);
};
