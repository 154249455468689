import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Button, Divider, Footer, Spacing, Text } from '../../base-ui/components';

import { ConnectBankList } from '../components/connect-bank-list';

type Props = {
	onContinue: () => void;
};

export const ConnectCard = ({ onContinue }: Props) => {
	const intl = useIntl();

	return (
		<>
			<Spacing $size="s" />
			<Text type="title">
				<FormattedMessage defaultMessage="Provide card details" id="connect-card.provide-card-details" />
			</Text>
			<Spacing $size="m" />
			<Text>
				<FormattedMessage
					defaultMessage="We use industry leading security platforms to keep Visa and Mastercard details secure."
					id="connect-card.security-message"
				/>
			</Text>
			<Spacing $size="s" />
			<Divider />
			<Spacing $size="s" />

			<ConnectBankList
				items={[
					{
						icon: 'automatedPayments',
						title: intl.formatMessage({
							defaultMessage: 'Safe payments',
							id: 'connect-card.safe-payments',
						}),
						subtitle: intl.formatMessage({
							defaultMessage: 'Payments are real time and are immediately processed.',
							id: 'connect-card.payments-subtitle',
						}),
					},
					{
						icon: 'overdraftAlerts',
						title: intl.formatMessage({
							defaultMessage: 'Overdraft free',
							id: 'connect-card.overdraft-free',
						}),
						subtitle: (
							<FormattedMessage
								defaultMessage="With debit and credit card payments you're never over-drafted."
								id="connect-card.overdraft-subtitle"
							/>
						),
					},
					{
						icon: 'securityAndPrivacy',
						title: intl.formatMessage({
							defaultMessage: 'Security and privacy',
							id: 'connect-bank-action.list-item3-title',
						}),
						subtitle: intl.formatMessage({
							defaultMessage: 'We never store your banking details.',
							id: 'connect-card.privacy-subtitle',
						}),
					},
				]}
			/>

			<Spacing $size="l" />
			<Footer>
				<Button
					htmlType="button"
					type="large"
					onClick={onContinue}
					testId="onboarding-cb-card-details-continue-button"
				>
					<FormattedMessage defaultMessage="Continue" id="common.continue" />
				</Button>
			</Footer>
		</>
	);
};
