import React from 'react';

import { useUserData } from '../../contexts/hooks';
import { ReconnectFlinks } from '../flinks-reconnect';

export function ReconnectBank() {
	const { home } = useUserData();
	const item = home?.item;
	if (!item) {
		return <></>;
	}
	return <ReconnectFlinks itemId={item.id} />;
}
