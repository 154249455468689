import { getEnvironment } from './environment';

export const dataLayer = {
	push(payload: unknown) {
		if (getEnvironment() !== 'production') {
			// eslint-disable-next-line no-console
			console.log('dataLayer.push', payload);
		}

		window.dataLayer = window.dataLayer || [];

		try {
			window.dataLayer.push(payload);
		} catch (error) {
			// eslint-disable-next-line no-console
			console.error('Failed to push to dataLayer', payload, error);
		}
	},
};
