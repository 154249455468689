import React, { ReactNode, useCallback, useEffect } from 'react';
import styled from 'styled-components';

import { useInterface } from '../../../app/contexts/hooks';
import { colors } from '../../variables';
import { Icon } from '../Icon/Icon';

export const Background = styled.div<{ $visible: boolean }>`
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background-color: ${({ $visible }) => ($visible ? 'rgba(23, 23, 23, 0.4)' : 'rgba(23, 23, 23, 0)')};
	pointer-events: ${({ $visible }) => ($visible ? 'auto' : 'none')};
	z-index: 1000;
	transition: background-color 300ms ease-in-out;
`;

const Wrapper = styled.div<{ $visible: boolean }>`
	background: ${colors.white};
	max-width: 54rem;
	min-height: 20rem;
	border-radius: 2rem;
	position: relative;
	left: 50%;
	top: 50%;
	padding: 2rem;
	transform: translate(-50%, -50%);
	max-height: 100vh;
	overflow: auto;
	opacity: ${({ $visible }) => ($visible ? '1' : '0')};
	transition: opacity 150ms ease-in-out;

	@media (max-width: 450px) {
		transition: transform 300ms ease-in-out;
		opacity: 1;
		transform: ${({ $visible }) => ($visible ? 'translate(0, -100%)' : 'translate(0, 0)')};
		width: 100%;
		top: 100%;
		left: 0;
		/* transform: translate(0, -100%); */
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
		padding: 6rem 2rem 12rem;
	}
`;
const MobileCloser = styled.div`
	display: none;

	&:after {
		display: block;
		content: '';
		width: 3rem;
		height: 0.4rem;
		background: rgba(23, 23, 23, 0.5);
		border-radius: 2px;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
	}

	@media (max-width: 450px) {
		display: block;
		width: 100%;
		height: 6rem;
		position: absolute;
		top: 0;
		left: 0;
	}
`;

const IconWrap = styled.div`
	position: absolute;
	top: 10px;
	right: 10px;
	cursor: pointer;
	@media (max-width: 450px) {
		display: none;
	}
`;

type ModalProps = {
	component: ReactNode;
	$visible: boolean;
};

let lastTouchY = 0;

export const ModalDialog = ({ component, $visible }: ModalProps) => {
	const { closeModal } = useInterface();

	const handleClose = () => {
		closeModal();
		lastTouchY = 0;
	};
	const escFunction = useCallback((event) => {
		if (event.key === 'Escape') {
			handleClose();
		}
	}, []);

	const handleDrag = (e) => {
		const currentY = e.touches[0].clientY;

		if (currentY > lastTouchY) {
			closeModal();
		}

		lastTouchY = currentY;
	};

	useEffect(() => {
		if ($visible) {
			document.addEventListener('keydown', escFunction, false);
			document.addEventListener('touchmove', handleDrag, false);
		} else {
			document.removeEventListener('keydown', escFunction, false);
			document.removeEventListener('touchmove', handleDrag, false);
		}

		return () => {
			document.removeEventListener('keydown', escFunction, false);
			document.removeEventListener('touchmove', handleDrag, false);
		};
	}, [$visible]);

	return (
		<Background onClick={handleClose} $visible={Boolean($visible)}>
			<Wrapper onClick={(e) => e.stopPropagation()} $visible={Boolean($visible)}>
				<IconWrap onClick={handleClose}>
					<Icon icon="Close" />
				</IconWrap>
				<MobileCloser onClick={handleClose} />
				{component}
			</Wrapper>
		</Background>
	);
};
