import styled from 'styled-components';

import { colors } from '../../../base-ui/variables';

export const ContainerContent = styled.div`
	position: relative;
	z-index: 1;
`;

export const ContainerBackground = styled.div`
	position: absolute;
	z-index: 0;
	top: 0;
	right: 0;
	left: 0;
	height: 41rem;
	background-color: ${colors.blue};
`;
