import React from 'react';
import { subYears } from 'date-fns';
import { useIntl } from 'react-intl';

import { FormattedDateInput, FormattedDateInputProps } from './formatted-date-input';

export const DateOfBirthInput = (props: Omit<FormattedDateInputProps, 'placeholder'>) => {
	const intl = useIntl();
	return (
		<FormattedDateInput
			{...props}
			placeholder={intl.formatMessage({
				defaultMessage: 'Date of Birth',
				id: 'date-of-birth-input.aria-label',
			})}
			selectedDay={subYears(new Date(), 18)}
			changeSince={subYears(new Date(), 120)}
			changeUntil={subYears(new Date(), 18)}
			handleSelect={props.handleSelect}
		/>
	);
};
