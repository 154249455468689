import React, { useEffect, useMemo, useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { FirebaseError } from 'firebase/app';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import styled from 'styled-components';
import z from 'zod';

import { BusinessEventType } from '../../../../../functions/src/shared/business-events';

import { CopyToClipboardButton } from '../../../shared/components/copy-to-clipboard-button';
import { getEnvironment } from '../../../shared/environment';
import { zodPhoneVerificationCodeSchema } from '../../../shared/zod-schemas';

import {
	Alert,
	AlertText,
	AlertTitle,
	Button,
	Center,
	Footer,
	FormInput,
	Spacing,
	Text,
} from '../../../base-ui/components';

import { ChangePhoneNumber } from '../../components/change-phone-number';
import { firebaseErrors } from '../../contexts/Firebase/firebaseApi';
import { useFirebase, useUserData } from '../../contexts/hooks';
import { useAsyncHandler } from '../../utils/hooks/useAsyncHandler';
import { useTrackOnboardingEvent } from '../../utils/track-onboarding-event';

const Panel = styled.div`
	margin-bottom: 3rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: 44.6rem;
`;

export const RegistrationVerifyPhone = () => {
	const { handlers } = useFirebase();
	const { user } = useUserData();
	const [codeResent, setCodeResent] = useState(false);
	const [changePhoneNumber, setChangePhoneNumber] = useState(false);
	const intl = useIntl();

	const schema = useMemo(
		() =>
			z.object({
				code: zodPhoneVerificationCodeSchema(intl),
			}),
		[intl],
	);

	type Schema = z.infer<typeof schema>;
	const form = useForm<Schema>({ resolver: zodResolver(schema) });

	const [verifyError, setVerifyError] = useState<unknown>();

	const [handleEnrollVerifySMSCode, { loading: verifyLoading }] = useAsyncHandler(handlers.handleEnrollVerifySMSCode);
	const [
		handleSendEnrollVerificationSms,
		{ data: codeSent, clearData: clearCodeSent, loading: enrollLoading, error: enrollError },
	] = useAsyncHandler(handlers.handleSendEnrollVerificationSms);

	const trackOnboardingEvent = useTrackOnboardingEvent();

	useEffect(() => trackOnboardingEvent.mutate({ type: BusinessEventType.OnboardingSmsVerificationStart }), []);

	const handleSubmit = ({ code }: Schema) => {
		setVerifyError(undefined);
		setCodeResent(false);
		handleEnrollVerifySMSCode(code.toString())
			.then(async () => {
				await trackOnboardingEvent.mutateAsync({ type: BusinessEventType.OnboardingSmsVerificationEnd });
			})
			.catch((error) => {
				if (error.code === firebaseErrors.ERROR_CODE_MULTI_FACTOR_INVALID_CODE) {
					return form.setError('code', {
						message: intl.formatMessage({
							defaultMessage: 'Verification code is invalid',
							id: 'verify-phone.verification-code-is-invalid',
						}),
					});
				}

				setVerifyError(error);
			});
	};

	const handleSendVerificationCode = () => {
		if (user?.phone) {
			handleSendEnrollVerificationSms(user.phone);
		}
	};

	const handleResendCode = () => {
		setCodeResent(true);
		handleSendVerificationCode();
	};

	const formattedPhoneNumber = user?.phone ? formatPhoneNumberIntl(user.phone) : '';

	if (changePhoneNumber) {
		return (
			<ChangePhoneNumber
				onBack={() => {
					clearCodeSent();
					setChangePhoneNumber(false);
				}}
			/>
		);
	}

	return (
		<Center>
			<Panel>
				<Text type="title" center>
					<FormattedMessage
						defaultMessage="Verify phone number"
						id="registration-verify-phone.verify-phone-number"
					/>
				</Text>
				<Spacing $size="l" />
				<Text center>
					{codeSent ? (
						<FormattedMessage
							defaultMessage={`A code was sent to phone number {formattedPhoneNumber}. Please enter the code here once it arrives.`}
							id="registration-verify-phone.code-sent"
							values={{ formattedPhoneNumber }}
						/>
					) : (
						<FormattedMessage
							defaultMessage={`To keep your account secure, please verify your number by entering the code that will be sent to your phone number {formattedPhoneNumber}.`}
							id="registration-verify-phone.verify-number"
							values={{ formattedPhoneNumber }}
						/>
					)}
				</Text>
				{getEnvironment() !== 'production' && (
					<Text>
						Test code: 123123 <CopyToClipboardButton text="123123" />
					</Text>
				)}
			</Panel>

			{Boolean(enrollError || verifyError) && (
				<Alert>
					<AlertTitle>
						<FormattedMessage defaultMessage="An error ocurred" id="alert-error.an-error-occurred" />
					</AlertTitle>
					{(enrollError as FirebaseError | undefined)?.code?.includes('auth/invalid-phone-number') ? (
						<AlertText>
							<FormattedMessage
								defaultMessage="Your phone number is invalid. Please change it to a valid one."
								id="registration-verify-phone.invalid-phone-number"
							/>
						</AlertText>
					) : (
						<AlertText>
							<FormattedMessage
								defaultMessage="Something went wrong at our end. Don't worry, we'll fix soon."
								id="forgot-password.error-text"
							/>
						</AlertText>
					)}
				</Alert>
			)}

			<FormProvider {...form}>
				<form onSubmit={form.handleSubmit(handleSubmit)}>
					{Boolean(codeSent) && (
						<>
							<FormInput
								name="code"
								placeholder={intl.formatMessage({
									defaultMessage: 'Verification code',
									id: 'verify-phone.verification-code',
								})}
								inputMode="numeric"
								testId="onboarding-verify-phone-input-code"
							/>
							<Footer>
								<Button
									htmlType="submit"
									disabled={verifyLoading}
									testId="onboarding-verify-phone-button-verify"
								>
									<FormattedMessage defaultMessage="Verify" id="common.verify" />
								</Button>
								<Button
									type="link"
									onClick={handleResendCode}
									disabled={enrollLoading || codeResent}
									testId="onboarding-verify-phone-button-resend"
								>
									{!enrollLoading && codeResent ? (
										<FormattedMessage
											defaultMessage="Verification code was resent"
											id="registration-verify-phone.code-resent"
										/>
									) : (
										<FormattedMessage
											defaultMessage="Resend the verification code"
											id="registration-verify-phone.resend-code"
										/>
									)}
								</Button>
								<Button type="link" onClick={() => setChangePhoneNumber(true)}>
									<FormattedMessage
										defaultMessage="Change phone number"
										id="registration.change-phone-number"
									/>
								</Button>
							</Footer>
						</>
					)}
				</form>
			</FormProvider>

			{!codeSent && (
				<Footer>
					<Button
						type="large"
						onClick={handleSendVerificationCode}
						disabled={enrollLoading}
						testId="onboarding-verify-phone-send-code-button"
					>
						<FormattedMessage defaultMessage="Send Code" id="registration-verify-phone.send-code" />
					</Button>

					<Button type="link" onClick={() => setChangePhoneNumber(true)}>
						<FormattedMessage defaultMessage="Change phone number" id="registration.change-phone-number" />
					</Button>
				</Footer>
			)}
		</Center>
	);
};
