import React from 'react';

import { CommonIconProps } from './types';

export const LeftArrow = ({ fill }: CommonIconProps) => {
	return (
		<svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M16.3327 6.99939H1.66602"
				stroke={fill}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M7.66602 0.99939L1.66602 6.99939L7.66602 12.9994"
				stroke={fill}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};
