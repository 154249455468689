import React, { PropsWithChildren } from 'react';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { createClient, queryClient, trpc } from './client';

type Props = PropsWithChildren<{
	passImpersonationHeader?: boolean;
}>;

export const TrpcProvider = ({ passImpersonationHeader, children }: Props) => {
	return (
		<trpc.Provider client={createClient({ passImpersonationHeader })} queryClient={queryClient}>
			<QueryClientProvider client={queryClient}>
				{children}
				<ReactQueryDevtools initialIsOpen={false} buttonPosition="bottom-left" />
			</QueryClientProvider>
		</trpc.Provider>
	);
};
