import React from 'react';
import { FormattedMessage } from 'react-intl';

import { triggerChatWidget } from '../../../shared/zendesk';

import { WarningModal } from '../../../base-ui/components';

type Props = {
	headerVariant: 'now' | 'early';
	toggleEdit: () => void;
};

export const PayTodayModal = ({ headerVariant, toggleEdit }: Props) => {
	return (
		<WarningModal
			headerText={
				headerVariant === 'early' ? (
					<FormattedMessage
						defaultMessage={`Do you want to pay early?`}
						id="pay-today-modal.header-text-early"
					/>
				) : (
					<FormattedMessage defaultMessage={`Do you want to pay now?`} id="pay-today-modal.header-text-now" />
				)
			}
			contentText={
				<>
					<FormattedMessage
						defaultMessage="You have two options! You can either send us an e-Transfer at {email} or you can {contactLink} to setup a pre-authorized debit payment that will be withdrawn from your connected bank account."
						id="pay-today-modal.content-text"
						values={{
							email: <a href="mailto:payments@myzenbase.com">payments@myzenbase.com</a>,
							contactLink: (
								<a onClick={() => triggerChatWidget()}>
									<FormattedMessage defaultMessage="contact us" id="pay-today-modal.contact-us" />
								</a>
							),
						}}
					/>
				</>
			}
			altButtonText={<FormattedMessage defaultMessage="Cancel" id="common.cancel" />}
			altButtonHandler={toggleEdit}
		/>
	);
};
