import React from 'react';
import { FormattedMessage } from 'react-intl';

import { useFlinksConnectForConnect } from '../../components/flinks-connect';
import { ConnectbankManuallyModal } from '../../components/modals/connectbank-manually-modal';
import { useBankOnboarding, useInterface } from '../../contexts/hooks';
import { ConnectBankAction } from '../../templates/connect-bank-action';

const ConnectBankFlinks = () => {
	const { setModal } = useInterface();
	const { links, variant } = useBankOnboarding();

	const { setIsOpen, isOpen, loadIframe } = useFlinksConnectForConnect({
		onSuccessLink: links.AWAITING_TRANSACTIONS,
		onErrorLink: links.CANNOT_VALIDATE_EARNINGS,
	});

	const handleOpenModal = () => {
		setModal(<ConnectbankManuallyModal links={links} variant={variant} />);
	};

	return isOpen ? (
		loadIframe()
	) : (
		<ConnectBankAction
			manuallyConnect={variant === 'custom-rent'}
			buttonText={
				<FormattedMessage defaultMessage="Instantly connect" id="connect-bank-flinks.instantly-connect" />
			}
			handleOnClick={() => {
				setIsOpen(true);
			}}
			handleOpenModal={handleOpenModal}
			variant={variant}
		/>
	);
};

export const ConnectBank = ConnectBankFlinks;
