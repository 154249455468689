import { QueryClient } from '@tanstack/react-query';
import { httpLink, splitLink } from '@trpc/client';
import { createTRPCReact, httpBatchLink } from '@trpc/react-query';
import superjson from 'superjson';

import type { AppRouter } from '../../../../functions/src/api/trpc/types';
import { publicConfig } from '../../../../functions/src/shared/config';

import { isInSuperadminMode } from '../../admin/hooks/use-admin-user';
import { getAuthToken } from '../../app/contexts/Firebase';
import { getImpersonatedUserId } from '../impersonation';

export * from '../../../../functions/src/api/trpc/types';

export const queryClient = new QueryClient();

export const trpc = createTRPCReact<AppRouter>();

type Options = {
	passImpersonationHeader?: boolean;
};

export const SKIP_BATCH_CONTEXT = {
	skipBatch: true,
};

const BASE_URL = '/api/v2/trpc';
const createHeadersCallback = (passImpersonationHeader?: boolean) => {
	return async () => {
		const headers: Record<string, string> = {};

		const token = await getAuthToken();
		if (token) {
			headers['Authorization'] = `Bearer ${token}`;
		}

		if (passImpersonationHeader) {
			const impersonateUserId = getImpersonatedUserId();
			if (impersonateUserId) {
				headers[publicConfig.adminImpersonatesUserHeader] = impersonateUserId;
			}
		}

		if (isInSuperadminMode()) {
			headers[publicConfig.superadminModeHeader] = 'on';
		}

		return headers;
	};
};

export const createClient = ({ passImpersonationHeader }: Options = {}) =>
	trpc.createClient({
		links: [
			splitLink({
				condition(op) {
					// check for context property `skipBatch`
					return op.context.skipBatch === true;
				},
				true: httpLink({
					url: BASE_URL,
					headers: createHeadersCallback(passImpersonationHeader),
					transformer: superjson,
				}),
				false: httpBatchLink({
					url: BASE_URL,
					headers: createHeadersCallback(passImpersonationHeader),
					transformer: superjson,
				}),
			}),
		],
	});
