import React, { PropsWithChildren } from 'react';
import { IdTokenResult, User } from 'firebase/auth';

import { UiAppName } from '../../../../../functions/src/shared/ui-app.schema';

import { RouterInput } from '../../../shared/trpc/client';

import { auth } from './firebaseApi';
import { FirebaseProvider } from './FirebaseContext';

export enum SignInMethods {
	email,
	google,
	fake,
	microsoft,
}
export type SignInOptions =
	| { method: SignInMethods.email; email: string; password: string }
	| { method: SignInMethods.google | SignInMethods.microsoft };

export type ActionParams = { continueUrl?: string };

export type AuthUser = User & { tokenResult?: IdTokenResult };

export interface FirebaseContextState {
	isUserAuthenticated: boolean;
	isEmailVerified: boolean;
	isPhoneEnrolled: boolean;
	authUser: AuthUser | null;
	authUserLoaded: boolean;
	handlers: {
		handleSignIn: (options: SignInOptions) => Promise<void>;
		handleSignUp: (
			registration: Omit<RouterInput['user']['create'], 'inviteId'> & { password: string },
			sendVerificationEmailParams?: ActionParams,
		) => Promise<void>;
		handleSignOut: () => Promise<void>;

		handleReSendEmailVerification: (params?: ActionParams) => Promise<boolean | void>;
		handleVerifyEmailCode: (code: string, continueUrl?: string) => Promise<boolean>;

		handleSignInVerifySMSCode: (code: string) => Promise<void>;
		handleReSendSmsCode: () => Promise<void>;

		handlePasswordResetRequest: (email: string, params?: ActionParams) => Promise<{ email: string } | void>;
		handlePasswordReset: (code: string, newPassword: string) => Promise<void>;

		handleSendEnrollVerificationSms: (phoneNumber: string) => Promise<boolean | void>;
		handleEnrollVerifySMSCode: (code: string) => Promise<void>;

		handleTokenRefresh: () => Promise<void>;

		handleReloadUser: () => Promise<void>;
	};
}

export type FirebaseProviderProps = PropsWithChildren<{
	appName: UiAppName;
	paths: {
		default: string;
		login: string;
		onLogin: string;
		onPhoneVerify: string;
		onRegistrationPhoneVerify: string;
		onRegistrationEmailVerify?: string;
		onRegistrationCompleted?: string;
	};
}>;

export const initialState: FirebaseContextState = {
	isUserAuthenticated: false,
	isEmailVerified: false,
	isPhoneEnrolled: false,
	authUser: null,
	authUserLoaded: false,
	handlers: {
		handleSignIn: async () => {
			console.error('context not initialized');
		},
		handleSignOut: async () => {
			console.error('context not initialized');
		},
		handleSignInVerifySMSCode: async () => {
			console.error('context not initialized');
		},
		handleSendEnrollVerificationSms: async () => {
			console.error('context not initialized');
		},
		handleEnrollVerifySMSCode: async () => {
			console.error('context not initialized');
		},
		handleSignUp: async () => {
			console.error('context not initialized');
		},
		handleReSendSmsCode: async () => {
			console.error('context not initialized');
		},
		handleReSendEmailVerification: async () => {
			console.error('context not initialized');
		},
		handleVerifyEmailCode: async () => {
			console.error('context not initialized');
			return false;
		},
		handlePasswordResetRequest: async () => {
			console.error('context not initialized');
		},
		handlePasswordReset: async () => {
			console.error('context not initialized');
		},
		handleTokenRefresh: async () => {
			console.error('context not initialized');
		},
		handleReloadUser: async () => {
			console.error('context not initialized');
		},
	},
};

export const AuthProvider = ({ children, ...props }: FirebaseProviderProps) => {
	return <FirebaseProvider {...props}>{children}</FirebaseProvider>;
};

export async function getAuthToken() {
	return await auth.currentUser?.getIdToken();
}

export const AuthContext = React.createContext(initialState);

export const useFirebase = () => {
	const context = React.useContext(AuthContext);
	if (context === undefined) {
		throw new Error('useFirebase must be used within a FirebaseProvider');
	}
	return context;
};
