import React from 'react';

export const AvenueLivingListIcon2 = () => {
	return (
		<svg width="46" height="45" viewBox="0 0 46 45" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M28.2977 25.5005H25.8535C25.5881 25.5005 25.3669 25.7106 25.3669 25.9871V30.1235C25.3669 30.3889 25.577 30.6101 25.8535 30.6101H28.2977C28.4304 30.6101 28.5521 30.5548 28.6406 30.4663L30.7087 28.3981C30.8967 28.2101 30.8967 27.9004 30.7087 27.7124L28.6406 25.6443C28.5521 25.5558 28.4304 25.5005 28.2977 25.5005Z"
				fill="#FFB9AA"
			/>
			<path
				d="M15.4776 19.1096H17.9218C18.1872 19.1096 18.4084 18.8995 18.4084 18.623V14.4866C18.4084 14.2212 18.1983 14 17.9218 14L15.4776 14.0111C15.3449 14.0111 15.2232 14.0664 15.1347 14.1548L13.0776 16.223C12.8896 16.411 12.8896 16.7207 13.0776 16.9087L15.1458 18.9658C15.2343 19.0543 15.3559 19.1096 15.4776 19.1096Z"
				fill="#1ECEC9"
			/>
			<path
				d="M29.7014 14.0093H27.1134C26.9807 14.0093 26.859 14.0646 26.7705 14.1531L13.7422 27.1925C13.6427 27.281 13.5984 27.4027 13.5984 27.5354V30.1233C13.5984 30.3888 13.8086 30.61 14.085 30.61H16.673C16.8057 30.61 16.9274 30.5547 17.0159 30.4662L30.0442 17.4157C30.1327 17.3273 30.188 17.2056 30.188 17.0729V14.496C30.188 14.2195 29.9668 14.0093 29.7014 14.0093Z"
				fill="#0A6375"
			/>
			<path
				d="M28.0448 5.81162C27.5457 5.63339 27.1465 5.49082 26.6474 5.31259C18.2845 2.88882 9.09481 7.82264 6.07216 15.9717C2.8784 24.2847 6.54323 34.8219 15.1842 38.3578C25.0871 42.457 36.216 37.7655 39.5523 27.4776C40.7357 23.8487 40.7784 20.2626 39.4094 16.8477C37.3703 11.8429 33.064 7.82911 28.0448 5.81162Z"
				stroke="#0A6375"
				strokeWidth="3"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};
