import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import onboardingComplete from 'url:../../static/shapes/onboarding-complete.png';

import { BusinessEventType } from '../../../../../functions/src/shared/business-events';

import { Box, Button, Footer, Spacing, Text } from '../../../base-ui/components';

import { useUserData } from '../../contexts/user-data-context';
import { Image } from '../../templates/onboarding-success';
import { useTrackOnboardingEvent } from '../../utils/track-onboarding-event';

export function CustomRentWaitlist() {
	const { home } = useUserData();
	const trackOnboardingEvent = useTrackOnboardingEvent();

	const propertyManagerName = home?.residency?.propertyManagerName ?? home?.residency?.landlordName;

	function onNext() {
		trackOnboardingEvent.mutate({
			type: [BusinessEventType.OnboardingCustomRentWaitlistEnd],
		});
	}

	return (
		<Box isColumn padding="xl">
			<Spacing $size="m" />
			<Image padding="5rem 3rem 0" textAlign="center" maxHeight="28rem">
				<img src={onboardingComplete} />
			</Image>

			<TextWrapper>
				<Text testId="custom-rent-waitlist-title" type="title" style={{ alignSelf: 'flex-start' }}>
					<FormattedMessage id="custom-rent-waitlist.title" defaultMessage="You are now on the waitlist!" />
				</Text>

				<Spacing $size="m" />

				<Text type="body" color="title" style={{ alignSelf: 'flex-start' }}>
					<FormattedMessage
						id="custom-rent-waitlist.subtitle"
						defaultMessage={`Your property manager currently doesn't offer custom rent payments that helps budgeting your rent payments. {br} {br} As soon as your property manager {propertyManagerName} enables this feature, you will be notified.`}
						values={{ br: <br />, propertyManagerName }}
					/>
				</Text>
				<Spacing $size="xl" />
				<Spacing $size="xl" />
			</TextWrapper>

			<Footer>
				<Button onClick={onNext} testId="custom-rent-waitlist-confirm">
					<FormattedMessage
						defaultMessage="Ok, I Understand"
						id="credit-builder-payment-completed-ok-button"
					/>
				</Button>
			</Footer>
		</Box>
	);
}

const TextWrapper = styled.div`
	padding-left: 3rem;
	@media (max-width: 450px) {
		padding-left: 1rem;
	}
`;
