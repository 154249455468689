import React from 'react';

export const AvenueLivingListIcon1 = () => {
	return (
		<svg width="46" height="45" viewBox="0 0 46 45" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M28.7264 5.81162C28.2273 5.63339 27.8281 5.49082 27.329 5.31259C18.9661 2.88882 9.77639 7.82264 6.75374 15.9717C3.55998 24.2847 7.22481 34.8219 15.8658 38.3578C25.7687 42.457 36.8976 37.7655 40.2339 27.4776C41.4173 23.8487 41.46 20.2626 40.091 16.8477C38.0518 11.8429 33.7456 7.82911 28.7264 5.81162Z"
				stroke="#0A6375"
				strokeWidth="3"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M28.4781 14.8414C28.0064 14.6729 27.5681 14.4107 27.1908 14.2759C21.6244 12.2881 17.75 14.4988 17.1808 16.0927C16.6786 17.4991 17.4911 19.6921 20.6045 20.8039C22.0196 21.3093 23.6296 21.5671 25.0448 22.0725C26.743 22.6789 28.4899 24.0427 27.9207 25.6366C27.385 27.1368 24.5667 28.7732 21.2646 27.594C19.472 26.9538 18.1025 25.7247 16.6722 24.3682"
				stroke="#1ECEC9"
				strokeWidth="3"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M24.6723 10C24.302 11.037 25.0724 12.4814 24.5909 13.8295"
				stroke="#1ECEC9"
				strokeWidth="3"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M21.1711 28.1343C20.9914 28.6375 20.7757 29.2414 20.56 29.8454C20.3444 30.4493 20.1287 31.0532 19.949 31.5564"
				stroke="#1ECEC9"
				strokeWidth="3"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};
