import React, { Fragment, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { AccountStatus } from '../../../../functions/src/shared/account';
import { PaymentProvider } from '../../../../functions/src/shared/payment-provider';

import { trpc } from '../../shared/trpc/client';

import { Box, Icon, Paper, PaperSection, Spacing } from '../../base-ui/components';

import { useUserData } from '../contexts/hooks';
import { ConfirmResidencyAddress } from './confirm-residency-address';

const isManuallyOnboardedUnverifiedAccount = (provider: PaymentProvider, status: AccountStatus) =>
	(provider === PaymentProvider.ModernTreasury || provider === PaymentProvider.Bmo) &&
	status === AccountStatus.Unverified;

type Props = {
	showBankInformation?: boolean;
};

export const Confirm = ({ showBankInformation = true }: Props) => {
	const { home } = useUserData();
	const { data: accountInfo } = trpc.user.findDefaultOrPendingAccount.useQuery();

	return (
		<Paper>
			{accountInfo && showBankInformation && (
				<PaperSection
					label={
						accountInfo.provider === PaymentProvider.ZumRails ? (
							<FormattedMessage defaultMessage="Debit Card Details" id="confirm.debit-card-details" />
						) : (
							<FormattedMessage defaultMessage="Bank Information" id="account.bank-info" />
						)
					}
				>
					**** {accountInfo.mask}
					<br />
					{accountInfo.bankName}
					{isManuallyOnboardedUnverifiedAccount(accountInfo.provider, accountInfo.status) && (
						<>
							<br />
							<small>
								<FormattedMessage
									defaultMessage="Verification Pending"
									id="confirm.verification-pending"
								/>
							</small>
						</>
					)}
				</PaperSection>
			)}
			<ConfirmResidencyAddress home={home} />
		</Paper>
	);
};

type ListItem = { label: ReactNode; testId?: string };

export const ConfirmList = ({ items }: { items: ListItem[] }) => {
	return (
		<ul>
			{items.map(({ label, testId }, i) => {
				return (
					<Fragment key={i}>
						<Box verticalCenter>
							<StyledIcon icon="CheckmarkCircle" />
							<ListText data-testid={testId}>{label}</ListText>
						</Box>
						<Spacing $size="m" />
					</Fragment>
				);
			})}
		</ul>
	);
};

const StyledIcon = styled(Icon)`
	flex-shrink: 0;
	height: 2.4rem;
	width: 2.4rem;
	font-size: 2.4rem;
	margin-right: 2rem;
	@media (max-width: 450px) {
		margin-right: 1rem;
	}
`;

const ListText = styled.div`
	font-size: 1.8rem;
	@media (max-width: 450px) {
		font-size: 1.4rem;
	}
`;
