import React from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { PropertyManagerCreditBuilder } from '../../../../../functions/src/shared/landlords';

import { Button, Center, Icon, Paper, PaperSection, Spacing, Text, WarningModal } from '../../../base-ui/components';
import { colors } from '../../../base-ui/variables';

import { ContainerBackground, ContainerContent } from '../../components/app-container';
import { CloseButton } from '../../components/close-button';
import { useFirebase, useInterface } from '../../contexts/hooks';
import { useUserData } from '../../contexts/user-data-context';
import { links } from '../../Router/paths';
import { OneOffChargesEnabledDialog } from '../../templates/one-off-charges-enabled';
import { LogoutIcon } from './logout-icon';

const Avatar = styled.div`
	width: 10rem;
	height: 10rem;
	min-height: 10rem;

	border-radius: 50%;
	border: solid 0.4rem ${colors.white};
	background-color: ${colors.pink};
	box-shadow: 0px 5px 20px rgba(23, 23, 23, 0.08);

	font-size: 4rem;
	font-weight: 700;
	color: ${colors.white};

	display: flex;
	align-items: center;
	justify-content: center;

	margin: 0 auto 1.5rem;
`;

const ProductCard = styled(Paper)`
	padding: 2.5rem;
	flex-direction: row;
	align-items: center;
`;

const ProductCircleIcon = styled(Icon)`
	width: 4rem;
	height: 4rem;
	font-size: 4rem;
	margin-right: 2rem;
`;

export const AccountSettings = () => {
	const {
		handlers: { handleSignOut },
	} = useFirebase();

	const navigate = useNavigate();

	const { setModal, closeModal } = useInterface();

	const { user, home, customRentSupported } = useUserData();

	if (!user) return <></>;

	const fullName = `${user?.firstName || ''} ${user?.lastName || ''}`;
	const avatar = `${user?.firstName[0] || ''}${user?.lastName[0] || ''}`;

	const changeBankAccount = () => {
		setModal(
			<WarningModal
				headerText={
					<FormattedMessage
						defaultMessage="Change your bank account?"
						id="account-settings.change-bank-account.header"
					/>
				}
				contentText={
					<FormattedMessage
						defaultMessage="Any paychecks in this account that are validated and qualify for cash advance will be lost. Please confirm if you would like to change the linked account."
						id="account-settings.change-bank-account.content"
					/>
				}
				buttonText={<FormattedMessage defaultMessage="Confirm" id="common.confirm" />}
				buttonHandler={() => {
					navigate(links.ACCOUNT.BANK.CONNECT_BANK);
					closeModal();
				}}
			/>,
		);
	};

	const oneOffChargesEnabledDialog = () => {
		setModal(<OneOffChargesEnabledDialog onClose={() => closeModal()} />);
	};

	const handleSignOutClick = async () => {
		await handleSignOut();
		window.location.href = '/';
	};

	const plaidItemReconnectRequired = home?.item?.reconnectRequired;

	const creditBuilderSupported = home?.landlord?.creditBuilder !== PropertyManagerCreditBuilder.Disabled;

	return (
		<>
			<ContainerContent>
				<CloseButton to={links.ACCOUNT.DASHBOARD} />

				<Avatar>{avatar}</Avatar>

				<Text type="title" center>
					{fullName}
				</Text>
				<Spacing $size="l" />

				<Paper>
					{/* name */}
					<PaperSection label={<FormattedMessage defaultMessage="Name" id="account-settings.name.label" />}>
						{fullName}
					</PaperSection>

					{/* location */}
					{Boolean(home?.residency) && (
						<PaperSection
							label={<FormattedMessage defaultMessage="Location" id="account-settings.location.label" />}
							labelAction={
								<Link to={links.ACCOUNT.RESIDENCY.SELECT_PROPERTY}>
									<Icon icon="Edit" />
								</Link>
							}
						>
							{home?.residency?.unitAddress || home?.residency?.streetAddress}
							<br />
							{home?.residency?.city}, {home?.residency?.state}, {home?.residency?.postalCode}
						</PaperSection>
					)}

					{/* bank info */}
					<PaperSection
						label={<FormattedMessage defaultMessage="Bank Information" id="account.bank-info" />}
						labelAction={<Icon onClick={changeBankAccount} icon="Edit" />}
					>
						**** {home?.account?.mask}
						<br />
						{home?.account?.bankName}
					</PaperSection>

					{/* email */}
					<PaperSection
						label={<FormattedMessage defaultMessage="E-mail" id="account-settings.email.label" />}
					>
						{user?.email}
					</PaperSection>

					{/* phone number */}
					<PaperSection
						label={
							<FormattedMessage defaultMessage="Mobile Phone Number" id="account-settings.phone.label" />
						}
					>
						{user?.phone}
					</PaperSection>

					{/* rent */}
					{home?.activeRentConfig && (
						<>
							<PaperSection
								label={
									<FormattedMessage
										defaultMessage="Rent amount"
										id="account-settings.rent-amount.label"
									/>
								}
								labelAction={
									<Link to={links.ACCOUNT.CHANGE_RENT_AMOUNT}>
										<Icon icon="Edit" />
									</Link>
								}
							>
								<FormattedNumber
									value={home.activeRentConfig.rentAmountCents / 100}
									style="currency"
									currency="CAD"
									maximumFractionDigits={0}
								/>
							</PaperSection>
							<PaperSection
								label={
									<FormattedMessage
										defaultMessage="Non-recurring charges"
										id="account-settings.non-recurring-charges.label"
									/>
								}
								divider={false}
								labelAction={<Icon onClick={oneOffChargesEnabledDialog} icon="Edit" />}
							>
								{home.activeRentConfig.oneOffChargesEnabled ? (
									<FormattedMessage
										defaultMessage="Added"
										id="account-settings.non-recurring-charges.added"
									/>
								) : (
									<FormattedMessage
										defaultMessage="Not Added"
										id="account-settings.non-recurring-charges.not-added"
									/>
								)}
							</PaperSection>
						</>
					)}
				</Paper>
				<Spacing $size="l" />

				{customRentSupported && (
					<Link to={links.ACCOUNT.HISTORY.CUSTOM_RENT}>
						<ProductCard>
							<ProductCircleIcon icon="CustomRentCircle" />
							<Text>
								<FormattedMessage
									defaultMessage="CustomRent history"
									id="account-custom-rent-history.title"
								/>
							</Text>
						</ProductCard>
					</Link>
				)}
				<Spacing $size="l" />

				{creditBuilderSupported && (
					<>
						<Link to={links.ACCOUNT.HISTORY.CREDIT_BUILDER}>
							<ProductCard>
								<ProductCircleIcon icon="ConfigurationCircle" />
								<Text>
									<FormattedMessage
										defaultMessage="CreditBuilder history"
										id="account-credit-builder-history.title"
									/>
								</Text>
							</ProductCard>
						</Link>
						<Spacing $size="l" />
					</>
				)}

				<Link to={links.ACCOUNT.HISTORY.INSTA_MONEY}>
					<ProductCard>
						<ProductCircleIcon icon="InstaMoneyCircle" />
						<Text>
							<FormattedMessage defaultMessage="Advance history" id="account.advance-history" />
						</Text>
					</ProductCard>
				</Link>
				<Spacing $size="l" />

				{plaidItemReconnectRequired && (
					<Link to={links.ACCOUNT.BANK.RECONNECT_BANK}>
						<ProductCard>
							<ProductCircleIcon icon="Bank" />
							<Text>
								<FormattedMessage
									defaultMessage="Reconnect bank"
									id="account-settings.reconnect-bank"
								/>
							</Text>
						</ProductCard>
					</Link>
				)}
				<Spacing $size="m" />

				<Center>
					<Button type="link" onClick={handleSignOutClick}>
						<FormattedMessage defaultMessage="Log out" id="common.logout" /> <LogoutIcon icon="SignOut" />
					</Button>
				</Center>
			</ContainerContent>
			<ContainerBackground />
		</>
	);
};
