import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Paragraph, Text } from '../../../base-ui/components';

export const AgreementPad = () => {
	return (
		<Text>
			<h2>
				<FormattedMessage
					defaultMessage="Pre-Authorized Debit (PAD) Agreement"
					id="agreement-pad.pre-authorized-debit-agreement"
				/>
			</h2>

			<Paragraph>
				<FormattedMessage
					defaultMessage='In this pre-authorized debit ("PAD") authorization, the terms "ours", "us", and "we" mean Zenbase Inc. and its successors and assigns, and the terms "you" and "your" mean the customer signing below. The term "Line of Credit Agreement" means any loan agreement you are entering into, or have entered into, with us.'
					id="agreement-pad.pad-authorization-terms"
				/>
			</Paragraph>

			<Paragraph>
				<FormattedMessage
					defaultMessage='You authorize us to debit your bank account specified on the AutoPay authorization screen (the "Account") for regular recurring payments of the amounts arising under or pursuant to your Line of Credit Agreement, as such amounts are set forth in the Line of Credit Agreement. Regular recurring payments will be debited from your Account on the dates selected by you on {link}.'
					id="agreement-pad.authorize-debit"
					values={{
						link: <a href="https://app.myzenbase.com">app.myzenbase.com</a>,
					}}
				/>
			</Paragraph>

			<Paragraph>
				<FormattedMessage
					defaultMessage="You further grant us the authority to debit your Account for payments in variable amounts and/or one-time payments from time to time for payment of the amounts arising under or pursuant to the Line of Credit Agreement. We will obtain your authorization for any one-time or sporadic debits. At any time, you may authorize us to issue a sporadic PAD against your Account for payment of a specified amount in accordance with your instructions. You agree that, where the payment frequency is sporadic, a password or secret code or other electronic signature equivalent will be issued and, when used, will constitute valid authorization for us to debit your Account."
					id="agreement-pad.authorize-variable-payments"
				/>
			</Paragraph>

			<Paragraph>
				<FormattedMessage
					defaultMessage="This PAD agreement provides for the issuance of personal PADs."
					id="agreement-pad.personal-pads"
				/>{' '}
				<strong>
					<FormattedMessage
						defaultMessage="I hereby agree to waive pre-notification of (a) the date and the amount of the first PAD to be debited from my Account, (b) each PAD (in the case of variable amount payments) and (c) any changes in the amount of a PAD."
						id="agreement-pad.waive-pre-notification"
					/>
				</strong>
			</Paragraph>

			<Paragraph>
				<FormattedMessage
					defaultMessage="You may cancel this PAD authorization by providing us with at least three (3)-day notice at {email}, before the next debit is scheduled from your Account. You may obtain a sample cancellation form, or further information on your right to cancel this PAD agreement, at your financial institution or by visiting {link}. You have certain recourse rights if any debit does not comply with this agreement. For example, you have the right to receive reimbursement for any debit that is not authorized or is not consistent with this PAD agreement. To obtain more information on your recourse rights, you may contact your financial institution or visit {link}."
					id="agreement-pad.cancel-authorization"
					values={{
						email: <a href="mailto:support@myzenbase.com">support@myzenbase.com</a>,
						link: <a href="https://www.payments.ca">www.payments.ca</a>,
					}}
				/>
			</Paragraph>

			<Paragraph>
				<FormattedMessage
					defaultMessage="Termination by you of this PAD authorization or any other payment method shall not terminate your obligations to us under the Line of Credit Agreement and upon any such termination of a payment method you must make payments owing under the Line of Credit Agreement using another payment method acceptable to us."
					id="agreement-pad.termination"
				/>
			</Paragraph>

			<Paragraph>
				<FormattedMessage
					defaultMessage='We are required to verify details provided by you in connection with an electronic PAD agreement and provide you with written confirmation of this PAD authorization (the "<strong>Confirmation</strong>") at least fifteen (15) days before the due date of the first PAD. You agree to the reduction of the period between Confirmation and the due date of the first PAD to three (3) days.'
					id="agreement-pad.confirmation"
				/>
			</Paragraph>

			<Paragraph>
				<FormattedMessage
					defaultMessage="We may not assign this authorization, whether directly or indirectly, by operation of law, change of control or otherwise, without providing at least ten (10)-day prior written notice to you."
					id="agreement-pad.authorization-assignment"
				/>
			</Paragraph>

			<Paragraph>
				<FormattedMessage
					defaultMessage="You represent and warrant that the Account is maintained in your name and you are the authorized signer on the Account. To specify new account information or a new payment method, you must contact us to provide the new account information and you will need to authorize payments from that new account."
					id="agreement-pad.account-information"
				/>
			</Paragraph>

			<Paragraph>
				<FormattedMessage
					defaultMessage="If you have any questions regarding this agreement, please contact us at: {email}"
					id="agreement-pad.contact-us"
					values={{
						email: <a href="mailto:support@myzenbase.com">support@myzenbase.com</a>,
					}}
				/>
				<br />
			</Paragraph>

			<Paragraph>
				<FormattedMessage
					defaultMessage="You have read, understood, and agree to the terms and conditions of this PAD agreement."
					id="agreement-pad.agreement-terms"
				/>
			</Paragraph>
		</Text>
	);
};
