import React from 'react';
import { Outlet } from 'react-router-dom';

import { InstaMoneyProvider } from '../../contexts';

export const InstaMoneyWrapper = () => {
	return (
		<>
			<InstaMoneyProvider>
				<Outlet />
			</InstaMoneyProvider>
		</>
	);
};
