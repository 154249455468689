import React from 'react';

import { CommonIconProps } from './types';

export const DownArrow = ({ fill }: CommonIconProps) => {
	return (
		<svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M6.99939 1.66602V16.3327"
				stroke={fill}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M0.99939 10.3327L6.99939 16.3327L12.9994 10.3327"
				stroke={fill}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};
