import { css } from 'styled-components';

import { colors } from '../../variables';

export const bodyCss = css`
	font-family: 'Satoshi', sans-serif;
	font-weight: 400;
	font-size: 2rem;
	line-height: 2.8rem;
	color: ${colors.textBody};

	@media (max-width: 450px) {
		font-size: 1.6rem;
		line-height: 2.4rem;
	}
`;
