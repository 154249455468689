import styled from 'styled-components';

import { colors } from '../../variables';

export const Divider = styled.div`
	height: 1px;
	width: 100%;
	margin: 2rem 0;
	background-color: ${colors.borderGray};
	opacity: 0.2;
`;
