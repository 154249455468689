import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { Button, ButtonProps } from '../../../base-ui/components/Button/Button';

const SplitPaymentsLink = styled.div`
	display: flex;
	justify-content: center;
	width: 100%;
`;

export const SplitPayments = ({ onClick }: Required<Pick<ButtonProps, 'onClick'>>) => {
	return (
		<SplitPaymentsLink>
			<Button type="link" onClick={onClick}>
				<FormattedMessage
					defaultMessage="Split your rent into two payments"
					id="split-payments.split-rent-into-two-payments"
				/>
			</Button>
		</SplitPaymentsLink>
	);
};
