import styled from 'styled-components';

import { Text } from '../../../base-ui/components';
import { colors } from '../../../base-ui/variables';

export const Title = styled.p`
	color: ${colors.teal};
	font-size: 1.6rem;
	line-height: 2.4rem;
`;
export const Amount = styled.p`
	color: ${colors.green};
	font-size: 2.2rem;
	line-height: 3.4rem;
	font-weight: 700;
`;

export const IconWrapper = styled.div`
	cursor: pointer;
	opacity: 0.5;
	transition: opacity 150ms;
	&:hover {
		opacity: 1;
	}
`;

export const Day = styled.div`
	font-weight: 700;
`;

export const Left = styled.div`
	display: flex;
	align-items: center;
`;

export const Date = styled.div`
	background: ${colors.lightGray};
	border-radius: 5px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 6rem;
	height: 6rem;
	color: ${colors.green};
	margin-right: 2rem;
`;

export const Wrapper = styled.div<{ $isEditable: boolean }>`
	box-shadow: 0px 5px 20px rgba(23, 23, 23, 0.08);
	border-radius: 10px;
	background-color: ${colors.white};
	padding: 1.5rem;
	margin-bottom: 2rem;
	cursor: ${({ $isEditable }) => ($isEditable ? 'pointer' : 'auto')};

	@media (max-width: 450px) {
		margin-bottom: 1.6rem;
		padding: 1rem;
	}
`;
export const MainContent = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

export const DatePickerWrapper = styled.div<{ 'data-testid'?: string }>`
	display: block;
	overflow: auto;
	height: auto;
	& > div {
		box-shadow: none;
	}
	.rdp {
		margin-left: -10px;
	}
	@media (max-width: 450px) {
		.rdp {
			margin-left: 0;
		}
	}
`;

export const InfoText = styled(Text)`
	padding: 2.5rem 2rem 1rem;
	margin: 0 auto;
`;
