import React, { useEffect, useMemo } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import { z } from 'zod';

import { Alert, AlertText, AlertTitle, Button, Footer, FormInput, Spacing, Text } from '../../../base-ui/components';

import { BackButton } from '../../components/back-button';
import { useFirebase } from '../../contexts/Firebase';
import { links } from '../../Router/paths';
import { useAsyncHandler } from '../../utils/hooks/useAsyncHandler';

export const ResetPassword = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const intl = useIntl();
	const schema = useMemo(
		() =>
			z
				.object({
					password: z.string().min(6).max(30),
					passwordConfirmation: z.string(),
				})
				.refine((data) => data.password, {
					message: intl.formatMessage({
						defaultMessage: 'Required',
						id: 'common.required',
					}),
					path: ['password'],
				})
				.refine((data) => data.password === data.passwordConfirmation, {
					message: intl.formatMessage({
						defaultMessage: 'Passwords must match',
						id: 'reset-password.passwords-must-match',
					}),
					path: ['passwordConfirmation'],
				}),
		[intl],
	);
	type Schema = z.infer<typeof schema>;
	const {
		isUserAuthenticated,
		authUserLoaded,
		handlers: { handleSignOut, handlePasswordReset },
	} = useFirebase();

	const [handlePasswordResetAsync, { error, loading }] = useAsyncHandler(handlePasswordReset);

	useEffect(() => {
		// if user is trying to reset password while being logged in, sign him out
		// otherwise he might be confused about which account he is resetting the password for.
		if (authUserLoaded && isUserAuthenticated) {
			handleSignOut();
		}
	}, [isUserAuthenticated, authUserLoaded]);

	const form = useForm<Schema>({
		resolver: zodResolver(schema),
		mode: 'onTouched',
	});

	const handleSubmit = ({ password }: Schema) => {
		const { code, continueUrl } = location.state as { code: string; continueUrl?: string };

		handlePasswordResetAsync(code, password).then(
			() => {
				if (continueUrl) {
					window.location.href = continueUrl;
					return;
				}
				navigate(links.DEFAULT.SIGN_IN, { replace: true });
			},
			() => null,
		);
	};

	return (
		<>
			<BackButton to={links.DEFAULT.SIGN_IN} />

			<Text type="title">
				<FormattedMessage defaultMessage="Enter your new password" id="reset-password.enter-new-password" />
			</Text>
			<Spacing $size="l" />

			<FormProvider {...form}>
				<form onSubmit={form.handleSubmit(handleSubmit)}>
					<FormInput
						name="password"
						type="password"
						placeholder={intl.formatMessage({
							defaultMessage: 'New Password',
							id: 'reset-password.new-password',
						})}
					/>
					<FormInput
						name="passwordConfirmation"
						type="password"
						placeholder={intl.formatMessage({
							defaultMessage: 'Confirm New Password',
							id: 'reset-password.confirm-new-password',
						})}
					/>
					<Spacing $size="m" />

					{!!error && (
						<Alert>
							<AlertTitle>
								<FormattedMessage
									defaultMessage="An error ocurred"
									id="alert-error.an-error-occurred"
								/>
							</AlertTitle>
							<AlertText>
								<FormattedMessage
									defaultMessage="Something went wrong at our end. Don't worry, we'll fix soon."
									id="forgot-password.error-text"
								/>
							</AlertText>
						</Alert>
					)}

					<Footer>
						<Button htmlType="submit" disabled={loading}>
							<FormattedMessage defaultMessage="Continue" id="common.continue" />
						</Button>
					</Footer>
				</form>
			</FormProvider>
		</>
	);
};
