'use strict';

// reference: https://www.jsonrpc.org/specification
/**
 * JSON-RPC 2.0 Error codes
 *
 * `-32000` to `-32099` are reserved for implementation-defined server-errors.
 * For tRPC we're copying the last digits of HTTP 4XX errors.
 */ const TRPC_ERROR_CODES_BY_KEY = {
    /**
   * Invalid JSON was received by the server.
   * An error occurred on the server while parsing the JSON text.
   */ PARSE_ERROR: -32700,
    /**
   * The JSON sent is not a valid Request object.
   */ BAD_REQUEST: -32600,
    // Internal JSON-RPC error
    INTERNAL_SERVER_ERROR: -32603,
    NOT_IMPLEMENTED: -32603,
    BAD_GATEWAY: -32603,
    SERVICE_UNAVAILABLE: -32603,
    GATEWAY_TIMEOUT: -32603,
    // Implementation specific errors
    UNAUTHORIZED: -32001,
    FORBIDDEN: -32003,
    NOT_FOUND: -32004,
    METHOD_NOT_SUPPORTED: -32005,
    TIMEOUT: -32008,
    CONFLICT: -32009,
    PRECONDITION_FAILED: -32012,
    PAYLOAD_TOO_LARGE: -32013,
    UNSUPPORTED_MEDIA_TYPE: -32015,
    UNPROCESSABLE_CONTENT: -32022,
    TOO_MANY_REQUESTS: -32029,
    CLIENT_CLOSED_REQUEST: -32099
};
// pure
const TRPC_ERROR_CODES_BY_NUMBER = {
    [-32700]: 'PARSE_ERROR',
    [-32600]: 'BAD_REQUEST',
    [-32603]: 'INTERNAL_SERVER_ERROR',
    [-32001]: 'UNAUTHORIZED',
    [-32003]: 'FORBIDDEN',
    [-32004]: 'NOT_FOUND',
    [-32005]: 'METHOD_NOT_SUPPORTED',
    [-32008]: 'TIMEOUT',
    [-32009]: 'CONFLICT',
    [-32012]: 'PRECONDITION_FAILED',
    [-32013]: 'PAYLOAD_TOO_LARGE',
    [-32015]: 'UNSUPPORTED_MEDIA_TYPE',
    [-32022]: 'UNPROCESSABLE_CONTENT',
    [-32029]: 'TOO_MANY_REQUESTS',
    [-32099]: 'CLIENT_CLOSED_REQUEST'
};

exports.TRPC_ERROR_CODES_BY_KEY = TRPC_ERROR_CODES_BY_KEY;
exports.TRPC_ERROR_CODES_BY_NUMBER = TRPC_ERROR_CODES_BY_NUMBER;
