import React from 'react';

export const Error = () => {
	return (
		<svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
			<circle cx="30" cy="29.9998" r="30" fill="white" />
			<circle opacity="0.9" cx="30.0009" cy="30.0016" r="27.0732" fill="#FFB9AA" />
			<path
				d="M32.7559 41.444V38.6905C32.7559 38.3915 32.5191 38.1423 32.2077 38.1423H27.548C27.249 38.1423 26.9998 38.3791 26.9998 38.6905V41.444C26.9998 41.5935 27.0621 41.7305 27.1618 41.8302L29.4916 44.16C29.7034 44.3718 30.0523 44.3718 30.2641 44.16L32.5939 41.8302C32.6936 41.7305 32.7559 41.5935 32.7559 41.444Z"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M27.4799 15.7337L29.5414 13.6722C29.7528 13.4607 30.0964 13.4519 30.3166 13.6722L32.3693 15.7248C32.475 15.8306 32.5279 15.9715 32.5279 16.1125L32.5448 35.9988H27.3297L27.3213 16.1213C27.3213 15.9803 27.3741 15.8394 27.4799 15.7337Z"
				fill="white"
			/>
		</svg>
	);
};
