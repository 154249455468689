import React from 'react';
import { Link, To } from 'react-router-dom';
import styled from 'styled-components';

import { Icon } from '../../base-ui/components';

const StyledClose = styled.div`
	display: flex;
	justify-content: flex-end;
	cursor: pointer;
`;

type Props = { onClick?: () => void; to?: To; className?: string };

export const CloseButton = ({ onClick, to, className }: Props) => {
	const icon = <Icon icon="Close" />;

	return (
		<StyledClose onClick={onClick} className={className}>
			{to ? <Link to={to}>{icon}</Link> : icon}
		</StyledClose>
	);
};
