import React, { useEffect } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { z } from 'zod';

import { BusinessEventType } from '../../../../functions/src/shared/business-events';

import { trpc } from '../../shared/trpc/client';

import { Button, Footer, FormInput, Spacing, Text } from '../../base-ui/components';

import { AlertError } from '../components/alert-error';
import { useLandlordVerificationSchema } from '../components/use-landlord-verification-schema.hook';
import { useUserData } from '../contexts/user-data-context';
import { shouldPrefill } from '../utils/handover-should-prefill';

type Props = {
	onNext: () => void;
};

export const LandlordVerification = ({ onNext }: Props) => {
	const { home, userStatus } = useUserData();
	const intl = useIntl();

	const schema = useLandlordVerificationSchema(intl);
	type Schema = z.infer<typeof schema>;

	const emailToPrefill =
		shouldPrefill({ userStatus, home }) && home?.residency?.indirectLandlordEmail
			? home.residency.indirectLandlordEmail
			: undefined;

	const form = useForm<Schema>({
		resolver: zodResolver(schema),
		defaultValues: {
			landlordName: home?.residency?.landlordName ?? '',
			userEmail: home?.user?.email ?? '',
			email: emailToPrefill,
		},
	});

	const trackOnboardingEvent = trpc.user.trackOnboardingEvent.useMutation();
	useEffect(() => {
		trackOnboardingEvent.mutate({ type: BusinessEventType.OnboardingHousingProviderStart });
	}, []);

	const { mutate, isError, isPending } = trpc.user.housingProvider.useMutation({
		onSuccess() {
			onNext();
		},
	});

	const handleSubmit = (data: Schema) => {
		const email = (data.email === '' ? null : data.email) ?? null;
		mutate({
			email,
			landlordName: data.landlordName,
		});
	};

	return (
		<>
			<Text type="title">
				<FormattedMessage
					defaultMessage="Enter the details of your housing provider"
					id="landlord-verification.title"
				/>
			</Text>
			<Spacing $size="s" />
			<Text type="small">
				<FormattedMessage
					defaultMessage="These details are only used for verification purposes to make sure that your rental data is complete and reported consistently."
					id="landlord-verification.description"
				/>
			</Text>
			<Spacing $size="m" />

			{isError && <AlertError />}

			<FormProvider {...form}>
				<form onSubmit={form.handleSubmit(handleSubmit)}>
					<FormInput
						name="landlordName"
						placeholder={intl.formatMessage({
							defaultMessage: 'Property Management Company or Landlord Name',
							id: 'address.property-manager-or-landlord-name',
						})}
						testId="onboarding-cb-landlord-input-name"
					/>
					<FormInput
						name="email"
						placeholder={intl.formatMessage({
							defaultMessage: 'Housing Provider E-mail',
							id: 'landlord-verification.email-placeholder',
						})}
						testId="onboarding-cb-landlord-input-email"
					/>
					<Spacing $size="m" />

					<Footer>
						<Button disabled={isPending} htmlType="submit" testId="onboarding-cb-landlord-confirm-button">
							<FormattedMessage defaultMessage="Confirm" id="common.confirm" />
						</Button>
					</Footer>
				</form>
			</FormProvider>
		</>
	);
};
