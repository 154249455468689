import { z } from 'zod';

import { AccountSource, AccountStatus } from './account';
import { PaymentProvider } from './payment-provider';
import { PaymentMethod } from './payment-transaction';

export enum ItemStatus {
	NotReady = 0,
	Good,
	Bad,
	Ineligible,
}

export const paymentAccountEftProviders = [PaymentProvider.ModernTreasury, PaymentProvider.Plaid, PaymentProvider.Bmo];

export enum ItemProvider {
	Plaid = 'plaid',
	Flinks = 'flinks',
}

/**
 * When plaid accounts have provider.modern-treasury, this can be removed
 * @deprecated
 */
export function accountSourceHasModernTreasuryAccount(source: AccountSource | null) {
	if (source === null) {
		return false;
	}
	return [AccountSource.Manual, AccountSource.Plaid, AccountSource.Flinks].includes(source);
}

export const instantConnectionAccounts = [AccountSource.Flinks, AccountSource.Plaid] as const;
export const accountSourcesWithTransactions = [AccountSource.Manual, ...instantConnectionAccounts] as const;

/**
 * Supported payment method for an account
 *
 * At some point we will probably have a provider that supports
 * multiple payment methods, and this will need to be a column on accounts.
 */
export const supportedAccountPaymentsMethod = (provider: PaymentProvider): PaymentMethod =>
	({
		[PaymentProvider.Plaid]: PaymentMethod.Eft,
		[PaymentProvider.ModernTreasury]: PaymentMethod.Eft,
		[PaymentProvider.ZumRails]: PaymentMethod.Eft,
		[PaymentProvider.Stripe]: PaymentMethod.CreditCard,
		[PaymentProvider.Bmo]: PaymentMethod.Eft,
		[PaymentProvider.Moneris]: PaymentMethod.DirectDebit,
	})[provider];

export type AdminPaymentAccountItem = {
	id: string;
	name: string;
	bankName: string;
	mask: string;
	status: AccountStatus;
	provider: PaymentProvider;
	mtCounterpartyId: string | null;
	externalAccountId: string | null;
	currentBalance: number;
	availableBalance: number;
	lastSourceConnection: Record<string, string>;
	createdAt: Date;
	updatedAt: Date;
	// if we fill status and user_id for Plaid accounts too, this wouldn't be needed
	itemId: string | null;
	itemStatus: ItemStatus | null;
	itemDeleted: boolean | null;
	itemReconnectRequired: boolean | null;
	itemScheduledRefreshEnabled: boolean;
	itemProvider: ItemProvider;
	isDefaultPaymentAccount: boolean;
	isDefaultPaymentCard: boolean;
	subtype: string | null;
	type: string | null;
	source: AccountSource | null;
	sourceId: string | null;
	transactionsFetchedAt: string;
	userId: string;
};

export const mtRoutingSchema = z.union([z.record(z.unknown()), z.array(z.record(z.unknown()))]);
