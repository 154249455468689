import React, { useState } from 'react';
import styled from 'styled-components';

import { colors } from '../variables';

const Container = styled.div`
	width: 5.2rem;
	height: 2.8rem;
	border-radius: 1.4rem;
	background-color: #e3eced;
	position: relative;
	cursor: pointer;
`;

const InnerElement = styled.div<{ $value: boolean }>`
	width: 2rem;
	height: 2rem;
	border-radius: 50%;
	background-color: ${(props) => (props.$value ? colors.teal : colors.white)};
	top: 0.4rem;
	transform: translateX(${(props) => (props.$value ? '2.8rem' : '0.4rem')});
	transition: all 300ms ease;
	position: relative;
`;

type ToggleProps = {
	value: boolean;
	handler: () => void;
};

export const Toggle = (props: ToggleProps) => {
	const [value, setValue] = useState(props.value);

	const handleClick = () => {
		setValue(!value);
		props.handler();
	};

	return (
		<Container onClick={handleClick}>
			<InnerElement $value={value} />
		</Container>
	);
};
