import { IntlShape } from 'react-intl';

import { CanadianProvincialCodes } from '../../../../functions/src/shared/state-codes';

export const provincialCodesService = {
	translateCanadaProvince: (intl: IntlShape, code: string) => {
		const codeTyped = code as keyof typeof CanadianProvincialCodes;
		switch (codeTyped) {
			case 'AB':
				return intl.formatMessage({ defaultMessage: 'Alberta', id: 'common.province.ab' });
			case 'BC':
				return intl.formatMessage({ defaultMessage: 'British Columbia', id: 'common.province.bc' });
			case 'MB':
				return intl.formatMessage({ defaultMessage: 'Manitoba', id: 'common.province.mb' });
			case 'NB':
				return intl.formatMessage({ defaultMessage: 'New Brunswick', id: 'common.province.nb' });
			case 'NL':
				return intl.formatMessage({ defaultMessage: 'Newfoundland and Labrador', id: 'common.province.nl' });
			case 'NT':
				return intl.formatMessage({ defaultMessage: 'Northwest Territories', id: 'common.province.nt' });
			case 'NS':
				return intl.formatMessage({ defaultMessage: 'Nova Scotia', id: 'common.province.ns' });
			case 'NU':
				return intl.formatMessage({ defaultMessage: 'Nunavut', id: 'common.province.nu' });
			case 'ON':
				return intl.formatMessage({ defaultMessage: 'Ontario', id: 'common.province.on' });
			case 'PE':
				return intl.formatMessage({ defaultMessage: 'Prince Edward Island', id: 'common.province.pe' });
			case 'QC':
				return intl.formatMessage({ defaultMessage: 'Quebec', id: 'common.province.qc' });
			case 'SK':
				return intl.formatMessage({ defaultMessage: 'Saskatchewan', id: 'common.province.sk' });
			case 'YT':
				return intl.formatMessage({ defaultMessage: 'Yukon', id: 'common.province.yt' });
			default:
				const exhaustiveCode: never = codeTyped;
				return exhaustiveCode;
		}
	},
};
