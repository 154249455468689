import React, { ReactNode } from 'react';
import styled from 'styled-components';
import automatedPayments from 'url:../static/shapes/automated-payments@2x.png';
import overdraftAlerts from 'url:../static/shapes/overdraft-alerts@2x.png';
import securityAndPrivacy from 'url:../static/shapes/security-and-privacy@2x.png';

import { Text } from '../../base-ui/components';
import { colors } from '../../base-ui/variables';

const ListItemWithImage = styled.div`
	display: flex;
	margin-bottom: 3.5rem;
	&:last-child {
		margin-bottom: 0;
	}
	img {
		margin-right: 3.5rem;
		align-self: flex-start;
		width: 8rem;
		@media (max-width: 450px) {
			margin-right: 2rem;
			width: 6rem;
		}
	}
	${Text}:first-child {
		color: ${colors.green};
		font-weight: 700;
	}
`;

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	margin: 1.2rem 0;
	@media (max-width: 450px) {
		margin: 0.5rem 0;
	}
`;

type Item = {
	icon: 'automatedPayments' | 'overdraftAlerts' | 'securityAndPrivacy';
	title: string;
	subtitle: ReactNode;
};

type Props = {
	items: Item[];
};

export const ConnectBankList = ({ items }: Props) => {
	return (
		<Wrapper>
			{items.map(({ icon, title, subtitle }, i) => {
				return (
					<ListItemWithImage key={i}>
						<img
							alt={title}
							src={
								icon === 'automatedPayments'
									? automatedPayments
									: icon === 'overdraftAlerts'
										? overdraftAlerts
										: icon === 'securityAndPrivacy'
											? securityAndPrivacy
											: ''
							}
						/>
						<div>
							<Text>{title}</Text>
							<Text type="small">{subtitle}</Text>
						</div>
					</ListItemWithImage>
				);
			})}
		</Wrapper>
	);
};
