import styled, { createGlobalStyle } from 'styled-components';

import { Footer, Text } from '../../../base-ui/components';
import { colors } from '../../../base-ui/variables';

import { StyledPaper } from '../../components/app-container/app-container';
import { AppWrapper } from '../../components/app-wrapper';
import { HeaderWrapper } from '../../components/profile/header';
import illustration from '../../static/shapes/landing-illustration.png';

export const GlobalStyle = createGlobalStyle`
  body {
    background: linear-gradient(360deg, #FFEFE4 72.23%, rgba(255, 239, 228, 0) 100%) !important;
  }

  ${AppWrapper} {
	background-color: transparent;
	overflow: hidden;
  }

  ${HeaderWrapper} {
	background-color: #f5f5f5;
  }

  ${StyledPaper} {
	background: transparent;
	box-shadow: none;

	max-width: 120rem;
  }
`;

export const Title = styled(Text).attrs({ type: 'title-extra-large-primary' })`
	margin-bottom: 0.8em;
	color: ${colors.green};

	span:first-child {
		color: ${colors.pink};
	}
	span:last-child {
		color: ${colors.teal};
	}
`;

export const BodyText = styled(Text)`
	line-height: 1.6;

	b {
		color: ${colors.green};
	}
`;

export const List = styled(Text)`
	margin: 2rem 0 4rem;

	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;

	line-height: 1.6;
`;

export const ListItem = styled.span`
	display: flex;
	align-items: center;

	& svg {
		margin-right: 0.8rem;
	}
`;

export const Illustration = styled.img.attrs({ src: illustration })<{ mobile?: boolean }>`
	display: ${({ mobile }) => (mobile ? 'none' : 'block')};
	@media (max-width: 768px) {
		display: ${({ mobile }) => (mobile ? 'block' : 'none')};
	}
`;

export const Header = styled.div`
	display: flex;
	column-gap: 5rem;
	align-items: center;

	@media (max-width: 768px) {
		flex-direction: column;
	}
`;

export const Content = styled.div``;

export const HeaderFooter = styled(Footer).attrs({ stickyOnMobile: true })`
	padding-bottom: 2rem;

	& > div {
		@media (min-width: 768px) {
			align-items: flex-start;
			padding: 0;
		}
	}
`;
