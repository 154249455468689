import React, { useState } from 'react';
import { FormattedDate } from 'react-intl';
import styled from 'styled-components';

import { RentMonth } from '../../../../../../functions/src/shared/rent-month';

import { colors } from '../../../../base-ui/variables';

import { useUserData } from '../../../contexts/hooks';
import { MonthPaymentsList } from './month-payments-list';

export const ScheduledPayments = () => {
	const {
		home,
		handlers: { silentRefetch },
	} = useUserData();

	const dashboardMonths = home?.dashboardMonths ?? {
		current: { month: RentMonth.current().asDbParams(), payments: [] },
		next: { month: RentMonth.current().next().asDbParams(), payments: [] },
	};
	const currentMonth = RentMonth.fromDbParams(dashboardMonths.current.month);
	const nextMonth = RentMonth.fromDbParams(dashboardMonths.next.month);

	const [$nextMonthActive, setNextMonthActive] = useState(
		dashboardMonths.current.payments.length === 0 && dashboardMonths.next.payments.length > 0,
	);

	const handleSwitch = (value: boolean) => () => {
		silentRefetch();
		setNextMonthActive(value);
	};

	return (
		<div data-testid="account-dashboard-list-scheduled-payments-wrapper">
			<ToggleMonth $nextMonthActive={$nextMonthActive}>
				<Option onClick={handleSwitch(false)} $active={!$nextMonthActive}>
					<FormattedDate value={currentMonth.firstDayAsDate()} month="long" />
				</Option>
				<Option onClick={handleSwitch(true)} $active={$nextMonthActive}>
					<FormattedDate value={nextMonth.firstDayAsDate()} month="long" />
				</Option>
			</ToggleMonth>
			<MonthPaymentsList
				payments={$nextMonthActive ? dashboardMonths.next.payments : dashboardMonths.current.payments}
				testId="account-dashboard-list-scheduled-payments"
				isEligible={home?.eligibilityRules.success ?? false}
			/>
		</div>
	);
};

export const ToggleMonth = styled.div<{ $nextMonthActive?: boolean }>`
	display: flex;
	justify-content: space-between;
	margin-bottom: 2rem;
	border-radius: 35px;
	background-color: #f5f5f5;
	border: 1px solid #e5e4e4;
	position: relative;
	&:after {
		display: block;
		content: '';
		width: 50%;
		height: 100%;
		background: ${colors.green};
		position: absolute;
		border-radius: 35px;
		transform: translateX(${(props) => (props.$nextMonthActive ? '100%' : '0')});
		transition: transform 120ms ease-in-out;
	}
`;

export const Option = styled.div<{ $active?: boolean }>`
	padding: 1.2rem;
	width: 50%;
	border-radius: 35px;
	color: ${(props) => (props.$active ? colors.white : colors.textBody)};
	transition: color 120ms;
	text-align: center;
	font-weight: bold;
	cursor: pointer;
	position: relative;
	z-index: 1;
`;

export const PaymentWrapper = styled.div`
	min-height: fit-content;
`;
