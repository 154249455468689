import { IntlShape } from 'react-intl';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import { z } from 'zod';

export const preprocessFloat = (numberSchema: z.ZodNumber) =>
	z.preprocess((val) => parseFloat(String(val).replace(',', '')), numberSchema);

export const preprocessFloatOptional = (numberSchema: z.ZodNumber) =>
	z.preprocess((val) => {
		if (typeof val === 'undefined' || val === '') return undefined;
		return parseFloat(String(val).replace(',', ''));
	}, numberSchema);

export const trimString = (u: unknown) => (typeof u === 'string' ? u.trim() : u);

export const trimmedString = () => z.preprocess(trimString, z.string());

export const nonemptyTrimmedString = (message) => z.preprocess(trimString, z.string().min(1, { message }));

export const zodPhoneNumber = (intl: IntlShape) =>
	z
		.string()
		.trim()
		.refine(isPossiblePhoneNumber, {
			message: intl.formatMessage({
				defaultMessage: 'Please type valid phone number',
				id: 'form.errors.phone-number-invalid',
			}),
		});

export const zodPhoneNumberDefaultUs = (intl: IntlShape) =>
	z
		.string()
		.trim()
		.refine((val) => isPossiblePhoneNumber(val || '', 'US'), {
			message: intl.formatMessage({
				defaultMessage: 'Please type valid phone number',
				id: 'form.errors.phone-number-invalid',
			}),
		});

export const zodPhoneVerificationCodeSchema = (intl: IntlShape) =>
	z.preprocess(
		(val) => (val ? Number(val) : undefined),
		z.number({
			required_error: intl.formatMessage({
				defaultMessage: 'Please type verification code',
				id: 'form.errors.verification-code-required',
			}),
			invalid_type_error: intl.formatMessage({
				defaultMessage: 'Verification code has to be a number',
				id: 'form.errors.verification-code-not-a-number',
			}),
		}),
	);
