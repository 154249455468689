import { useQuery } from '@tanstack/react-query';

import { isUserAdminByClaims, isUserSuperadminByClaims } from '../../../../functions/src/shared/user';

import { useFirebase } from '../../app/contexts/hooks';

export const useAdminUser = () => {
	const { authUser } = useFirebase();
	return { isAdmin: authUser?.tokenResult?.claims && isUserAdminByClaims(authUser.tokenResult.claims) };
};

let globalSuperadminMode: ReturnType<typeof setTimeout> | null = null;

export const isInSuperadminMode = () => globalSuperadminMode !== null;

export const useSuperadmin = () => {
	const query = useQuery({ queryKey: ['SUPERADMIN_MODE'], queryFn: isInSuperadminMode });

	const { authUser } = useFirebase();
	const userIsSuperadmin = isUserSuperadminByClaims(authUser?.tokenResult?.claims);

	const leaveSuperadminMode = () => {
		globalSuperadminMode = null;
		query.refetch();
	};

	return {
		userIsSuperadmin,
		superadminMode: Boolean(query.data),
		enterSuperadminMode: () => {
			if (globalSuperadminMode !== null) return;
			globalSuperadminMode = setTimeout(leaveSuperadminMode, 5 * 60_000);
			query.refetch();
		},
		leaveSuperadminMode,
	};
};
