import React from 'react';

export const SignOut = () => (
	<svg width="1em" height="1em" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M7.99707 18.5019V22.0034C7.99707 23.9372 9.56473 25.5048 11.4985 25.5048H22.0029C23.9367 25.5048 25.5044 23.9372 25.5044 22.0034V7.99755C25.5044 6.06375 23.9367 4.49609 22.0029 4.49609H11.4985C9.56473 4.49609 7.99707 6.06375 7.99707 7.99755V11.499"
			stroke="#0A6375"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M15 18.501L18.5015 14.9995L15 11.498"
			stroke="#0A6375"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M4.49512 14.9996H18.501"
			stroke="#0A6375"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);
