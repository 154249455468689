import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { UserPmsResidencyType } from '../../../../functions/src/shared/pms';

import { RouterOutput } from '../../shared/trpc/client';

import { PaperSection } from '../../base-ui/components';

import { provincialCodesService } from '../utils/provincial-codes-service';

type Props = {
	home: RouterOutput['user']['home'] | null;
	divider?: boolean;
};

export const ConfirmResidencyAddress = ({ home, divider = false }: Props) => {
	if (!home) return null;

	const intl = useIntl();
	const { residency } = home;

	return (
		<PaperSection
			label={
				<FormattedMessage defaultMessage="Residence Details" id="confirm-residency-address.residence-details" />
			}
			divider={divider}
		>
			{residency && (
				<>
					{residency.apartment}, {residency.streetAddress}
					<br />
					{residency.city},{' '}
					{residency.type === UserPmsResidencyType.Indirect && residency.state
						? provincialCodesService.translateCanadaProvince(intl, residency.state)
						: residency.state}
					, {residency.postalCode}
				</>
			)}
		</PaperSection>
	);
};
