import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Text } from '../../base-ui/components';

type Props = {
	hasInitialRentAmount: boolean;
	landlordName?: string;
};

export const SelectRentAmountContent = ({ hasInitialRentAmount, landlordName }: Props) => {
	const intl = useIntl();

	const amountLabel = (
		<FormattedMessage
			defaultMessage={`This amount is what {landlordName} charges you every month for rent as per your lease agreement.`}
			id="select-rent-amount-content.rent-amount-description"
			values={{
				landlordName:
					landlordName ??
					intl.formatMessage({
						defaultMessage: 'your landlord',
						id: 'select-rent-amount-content.your-landlord',
					}),
			}}
		/>
	);
	if (hasInitialRentAmount) {
		return (
			<>
				<Text type="title">
					<FormattedMessage
						defaultMessage="Review your monthly rent"
						id="select-rent-amount-content.review-monthly-rent"
					/>
				</Text>
				<Text>{amountLabel}</Text>
			</>
		);
	}

	return (
		<>
			<Text type="title">
				<FormattedMessage
					defaultMessage="Enter your rent amount"
					id="change-rent-amount.enter-rent-amount-title"
				/>
			</Text>
			<Text>{amountLabel}</Text>
		</>
	);
};
