import React, { ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import cbPaymentSummary from 'url:../../static/shapes/credit-builder-payment-summary.png';

import { FormattedCents } from '../../../shared/components/formatted-cents';
import { trpc } from '../../../shared/trpc/client';

import { Alert } from '../../../base-ui/components';

import { BackButton } from '../../components/back-button';
import { Spinner } from '../../components/profile/dashboard-card.styles';
import { getPaymentFromGetInfo } from '../../templates/credit-builder-success';
import { OnboardingSuccess } from '../../templates/onboarding-success';

export function CreditBuilderPaymentSummary() {
	const navigate = useNavigate();
	const info = trpc.creditReporting.getUserInfo.useQuery();
	const intl = useIntl();

	const { payment, checkoutLink } = getPaymentFromGetInfo('account', info.data);

	const anyCompleted = Boolean(info.data?.hasCompletedPayments);

	if (info.isPending) {
		return <Spinner $spinnerColor="green" />;
	}

	if (!payment || !checkoutLink) {
		return (
			<>
				<BackButton />
				<Alert>
					<FormattedMessage
						defaultMessage="There was an error loading your payment details. Please try again or contact support"
						id="credit-builder-payment-summary.no-data-text"
					/>
				</Alert>
			</>
		);
	}

	const subscriptionPeriod = payment.metadata?.subscriptionPeriodType;
	const includesHistory = payment?.metadata?.rentHistoryUpsellEnrolled;
	let subtitle: ReactNode | null = null;

	const formattedCents = <FormattedCents value={payment.amountCents} />;
	if (anyCompleted) {
		subtitle = (
			<FormattedMessage
				defaultMessage="Your card will be charged {fee} to continue building your credit with your rent payments you are already making."
				id="credit-builder-payment-summary.recurring"
				values={{
					fee: formattedCents,
				}}
			/>
		);
	} else {
		const andHistoryIntl = intl.formatMessage({
			defaultMessage: 'and history reporting',
			id: 'credit-builder-payment-summary.and-history-reporting',
		});
		subtitle = (
			<FormattedMessage
				defaultMessage="Your card will be charged {fee} for {subscriptionPeriod} rent reporting {includesHistory, select, true {{andHistoryIntl}} other {}} to build your credit. Simplest way to build credit."
				id="credit-builder-payment-summary.first-payment"
				values={{
					fee: formattedCents,
					subscriptionPeriod,
					includesHistory,
					andHistoryIntl,
				}}
			/>
		);
	}

	return (
		<OnboardingSuccess
			title={
				<FormattedMessage
					defaultMessage="Make your rent payments count"
					id="credit-builder-payment-summary.title"
				/>
			}
			subtitle={<div data-testid="credit-builder-payment-summary.subtitle">{subtitle}</div>}
			onNext={() => navigate(checkoutLink)}
			cta={<FormattedMessage defaultMessage="Continue" id="common.continue" />}
			ctaTestId={'credit-builder-payment-summary-continue-button'}
			imageSrc={cbPaymentSummary}
		/>
	);
}
