import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { Button, Footer } from '../../../base-ui/components';

import { useFirebase } from '../../contexts/hooks';
import { links } from '../../Router/paths';

export const SignOut = () => {
	const {
		isUserAuthenticated,
		authUserLoaded,
		handlers: { handleSignOut },
	} = useFirebase();
	const navigate = useNavigate();

	useEffect(() => {
		if (authUserLoaded && !isUserAuthenticated) {
			navigate(links.DEFAULT.ROOT);
		}
	}, [isUserAuthenticated, authUserLoaded]);

	return (
		<Footer>
			<Button disabled={!authUserLoaded} onClick={handleSignOut} testId="sign-out-button">
				<FormattedMessage defaultMessage="Sign Out" id="common.sign-out" />
			</Button>
		</Footer>
	);
};
