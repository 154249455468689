import React, { ReactNode, useEffect, useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useIntl } from 'react-intl';

import { publicConfig } from '../../../../../functions/src/shared/config';
import { jsonParseSafe } from '../../../../../functions/src/shared/json-parse';
import { UserLocale } from '../../../../../functions/src/shared/user';

import { getEnvironment } from '../../../shared/environment';
import { trpc } from '../../../shared/trpc/client';

import { useInterface } from '../../contexts/hooks';
import { links } from '../../Router/paths';
import { GENERIC_STRIPE_ERROR_MESSAGE, mapStripeErrorToMessage, StripeApiError } from './stripe-errors';
import { StripePaymentMethodForm } from './stripe-payment-method-form';

type Options = {
	ptid?: string;
	successPath?: string;
	load?: boolean;
	onIntentCreationSuccess?: () => void;
};

const getStripe = () => loadStripe(publicConfig.stripe[getEnvironment()].publicKey);

export const useStripeCheckout = ({ ptid, successPath, load = true, onIntentCreationSuccess }: Options) => {
	const { setLoading, isPending } = useInterface();

	const intl = useIntl();
	const [stripePromise, setStripePromise] = useState(() => (load ? getStripe() : null));
	const [paymentMethodId, setPaymentMethodId] = useState<string | null>(null);
	const [confirmationError, setConfirmationError] = useState<string | null | ReactNode>(null);
	const paymentDetails = trpc.user.paymentCards.getCheckoutPaymentDetails.useQuery(
		{ ptid: ptid || '' },
		{ enabled: Boolean(ptid), retry: 0 },
	);

	const handleConfirmationError = (error: ReactNode) => {
		setConfirmationError(error);
		paymentDetails.refetch();
	};

	const clearState = () => {
		setConfirmationError(null);
		setPaymentMethodId(null);
	};

	useEffect(() => {
		if (load) setStripePromise(getStripe());
	}, [load]);

	const paymentIntent = trpc.user.paymentCards.createCheckoutPaymentIntent.useMutation({
		async onSuccess({ clientSecret, intentStatus }) {
			if (onIntentCreationSuccess) onIntentCreationSuccess();

			const stripe = await stripePromise;
			if (!stripe) throw new Error('Stripe.js not loaded');

			if (intentStatus !== 'requires_confirmation') {
				// this should offer either new payment form or success page,
				// depending on the status
				handleConfirmationError(GENERIC_STRIPE_ERROR_MESSAGE);
				return;
			}
			const returnUrl = new URL(
				`${window.location.origin}${links.DEFAULT.PAYMENT_STATUS}?ptid=${ptid}${
					successPath ? `&success=${successPath}` : ''
				}`,
			);
			const { error } = await stripe.confirmPayment({
				clientSecret,
				redirect: 'always',
				confirmParams: {
					return_url: returnUrl.toString(),
				},
			});
			setLoading(false);
			if (error) {
				handleConfirmationError(GENERIC_STRIPE_ERROR_MESSAGE);
			}
		},
		onError(e) {
			console.error('Error creating payment intent', e);
			const { message } = e;
			const parsedJson = jsonParseSafe(message) as StripeApiError | undefined;
			if (parsedJson?.code || parsedJson?.declineCode) {
				handleConfirmationError(mapStripeErrorToMessage(parsedJson));
			} else {
				handleConfirmationError(message);
			}
			setLoading(false);
		},
	});

	const handleStartPayment = async (paymentMethodIdArg?: string) => {
		const methodId = paymentMethodIdArg ?? paymentMethodId;
		if (methodId && ptid) {
			setLoading(true);
			await stripePromise; // to make sure it is loaded
			paymentIntent.mutate({ ptid, paymentMethodId: methodId });
		}
	};

	const paymentMethodForm = paymentDetails.data && (
		<Elements
			stripe={stripePromise}
			options={{
				paymentMethodCreation: 'manual',
				mode: 'payment',

				// trying out if this will bring up apple pay on demo
				//setup_future_usage: 'off_session',

				// used as payment details in some payment methods,
				// but it is just for an information only at this point
				amount: paymentDetails.data?.amountCents,
				currency: paymentDetails.data?.currency,
				locale: intl.locale as UserLocale,
			}}
		>
			<StripePaymentMethodForm
				key={ptid}
				onPaymentMethodCreated={(newPaymentMethodId) => {
					setPaymentMethodId(newPaymentMethodId);
					handleStartPayment(newPaymentMethodId);
				}}
			/>
		</Elements>
	);

	return {
		paymentDetails,
		confirmationError,
		paymentMethodId,
		handleStartPayment,
		isPending,
		paymentMethodForm,
		clearState,
	};
};
