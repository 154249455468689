import React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import { BankOnboardingProvider } from '../../contexts';
import { Variant } from '../../contexts/BankOnboardingContext/BankOnboardingContext';
import { UserStatus, useUserData } from '../../contexts/user-data-context';
import { NavigateParams } from '../../utils/react-router';
import { links } from '../paths';

type Props = {
	links: typeof links.REGISTRATION.BANK;
	onCompletedNavigateParams?: NavigateParams;
	variant: Variant;
};

export const BankOnboardingWrapper = ({ links: l, onCompletedNavigateParams, variant }: Props) => {
	const navigate = useNavigate();
	const { userStatus } = useUserData();

	const onCompleted = () => {
		if (onCompletedNavigateParams) {
			navigate(...onCompletedNavigateParams);
		} else {
			const completedLink =
				userStatus === UserStatus.ValidUser
					? links.ACCOUNT.DASHBOARD
					: links.REGISTRATION.CONFIGURE.CONFIRM_CUSTOM_RENT;

			navigate(completedLink);
		}
	};

	return (
		<BankOnboardingProvider links={l} onCompleted={onCompleted} variant={variant}>
			<Outlet />
		</BankOnboardingProvider>
	);
};
