export const firebaseErrors = {
	ERROR_CODE_MULTI_FACTOR_AUTH_REQUIRED: 'auth/multi-factor-auth-required',
	ERROR_CODE_MULTI_FACTOR_INVALID_CODE: 'auth/invalid-verification-code',
	ERROR_CODE_EMAIL_NOT_FOUND: 'auth/user-not-found',
	ERROR_CODE_INVALID_PASSWORD: 'auth/wrong-password',
	ERROR_CODE_USER_DISABLED: 'auth/user-disabled',
	ERROR_CODE_TOO_MANY_REQUEST: 'auth/too-many-requests',
	ERROR_CODE_RECENT_SIGN_IN: 'auth/requires-recent-login',
	ERROR_CODE_EMAIL_ALREADY_IN_USE: 'auth/email-already-in-use',
	ERROR_CODE_INVALID_ACTION_CODE: 'auth/invalid-action-code',
	ERROR_CODE_CODE_EXPIRED: 'auth/code-expired',
	ERROR_CODE_NETWORK_REQUEST_FAILED: 'auth/network-request-failed',
	ERROR_CODE_CAPTCHA_FAILED: 'auth/captcha-check-failed',
	ERROR_MESSAGE_SIGNIN_ERROR: 'Email and password combination does not match our records. Please try again.',
	ERROR_MESSAGE_USER_DISABLED: 'Your account is not activated. Please contact support@myzenbase.com',
	ERROR_MESSAGE_MULTI_FACTOR_INVALID_CODE: 'Verification code is invalid',
};
