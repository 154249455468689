import { CreditBuilderSubscriptionPeriodType } from '../credit-reporting/credit-reporting-subscription';
import { IsoDate } from './iso-date';
import type { LedgerEntry } from './ledger';
import { PaymentProvider } from './payment-provider';
import type { RentChargeType } from './rent-charges';
import type { FeatureType } from './user';
import { typedIncludes } from './utils';

export enum TransactionStatus {
	Pending = 'pending',
	Executing = 'executing',
	PendingComplete = 'pending-complete',
	Completed = 'completed',
	Returned = 'returned',
	Canceled = 'canceled',
	Refunded = 'refunded',
	Failed = 'failed',
}

export const editableStatuses = [TransactionStatus.Pending] as const;

export const statusesThatNeedCompletion = [
	TransactionStatus.Pending,
	TransactionStatus.Executing,
	TransactionStatus.PendingComplete,
] as const;

export const statusesThatHaveRecreateButton = [
	TransactionStatus.Canceled,
	TransactionStatus.Failed,
	TransactionStatus.Returned,
] as const;

export const cancelableStatuses = [...editableStatuses, TransactionStatus.Executing] as const;

export const transactionCancellable = (transaction: {
	status: TransactionStatus;
	provider?: PaymentProvider | null | undefined;
}) => {
	if (transaction.provider !== PaymentProvider.Bmo) {
		return typedIncludes(cancelableStatuses, transaction.status);
	}
	return transaction.status === TransactionStatus.Pending;
};

export const pendingOrCompletedStatuses = [...statusesThatNeedCompletion, TransactionStatus.Completed] as const;

export const statusesThatMayEventuallyBeCompleted = [...pendingOrCompletedStatuses] as const;

export const incompleteStatuses = [
	...editableStatuses,
	TransactionStatus.Executing,
	TransactionStatus.PendingComplete,
] as const;

export const statusesConsideredCompleteForLedger = [
	TransactionStatus.Completed,
	TransactionStatus.PendingComplete,
] as const;

export const statusesConsideredCompleteForMicrodepositVerification = [
	TransactionStatus.Executing,
	TransactionStatus.PendingComplete,
	TransactionStatus.Completed,
] as const;

export const paymentInProgressStatuses = [TransactionStatus.Executing, TransactionStatus.PendingComplete] as const;

export enum PaymentMethod {
	Eft = 'eft',
	ManualETransfer = 'manual-e-transfer',
	AutomaticETransfer = 'automatic-e-transfer',
	DirectDebit = 'direct-debit',
	CreditCard = 'credit-card',
	Discount = 'discount',
	Concession = 'concession',
	DirectlyToLandlord = 'directly-to-landlord',
	// Transfer balance from one rent month / cash-advance to another
	BalanceTransfer = 'balance-transfer',

	// "pay now" with whatever method available
	Checkout = 'checkout',
	// For bank transfers done without using Modern Treasury.
	// Only for recording historical transactions that we already made.
	ManualBankTransfer = 'manual-bank-transfer',
}

export type PaymentMetadata = {
	cardEnding?: string;
	skipAutomaticProcessing?: boolean;
	subscriptionPeriodType?: CreditBuilderSubscriptionPeriodType;
	rentHistoryUpsellEnrolled?: boolean;
	refundInitiatedAt?: string;
	initiatedBySuperadmin?: boolean;
	decoupledAuthentication?: boolean;
} | null;

export const paymentMethodsVisibleToUser = [
	PaymentMethod.Eft,
	PaymentMethod.DirectDebit,
	PaymentMethod.CreditCard,
	PaymentMethod.ManualETransfer,
	PaymentMethod.ManualBankTransfer,
	PaymentMethod.Checkout,
	PaymentMethod.DirectlyToLandlord,
] as const;

export const alwaysEditablePaymentMethods = [
	PaymentMethod.Concession,
	PaymentMethod.Discount,
	PaymentMethod.BalanceTransfer,
] as const;

export const automaticallyProcessableMethods = [PaymentMethod.Eft, PaymentMethod.DirectDebit] as const;
export const paymentMethodsForCreditPayments = [PaymentMethod.Eft] as const;

export const paymentMethodsForPaymentPlans = [PaymentMethod.Eft, PaymentMethod.ManualETransfer] as const;

export const paymentMethodsForNewPayment = [
	PaymentMethod.Eft,
	PaymentMethod.ManualETransfer,
	PaymentMethod.Checkout,
	PaymentMethod.Concession,
	PaymentMethod.Discount,
	PaymentMethod.DirectDebit,
];

export const transactionFullyEditable = (transaction: {
	status: TransactionStatus;
	paymentMethod: PaymentMethod;
	provider?: PaymentProvider | null | undefined;
}) => transactionCancellable(transaction) || typedIncludes(alwaysEditablePaymentMethods, transaction.paymentMethod);

export const allowedNewPaymentStatusesForMethod = (method: PaymentMethod): TransactionStatus[] =>
	({
		[PaymentMethod.Concession]: [TransactionStatus.Completed],
		[PaymentMethod.Discount]: [TransactionStatus.Completed],
		[PaymentMethod.DirectlyToLandlord]: [TransactionStatus.Completed],
		[PaymentMethod.ManualETransfer]: [TransactionStatus.Pending],
		[PaymentMethod.AutomaticETransfer]: [TransactionStatus.Pending],
		[PaymentMethod.Eft]: [TransactionStatus.Pending],
		[PaymentMethod.DirectDebit]: [TransactionStatus.Pending],
		[PaymentMethod.CreditCard]: [TransactionStatus.Pending],
		[PaymentMethod.Checkout]: [TransactionStatus.Pending],
		[PaymentMethod.ManualBankTransfer]: [TransactionStatus.Completed],
		[PaymentMethod.BalanceTransfer]: [TransactionStatus.Completed],
	})[method] || [TransactionStatus.Pending];

export const allowedMethodsForEditingStatus = (status: TransactionStatus): PaymentMethod[] => [
	...{
		[TransactionStatus.Pending]: paymentMethodsForNewPayment,
		[TransactionStatus.Executing]: [PaymentMethod.ManualETransfer],
		[TransactionStatus.PendingComplete]: [],
		[TransactionStatus.Completed]: alwaysEditablePaymentMethods,
		[TransactionStatus.Failed]: [],
		[TransactionStatus.Returned]: [],
		[TransactionStatus.Canceled]: [],
		[TransactionStatus.Refunded]: [],
	}[status],
];

export enum TransactionType {
	Debit = 'debit',
	Credit = 'credit',
}

export enum PaymentDestination {
	Member = 'member',
	Landlord = 'landlord',
}

export interface UserTransaction {
	paymentTransactionId: string;
	status: TransactionStatus;
	amountCents: number;
	baseAmountCents: number;
	feeAmountCents: number;
	featureId: FeatureType;
	originatingId: string;
	effectiveDate: IsoDate;
	isNeededForUpfrontPayment?: boolean;
	lastAllowedDate: IsoDate;
	rentMonth?: number | null;
	rentYear?: number | null;
	paymentMethod: PaymentMethod;
	customRentUpfrontPaymentDeadline?: IsoDate;
}

export type PaymentTransaction = (
	| {
			featureId: FeatureType.BalanceBoost | FeatureType.InstaMoney;
			userRentId: null;
			cashAdvanceId: string;
			landlordPaymentId: null;
			userId: string;
			landlordId: null;
	  }
	| {
			featureId: FeatureType.CustomRent | FeatureType.SecuredLineOfCredit;
			userRentId: string;
			cashAdvanceId: null;
			landlordPaymentId: null;
			userId: string;
			landlordId: null;
	  }
	| {
			featureId: FeatureType.UnifiedPayments;
			userRentId: string;
			cashAdvanceId: null;
			landlordPaymentId: null;
			userId: string;
			landlordId: null;
	  }
	| {
			featureId:
				| FeatureType.MicrodepositVerification
				| FeatureType.OtherPayment
				| FeatureType.CreditReportingRental
				| FeatureType.CreditReportingRentHistoryUpsell
				| FeatureType.CreditReportingLineOfCredit;
			userRentId: null;
			cashAdvanceId: null;
			landlordPaymentId: null;
			userId: string;
			landlordId: null;
	  }
	| {
			featureId: FeatureType.LandlordPayment;
			userRentId: null;
			cashAdvanceId: null;
			landlordPaymentId: string;
			userId: null;
			landlordId: string;
	  }
	| {
			featureId: FeatureType.ZumWallet;
			userRentId: null;
			cashAdvanceId: null;
			landlordPaymentId: null;
			userId: null;
			landlordId: null;
	  }
) & {
	id: string;
	originatingId: string;
	createdAt: Date;
	updatedAt: Date;
	effectiveDate: IsoDate;
	confirmationMessage: string;
	amount: number;
	feeAmount: number;
	baseAmount: number;
	status: TransactionStatus;
	transactionType: TransactionType;
	accountId: string;
	externalPaymentId: string | null;
	processingRetries: number;
	syncProblem: string;
	lastSyncAt: Date;
	paymentMethod: PaymentMethod;
	provider?: PaymentProvider | null;
	parentId?: string | null;
	description: string | null;
	ledgerEntryId: string | null;
	paymentPlanId: string | null;
	tradelineId: string | null;
	metadata?: PaymentMetadata | null;
};

export interface Installment {
	id: string;
	effectiveDate: IsoDate;

	amountCents: number;
	feeAmountCents: number;
	baseAmountCents: number;
	paymentMethodFeeCents: number;

	status: TransactionStatus;
	paymentMethod: PaymentMethod;
	transactionType: TransactionType;
	parentId?: string;
	hasChildPayments: boolean;
	syncProblem: string | null;

	description: string | null;
	ledgerEntryId: LedgerEntry['id'] | null;
	createdAt: string; // iso date time string, not a date for be/frontend compatibility
	paymentPlanId: string | null;
	paymentProvider: PaymentProvider | null;
}

export interface UserLedgerItem {
	id: string;
	effectiveDate: IsoDate;
	sortingDate: string;
	creditCents?: number;
	debitCents?: number;
	balanceCents: number;
	description: string;
	parentDescription?: string;
	installment?: Installment;
	rentChargeType?: RentChargeType;
	missedCount?: number;
}
