import React from 'react';
import { Outlet } from 'react-router-dom';

import { ResidencyOnboardingProvider } from '../../contexts';
import { links } from '../paths';

export const ResidencyChangeWrapper = () => (
	<ResidencyOnboardingProvider
		links={{
			recommendedUnits: links.ACCOUNT.RESIDENCY.RECOMMENDED_UNITS,
			manualAddress: links.ACCOUNT.RESIDENCY.MANUAL_ADDRESS,
			selectUnit: links.ACCOUNT.RESIDENCY.SELECT_UNIT,
			success: links.ACCOUNT.RESIDENCY.LANDLORD_CONNECTED,
			done: links.ACCOUNT.DASHBOARD,
		}}
		variant="account"
	>
		<Outlet />
	</ResidencyOnboardingProvider>
);
