import { css } from 'styled-components';

import { colors } from '../../variables';

export const ternaryTitleCss = css`
	font-size: 3.2rem;
	line-height: 4rem;
	color: ${colors.teal};
	font-weight: 700;
	margin-bottom: 3rem;

	@media (max-width: 450px) {
		font-size: 2rem;
		line-height: 3rem;
	}
`;
