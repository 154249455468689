import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Navigate, useNavigate } from 'react-router-dom';
import directDebitOk from 'url:../../static/shapes/connect-bank.png';

import { BusinessEventType } from '../../../../../functions/src/shared/business-events';

import { getEnvironment } from '../../../shared/environment';

import { Box, Spacing } from '../../../base-ui/components';

import { useInterface, useUserData } from '../../contexts/hooks';
import { paths } from '../../Router/paths';
import { OnboardingSuccess } from '../../templates/onboarding-success';
import { useTrackOnboardingEvent } from '../../utils/track-onboarding-event';
import { DirectDebitCardCancelModal } from './direct-debit-card-cancel-modal';

export const DirectDebitSetup = () => {
	const navigate = useNavigate();
	const trackEvent = useTrackOnboardingEvent();
	const { setModal } = useInterface();
	const { user } = useUserData();

	function handleNext() {
		trackEvent.mutate(
			{ type: BusinessEventType.OnboardingCustomRentDirectDebitStart },
			{
				onSuccess: () => {
					navigate(`../${paths.REGISTRATION.DIRECT_DEBIT.CARD_DETAILS}`);
				},
			},
		);
	}
	function handleDecline() {
		setModal(<DirectDebitCardCancelModal />);
	}

	if (
		user?.paymentCardId ||
		(getEnvironment() === 'production' && !user?.email.includes('directdebitenabled@myzenbase.com'))
	) {
		// eslint-disable-next-line no-console
		console.log('User not eligible for direct debit setup, redirecting to home page', {
			paymentCardId: user?.paymentCardId,
			landlordId: user?.pmsResidency?.landlord?.id,
		});
		return <Navigate to="/" />;
	}

	return (
		<>
			<Box isColumn verticalCenter>
				<img src={directDebitOk} alt="" width="335" />
				<Spacing $size="m" />

				<OnboardingSuccess
					title={
						<FormattedMessage
							defaultMessage="Worry free payments"
							id="custom-rent-onboarding.direct-debit-setup.title"
						/>
					}
					subtitle={
						<FormattedMessage
							defaultMessage="Never worry about missing a payment or paying NSF fees with your debit card. For just 0.6% of your payment amount, you can make your Zenbase payments hassle and worry free."
							id="custom-rent-onboarding.direct-debit-setup.text"
						/>
					}
					cta={
						<FormattedMessage
							defaultMessage="Use debit card"
							id="custom-rent-onboarding.direct-debit-setup.confirm"
						/>
					}
					secondaryCta={
						<FormattedMessage
							defaultMessage="No, I don’t want to pay with debit card"
							id="custom-rent-onboarding.direct-debit-setup.decline"
						/>
					}
					ctaTestId="direct-debit-setup-continue-button"
					onNext={handleNext}
					onSecondaryCta={handleDecline}
				/>
			</Box>
		</>
	);
};
