import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import congrats from 'url:../../static/shapes/congrats.png';
import learning from 'url:../../static/shapes/learning.png';
import payToZenbase from 'url:../../static/shapes/pay-to-zenbase.png';
import reschedule from 'url:../../static/shapes/reschedule-etransfer.png';
import support from 'url:../../static/shapes/support.png';

import { Box, Spacing, Text } from '../../../base-ui/components';

import { ImgPreload } from '../../components/img-preload';
import { links } from '../../Router/paths';
import { OnboardingSuccess } from '../../templates/onboarding-success';

export const CustomRentOnboardingSuccess1 = () => {
	const navigate = useNavigate();

	const handleNext = () => {
		navigate(links.REGISTRATION.ONBOARDING_SUCCESS.LANDLORD);
	};

	return (
		<>
			<Box isColumn verticalCenter>
				<img src={congrats} alt="" width="335" />
				<Spacing $size="m" />

				<OnboardingSuccess
					title={
						<FormattedMessage
							defaultMessage="Congrats for taking control of your rent."
							id="custom-rent-onboarding-success1.title"
						/>
					}
					subtitle={
						<FormattedMessage
							defaultMessage="You now have full control of your rent payment schedule."
							id="custom-rent-onboarding-success1.subtitle"
						/>
					}
					beforeCta={
						<>
							<img src={learning} alt="" width="180" />
							<Spacing $size="m" />
							<Text center color="title">
								<FormattedMessage
									defaultMessage="Here are a few things to remember."
									id="credit-builder-payment-completed-learning-title"
								/>
							</Text>
							<Spacing $size="l" />
						</>
					}
					cta={<FormattedMessage defaultMessage="Next" id="common.next" />}
					ctaTestId="onboarding-success-cr-step1-continue-button"
					onNext={handleNext}
					backButton={false}
				/>
			</Box>

			{/* preload next steps images */}
			<ImgPreload src={payToZenbase} />
			<ImgPreload src={reschedule} />
			<ImgPreload src={support} />
		</>
	);
};
