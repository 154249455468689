import React, { useEffect, useState } from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';

import { BusinessEventType } from '../../../../../../functions/src/shared/business-events';
import { PropertyResidentStatus } from '../../../../../../functions/src/shared/pms';
import { RentMonth } from '../../../../../../functions/src/shared/rent-month';

import { FormattedCents } from '../../../../shared/components/formatted-cents';
import { triggerChatWidget } from '../../../../shared/zendesk';

import { Paragraph } from '../../../../base-ui/components';
import { ConfigurationModal } from '../../../../base-ui/components/Modal/modal';

import { useInterface, useUserData } from '../../../contexts/hooks';
import { useTrackOnboardingEvent } from '../../../utils/track-onboarding-event';

export const CustomRentEarlyMoveInModal = () => {
	const [shown, setShown] = useState(false);
	const { onboardingEvents, home } = useUserData();
	const { setModal, closeModal } = useInterface();
	const trackEvent = useTrackOnboardingEvent();

	let modal: null | React.ReactNode = null;
	const confirmedModal = BusinessEventType.OnboardingCustomRentEarlyMoveInPaymentInfoConfirmed in onboardingEvents;

	if (
		!confirmedModal &&
		home?.earlyMoveIn &&
		home.activeRentConfig &&
		home?.residency?.residentStatus === PropertyResidentStatus.Future
	) {
		modal = (
			<ConfigurationModal
				headerText={
					<FormattedMessage
						defaultMessage="Make your rent payment before you move in"
						id="custom-rent-future-modal.title"
					/>
				}
				contentText={
					<>
						<Paragraph>
							<FormattedMessage
								defaultMessage="{landlordName} requires you to make your rent payment before your move-in date."
								id="custom-rent-future-modal.text-1"
								values={{
									landlordName: <i>{home?.residency?.landlordName}</i>,
								}}
							/>
						</Paragraph>
						<Paragraph>
							<FormattedMessage
								defaultMessage="If you are required to make a prorated payment for {currentMonth} e-Transfer your prorated payment to payments@myzenbase.com before splitting your {nextMonth} rent."
								id="custom-rent-future-modal.text-2"
								values={{
									currentMonth: (
										<FormattedDate value={RentMonth.current().lastDayAsDate()} month="long" />
									),
									nextMonth: (
										<FormattedDate
											value={RentMonth.fromDbParams(
												home.activeRentConfig.rentMonth,
											).lastDayAsDate()}
											month="long"
										/>
									),
									landlordName: <i>{home?.residency?.landlordName}</i>,
								}}
							/>
						</Paragraph>
						<Paragraph>
							<FormattedMessage
								defaultMessage="If your 1st rent payment is for {nextMonth} then e-Transfer your initial payment of {blockingPayment} to payments@myzenbase.com before your move in date."
								id="custom-rent-future-modal.text-3"
								values={{
									nextMonth: (
										<FormattedDate
											value={RentMonth.fromDbParams(
												home.activeRentConfig.rentMonth,
											).lastDayAsDate()}
											month="long"
										/>
									),
									blockingPayment: home?.activeRentConfig?.rentAmountCents && (
										<FormattedCents value={home?.activeRentConfig?.rentAmountCents} />
									),
								}}
							/>
						</Paragraph>
					</>
				}
				buttonText={<FormattedMessage defaultMessage="I UNDERSTAND" id="custom-rent-future-modal.ok" />}
				altButtonText={
					<FormattedMessage defaultMessage="Contact Support" id="custom-rent-future-modal.contact-support" />
				}
				altButtonHandler={() => {
					triggerChatWidget();
					closeModal();
				}}
				buttonHandler={() => {
					trackEvent.mutate(
						{ type: BusinessEventType.OnboardingCustomRentEarlyMoveInPaymentInfoConfirmed },
						{
							onSuccess() {
								closeModal();
							},
						},
					);
				}}
			/>
		);
	}

	useEffect(() => {
		if (modal && !shown) {
			setModal(modal);
			setShown(true);
			trackEvent.mutate({ type: BusinessEventType.OnboardingCustomRentEarlyMoveInPaymentInfoStart });
		}
	}, [modal, shown]);

	return modal;
};
