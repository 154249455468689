import React from 'react';
import { FormattedMessage } from 'react-intl';
import support from 'url:../../static/shapes/support.png';

import { BusinessEventType } from '../../../../../functions/src/shared/business-events';

import { OnboardingSuccess } from '../../templates/onboarding-success';
import { useTrackOnboardingEvent } from '../../utils/track-onboarding-event';

export const CustomRentOnboardingSuccess4 = () => {
	const trackOnboardingEvent = useTrackOnboardingEvent();

	const handleNext = async () => {
		await trackOnboardingEvent.mutateAsync({
			type: BusinessEventType.OnboardingSuccessEnd,
		});
		// no navigate here, it will happen by updated user state in router
	};

	return (
		<OnboardingSuccess
			imageSrc={support}
			title={
				<>
					<FormattedMessage
						defaultMessage="Reschedule payments to stop NSF's"
						id="custom-rent-onboarding-success4.reschedule-payments"
					/>
				</>
			}
			subtitle={
				<>
					<FormattedMessage
						defaultMessage="Our reminder SMS makes this easy"
						id="custom-rent-onboarding-success4.reminder-sms"
					/>
					,{' '}
					<FormattedMessage
						defaultMessage="reschedule your payments as often as you'd like in the same month."
						id="custom-rent-onboarding-success4.reschedule-payments-month"
					/>
				</>
			}
			ctaTestId="onboarding-success-cr-step4-continue-button"
			onNext={handleNext}
		/>
	);
};
