import styled from 'styled-components';

import { colors } from '../../../base-ui/variables';

export const Carousel = styled.div`
	width: 40rem;
	overflow: hidden;
	position: relative;
	height: 55rem;
	@media (max-width: 450px) {
		width: 100%;
		height: 45rem;
	}
`;

export const Panel = styled.div`
	margin-bottom: 3rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: 44.6rem;
	margin: 0 auto;
	flex-shrink: 0;
	padding: 0 2rem;
	display: flex;
	position: absolute;
	top: 0;
`;
export const PanelContent = styled.div<{ $visible: boolean }>`
	opacity: ${(props) => (props.$visible ? '1' : '0')};
	transition: opacity 250ms;
	display: block;
	min-height: 60rem;

	img {
		user-select: none;
	}
`;

export const Dot = styled.div<{ $active: boolean }>`
	width: 2.4rem;
	height: 2.4rem;
	padding: 0.6rem;
	border-radius: 50%;
	position: relative;
	cursor: pointer;
	&:hover::after {
		background-color: ${colors.green};
	}

	&::after {
		display: block;
		content: '';
		transition: background-color 150ms;
		width: 1.2rem;
		height: 1.2rem;
		top: 0.6rem;
		left: 0.6rem;
		position: absolute;
		background: ${({ $active }) => ($active ? colors.green : colors.lightGray)};
		border-radius: 50%;
	}
`;

export const Controls = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	padding: 0 2rem 3.6rem;
`;

export const Indicators = styled.div`
	display: flex;
	align-items: center;
`;

export const ControlButton = styled.div`
	width: 4rem;
	height: 4rem;
	background: ${colors.green};
	border-radius: 50%;
	cursor: pointer;
	&:hover {
		background: ${colors.greenHover};
	}
`;
