import React from 'react';
import styled from 'styled-components';
import houseLeft from 'url:./houseLeft@2x.png';

export const StyledHouseLeft = styled.div`
	position: absolute;
`;

export const HouseLeft = () => {
	return (
		<StyledHouseLeft>
			<img src={houseLeft} alt="" width="557px" height="517px" />
		</StyledHouseLeft>
	);
};
