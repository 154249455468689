import React, { useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import email from 'url:../../static/shapes/verify-email.png';
import { z } from 'zod';

import {
	Alert,
	AlertText,
	AlertTitle,
	Button,
	Center,
	Footer,
	FormInput,
	Spacing,
	Text,
} from '../../../base-ui/components';

import { BackButton } from '../../components/back-button';
import { useFirebase } from '../../contexts/Firebase';
import { links } from '../../Router/paths';
import { useAsyncHandler } from '../../utils/hooks/useAsyncHandler';

const schema = z.object({
	email: z.string().email().max(50),
});

type Schema = z.infer<typeof schema>;

const ImagePanel = styled.div`
	margin-bottom: 2rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: 44.6rem;
`;

export const ForgotPassword = () => {
	const { handlers } = useFirebase();
	const intl = useIntl();

	const [reSendSuccess, setReSendSuccess] = useState(false);
	const [handlePasswordResetRequest, { loading, error, data: success }] = useAsyncHandler(
		handlers.handlePasswordResetRequest,
	);

	const form = useForm<Schema>({
		resolver: zodResolver(schema),
		mode: 'onTouched',
	});

	const handleSubmit = async (input: Schema) => {
		await handlePasswordResetRequest(input.email).catch(() => null);
	};

	const handleReSend = () => {
		if (success?.email) {
			handleSubmit({ email: success?.email }).then(() => {
				setReSendSuccess(true);
			});
		}
	};

	if (success?.email) {
		return (
			<Center>
				<ImagePanel>
					<img src={email} />
					<Text type="title" center>
						<FormattedMessage
							defaultMessage="Reset link sent to your e-mail"
							id="forgot-password.reset-link-sent"
						/>
					</Text>

					<Spacing $size="s" />

					<Text center>
						<FormattedMessage
							defaultMessage="Please check your email and enter the code received. Please also check your spam box."
							id="forgot-password.check-email"
						/>
					</Text>
					<Spacing $size="xl" />
					<Text center>
						<FormattedMessage
							defaultMessage="Haven’t received the code?"
							id="forgot-password.havent-received"
						/>
					</Text>
				</ImagePanel>

				{!!error && (
					<Alert>
						<AlertTitle>
							<FormattedMessage defaultMessage="An error ocurred" id="alert-error.an-error-occurred" />
						</AlertTitle>
						<AlertText>
							<FormattedMessage
								defaultMessage="Something went wrong at our end. Don't worry, we'll fix soon."
								id="forgot-password.error-text"
							/>
						</AlertText>
					</Alert>
				)}
				<Footer>
					<Button type="link" onClick={handleReSend} disabled={loading || reSendSuccess}>
						{reSendSuccess ? (
							<FormattedMessage defaultMessage="Code has been resend" id="forgot-password.code-resend" />
						) : (
							<FormattedMessage defaultMessage="Resend Code" id="forgot-password.resend-code" />
						)}
					</Button>
				</Footer>
			</Center>
		);
	}

	return (
		<>
			<BackButton to={links.DEFAULT.SIGN_IN} testId="forgot-password-button-back" />

			<Text type="title" testId="forgot-password-title">
				<FormattedMessage defaultMessage="Reset Account Password" id="forgot-password.reset-password" />
			</Text>
			<Spacing $size="s" />
			<Text>
				<FormattedMessage
					defaultMessage="Please tell us the email you used to create your Zenbase account."
					id="forgot-password.enter-email"
				/>
			</Text>

			<Spacing $size="m" />

			<FormProvider {...form}>
				<form onSubmit={form.handleSubmit(handleSubmit)}>
					<FormInput
						name="email"
						placeholder={intl.formatMessage({
							defaultMessage: 'Email',
							id: 'forgot-password.email-placeholder',
						})}
						testId="forgot-password-input-email"
					/>

					{!!error && (
						<Text>
							<FormattedMessage
								defaultMessage="Failed to request password reset link."
								id="forgot-password.failed-reset-link"
							/>
						</Text>
					)}

					<Spacing $size="l" />
					<Footer>
						<Button htmlType="submit" disabled={loading} testId="forgot-password-button-continue">
							{loading ? (
								<FormattedMessage defaultMessage="Submitting" id="common.submitting" />
							) : (
								<FormattedMessage defaultMessage="Continue" id="common.continue" />
							)}
						</Button>
					</Footer>
				</form>
			</FormProvider>
		</>
	);
};
