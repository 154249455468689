'use strict';

/**
 * ================================
 * Useful utility types that doesn't have anything to do with tRPC in particular
 * ================================
 */ /**
 * @public
 */ const ERROR_SYMBOL = Symbol('TypeError');

exports.ERROR_SYMBOL = ERROR_SYMBOL;
