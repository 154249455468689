import React from 'react';
import { FormattedMessage } from 'react-intl';
import modalAlert from 'url:../../static/shapes/modal-alert.png';

import { Box, Spacing } from '../../../base-ui/components';

import { OnboardingSuccess } from '../../templates/onboarding-success';

export const MonerisErrorVerification = ({ onNext }: { onNext: () => void }) => {
	return (
		<Box isColumn verticalCenter>
			<img src={modalAlert} alt="" width="100" />
			<Spacing $size="m" />

			<OnboardingSuccess
				title={<FormattedMessage id="moneris.error" defaultMessage="Payment Verification Failed" />}
				subtitle={
					<>
						<p>
							<FormattedMessage
								id="moneris.error-message-v2a"
								defaultMessage="It seems there was an issue verifying your payment card. <b>Please note that we only support debit cards</b>, and credit cards are not accepted."
							/>
						</p>
						<br />
						<p>
							<FormattedMessage
								id="moneris.error-message-v2b"
								defaultMessage="If the problem persists, please contact our support team for assistance."
							/>
						</p>
					</>
				}
				cta={<FormattedMessage id="moneris.try-again" defaultMessage="Try again" />}
				onNext={() => onNext()}
				ctaTestId="direct-debit-card-fail-try-again-button"
				backButton={false}
			/>
		</Box>
	);
};
