import React from 'react';
import { FormattedMessage } from 'react-intl';
import modalAlert from 'url:../../static/shapes/modal-alert.png';

import { Box, Spacing } from '../../../base-ui/components';

import { OnboardingSuccess } from '../../templates/onboarding-success';

type Props = {
	onNext: () => void;
	canRetry?: boolean;
	errorCode?: string;
};
export const MonerisErrorCharge = ({ onNext, errorCode, canRetry }: Props) => {
	return (
		<Box isColumn verticalCenter>
			<img src={modalAlert} alt="" width="100" />
			<Spacing $size="m" />

			<OnboardingSuccess
				title={<FormattedMessage id="moneris.error-charge-title" defaultMessage="Payment Failed" />}
				subtitle={
					<>
						<p>
							<FormattedMessage
								id="moneris.error-charge-message-1"
								defaultMessage="It seems there was an issue charging your card. Please try again."
							/>
						</p>
						<br />
						<p>
							{errorCode && (
								<FormattedMessage
									id="moneris.error-charge-message-2"
									defaultMessage="Error: {errorCode}"
									values={{
										errorCode,
									}}
								/>
							)}
							{!errorCode && (
								<FormattedMessage id="moneris.error-charge-message-2" defaultMessage="Error: unknown" />
							)}
						</p>
						<br />
						<p>
							<FormattedMessage
								id="moneris.error-charge-message-3"
								defaultMessage="If the problem persists, please contact our support team for assistance."
							/>
						</p>
					</>
				}
				cta={
					<>
						{canRetry && <FormattedMessage id="moneris.try-again" defaultMessage="Try again" />}
						{!canRetry && <FormattedMessage id="moneris.close" defaultMessage="Close" />}
					</>
				}
				onNext={() => onNext()}
				ctaTestId="direct-debit-card-fail-try-again-button"
			/>
		</Box>
	);
};
