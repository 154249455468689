import React from 'react';
import { Outlet } from 'react-router-dom';

import { ResidencyOnboardingProvider } from '../../contexts';
import { links } from '../paths';

export const ResidencyOnboardingWrapper = () => (
	<ResidencyOnboardingProvider
		links={{
			recommendedUnits: links.REGISTRATION.RESIDENCY.RECOMMENDED_UNITS,
			manualAddress: links.REGISTRATION.RESIDENCY.MANUAL_ADDRESS,
			selectUnit: links.REGISTRATION.RESIDENCY.SELECT_UNIT,
			success: links.REGISTRATION.RESIDENCY.LANDLORD_CONNECTED,
			done: links.REGISTRATION.BANK.CONNECT_BANK,
		}}
		variant="onboarding"
	>
		<Outlet />
	</ResidencyOnboardingProvider>
);
