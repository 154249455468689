import { css } from 'styled-components';

import { colors } from '../../variables';

export const extraLargeTitleCss = css`
	font-size: 4.8rem;
	line-height: 1.5;
	font-weight: 700;
	color: ${colors.green};
	margin-top: 3rem;

	@media (max-width: 450px) {
		font-size: 3.2rem;
		line-height: 4.9rem;
	}
`;
