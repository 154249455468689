import { publicConfig } from '../../../functions/src/shared/config';

import { leaseAgreementStorage } from '../app/contexts/Firebase/firebaseApi';

export const uploadLeaseAgreementsOptions = (userId: string, otherMetadata: { manualResidencyId?: string } = {}) => {
	const manualResidencyMetadata = otherMetadata.manualResidencyId
		? { [publicConfig.leaseAgreements.uploadMetadata.manualResidencyId]: otherMetadata.manualResidencyId }
		: null;

	return {
		storage: leaseAgreementStorage,
		pathPrefix: `${userId}/`,
		metadata: {
			[publicConfig.leaseAgreements.uploadMetadata.userId]: userId,
			...(manualResidencyMetadata ?? {}),
		},
	};
};
