import { useMemo } from 'react';
import { IntlShape } from 'react-intl';
import z from 'zod';

import { Country } from '../../shared/country';

import { landlordInputZodSchema } from './manual-address-landlord-input';

export const useManualAddressSchema = (intl: IntlShape, opts?: { onlyCA?: boolean }) =>
	useMemo(
		() => ({
			schema: z.object({
				streetAddress: z.string(),
				apartment: z.string().nullish(),
				postalCode: z.string(),
				city: z.string(),
				landlord: landlordInputZodSchema,
				employer: z.string().optional(),
				country: z.string(),
				state: z.string(),
			}),
			CountryLabelMap: {
				[Country.CA]: intl.formatMessage({
					defaultMessage: 'Canada',
					id: 'manual-address.canada',
				}),
				...(opts?.onlyCA
					? {}
					: {
							[Country.US]: intl.formatMessage({
								defaultMessage: 'United States',
								id: 'manual-address.us',
							}),
						}),
			},
		}),
		[intl, opts],
	);
