import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import onboarding1 from 'url:../../static/shapes/onboarding-1.png';
import onboarding2 from 'url:../../static/shapes/onboarding-2.png';
import onboarding3 from 'url:../../static/shapes/onboarding-3.png';

import { Icon, Spacing, Text } from '../../../base-ui/components';

import { Carousel, ControlButton, Controls, Dot, Indicators, Panel, PanelContent } from './carousel.styles';

export const OnboardingCarousel = () => {
	const [activeIndex, setActiveIndex] = useState(0);

	const handleNext = () => {
		if (activeIndex === 2) {
			setActiveIndex(0);
		} else {
			setActiveIndex(activeIndex + 1);
		}
	};
	const handlePrev = () => {
		if (activeIndex === 0) {
			setActiveIndex(2);
		} else {
			setActiveIndex(activeIndex - 1);
		}
	};

	useEffect(() => {
		const interval = setInterval(() => {
			handleNext();
		}, 15000);

		return () => {
			clearInterval(interval);
		};
	}, [activeIndex]);

	return (
		<>
			<Carousel>
				<Panel>
					<PanelContent $visible={activeIndex === 0} onClick={handleNext}>
						<img src={onboarding1} width={'446px'} height={'400px'} />
						<Text type="title" center>
							<FormattedMessage
								defaultMessage="The most flexible rent payments ever"
								id="credit-builder-payment-completed-flexible-rent-payments-title"
							/>
						</Text>
						<Spacing $size="l" />
						<Text center>
							<FormattedMessage
								defaultMessage="Split your rent into smaller payments and build credit."
								id="onboarding-carousel.split-rent-credit"
							/>
						</Text>
					</PanelContent>
				</Panel>
				<Panel>
					<PanelContent $visible={activeIndex === 1} onClick={handleNext}>
						<img src={onboarding2} width={'446px'} height={'400px'} />
						<Text type="title" center>
							<FormattedMessage
								defaultMessage="Boost your bank balance"
								id="onboarding-carousel.boost-bank-balance"
							/>
						</Text>
						<Spacing $size="l" />
						<Text center>
							<FormattedMessage
								defaultMessage="Automatically budget and free up cash between paychecks."
								id="onboarding-carousel.automatically-budget"
							/>
						</Text>
					</PanelContent>
				</Panel>
				<Panel>
					<PanelContent $visible={activeIndex === 2} onClick={handleNext}>
						<img src={onboarding3} width={'446px'} height={'400px'} />
						<Text type="title" center>
							<FormattedMessage
								defaultMessage="Build your credit"
								id="onboarding-carousel.build-credit"
							/>
						</Text>
						<Spacing $size="l" />
						<Text center>
							<FormattedMessage
								defaultMessage="Get rewarded for timely rent payments and improve your payment history."
								id="onboarding-carousel.rewarded-rent-payments"
							/>
						</Text>
					</PanelContent>
				</Panel>
			</Carousel>
			<Controls>
				<ControlButton onClick={handlePrev}>
					<Icon icon="LeftArrow" color="white" />
				</ControlButton>
				<Indicators>
					<Dot $active={activeIndex === 0} onClick={() => setActiveIndex(0)} />
					<Dot $active={activeIndex === 1} onClick={() => setActiveIndex(1)} />
					<Dot $active={activeIndex === 2} onClick={() => setActiveIndex(2)} />
				</Indicators>
				<ControlButton onClick={handleNext}>
					<Icon icon="RightArrow" color="white" />
				</ControlButton>
			</Controls>
		</>
	);
};
