import React, { useEffect, useMemo } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { z } from 'zod';

import { typedIncludes } from '../../../../../functions/src/shared/utils';

import { Button, Footer, FormInput, Radio, RadioGroup, Spacing, Text } from '../../../base-ui/components';

import { BackButton } from '../../components/back-button';
import { useInstaMoney } from '../../contexts/InstaMoneyContext/InstaMoneyContext';
import { links } from '../../Router/paths';

const otherOption = 'Other';

const noTipReasons = [
	'I don’t think InstaMoney deserves a tip',
	'I don’t like giving tips',
	'I can’t afford to leave a tip',
	'I’m not interested in helping myself and others by leaving a tip',
	otherOption,
] as const;

const RadioGroupCustom = styled(RadioGroup)`
	grid-template-columns: 1fr;
`;

export const WhyNoTip = () => {
	const navigate = useNavigate();
	const { setNoTipReason, noTipReason, setTipAmount } = useInstaMoney();
	const intl = useIntl();
	const { schema, getFormData } = useMemo(() => {
		const schemaMemo = z
			.object({
				selectedOption: z.enum(noTipReasons, {
					invalid_type_error: intl.formatMessage({
						defaultMessage: 'Please choose one of the above options',
						id: 'common.choose-one-of-the-above',
					}),
				}),
				other: z.string().optional(),
			})
			.superRefine((data, ctx) => {
				if (data.selectedOption === otherOption && !data.other) {
					ctx.addIssue({
						code: z.ZodIssueCode.custom,
						path: ['other'],
						message: intl.formatMessage({
							defaultMessage: 'Required field',
							id: 'common.required-field',
						}),
					});
				}
			});
		const getFormDataMemo = (newNoTipReason: string | undefined) => {
			if (!newNoTipReason) return;

			if (typedIncludes(noTipReasons, newNoTipReason)) {
				return schemaMemo.parse({ selectedOption: newNoTipReason });
			}
			return schemaMemo.parse({ selectedOption: otherOption, other: newNoTipReason });
		};

		return {
			schema: schemaMemo,
			getFormData: getFormDataMemo,
		};
	}, [intl]);
	type FormData = z.infer<typeof schema>;

	const form = useForm<FormData>({
		resolver: zodResolver(schema),
		defaultValues: getFormData(noTipReason),
	});

	useEffect(() => {
		const formData = getFormData(noTipReason);
		if (formData) form.reset(formData);
	}, [noTipReason]);

	const showOtherInput = form.watch('selectedOption') === otherOption;

	const submit = (data: FormData) => {
		if (data.selectedOption === otherOption && data.other) {
			setNoTipReason(data.other);
		} else {
			setNoTipReason(data.selectedOption);
		}
		setTipAmount('0');
		navigate(links.ACCOUNT.INSTA_MONEY.CONFIRM_PAYBACK_DATE);
	};

	return (
		<FormProvider {...form}>
			<form onSubmit={form.handleSubmit(submit)}>
				<BackButton to={links.ACCOUNT.INSTA_MONEY.TIP} />
				<Spacing $size="l" />
				<Text type="title">
					<FormattedMessage
						defaultMessage="Did we do something wrong?"
						id="why-no-tip.did-we-do-something-wrong"
					/>
				</Text>
				<Spacing $size="m" />
				<Text>
					<FormattedMessage
						defaultMessage="We hope that our fee free InstaMoney helps you when you need it the most. Your voluntary tip in exchange helps us to continue offering this service in the future. ❤️"
						id="why-no-tip.hope-that-our-fee-free-instamoney-helps-you"
					/>
				</Text>
				<Spacing $size="m" />
				<Text>
					<FormattedMessage
						defaultMessage="If you are still not interested in leaving a tip please let us know what didn’t work for you and we can work on it. 🙏"
						id="why-no-tip.if-you-are-still-not-interested-in-leaving-a-tip"
					/>
				</Text>

				<Spacing $size="l" />
				<RadioGroupCustom name="selectedOption">
					{noTipReasons.map((reason) => (
						<Radio {...form.register('selectedOption')} value={reason} key={reason} label={reason} />
					))}
				</RadioGroupCustom>

				{showOtherInput && <FormInput name="other" />}

				<Footer>
					<Button htmlType="submit" testId="instamoney-no-tip-button-continue">
						<FormattedMessage defaultMessage="Continue" id="common.continue" />
					</Button>
				</Footer>
			</form>
		</FormProvider>
	);
};
