import { useIntl } from 'react-intl';

import { UserLocale } from '../../../functions/src/shared/user';

const BASE = 'https://myzenbase.com';

const links = {
	terms: 'terms-of-use',
	privacy: 'privacy-policy',
	cbTerms: 'creditbuilder-terms-of-use',
	home: '',
};

function createLink(path: string, locale: string) {
	if (locale === UserLocale.FrenchCa) {
		return `${BASE}/fr/${path}-fr`;
	}
	return `${BASE}/${path}`;
}

export const useMyZenbaseLinks = () => {
	const intl = useIntl();

	return {
		terms: createLink(links.terms, intl.locale),
		privacy: createLink(links.privacy, intl.locale),
		cbTerms: createLink(links.cbTerms, intl.locale),
		home: createLink(links.home, UserLocale.EnglishCa),
	};
};
