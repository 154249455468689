import React, { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { Box, Button, Spacing, Text } from '../../../base-ui/components';

import { BankOnboardingState, Variant } from '../../contexts/BankOnboardingContext/BankOnboardingContext';
import { useInterface } from '../../contexts/Interface/InterfaceContext';

type Props = {
	links: BankOnboardingState['links'];
	variant: Variant;
};

const content: Record<
	Variant,
	{
		content: ReactNode;
	}
> = {
	'custom-rent': {
		content: (
			<FormattedMessage
				defaultMessage="Please note that if your bank account is manually entered you will not have access to our InstaMoney (fee-free cash advance) or BalanceAlert (low bank balance alert) features."
				id="connect-bank-manually-modal.custom-rent.content"
			/>
		),
	},
	'credit-builder': {
		content: (
			<FormattedMessage
				defaultMessage="If you connect manually, you will have to upload your bank statement each month to continue reporting your payments"
				id="connect-bank-manually-modal.credit-builder.content"
			/>
		),
	},
};

export const ConnectbankManuallyModal = ({ links, variant }: Props) => {
	const { closeModal } = useInterface();
	const navigate = useNavigate();

	const goToManualBankOnboarding = () => {
		closeModal();
		navigate(links.MANUAL_ENTER_BANK_DETAILS);
	};

	return (
		<Box isColumn verticalCenter>
			<Spacing $size="m" />
			<Text type="title">
				<FormattedMessage defaultMessage="Enter manually?" id="connect-bank-manually-modal.enter-manually" />
			</Text>
			<Spacing $size="l" />
			<Text type="small" center>
				{content[variant].content}
			</Text>
			<Spacing $size="l" />
			<Box justify="center">
				<Button onClick={goToManualBankOnboarding} testId="connect-bank-modal-button-connect-manually-confirm">
					<FormattedMessage defaultMessage="Confirm" id="common.confirm" />
				</Button>
			</Box>
			<Spacing $size="m" />
		</Box>
	);
};
