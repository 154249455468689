import React, { PropsWithChildren, ReactNode } from 'react';
import styled from 'styled-components';

import { colors } from '../../variables';
import { Divider } from '../Divider/Divider';
import { Text } from '../Text/Text';

const LabelWrapper = styled.div`
	display: flex;
	justify-content: space-between;
`;

const Label = styled(Text)`
	color: ${colors.textLight};
`;

const LabelActionWrapper = styled.div`
	cursor: pointer;
`;

const Value = styled(Text)`
	margin-top: 1rem;
`;

const StyledDivider = styled(Divider)`
	margin: 1.5rem 0;
`;

export const PaperSection = ({
	children,
	label,
	labelAction = null,
	divider = true,
}: PropsWithChildren<{ label: string | ReactNode; labelAction?: ReactNode; divider?: boolean }>) => (
	<>
		<LabelWrapper>
			<Label>{label}</Label>
			{labelAction && <LabelActionWrapper>{labelAction}</LabelActionWrapper>}
		</LabelWrapper>
		<Value>{children}</Value>
		{divider && <StyledDivider />}
	</>
);
