import React from 'react';

import { CommonIconProps } from './types';

export const EyeClosed = ({ fill }: CommonIconProps) => {
	return (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M3.59543 12.544C3.34354 12.2151 2.87278 12.1528 2.54395 12.4047C2.21513 12.6565 2.15276 13.1273 2.40465 13.4561C2.82994 14.0113 3.42532 14.736 4.15857 15.4924C4.11011 15.5562 4.07113 15.6289 4.04439 15.7092L3.04439 18.7092C2.9134 19.1021 3.12577 19.5269 3.51873 19.6578C3.91168 19.7888 4.33642 19.5765 4.46741 19.1835L5.32646 16.6063C5.57985 16.8287 5.84383 17.048 6.1176 17.2607C6.59759 17.6336 7.11414 17.9913 7.66217 18.311L6.71406 21.092C6.58039 21.484 6.78986 21.9102 7.18191 22.0439C7.57396 22.1775 8.00014 21.9681 8.13381 21.576L9.01677 18.9862C9.72511 19.2787 10.4724 19.4918 11.25 19.5869V22.2207C11.25 22.635 11.5858 22.9707 12 22.9707C12.4143 22.9707 12.75 22.635 12.75 22.2207V19.6173C13.6473 19.5435 14.5064 19.3092 15.3144 18.972L16.2022 21.576C16.3359 21.9681 16.762 22.1775 17.1541 22.0439C17.5462 21.9102 17.7556 21.484 17.622 21.092L16.6674 18.292C17.2034 17.9772 17.709 17.6262 18.1795 17.2607C18.4641 17.0395 18.7382 16.8113 19.0007 16.5799L19.8686 19.1835C19.9996 19.5765 20.4243 19.7888 20.8173 19.6578C21.2102 19.5269 21.4226 19.1021 21.2916 18.7092L20.2916 15.7092C20.2617 15.6195 20.2166 15.5392 20.16 15.4703C20.8834 14.7218 21.4713 14.0059 21.8925 13.4561C22.1443 13.1273 22.082 12.6565 21.7532 12.4047C21.4243 12.1528 20.9536 12.2151 20.7017 12.544C19.9842 13.4805 18.7749 14.8986 17.2592 16.0762C15.7351 17.2603 13.9776 18.1422 12.1486 18.1422C10.3195 18.1422 8.56202 17.2603 7.03788 16.0762C5.52219 14.8986 4.31287 13.4805 3.59543 12.544Z"
				fill={fill}
			/>
		</svg>
	);
};
