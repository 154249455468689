import React, { useState } from 'react';
import { times } from 'lodash';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';

import { Block, Button, Footer, Input, Spacing, Text } from '../../../base-ui/components';
import { BlockSkeleton, ScrollableBlockList } from '../../../base-ui/components/Block/Block';

import { BackButton } from '../../components/back-button';
import { useResidencyOnboarding } from '../../contexts/hooks';
import { RecommendedUnits } from '../../templates/recommended-units';

export const SelectProperty = () => {
	const navigate = useNavigate();
	const { state } = useLocation();

	const [selectedPropertyId, setSelectedPropertyId] = useState<string | null>();
	const [search, setSearch] = useState('');
	const [noneSelected, setNoneSelected] = useState(false);

	const {
		propertiesList,
		handleSelectedPropertyId,
		gotToManualAddressOrRecommended,
		recommendedUnitsWithFullMatch,
		handleSelectedUnit,
		handlePropertySearchQueryChange,
		isPending,
	} = useResidencyOnboarding();

	const handleBlockSelect = (id: string) => {
		setSelectedPropertyId(id);
		setNoneSelected(false);
	};

	const handleNoneSelected = () => {
		setSelectedPropertyId(null);
		setNoneSelected(true);
	};

	const handleContinue = () => {
		if (noneSelected) {
			gotToManualAddressOrRecommended();
		} else if (selectedPropertyId) {
			handleSelectedPropertyId(selectedPropertyId);
		}
	};

	const handlePropertySearch = (value: string) => {
		setSelectedPropertyId(null);
		setNoneSelected(false);

		setSearch(value);
		handlePropertySearchQueryChange(value);
	};

	const intl = useIntl();

	const filteredProperties = propertiesList ?? [];

	const recommendedUnitsHidden = typeof state === 'object' && state && 'recommendedUnitsHidden' in state;
	const recommendedUnitsWithFullMatchAvailable = Boolean(recommendedUnitsWithFullMatch.length);
	if (recommendedUnitsWithFullMatchAvailable && !recommendedUnitsHidden) {
		return (
			<RecommendedUnits
				recommendedUnits={recommendedUnitsWithFullMatch}
				handleContinue={(unitId) => {
					if (unitId) {
						handleSelectedUnit(unitId);
					} else {
						navigate('', { state: { recommendedUnitsHidden: true } });
					}
				}}
				isPending={isPending}
				backButtonDisabled
			/>
		);
	}

	const noPropertiesFound = search && filteredProperties.length === 0;

	return (
		<>
			<BackButton
				disabled={!recommendedUnitsWithFullMatchAvailable}
				onClick={() => {
					if (recommendedUnitsWithFullMatchAvailable) {
						navigate('', { state: {} });
					}
				}}
				testId="address-select-property-button-back"
			/>
			<Text type="title" testId="address-select-title">
				<FormattedMessage defaultMessage="Select your address" id="select-property.select-your-address" />
			</Text>
			<Spacing $size="m" />
			<Text testId="address-select-property-info">
				<FormattedMessage
					defaultMessage="You can search results by entering your address details."
					id="select-property.search-results"
				/>
			</Text>
			<Input
				placeholder={intl.formatMessage({
					defaultMessage: '123 Street, City',
					id: 'select-property.placeholder',
				})}
				value={search}
				onInputChange={handlePropertySearch}
				testId="address-select-property-input-search"
			></Input>
			<Spacing $size="s" />
			<ScrollableBlockList data-testid="address-select-property-list-property">
				{search &&
					filteredProperties.map(
						({ id, addressLine1, addressLine2, city, state: propertyState, postalCode }) => (
							<Block
								key={id}
								title={addressLine1}
								line1={addressLine2 || addressLine1}
								line2={[city, propertyState, postalCode].join(', ')}
								line3={''}
								onClick={() => handleBlockSelect(id)}
								selected={id === selectedPropertyId}
								testId={'address-select-property-option-property'}
							/>
						),
					)}
				{noPropertiesFound &&
					(isPending ? (
						times(5, () => <BlockSkeleton></BlockSkeleton>)
					) : (
						<Block
							selected={noneSelected}
							title={intl.formatMessage({
								defaultMessage: 'No properties found, add address manually',
								id: 'select-property.no-properties-found',
							})}
							onClick={handleNoneSelected}
							testId="address-select-property-option-none-of-the-above"
						/>
					))}
			</ScrollableBlockList>

			<Footer>
				<Button
					onClick={handleContinue}
					disabled={!selectedPropertyId && !noneSelected}
					testId="address-select-property-button-continue"
				>
					<FormattedMessage defaultMessage="Continue" id="common.continue" />
				</Button>
			</Footer>
		</>
	);
};
