import React from 'react';
import { FormattedDate, IntlShape } from 'react-intl';

import { RentMonth, RentMonthLike } from '../../../../functions/src/shared/rent-month';

export const formatRentMonthIntl = (intl: IntlShape, rentMonth: RentMonthLike | RentMonth) => {
	const rm = rentMonth instanceof RentMonth ? rentMonth : RentMonth.fromDbParams(rentMonth);
	return intl.formatDate(rm.lastDayAsDate(), { month: 'long', year: 'numeric' });
};

export const FormattedRentMonth = (props: { rentMonth?: RentMonthLike | RentMonth }) => {
	if (!props?.rentMonth) {
		return null;
	}
	const rentMonth = props.rentMonth instanceof RentMonth ? props.rentMonth : RentMonth.fromDbParams(props.rentMonth);
	return <FormattedDate value={rentMonth.lastDayAsDate()} month="long" year="numeric" />;
};
