import React, { useEffect, useMemo } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';

import { typedIncludes } from '../../../../../functions/src/shared/utils';

import { Button, Footer, FormInput, Radio, RadioGroup, Spacing, Text } from '../../../base-ui/components';

import { BackButton } from '../../components/back-button';
import { useInstaMoney } from '../../contexts/InstaMoneyContext/InstaMoneyContext';
import { links } from '../../Router/paths';

const otherOption = 'Other';

const predefinedTypesOfUse = [
	'Rent payment',
	'Utilities',
	'Phone & internet',
	'Debt payment',
	'Groceries',
	'Gas',
	otherOption,
] as const;

export const InstaMoneyUse = () => {
	const navigate = useNavigate();
	const { setUse, use } = useInstaMoney();
	const intl = useIntl();
	const { schema, getFormData } = useMemo(() => {
		const schemaMemo = z
			.object({
				selectedOption: z.enum(predefinedTypesOfUse, {
					invalid_type_error: intl.formatMessage({
						defaultMessage: 'Please choose one of the above options',
						id: 'common.choose-one-of-the-above',
					}),
				}),
				other: z.string().optional(),
			})
			.superRefine((data, ctx) => {
				if (data.selectedOption === otherOption && !data.other) {
					ctx.addIssue({
						code: z.ZodIssueCode.custom,
						path: ['other'],
						message: intl.formatMessage({ defaultMessage: 'Required field', id: 'common.required-field' }),
					});
				}
			});

		const getFormDataMemo = (useReason: string | undefined) => {
			if (!useReason) return;

			if (typedIncludes(predefinedTypesOfUse, useReason)) {
				return schemaMemo.parse({ selectedOption: useReason });
			}

			return schemaMemo.parse({ selectedOption: otherOption, other: useReason });
		};
		return {
			schema: schemaMemo,
			getFormData: getFormDataMemo,
		};
	}, [intl]);
	type FormData = z.infer<typeof schema>;

	const form = useForm<FormData>({
		resolver: zodResolver(schema),
	});
	const showOtherInput = form.watch('selectedOption') === otherOption;

	useEffect(() => {
		const formData = getFormData(use);
		if (formData) form.reset(formData);
	}, [use]);

	const submit = (data: FormData) => {
		if (data.selectedOption === otherOption && data.other) {
			setUse(data.other);
		} else {
			setUse(data.selectedOption);
		}

		navigate(links.ACCOUNT.INSTA_MONEY.CHOOSE_PAYBACK_DATE);
	};

	return (
		<FormProvider {...form}>
			<form onSubmit={form.handleSubmit(submit)}>
				<BackButton to={links.ACCOUNT.INSTA_MONEY.REQUEST} />
				<Spacing $size="l" />
				<Text type="title">
					<FormattedMessage
						defaultMessage="What will you use your cash advance for?"
						id="insta-money-use.what-will-you-use-your-cash-advance-for"
					/>
				</Text>
				<Spacing $size="s" />
				<Text>
					<FormattedMessage
						defaultMessage="Select what resonates with you most."
						id="insta-money-use.select-what-resonates-with-you-most"
					/>
				</Text>

				<Spacing $size="l" />

				<RadioGroup name="selectedOption">
					{predefinedTypesOfUse.map((useReason) => (
						<Radio
							{...form.register('selectedOption')}
							value={useReason}
							key={useReason}
							label={useReason}
						/>
					))}
				</RadioGroup>

				{showOtherInput && (
					<FormInput
						name="other"
						placeholder={intl.formatMessage({ defaultMessage: 'For', id: 'insta-money-use.for' })}
					/>
				)}

				<Spacing $size="m" />
				<Footer>
					<Button htmlType="submit" testId="instamoney-use-button-continue">
						<FormattedMessage defaultMessage="Continue" id="common.continue" />
					</Button>
				</Footer>
			</form>
		</FormProvider>
	);
};
